import React, {useEffect, useState} from "react";
import {Avatar, Box, Grid, TextField, Typography,} from "@mui/material";
import IRecipient from "../../../shared/types/IRecipient";
import Spacing from "../../../components/Spacing";
import {styled} from "@mui/material/styles";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import SubOperation from "../../../shared/types/SubOperation";
import {walletRepository} from "../../Wallet/repository/wallet.repository";
import RoundGradientButton from "../../../components/RoundGradientButton";
import {isEmpty} from "../../../shared/_utils/isEmpty";
import AmountField from "../../../components/AmountField";
import {t} from "i18next";

interface IPropsSendMoneyForm {
    user: IRecipient | null;
    balance: number;
    onSubmit: (params: any) => any;
    provider?: SubOperation | null;
    transferLoading: boolean
}

export interface ITransferForm {
    note: string | null;
    amount: string;
    provider: SubOperation;
    code?: string;
}

const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#C7C7C7",
        backgroundColor: "#C7C7C7",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#C7C7C7",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#C7C7C7",
        },
        "&:hover fieldset": {
            borderColor: "#C7C7C7",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#C7C7C7",
        },
    },
});

const SendMoneyForm = (props: IPropsSendMoneyForm) => {
    const {user, balance, onSubmit, provider, transferLoading} = props;

    const [showSuccessPopup, setShowSuccessPopup] = useState(true);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [loadingFeeCalculation, setLoadingFeeCalculation] =
        useState<boolean>(false);
    const [errorLoadingFeeCalculation, setErrorLoadingFeeCalculation] = useState<string | null>(null);
    const [topUpFee, setTopUpFee] = useState<number | null>(null);
    const [withFeeAmount, setWithFeeAmount] = useState<number | null>(null);
    const [transaction, setTransaction] = useState<string | null>(null);
    const [operatorName, setOperatorName] = useState<string | null>(null);

    useEffect(() => {
        getParams();
    }, []);

    useEffect(() => {
        setIsSubmitting(transferLoading)
    }, [transferLoading]);

    const {
        register,
        control,
        handleSubmit,
        formState: {errors, isValid},
        setValue,
        setError,
    } = useForm<ITransferForm>({mode: "onChange"});

    const handleTransferForm: SubmitHandler<ITransferForm> = async (data) => {

        // TO DO : UNCOMMENT
        if (!provider) {
            setError("amount", {
                type: "custom",
                message: "Aucun opérator sélectionné",
            });
            return;
        }

        data.provider = provider;
        const res = await onSubmit(data);

    };

    const onChangeAmount = async (amount: string) => {
        setLoadingFeeCalculation(true);
        if (!amount) {
            setTopUpFee(null);
            setWithFeeAmount(null);
            setLoadingFeeCalculation(false);
        }

        setValue("amount", amount);

        if (timer) {
            clearTimeout(timer);
        }

        if (null == transaction) {
            setLoadingFeeCalculation(false);
            return;
        }

        if (!user) {
            setLoadingFeeCalculation(false);
            return;
        }

        const newTimer = setTimeout(async () => {
            const res = await walletRepository.postTopUpFee(
                amount,
                transaction,
                user.internationalPhoneNumber,
                operatorName
            );

            if (res.data && res.data?.success) {
                const data = res.data;
                const feeTopUp: number = data.operatorFeeRate + data.payqinFeeRate;
                setTopUpFee(feeTopUp);
                setWithFeeAmount(data.withFeeAmount);
            } else {
                if (res.error)
                    setError("amount", {type: "custom", message: res.error});
            }
            setLoadingFeeCalculation(false);
        }, 4000);

        setTimer(newTimer);
    };

    const getParams = () => {
        if (!provider) {
            return;
        }

        if (provider.type == "PAYQIN_TRANSFER") {
            setTransaction("transfer");
        } else {
            const operator =
                provider.type == "WAVE_TRANSFER" ? provider?.code || "wave" : null;
            setTransaction("transfert-mobile-money");
            setOperatorName(operator);
        }
    };

    return (
        <Box sx={styles.container}>
            <Typography
                sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginBottom: "3%",
                }}
            >
                {provider && provider.type == "PAYQIN_TRANSFER"
                    ? t("payqinTransfer")
                    : provider && provider.type == "WAVE_TRANSFER"
                        ? t("transferWave")
                        : t("transferMobileMoney")}
            </Typography>
            <Box
                sx={styles.content}
                component={"form"}
                onSubmit={handleSubmit(handleTransferForm)}
            >
                <Box sx={styles.infos}>
                    <Box>
                        <Avatar
                            sx={styles.avatar}
                            alt={user?.firstName || ""}
                            src={user?.picture || ""}
                        />
                        {provider && (
                            <Avatar
                                sx={styles.provider}
                                alt={provider.text}
                                src={provider.img || ""}
                            />
                        )}
                    </Box>
                    <Typography sx={styles.userName}>
                        {user?.lastName ?? "-"} {user?.firstName ?? "-"}
                    </Typography>
                </Box>
                <Spacing marginBottom={"20px"}/>
                <AmountField
                    currency={"XOF"}
                    fullWidth={false}
                    {...register("amount")}
                    onChangeAmount={onChangeAmount}
                />
                <Box sx={styles.balance}>
                    <Typography sx={styles.availableBalance}>
                        {t("dashboard.balance")}{" "}
                        <span style={{fontWeight: "700", color: "#000000"}}>
              {balance.toLocaleString("fr-FR")} CFA
            </span>
                        {errors.amount && (
                            <p style={{color: "red"}}>{errors.amount.message}</p>
                        )}
                    </Typography>
                </Box>
                <Spacing marginBottom={"20px"}/>
                <Grid
                    container
                    direction={"column"}
                    sx={{display: "flex", flexDirection: "column"}}
                >
                    <Grid container spacing={1}>
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            sx={{display: "flex", justifyContent: "right"}}
                        >
                            <Typography sx={styles.label}>
                                {t("virtualsCards.transactionFee")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            {loadingFeeCalculation ? (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        marginRight: "5%",
                                    }}
                                />
                            ) : (
                                <Typography>
                                    {!isEmpty(topUpFee) ? `${topUpFee} ${topUpFee && "%"}` : ""}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{display: "flex", justifyContent: "right"}}
                        >
                            <Typography sx={styles.label}>
                                {t("recipients.finalReception")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: 700,
                                }}
                            >
                                {withFeeAmount} {withFeeAmount && "CFA"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Spacing marginBottom={"50px"}/>
                <Controller
                    control={control}
                    name={"note"}
                    render={({field}) => (
                        <CssTextField
                            {...field}
                            id="outlined-multiline-static"
                            label=""
                            placeholder={t("recipients.sendMoneyMessage")}
                            multiline
                            rows={1}
                            fullWidth
                            sx={styles.note}
                            {...register("note")}
                        />
                    )}
                />
                <RoundGradientButton
                    disabled={!isValid || isSubmitting || loadingFeeCalculation || errors.amount != null || topUpFee == null}
                    type="submit"
                    variant={"contained"}
                    color={"inherit"}
                    sx={{
                        background: isSubmitting
                            ? "linear-gradient(to right, #C471ED, #4A90ED)"
                            : "",
                        paddingLeft: 5,
                        paddingRight: 5,
                        mt: 5,
                    }}
                    fullWidth
                >
                    {isSubmitting ? (
                        <>
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "#FFFFFF",
                                    marginRight: "5%",
                                }}
                            />
                            <Typography sx={{color: "#FFFFFF"}}>{t("loading")}</Typography>
                        </>
                    ) : (
                        <Typography sx={{color: isSubmitting ? "#FFFFFF" : ""}}>
                            {t("recipients.sendMessage")}
                        </Typography>
                    )}
                </RoundGradientButton>
            </Box>
        </Box>
    );
};

export default SendMoneyForm;

/**
 * @type {import("@mui/material").SxProps}
 */
const styles = {
    label: {
        fontWeight: 500,
        fontSize: "13px",
        color: "#959595",
    },
    note: {
        // width: '400px',
        backgroundColor: "#F9F9F9",
        borderRadius: "8px",
        fontWeight: 500,
        fontSize: "13px",
    },
    availableBalance: {
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "22px",
        alignItems: "center",
        textAlign: "center",
        color: "#959595",
    },
    amount: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px",
        // width: 'px',
        order: 1,
        flexGrow: 0,
    },
    balance: {
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "26px",
        lineHeight: "51px",
        alignItems: "center",
        textAlign: "center",
        color: "#959595",
    },
    infos: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 0px",
        // gap: '8px',
        width: "auto",
        height: "134px",
        background: "#FFFFFF",
        borderRadius: "8px",
        justifyItems: "center",
        alignContent: "center",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        minWidth: "266px",
        // padding: '0px',
        width: {
            sm: "100px",
            md: "400px",
        },
        // height: '387px',
        alignItems: "center",
        // mr: 2,
        mb: 4,
    },
    container: {
        // marginTop: 2,
        padding: "0 48px",
    },
    provider: {
        position: "relative",
        bottom: "25%",
        left: "75%",
    },
    avatar: {
        display: "flex",
        flexDirection: "row",
        width: "64px",
        height: "64px",
    },
    userName: {
        display: "flex",
        flexDirection: "row",
        marginTop: 0,
        paddingTop: 0,
    },
};
