import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { IFrequentUserView } from "../../../Wallet/Wallet";
import Spacing from "../../../../components/Spacing";
import { walletRepository } from "../../../Wallet/repository/wallet.repository";
import { ArrowBackIos } from "@mui/icons-material";
import RoundGradientButton from "../../../../components/RoundGradientButton";
import AmountForm from "./Forms/AmountForm";
import DocumentForm from "./Forms/DocumentForm";
import ReviewBankTransfer from "./ReviewBankTransfer";
import IRecipient from "../../../../shared/types/IRecipient";
import { transactionsRepository } from "../../repository/transactions.repository";
import Popup from "../../../../components/Popup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { DialogFunction } from "../../../../shared/types/types";
import { IPurposeCode } from "../../../../shared/types/IPurposeCode";
import { t } from "i18next";
import ConfirmationCode from "../../../Recipients/components/ConfirmationCode";
import { transactionService } from "../../../../shared/services";

export interface IBankTransferData {
  recipient: IRecipient;
  amount: string;
  amountToReceive: string;
  note: number;
  noteTitle: string;
  documents: any;
  rate: string;
  fee: string;
  code?: string;
}

export interface IBankTransferDto {
  beneficiaryId: string;
  purposeId: number | string | null;
  amount: string;
  currency: string;
  code?: string;
}

const BankTransfer = () => {
  const steps = [
    t("recipients.marketRate"),
    t("business.documentsUploadTitle"),
  ];
  const navigate = useNavigate();
  const [recipient, setRecipient] = useState<IRecipient>();
  const [balance, setBalance] = useState<number | null>(null);
  const [balanceError, setBalanceError] = useState<string | null>(null);

  const [respError, setRespError] = useState<string>("");

  const [activeStep, setActiveStep] = useState<number>(0);
  const isLastStep = activeStep === steps.length;
  const [formData, setFormData] = useState<IBankTransferData>();

  const [dialogContent, setDialogContent] = useState<JSX.Element | null>(null);
  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<JSX.Element>();
  const [handleCloseDialog, setHandleCloseDialog] =
    useState<DialogFunction>(null);

  const location = useLocation();
  const data = location.state;

  useEffect(() => {
    if (data == null) navigate("/recipients");


    setRecipient(data as IRecipient);
    getBalance().then((r) => {});
  }, []);

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    getValues,
    clearErrors,
  } = useForm<IBankTransferData>({
    mode: "all",
    defaultValues: {
      recipient: data,
      amount: "",
      amountToReceive: "",
      documents: [],
      rate: "",
      fee: "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [transferData, setTransferData] = useState<IBankTransferData>();

  const getBalance = async (): Promise<void> => {
    const { data, error } = await walletRepository.getBalance();
    if (null != data) {
      setBalance(data.balance);
      setBalanceError(null);
    } else {
      setBalance(null);
      setBalanceError(error);
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <AmountForm
            register={register}
            isValid={isValid}
            errors={errors}
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
          />
        );
      case 1:
        return (
          <DocumentForm
            register={register}
            isValid={isValid}
            errors={errors}
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
          />
        );
      case 2:
        if (formData) return <ReviewBankTransfer data={formData} />;
        return;
      default:
        return <div></div>;
    }
  };

  const submitOperationToConfirmation = async (code: string) => {
    setIsSubmitting(true);
    const bankTransferDto = {} as IBankTransferDto;
    if (transferData === undefined) {
      return;
    }

    bankTransferDto.purposeId = transferData.note;
    bankTransferDto.beneficiaryId = transferData.recipient.id;
    bankTransferDto.amount = transferData.amount;
    bankTransferDto.currency = transferData.recipient.currency;
    bankTransferDto.code = code;

    const response = await transactionsRepository.postTransfer(bankTransferDto);

    const data = response.data;

    setIsSubmitting(false);

    if (null !== data) {
      dialogHandler(true, data.message || data.errorMessage, handleClose);
      setConfirmationModal(false);
    } else {
      const msg = response.error || t("errorOccurred");
      setRespError(msg)
    }
  };

  const submitForm: SubmitHandler<IBankTransferData> = async (
    bankTransferData
  ) => {
    setRespError('')
    setIsSubmitting(true);
    await transactionService.getOperationVerificationCode();

    setTransferData(bankTransferData);
    setIsSubmitting(false);
    setConfirmationModal(true);
  };

  const dialogHandler = (result: boolean, msg: string, onClose: any) => {
    setDialog(true);
    setDialogTitle(
      result ? (
        <CheckCircleIcon color={"success"} fontSize={"large"} />
      ) : (
        <CancelIcon color={"error"} fontSize={"large"} />
      )
    );
    setDialogContent(
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: "0 2",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          color={result ? "#000000" : "red"}
          variant={"h6"}
          marginTop={2}
        >
          {msg}
        </Typography>
      </Box>
    );
    setHandleCloseDialog(() => onClose);
  };

  const handleClose = () => {
    navigate("/wallet");
  };

  const handleBankTransferSubmit: SubmitHandler<IBankTransferData> = async (
    data
  ) => {

    setFormData({ ...data });
    if (isLastStep) {
      submitForm(data);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Box sx={styles.container}>
      <Box m={1}>
        <Box>
          {activeStep !== 0 && (
            <Button
              onClick={handleBack}
              startIcon={<ArrowBackIos htmlColor={"#959595"} />}
            >
              <Typography sx={styles.label}>{t("back")}</Typography>
            </Button>
          )}
          <Typography sx={styles.title}>
            {isLastStep ? t("recipients.SendReviewTitle") : t("bankTransfer")}
          </Typography>
        </Box>
        <Spacing marginBottom={"30px"} />
        {!isLastStep && (
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}

        <Box
          sx={{ p: 3 }}
          component={"form"}
          onSubmit={handleSubmit(handleBankTransferSubmit)}
        >
          {renderStepContent(activeStep)}
          <Grid container columns={12} mt={1} spacing={4}>
            <Grid item xs={12}>
              <RoundGradientButton
                fullWidth
                type="submit"
                variant={"contained"}
                color={"inherit"}
                disabled={!isValid || isSubmitting}
                sx={{
                  background: !isSubmitting
                    ? "linear-gradient(to right, #C471ED, #4A90ED)"
                    : "",
                  mt: 3,
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                }}
              >
                {isSubmitting ? (
                  <>
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#FFFFFF",
                        marginRight: "5%",
                      }}
                    />
                    <Typography sx={{ color: isSubmitting ? "#FFFFFF" : "" }}>
                      {t("loading")}
                    </Typography>
                  </>
                ) : (
                <Typography

                // sx={{ color: isSubmitting ? "#FFFFFF" : "" }}
                >
                  {isLastStep
                    ? t("recipients.sendMessage")
                    : t("recipients.nextStepButtonLabel")}
                </Typography>
                )}
              </RoundGradientButton>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Popup
        title={dialogTitle}
        open={dialog}
        onClose={handleCloseDialog}
        additionalStyle={{ backgroundColor: "#FFFFFF" }}
      >
        {dialogContent}
      </Popup>

      <Popup
        title={t("recipients.confirmationCodeLabel")}
        open={confirmationModal}
        onClose={setConfirmationModal}
        additionalStyle={{ backgroundColor: "#FFFFFF" }}
      >
        <ConfirmationCode
          isLoading={isSubmitting}
          handleCompleted={(e) => submitOperationToConfirmation(e)}
          error={respError}
        />
      </Popup>
    </Box>
  );
};

export default BankTransfer;

/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
  note: {
    // width: '400px',
    backgroundColor: "#F9F9F9",
    borderRadius: "8px",
    fontWeight: 500,
    fontSize: "13px",
  },
  availableBalance: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "22px",
    alignItems: "center",
    textAlign: "center",
    color: "#959595",
  },
  amount: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    // width: 'px',
    order: 1,
    flexGrow: 0,
  },
  balance: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "26px",
    lineHeight: "51px",
    alignItems: "center",
    textAlign: "center",
    color: "#959595",
  },
  infos: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 0px",
    gap: "8px",
    width: "auto",
    height: "134px",
    background: "#FFFFFF",
    borderRadius: "8px",
    justifyItems: "center",
    alignContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    minWidth: "266px",
    // padding: '0px',
    width: {
      sm: "100px",
      md: "400px",
    },
    // height: '387px',
    alignItems: "center",
    // mr: 2,
    mb: 4,
  },
  avatar: {
    display: "flex",
    flexDirection: "row",
    width: "64px",
    height: "64px",
  },
  userName: {
    display: "flex",
    flexDirection: "row",
    mt: 1,
  },
  title: {
    fontSize: "1rem",
    fontWeight: "400",
    display: "flex",
    justifyContent: "center",
  },
  label: {
    fontWeight: 500,
    fontSize: "13px",
    color: "#959595",
    textTransform: "none",
  },
  container: {
    width: {
      sm: "70%",
      md: "60%",
      lg: "40%",
    },
    margin: "auto",
  },
};
