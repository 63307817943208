import React, {useEffect, useState} from "react";
import {Box, Button, FormControl, Typography,} from "@mui/material";
import {IStatusItem, ITransactionStatics} from "../Wallet/models/transactionStatics.response";
import {ITransactionView} from "../Wallet/Wallet";
import {walletRepository} from "../Wallet/repository/wallet.repository";
import {transactionsRepository} from "../../shared/repository/transactions.repository";
import BuildViewTransactions from "../../shared/_utils/_helpers/BuildViewTransactions";
import EnhancedTable from "../Wallet/components/EnhancedTable";
import {ArrowUpRightIcon} from "../../shared/_utils/constants/icon";
import {ITransactions} from "../Wallet/models/transactionsResponse";
import {t} from "i18next";
import KDateRange, {IDateRangeProps, IFilterTransactionsProps} from "./components/KDateRange";
import {isEmpty} from "../../shared/_utils/isEmpty";
import {IApiResponse} from "../../shared/types/IApiResponse";

const Transactions = () => {

    const [transactions, setTransactions] = useState<ITransactionView[]>([]);
    const [transactionsError, setTransactionsError] = useState<string | null>();
    const [loadingTransactions, setLoadingTransactions] = useState<boolean>(false);
    const [loadingMoreTransactions, setLoadingMoreTransactions] = useState<boolean>(false);
    const [types, setTypes] = useState<IStatusItem[]>([]);
    const [errorTypes, setErrorTypes] = useState<string | null>();
    const [fromDate, setFromDate] = useState<string>();
    const [toDate, setToDate] = useState<string>();
    const [page, setPage] = useState<number>(1);
    const [hasRemainingData, setHasRemainingData] = useState<boolean>(false);
    const fetchThresholdTransactions = 20

    useEffect(() => {
        setLoadingTransactions(true);
        loadInitialData().then(() => {
        });
    }, []);

    const handLeScroll = async () => {
        if (!hasRemainingData || window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loadingTransactions) {
            return;
        }

        setLoadingTransactions(true)

        const params = buildParams(fromDate, toDate, page)

        await getWalletTransactions(params)
    }

    useEffect(() => {
        window.addEventListener('scroll', handLeScroll);
        return () => window.removeEventListener('scroll', handLeScroll)
    }, [loadingTransactions])

    const filterTransactions = async (props: IDateRangeProps | undefined) => {
        setPage(1)
        let startDate;
        let endDate;
        if (props) {
            startDate = props.startDate
            endDate = props.endDate
        }

        setFromDate(startDate)
        setToDate(endDate)

        setTransactions([])
        setLoadingTransactions(true)
        const params = buildParams(startDate, endDate, 1)
        await getWalletTransactions(params)
    }

    const buildParams = (fromDate?: string, toDate?: string, page?: number) => {
        let params = {page: page, limit: fetchThresholdTransactions};
        if (fromDate) {
            params = {...params, ...{fromDate}}
        }

        if (toDate) {
            params = {...params, ...{toDate}}
        }

        return params;
    }

    const getTransactionStatics = async (): Promise<IApiResponse<ITransactionStatics | null>> => {
        return transactionsRepository.getTransactionStatics().then((result) => {
            const data = result.data;
            if (null != data) {
                setTypes(data.types);
                setErrorTypes(null);
            } else {
                setTypes([]);
                setErrorTypes(result.error);
            }
            return result;
        });
    };

    const loadInitialData = async () => {
        await getTransactionStatics();
        await getWalletTransactions({limit: fetchThresholdTransactions});
    };

    const getWalletTransactions = async (params?: {
        page?: number;
        limit?: number;
        fromDate?: string;
        toDate?: string;
        transactionDirection?: string;
    }): Promise<void> => {
        const {data, error} = await walletRepository.getTransactions(params);

        if (null !== data) {
            await buildTransactionsView(data);
        } else {
            setTransactionsError(error);
            setLoadingTransactions(false);
        }
    };

    const buildTransactionsView = async (transactionsResponse: ITransactions) => {
        let _types = types;

        if (isEmpty(types)) {
            const {data, error}  = await getTransactionStatics();
            if (data) {
                _types = data?.types
            }
        }

        const listTransactionsView: ITransactionView[] = BuildViewTransactions(
            transactionsResponse.transactions,
            _types
        );

        setHasRemainingData(transactionsResponse.count > (transactions.length + transactionsResponse.transactions.length))

        if (isEmpty(transactions)) {
            setTransactions(listTransactionsView)
        } else {
            setTransactions(prevState => [...prevState, ...listTransactionsView]);
            setPage(prevState => prevState + 1)
        }
        setLoadingTransactions(false);
    };

    return (
        <div>
            <EnhancedTable
                transactions={transactions}
                download={<Export/>}
                pagination={true}
                loading={loadingTransactions}
                children={<FilterToolbar filterFn={filterTransactions}/>}
            />
        </div>
    );
};

export default Transactions;

const Export = () => {
    return (
        <Button
            endIcon={<ArrowUpRightIcon/>}
            style={{
                textTransform: "none",
                fontWeight: 400,
                fontSize: "16px",
                color: "#001021",
            }}
        >
            Download
        </Button>
    );
};

const FilterToolbar = ({filterFn}: IFilterTransactionsProps) => {
    return (
        <Box sx={{display: "flex", alignItems: "center", paddingLeft: 2, margin: 0, border: "solid #C7C7C7 1px"}}>
            <Box sx={{display: "flex"}}>
                <Typography>{t('filterBy')}</Typography>
            </Box>
            <FormControl size={"small"} sx={{p: 1, width: {
                "xs": "100%",
                "md": "25%"
            }}}>
                <KDateRange filterFn={filterFn}/>
            </FormControl>
        </Box>
    );
};
