import React from 'react';
import Carousel from 'react-material-ui-carousel';
import CarouselItem from './CarouselItem';
import annonces from '../helpers/annonces';

const CarouselComponent = () => {
    return(
        <Carousel animation='slide' duration={4000} autoPlay={true}>
            {
                annonces.map( item => <CarouselItem key={item.id} item={item} /> )
            }
        </Carousel>
    )
}

export default CarouselComponent;