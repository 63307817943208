import {Box, CircularProgress, Grid, Slide, Typography,} from "@mui/material";
import React, {useEffect, useState} from "react";
import Spacing from "../../../../components/Spacing";
import {SubmitHandler, useForm} from "react-hook-form";
import RoundGradientButton from "../../../../components/RoundGradientButton";
import {accountService} from "../../../../shared/services";
import {EditNoteOutlined} from "@mui/icons-material";
import "./style.css"
import {walletRepository} from "../../repository/wallet.repository";
import Popup from "../../../../components/Popup";
import SubOperation from "../../../../shared/types/SubOperation";
import {DialogFunction, Operation} from "../../../../shared/types/types";
import useProviderMobileMoney from "../../../../shared/hook/useProviderMobileMoney";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {ITopUpBankCardForm} from "../bank/bank-card";
import AmountField from "../../../../components/AmountField";
import {isEmpty} from "../../../../shared/_utils/isEmpty";
import {TransitionProps} from "@mui/material/transitions";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";
import WaveBasketDialog from "../../components/WaveBasketDialog";

interface ITopUpForm {
    amount: string
}

export interface IFeeCalculationResponse {
    success: boolean;
    payqinFeeRate: number;
    operatorFeeRate: number;
    fee: number;
    withFeeAmount: number;
    operatorFee: number;
}

const MobileMoney = () => {

    const {
        register,
        control,
        handleSubmit,
        watch,
        formState: {errors, isValid},
        setError,
        setValue
    } = useForm<ITopUpForm>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [currency, setCurrency] = useState<string>('')
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [loadingFeeCalculation, setLoadingFeeCalculation] = useState<boolean>(false)
    const [errorLoadingFeeCalculation, setErrorLoadingFeeCalculation] = useState<string | null>(null)
    const [transaction, setTransaction] = useState<Operation | null>(null)
    const [providersMobileMoney, setProvidersMobileMoney] = useState<SubOperation[] | null>(null)
    const [selectedprovidersMobileMoney, setSelectedprovidersMobileMoney] = useState<SubOperation | null>(null)
    const [errorProviders, setErrorProviders] = useState<string | null>(null)
    const [topUpFee, setTopUpFee] = useState<number>()
    const [withFeeAmount, setWithFeeAmount] = useState<number|null>(null)
    const [popup, setPopup] = useState<boolean>(false)
    const [formData, setFormData] = useState<ITopUpBankCardForm>();
    const [dialog, setDialog] = useState<boolean>(false)
    const [checkoutDialog, setCheckoutDialog] = useState<boolean>(false)
    const [dialogContent, setDialogContent] = useState<JSX.Element | null>(null);
    const [dialogTitle, setDialogTitle] = useState<JSX.Element>();
    const [handleCloseDialog, setHandleCloseDialog] = useState<DialogFunction>(null);
    const [checkoutContent, setCheckoutContent] = useState<JSX.Element>();
    const [formattedValue, setFormattedValue] = useState<string|null>(null);
    const [waveBasketUrl, setWaveBasketUrl] = useState<string>('');
    const [resp, setResp] = useState<string>('');
    const [userInput, setUserInput] = useState<string>('');
    const queryParams = new URLSearchParams(location.search);
    const data = queryParams.get('provider')
    const providerCountry = queryParams.get('country')
    const result = queryParams.get('result')
    const navigate = useNavigate();

    const goToWalletScreenFn = () => {
        navigate("/")
    }

    useEffect(() => {
        const getSettings = async () => {
            const user = await accountService.getUser();
            if (user) {
                setCurrency(user.currency)
                setPhoneNumber(user.phone)
            }
        }

        getSettings().then()

        isOperationSelected()
    }, [])

    const isOperationSelected = () => {

        if (data) {
            useProviderMobileMoney()
                .then(res => {
                    // if (data.toLowerCase() == 'cinetpay') {
                        const provider = res.find(providersMobileMoney => providersMobileMoney.code == data.toLowerCase() && providersMobileMoney.country?.toLowerCase() == providerCountry?.toLowerCase())
                        if (provider) {
                            provider.type = 'CINETPAY_TOP_UP'
                            setSelectedprovidersMobileMoney(provider)
                        }
                    // } else if (data.toLowerCase() == 'wave') {
                    //     const provider = res.find(providersMobileMoney => providersMobileMoney.code == 'wave')
                    //     if (provider) {
                    //         provider.type = 'WAVE_TOP_UP'
                    //         setSelectedprovidersMobileMoney(provider)
                    //     }
                    // }
                })
                .catch(err => setErrorProviders(err))
        }

    }

    const onChangeAmount = async (amount: string) => {
        if (isEmpty(amount)) {
            setValue('amount', '')
            setWithFeeAmount(null)
            return;
        }

        setValue('amount', amount)
        setLoadingFeeCalculation(true);

        if (data == null)
            return;

        const operatorName = data.toUpperCase() == 'WAVE_TOP_UP' ? 'wave' : null;

        if (timer) {
            clearTimeout(timer);
        }

        const newTimer = setTimeout(async () => {
            const res = await walletRepository.postTopUpFee(amount, 'top-up', phoneNumber, operatorName);
            if (res.data && res.data?.success) {
                const data = res.data
                const feeTopUp: number = data.operatorFeeRate + data.payqinFeeRate
                setTopUpFee(feeTopUp)
                setWithFeeAmount(data.withFeeAmount)
            } else {
                if (res.error)
                    setErrorLoadingFeeCalculation(res.error)
            }
            setLoadingFeeCalculation(false);
        }, 4000);

        setTimer(newTimer);
    }

    const handleTopUpForm: SubmitHandler<ITopUpForm> = async topUpData => {
        if (isEmpty(topUpData.amount)) {
            dialogHandler(false, t('incorrectAmount'), setDialog)
            return ;
        }

        if (!selectedprovidersMobileMoney) {
            dialogHandler(false, t('noOperatorSelected'), setDialog)
            return;
        }

        setIsSubmitting(true);

        if (selectedprovidersMobileMoney.code?.toLowerCase() === "wave") {
            await performWaveTransfer(topUpData)
        } else {
            await performMobileMoneyTopUp(topUpData)
        }

        setIsSubmitting(false);

        // const {data, error} = res;
        //
        // if (data) {
        //     if (data.success || data.status) {
        //         dialogHandler(true, 'Success')
        //     } else {
        //         const msg = data.errorMessage || data.message || data.statusText
        //         dialogHandler(false, msg)
        //     }
        // } else {
        //     const msg = error || t('errorOccurred')
        //         dialogHandler(false, msg)
        // }

    }

    const performMobileMoneyTopUp = async (topUpData: ITopUpForm) => {
        const res = await walletRepository.postTopUp(topUpData.amount, phoneNumber, selectedprovidersMobileMoney?.code || '');

        if (null != res.data && res.data.success) {
            if (null != res.data.authurl && res.data.authurl.startsWith("http")) {
                const result = window.location.href = res.data.authurl
            } else {
                dialogHandler(true, res.data.message || "Un message vous sera envoyé pour continuer l'opération", goToWalletScreenFn)
            }
        } else {
            if (null != res.error)
                dialogHandler(false, res.error, setDialog)
        }

        setIsSubmitting(false);
    }

    const performWaveTransfer = async (formData: ITopUpForm) => {
        const {data, error} =  await walletRepository.postTopUpWave(phoneNumber, formData.amount,  currency);

        if (data && data.data.wave_launch_url && data.data.wave_launch_url.startsWith("http")) {

            setWaveBasketUrl(data.data.wave_launch_url)
            setCheckoutDialog(true)
            // console.log({result})
            // setCheckoutDialog(true)
            // setCheckoutContent(
            //     <iframe
            //         src={data.data.wave_launch_url}
            //         width="100%"
            //         height="400"
            //         frameBorder="0"
            //         allowFullScreen
            //     ></iframe>
            // )

        } else {
            if (null != error)
                dialogHandler(false, error, setDialog)
        }

        setIsSubmitting(false);
    }

    const closeCheckoutDialogFn = (result: boolean) => {
        setCheckoutDialog(false)
        if (result) {
            goToWalletScreenFn()
        }

    };

    const dialogHandler = (result: boolean, msg: string, callbackFn: any) => {
        setDialog(true)
        setDialogTitle(result ? <CheckCircleIcon color={"success"} fontSize={"large"}/> :  <CancelIcon color={"error"} fontSize={"large"}/>)
        setDialogContent(<Box sx={{
            display: "flex",
            flexDirection: "column",
            p: "0 2",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Typography color={result ? "#000000" : "red"} variant={"h6"} marginTop={2}>
                {msg}
            </Typography>
        </Box>)
        setHandleCloseDialog(() => callbackFn);
    }

    return (
        <Box>
            <Box sx={styles.container}>
                <Typography
                sx={{ textAlign: "center", fontSize: "22px", fontWeight: "bold" }}
            >
                {t("topUpMobileMoneyTitle")}
            </Typography>
                <Box m={2} p={2}>
                    <Box className={"phoneNumber"}>
                        <Box sx={{display: "flex", flexDirection: "row", textAlign: "center"}}>
                            <img src={selectedprovidersMobileMoney?.img || ""} width={"10%"}/>
                            <Typography sx={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                                fontSize: "1.25rem",
                                fontWeight: 700
                            }}>
                                {accountService.getUser()!.phone}
                            </Typography>
                        </Box>
                        <EditNoteOutlined fontSize="medium"/>
                    </Box>


                    <Spacing marginBottom={"50px"}/>
                    <Box component={"form"} onSubmit={handleSubmit(handleTopUpForm)}>
                        <Box sx={styles.voucher}>
                            <AmountField control={control} errors={errors}
                                         onChangeAmount={onChangeAmount} currency={"XOF"} fullWidth={true}/>
                            <hr/>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} sm={6}>
                                    <Typography>{t('topUpFees')}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    {loadingFeeCalculation
                                        ? <CircularProgress
                                            size={24}
                                            sx={{
                                                color: "linear-gradient(to right, #C471ED, #4A90ED)",
                                                marginRight: '5%',
                                            }}
                                        />
                                        :
                                        <Typography>{errorLoadingFeeCalculation || (topUpFee !== undefined ? `${topUpFee} ${topUpFee && '%'}` : '')}</Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <Typography>{t('recipients.finalReception')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {loadingFeeCalculation
                                        ? null
                                        : <Typography sx={{
                                            fontSize: "1rem",
                                            fontWeight: 700
                                        }}>{withFeeAmount?.toLocaleString('fr-FR')} {withFeeAmount && 'CFA'}</Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                        <Spacing marginBottom={"50px"}/>
                        <RoundGradientButton
                            variant="contained" color="inherit"
                            disabled={errorLoadingFeeCalculation ? true : false || !isValid || isSubmitting || loadingFeeCalculation}
                            type="submit"
                            fullWidth
                            sx={{
                                background: isSubmitting ? "linear-gradient(to right, #C471ED, #4A90ED)" : '',
                                mt: 3, display: 'flex', flexDirection: 'row', textAlign: 'center'
                            }}
                        >
                            {isSubmitting ? (
                                <>
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: '#FFFFFF',
                                            marginRight: '5%',
                                        }}
                                    />
                                    <Typography sx={{color: isSubmitting ? "#FFFFFF" : '',}}>
                                        {t('loading')}
                                    </Typography>
                                </>
                            ) : <Typography sx={{color: isSubmitting ? "#FFFFFF" : '',}}>
                                {t('next')}
                            </Typography>}
                        </RoundGradientButton>
                    </Box>
                </Box>
            </Box>
            <Popup
                title={dialogTitle}
                open={dialog}
                onClose={handleCloseDialog}
            >
                {dialogContent}
            </Popup>

            <WaveBasketDialog url={waveBasketUrl} open={checkoutDialog} onClose={closeCheckoutDialogFn} />
        </Box>

    )
}

export default MobileMoney;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
  voucher: {
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: {
      sm: "70%",
      md: "60%",
      lg: "40%",
    },
    margin: "auto",
  },
};
