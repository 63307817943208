import {httpService} from "./http.service";

const withDraw = (cardId: string, amount: string) => {
    const data =  {'amount': amount};
    return httpService.Axios.post(`/cards/sudo-card/${cardId}/withdraw`, data)
}

const topUp = (cardId: string, amount: string) => {
    const data =  {'amount': amount};
    return httpService.Axios.post(`/cards/sudo-card/${cardId}/fund`, data)
}

const getFundDetails = (amount: string, isFunding: boolean) => {
    return httpService.Axios.get(`/cards/fund/details?amount=${amount}&isFunding=${isFunding}`)
}

let getCardTransactions = (cardId: string) => {
    let page = 1;
    let limit = 5;
    let fromDate = '2017-01-01';
    let toDate = new Date().toISOString().split('T')[0];
    let transactionDirection = 'ALL'
    return httpService.Axios.post(`cards/sudo-card/${cardId}/transactions/?transactionDirection=${transactionDirection}&page=${page}&limit=${limit}&transactionDirection=${transactionDirection}`, {
        'fromDate': fromDate,
        'toDate': toDate,
    })
}

let getVirtualCards = () => {
    return httpService.Axios.get('cards')
}

let getCardDetails = (cardId: string) => {
    // return httpService.Axios.get(`cards/flutterwave-card/${cardId}`)
    return httpService.Axios.get(`cards/sudo-card/${cardId}`)
}

let getCardsId = () => {
    return httpService.Axios.get('cards/list/ids')
}

export const virtualCardsService = {
    getCardsId, getVirtualCards, getCardDetails, getCardTransactions, getFundDetails, topUp, withDraw
}