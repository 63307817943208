import {
    Box,
    Button, Card, CardActions, CardContent, CardHeader,
    CircularProgress,
    Collapse,
    Grid,
    Slide,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {ArrowBackIos} from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {TransitionProps} from "@mui/material/transitions";
import Popup from "../../../components/Popup";
import RoundGradientButton from "../../../components/RoundGradientButton";
import Spacing from "../../../components/Spacing";
import InfoCompanyForm from "./Forms/InfoCompanyForm";
import DocumentForm from "./Forms/DocumentForm";
import {DialogFunction, VertoBeneficiary} from "../../../shared/types/types";
import {TransitionGroup} from "react-transition-group";
import {IUploadFile} from "../../../shared/types/IUploadFile";
import {businessRepository} from "../business.repository";
import {accountService} from "../../../shared/services";
import {IUser} from "../../Auth/models/auth.response";
import {t} from "i18next";
import {displayError} from "../../../shared/_utils/_helpers/error";
import GradientCircularProgress from "../../../components/GradientCircularProgress";
import {
    EyeOffFilledIcon,
    EyeOnIsFilledIcon,
    SendIsFilledFalse,
    TopUpIsFilledFalse
} from "../../../shared/_utils/constants/icon";
import AmountFormatter from "../../../shared/_utils/_helpers/AmountFormatter";
import toast from "react-hot-toast";
import { uploadRepository } from "src/shared/repository/upload.repository";

export interface IBusinessRequestForm {
    companyName: string;
    rccm: string;
    firstName: string;
    lastName: string;
    email: string;
    billingAddress: string;
    nationalCard: File;
    dfe: File;
    businessRegistry: File;
    acceptedTerms: boolean;
    companyType: BusinessTypes
}

export interface IBusinessRequestDto {
    lat: string;
    lng: string;
    email: string;
    phone: string;
    isPerson: boolean;
    name: string;
    firstName: string;
    lastName: string;
    taxSystem: string;
    govId: string;
    dfeScanUrl: string;
    regComUrl: string;
    idCardUrl: string;
    companyType: BusinessTypes
}

export const BusinessTypesValues =
    {
        INDIVIDUAL: 'individual',
        BUSINESS: 'business'
    }

export const BusinessTypesValid: BusinessTypes[] = [
    "individual",
    "business",
];

export type BusinessTypes =
    'individual' | 'business'

const RequestBusiness = () => {
    const steps = [
        t("business.companyInfoTitle"),
        t("business.documentsUploadTitle"),
    ];
    const navigate = useNavigate();

    const [activeStep, setActiveStep] = useState<number>(0);
    const isLastStep = activeStep === steps.length - 1;

    const [dialogContent, setDialogContent] = useState<JSX.Element | null>(null);
    const [dialog, setDialog] = useState<boolean>(false);
    const [dialogTitle, setDialogTitle] = useState<JSX.Element>();
    const [handleCloseDialog, setHandleCloseDialog] =
        useState<DialogFunction>(null);
    const [user, setUser] = useState<IUser>();

    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [requestSuccess, setRequestSuccess] = useState(false)
    const [requestDate, setRequestDate] = useState()

    const [isVerified, setIsVerified] = useState(false)

    const [acceptedTerms, setAcceptedTerms] = useState(false)

    const {
        register,
        setValue,
        control,
        handleSubmit,
        formState: {errors, isValid},
        setError,
        clearErrors,
    } = useForm<IBusinessRequestForm>({
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: {},
        shouldUnregister: false
    });

    useEffect(() => {
        checkAll().then(r => {
        })
        const user = accountService.getUser();
        setUser(user);
        
    }, []);

    const checkAll = async () => {
        setLoading(true)
        await Promise.all([
            checkVerificationStatus(),
            checkPendingRequest()
        ])
        setLoading(false)
    }

    const checkVerificationStatus = async () => {
        const {data, error} = await businessRepository.accountVerification()
        if (data) {
            setIsVerified(data.isVerified)
        } else {
            if (null != error) displayError(error)
        }
    }

    const checkPendingRequest = async () => {
        try {
            const {data, error} = await businessRepository.checkPendingRetailerRequest()
            if (null != data && 'pendingRequest' in data && data.pendingRequest) {
                setRequestSuccess(true)
                localStorage.setItem("Business", "en_attente")
                if ('createdAt' in data.pendingRequest) setRequestDate(data.pendingRequest.createdAt);
            } else {
                if (null != error) displayError(error)
            }

        } catch (error) {
            displayError(error)
        }
    }

    const renderStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <InfoCompanyForm
                        clearErrors={clearErrors}
                        register={register}
                        isValid={isValid}
                        errors={errors}
                        control={control}
                        setValue={setValue}
                    />
                );
            case 1:
                return (
                    <DocumentForm
                        clearErrors={clearErrors}
                        register={register}
                        isValid={isValid}
                        errors={errors}
                        control={control}
                        setValue={setValue}
                    />
                );
            default:
                return <div></div>;
        }
    };

    const submitForm: SubmitHandler<IBusinessRequestForm> = async (
        businessRequestData
    ) => {

        if (!businessRequestData.companyType) {
            setError('companyType', {
                message: t("requiredField")
            })
            return;
        }

        if (!businessRequestData.businessRegistry) {
            setError('businessRegistry', {
                message: t("requiredField")
            })
            return;
        }

        if (!businessRequestData.dfe) {
            setError('dfe', {
                message: t("requiredField")
            })
            return;
        }

        if (!businessRequestData.nationalCard) {
            setError('nationalCard', {
                message: t("requiredField")
            })
            return;
        }

        setIsSubmitting(true);

        const resp = await Promise.all([
            uploadFile(businessRequestData.businessRegistry),
            uploadFile(businessRequestData.dfe),
            uploadFile(businessRequestData.nationalCard),
        ]);



        const businessRequestDto = {} as IBusinessRequestDto;

        businessRequestDto.idCardUrl = resp[2];
        businessRequestDto.dfeScanUrl = resp[1];
        businessRequestDto.regComUrl = resp[0];
        businessRequestDto.firstName = businessRequestData.firstName;
        businessRequestDto.lastName = businessRequestData.lastName;
        businessRequestDto.email = businessRequestData.email;
        businessRequestDto.name = businessRequestData.companyName;
        businessRequestDto.isPerson = businessRequestData.companyType == BusinessTypesValues.INDIVIDUAL;
        if (user) businessRequestDto.phone = user.phone;
        
        if(!resp[0] || !resp[1] || !resp[2]){
            dialogHandler(false, t("errorFile"), setDialog);
        }else{
            const {data, error} = await businessRepository.registrationRequest(
                businessRequestDto
            );

            if (null !== data) {
            if (data.success) {
                dialogHandler(
                    true,
                    data.message || t("business.successMessage"),
                    handleClose
                );
            } else {
                dialogHandler(false, data.message || data.errorMessage, setDialog);
            }
            } else {
                dialogHandler(false, error || t("errorOccurred"), setDialog);
            }
        }

        setIsSubmitting(false);

    };

    const uploadFile = async (file: File): Promise<string> => {
        const uploadFile: IUploadFile = {
            file: file,
            folder: "business",
        };
        const response: any = await uploadRepository.uploadFile(uploadFile);
        return response?.data?.secure_url;
    };

    const dialogHandler = (result: boolean, msg: string, onClose: any) => {
        setDialog(true);
        setDialogTitle(
            result ? (
                <CheckCircleIcon color={"success"} fontSize={"large"}/>
            ) : (
                <CancelIcon color={"error"} fontSize={"large"}/>
            )
        );
        setDialogContent(
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: "0 2",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    color={result ? "#000000" : "red"}
                    variant={"h6"}
                    marginTop={2}
                >
                    {msg}
                </Typography>
            </Box>
        );
        setHandleCloseDialog(() => onClose);
    };

    const handleClose = () => {
        navigate("/wallet");
    };

    const handleBankTransferSubmit: SubmitHandler<IBusinessRequestForm> = async (
        data
    ) => {
        if (isLastStep) {
            submitForm(data);
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <Box sx={styles.wrapper}>
            {loading && <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <GradientCircularProgress/>
            </div>}
            {(!loading && isVerified && !requestSuccess) &&
            <Box>
                <Box m={1}>
                    <Box sx={{display: "flex"}}>
                        <Box sx={styles.back}>
                            {activeStep !== 0 && (
                                <Button
                                    onClick={handleBack}
                                    startIcon={<ArrowBackIos htmlColor={"#959595"}/>}
                                >
                                    <Typography sx={{...styles.label}}>{t("back")}</Typography>
                                </Button>
                            )}
                        </Box>

                        <Box
                            sx={{
                                ...styles.container,
                                ...{
                                    width: {
                                        md: "100%",
                                    },
                                },
                            }}
                        >
                            <Typography sx={styles.title}>
                                {isLastStep
                                    ? t("business.addDocumentsTitle")
                                    : t("navigations.becomeBusiness")}
                            </Typography>
                        </Box>
                    </Box>

                    <Spacing marginBottom={"30px"}/>
                    {!isLastStep && (
                        <Box sx={styles.container}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    )}

                    <Box
                        sx={{p: 3}}
                        component={"form"}
                        onSubmit={handleSubmit(handleBankTransferSubmit)}
                    >
                        <TransitionGroup>
                            <Collapse key={activeStep} orientation={"vertical"} in={false}>
                                {renderStepContent(activeStep)}
                            </Collapse>
                        </TransitionGroup>

                        <Grid container columns={12} mt={1} spacing={4}>
                            <Grid item xs={12}>
                                <RoundGradientButton
                                    //   disabled={!isValid || isSubmitting}
                                    fullWidth
                                    type="submit"
                                    variant={"contained"}
                                    color={"inherit"}
                                >
                                    {isSubmitting && (
                                        <>
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: "#FFFFFF",
                                                    marginRight: "5%",
                                                }}
                                            />
                                        </>
                                    )}
                                    {isLastStep
                                        ? t("business.sendRequest")
                                        : t("recipients.nextStepButtonLabel")}
                                </RoundGradientButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <Popup
                    title={dialogTitle}
                    open={dialog}
                    onClose={handleCloseDialog}
                    additionalStyle={{backgroundColor: "#FFFFFF"}}
                >
                    {dialogContent}
                </Popup>
            </Box>
            }
            {(!loading && !isVerified) &&
            <Card sx={{
                display: "grid",
                alignItems: "center",
            }}>
                <CardHeader>
                    <Typography fontSize={"1rem"}>
                        Verification requise
                    </Typography>
                </CardHeader>
                <CardContent>
                    <Typography fontSize={"1.1rem"}>
                        Votre compte PayQin doit être vérifié avant que vous puissiez être distributeur.
                    </Typography>
                </CardContent>
            </Card>
            }
            {(!loading && isVerified && requestSuccess) &&
            <Card sx={{
                display: "grid",
                alignItems: "center",
            }}>
                <CardContent sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 5
                    // columnGap: "20px",
                    // gap: "20px",
                }}>
                    <Box
                        component={"img"}
                        src={"https://res.cloudinary.com/payqin-ltd/image/upload/v1699614935/web-app/check_3x_o9pmve.png"}
                        width={150}
                        margin={5}
                    />
                    <Typography sx={{fontSize:"1.1rem"}}>
                        {t("pendingRequestBusiness")}.
                    </Typography>
                </CardContent>

                <CardActions disableSpacing>
                    <Typography sx={{fontSize:"1rem", color:"#2eb85c"}}>
                        {t('submittedDate')} <strong>{requestDate}</strong>.
                    </Typography>
                </CardActions>
            </Card>
            }
        </Box>
    );
}
;

export default RequestBusiness;

/**
* @type
{
    import("@mui/material").SxProps
}
*/
const styles =
{
    note: {
        // width: '400px',
        backgroundColor: "#F9F9F9",
            borderRadius
    :
        "8px",
            fontWeight
    :
        500,
            fontSize
    :
        "13px",
    }
,
    availableBalance: {
        fontStyle: "normal",
            fontWeight
    :
        "400",
            fontSize
    :
        "13px",
            lineHeight
    :
        "22px",
            alignItems
    :
        "center",
            textAlign
    :
        "center",
            color
    :
        "#959595",
    }
,
    amount: {
        display: "flex",
            flexDirection
    :
        "column",
            alignItems
    :
        "center",
            padding
    :
        "0px",
            // width: 'px',
            order
    :
        1,
            flexGrow
    :
        0,
    }
,
    balance: {
        fontStyle: "normal",
            fontWeight
    :
        "700",
            fontSize
    :
        "26px",
            lineHeight
    :
        "51px",
            alignItems
    :
        "center",
            textAlign
    :
        "center",
            color
    :
        "#959595",
    }
,
    infos: {
        display: "flex",
            flexDirection
    :
        "column",
            justifyContent
    :
        "center",
            alignItems
    :
        "center",
            padding
    :
        "0px 0px",
            gap
    :
        "8px",
            width
    :
        "auto",
            height
    :
        "134px",
            background
    :
        "#FFFFFF",
            borderRadius
    :
        "8px",
            justifyItems
    :
        "center",
            alignContent
    :
        "center",
    }
,
    content: {
        display: "flex",
            flexDirection
    :
        "column",
            minWidth
    :
        "266px",
            // padding: '0px',
            width
    :
        {
            sm: "100px",
                md
        :
            "400px",
        }
    ,
        // height: '387px',
        alignItems: "center",
            // mr: 2,
            mb
    :
        4,
    }
,
    avatar: {
        display: "flex",
            flexDirection
    :
        "row",
            width
    :
        "64px",
            height
    :
        "64px",
    }
,
    userName: {
        display: "flex",
            flexDirection
    :
        "row",
            mt
    :
        1,
    }
,
    title: {
        fontSize: "1rem",
            fontWeight
    :
        "700",
            display
    :
        "flex",
            justifyContent
    :
        "center",
    }
,
    label: {
        fontWeight: 500,
            fontSize
    :
        "13px",
            color
    :
        "#959595",
            textTransform
    :
        "none",
    }
,
    back: {
        margin: "auto",
            // pl: -100,
            fontWeight
    :
        700,
    }
,
    container: {
        width: {
            sm: "70%",
                md
        :
            "60%",
                lg
        :
            "50%",
        }
    ,
        margin: "auto",
    }
,
    wrapper: {
        width: {
            sm: "70%",
        }
    ,
        margin: "auto",
    }
,
}
;
