import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import InputFilesForm from "./InputFilesForm";
import { IBusinessRequestForm } from "../index";
import { IUseFormProps } from "../../../../shared/types/IUseFormsProps";
import Spacing from "../../../../components/Spacing";
import { UploadFile } from "@mui/icons-material";
import { Label } from "../../../../components/Label";
import { HiddenInput } from "../../../../components/HiddenInput";
import HelpButton from "../../../Wallet/top-up/bank/components/HelpButton";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const DocumentForm: React.FC<IUseFormProps<IBusinessRequestForm>> = ({
  register,
  isValid,
  errors,
  control,
  setValue,
  clearErrors,
}) => {
  // // const [filename, setFilename] = useState<[]>([]);
  // const [uploadedFiles, setUploadedFiles] = useState([])
  //
  // const handleUploadFiles = (e: ChangeEvent<HTMLInputElement>) => {

  // const [filename, setFilename] = useState<[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [fileLimit, setFileLimit] = useState(false);
  const MAX_COUNT = 3;

  const [popup, setPopup] = useState<boolean>(false);
  const [selectedDfeFileName, setSelectedDfeFileName] = useState<string | null>(
    null
  );
  const [selectedNationalCardFileName, setSelectedNationalCardFileName] =
    useState<string | null>(null);
  const [
    selectedBusinessRegistryFileName,
    setSelectedBusinessRegistryFileName,
  ] = useState<string | null>(null);
  const navigate = useNavigate();
  const [content, setContent] = useState<JSX.Element | null>(null);

  const handleFileNationalCardUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      clearErrors("nationalCard");
      if (setValue) setValue("nationalCard", selectedFile);
      setSelectedNationalCardFileName(selectedFile.name);
    }
  };

  const handleFileBusinessRegistryUpload = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = e.target.files && e.target.files[0];

    if (selectedFile) {
      clearErrors("businessRegistry");
      if (setValue) setValue("businessRegistry", selectedFile);
      setSelectedBusinessRegistryFileName(selectedFile.name);
    }
  };

  const handleFileDfeUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      clearErrors("dfe");
      if (setValue) setValue("dfe", selectedFile);
      setSelectedDfeFileName(selectedFile.name);
    }
  };

  const handleUploadFiles = (files: File[]) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;

    const res = files.map((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`Vous ne pouvez pas uploader plus de ${MAX_COUNT} fichiers`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });

    // return uploaded;

    if (!limitExceeded) return uploaded;
  };

  const handleFileEvent = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box>
        <Button
          style={{
            border: "1px dashed rgb(170, 170, 170)",
            borderRadius: "4px",
            padding: 20,
            backgroundColor: "rgba(63, 81, 181, 0.04)",
          }}
          color={"primary"}
          fullWidth
          component="label"
          startIcon={<UploadFile />}
          sx={{ marginRight: "1rem" }}
        >
          <Label>
            {selectedBusinessRegistryFileName
              ? selectedBusinessRegistryFileName
              : t("business.documentsCertificate")}
          </Label>

          <HiddenInput
            onChange={(e) => handleFileBusinessRegistryUpload(e)}
            type="file"
            accept=".jpg, .jpeg, .png, .pdf"
          />
          {errors.businessRegistry && (
            <span
              style={{
                color: "#F94144",
                fontSize: "1rem",
                fontWeight: 400,
              }}
            >
              {errors.businessRegistry.message}
            </span>
          )}
        </Button>
      </Box>

      <Spacing marginBottom={"30px"} />

      <Box>
        <Button
          style={{
            border: "1px dashed rgb(170, 170, 170)",
            borderRadius: "4px",
            padding: 20,
            backgroundColor: "rgba(63, 81, 181, 0.04)",
          }}
          color={"primary"}
          fullWidth
          component="label"
          startIcon={<UploadFile />}
          sx={{ marginRight: "1rem" }}
        >
          <Label>
            {selectedDfeFileName
              ? selectedDfeFileName
              : t("business.documentsDFE")}
          </Label>

          <HiddenInput
            onChange={(e) => handleFileDfeUpload(e)}
            type="file"
            accept=".jpg, .jpeg, .png, .pdf"
          />
          {errors.dfe && (
            <span
              style={{
                color: "#F94144",
                fontSize: "1rem",
                fontWeight: 400,
              }}
            >
              {errors.dfe.message}
            </span>
          )}
        </Button>
      </Box>
      <Spacing marginBottom={"30px"} />

      <Box>
        <Button
          style={{
            border: "1px dashed rgb(170, 170, 170)",
            borderRadius: "4px",
            padding: 20,
            backgroundColor: "rgba(63, 81, 181, 0.04)",
          }}
          color={"primary"}
          fullWidth
          component="label"
          startIcon={<UploadFile />}
          sx={{ marginRight: "1rem" }}
        >
          <Label>
            {selectedNationalCardFileName
              ? selectedNationalCardFileName
              : t("business.documentsIDCard")}
          </Label>

          <HiddenInput
            onChange={(e) => handleFileNationalCardUpload(e)}
            type="file"
            accept=".jpg, .jpeg, .png, .pdf"
          />
          {errors.nationalCard && (
            <span
              style={{
                color: "#F94144",
                fontSize: "1rem",
                fontWeight: 400,
              }}
            >
              {errors.nationalCard.message}
            </span>
          )}
        </Button>
      </Box>

      <Spacing marginBottom={"30px"} />

      <FormControlLabel
        id={"acceptTerms"}
        control={
          <Checkbox
            {...register("acceptedTerms", {
              required: t("requiredField"),
            })}
            color="primary"
          />
        }
        label={
          <Typography>
            {t("business.acceptTermFirst")}{" "}
            <a
              href="https://policies.payqin.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("business.acceptTermsTwo")}
            </a>
          </Typography>
        }
      />
      {errors.acceptedTerms && (
        <p style={styles.errorLabel}>{errors.acceptedTerms.message}</p>
      )}
    </Box>
  );
};

export default DocumentForm;

/** @type{import("@mui/material").SxProps}*/
const styles = {
  titleField: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#000000",
    marginBottom: 5,
  },
  input: { backgroundColor: "rgb(249, 249, 249)", borderRadius: "8px" },
  label: {
    fontWeight: 500,
    fontSize: "13px",
    color: "#959595",
  },
  errorLabel: {
    fontSize: "14px",
    color: "red",
  },
  addCardButton: {
    display: "flex",
    justifyContent: "space-around",
  },
};
