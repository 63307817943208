import { useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { t } from "i18next";
import PinField from "react-pin-field";

interface IConfirmationCode {
  handleCompleted: (code: string) => any;
  isLoading: boolean;
  error?: string
}

const ConfirmationCode = ({
  handleCompleted,
  isLoading,
    error
}: IConfirmationCode) => {
  const handleCompleteTyping = (code: string) => {
    handleCompleted(code);
  };

  return (
    <Box sx={{ display: "block", paddingBottom: "10%" }}>
      <Typography sx={{ fontSize: "14px" }}>
        {t("recipients.confirmationCodeDescription")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "5%",
          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <CircularProgress
            size={24}
            sx={{
              marginRight: "5%",
            }}
          />
        ) : (
            <div>
                <PinField
                    className="pin-field"
                    length={6}
                    validate={/^[0-9]$/}
                    onChange={(e) => {
                    }}
                    onComplete={(code) => handleCompleteTyping(code)}
                />
                {error &&
                <Typography sx={{mt: 4, fontSize: "1rem", display: "flex", justifyContent: "center", color: "red" }}>{error}</Typography>}
            </div>
        )}
      </Box>
    </Box>
  );
};

export default ConfirmationCode;
