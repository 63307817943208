import { httpService } from "./http.service";
import { ITopUpBankCardDto } from "../../containers/Wallet/top-up/bank/bank-card";
import { ICardBankData } from "../types/ICardPaymentResponse";
import { IApiResponse } from "../types/IApiResponse";

const performWaveTransfer = (
  amount: string,
  recipientPhoneNumber: string,
  currency: string,
  code?: string
) => {
  const body = {
    currency: currency,
    mobile: recipientPhoneNumber,
    receive_amount: amount,
    code,
  };
  return httpService.Axios.post("/wave/web-app/transfer-wave", body);
};

const postMobileMoneyTransfer = (
  amount: string,
  recipientPhoneNumber: string,
  note: string | null,
  code?: string
) => {
  const body = {
    amount: amount,
    recipient: recipientPhoneNumber,
    message: note,
    code,
  };
  return httpService.Axios.post(
    "/wallets/web-app/create-transfer-request",
    body
  );
};

const postPayQinTransfer = (
  amount: string,
  payQinUserId: string,
  note: string | null,
  code?: string
) => {
  const body = { amount: amount, recipient: payQinUserId, message: note, code };
  return httpService.Axios.post("wallets/web-app/transfer", body);
};

const completeTopUpBankCard = (
  cardBankData: ICardBankData,
  pinCode: string,
  suggestedAuth: string
) => {
  return httpService.Axios.post("/wallets/credit-card/finalize-topup", {
    payload: cardBankData,
    pin: pinCode,
    suggested_auth: suggestedAuth,
  });
};

const initializeTopUpBankCard = (amount: number, txRef: string) => {
  const body = {
    amount: amount,
    txRef: txRef,
  };
  return httpService.Axios.post("/wallets/bank-card-topup", body);
};

const bankTopUpRequest = (amount: number, receiptUrl: string) => {
  return httpService.Axios.post("/retailer/request-top-up", {
    amount: amount,
    receiptUrl: receiptUrl,
  });
};

const getBankTransferFees = (
  amount: string,
  currencyFrom: string,
  currencyTo: string,
  countryTo: string
) => {
  return httpService.Axios.get(
    `/wallets/transfer/currency-rate?amount=${amount}&currencyFrom=${currencyFrom}&currencyTo=${currencyTo}&countryTo=${countryTo}`
  );
};

const getBankTransferFeesFromDest = (
  withFeeAmount: string,
  currencyFrom: string,
  currencyTo: string,
  countryTo: string
) => {
  return httpService.Axios.get(
    `/wallets/transfer/currency-rate-from-Dest?withFeeAmount=${withFeeAmount}&currencyFrom=${currencyFrom}&currencyTo=${currencyTo}&countryTo=${countryTo}`
  );
};

const getTransactions = (params?: {
  page?: number;
  limit?: number;
  fromDate?: string;
  toDate?: string;
  transactionDirection?: string;
}) => {
  const defaultParams = {
    page: 1,
    limit: 5,
    fromDate: "2017-01-01",
    toDate: new Date().toISOString().split("T")[0],
    transactionDirection: "ALL",
  };

  const mergedParams = { ...defaultParams, ...params };

  return httpService.Axios.post(
    `wallets/sort-transactions?transactionDirection=${mergedParams.transactionDirection}&page=${mergedParams.page}&limit=${mergedParams.limit}`,
    {
      dateDebut: mergedParams.fromDate,
      dateFin: mergedParams.toDate,
    }
  );
};

const postTopUpWave = (
  clientReference: string,
  amount: string,
  currency: string,
  errorUrl?: string,
  successUrl?: string
) => {
  const body = {
    client_reference: clientReference,
    amount: amount,
    currency: currency,
    error_url: errorUrl || "https://web.payqin.com/wallet/top-up/basket-wave-response?result=failed",
    success_url:
      successUrl || "https://web.payqin.com/wallet/top-up/basket-wave-response?result=success",
  };
  return httpService.Axios.post("/wave/wave-to-payqin", body);
};

const postTopUp = (amount: string, phone: string, providerCode: string | null) => {
  const body = {
    amount: amount,
    phone: phone,
    code: providerCode,
  };
  return httpService.Axios.post("wallets/top-up", body);
};

const postTopUpFee = (
  amount: string,
  isTopUp: string,
  phone: string,
  operatorName: string | null
) => {
  const body = {
    amount: amount,
    transaction: isTopUp,
    phone: phone,
    operatorName: operatorName,
  };

  return httpService.Axios.post("wallets/fee/top-up", body);
};

const getMobileMoneyProviders = (operation: string) => {
  return httpService.Axios.get(
    `wallets/networks-by-country?operation=${operation}`
  );
};

const walletStatistics = (period: string) => {
  return httpService.Axios.get(`wallets/web-statistics?lang=${localStorage.getItem("language")}&period=${period}`);
};

const getBalance = () => {
  return httpService.Axios.get("wallets/balance");
};

const postSendAirtimeCredit = (
  amount: string,
  phoneNumber: string,
  phoneCode: string
) => {
  const body = {
    amount,
    phoneNumber,
    phoneCode,
  };
  return httpService.Axios.post("wallets/airtime-send-money", body);
};

export const walletService = {
  getBalance,
  postTopUpFee,
  getMobileMoneyProviders,
  postTopUp,
  postTopUpWave,
  getTransactions,
  getBankTransferFees,
  getBankTransferFeesFromDest,
  bankTopUpRequest,
  initializeTopUpBankCard,
  completeTopUpBankCard,
  postPayQinTransfer,
  postMobileMoneyTransfer,
  performWaveTransfer,
  walletStatistics,
  postSendAirtimeCredit,
};
