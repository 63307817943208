import {Box, Grid, Typography,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AlertIcon} from "../../../shared/_utils/constants/icon";
import {CountrySelect} from "../components/CountrySelect";
import Spacing from "../../../components/Spacing";
import CircularProgress from "@mui/material/CircularProgress";
import {SubmitHandler, useForm} from "react-hook-form";
import {recipientsRepository} from "../repository/recipients.repository";
import {IBankRecipientData, IBankRecipientDto, VertoBeneficiaryValue} from "../BankRecipient";
import RoundGradientButton from "../../../components/RoundGradientButton";
import Popup from "../../../components/Popup";
import {useLocation, useNavigate} from "react-router-dom";
import SuccessfulAddRecipient from "../components/SuccessfulAddRecipient";
import {DialogFunction} from "../../../shared/types/types";
import KTextField from "../../../components/KTextField";
import {t} from "i18next";
import {accountService, countryService} from "../../../shared/services";
import toast from "react-hot-toast";

export interface IRecipientFormData {
    countryCode: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    email: string;
}

const PayQinRecipient = () => {
    const location = useLocation();
    const state = location.state;

    const {
        register,
        control,
        handleSubmit,
        watch,
        formState: {errors, isValid},
        setError,
    } = useForm<IRecipientFormData>({
        defaultValues: state ? {
            ...state,
        } : null
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [content, setContent] = useState<JSX.Element | null>(null);
    const [handleCloseDialog, setHandleCloseDialog] =
        useState<DialogFunction>(null);
    const [popup, setPopup] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleAddRecipientForm: SubmitHandler<IRecipientFormData> = async (
        recipientData
    ) => {

        setIsSubmitting(true);

        const recipientDto = {} as IBankRecipientDto;
        recipientDto.isBankClient = false;
        recipientDto.lastName = recipientData.lastName;
        recipientDto.firstName = recipientData.firstName;
        recipientDto.email = recipientData.email;
        recipientDto.phoneNumber = recipientData.phoneNumber;
        recipientDto.countryCode = countryService.normalizeCountryCode(recipientData.countryCode);

        const response = await recipientsRepository.createOrUpdateBeneficiary(recipientDto, state?.id);

        const data = response.data;

        setIsSubmitting(false);

        if (null !== data && data.success) {
            if (null !== state) {
                toast.success(data.message);
                handleUserConfirmation()
            } else {
                setPopup(true);
                setContent(
                    <SuccessfulAddRecipient
                        fullName={`${recipientData.firstName} ${recipientData.lastName}`}
                        lastName={recipientData.lastName}
                        firstName={recipientData.firstName}
                    />
                );
                setHandleCloseDialog(() => handleUserConfirmation);
            }
        } else {
            const msg = response.error;
            setContent(
                <Typography sx={{fontSize: "16px", color: "red"}}>{msg}</Typography>
            );
            setPopup(true);
            setHandleCloseDialog(() => setPopup);
        }
    };

    const handleUserConfirmation = (): any => {
        navigate("/recipients");
    };

    return (
        <Box>
            <Box sx={styles.container}>

                <Box m={1}>
                    <Typography
                        variant="h5"
                        sx={{display: "flex", justifyContent: "center"}}
                    >
                        {
                            state ?
                                t("recipients.updateBeneficiaryTitle")
                                : t("recipients.addNewMobileMoneyTitle")
                        }

                    </Typography>

                    <Spacing marginBottom={"50px"}/>

                    <Typography
                        sx={{
                            display: "flex",
                        }}
                    >
                        {t("recipients.recipientInfosTitle")}
                        <AlertIcon/>
                    </Typography>
                    <Spacing marginBottom={"20px"}/>
                    <Box
                        component={"form"}
                        onSubmit={handleSubmit(handleAddRecipientForm)}
                    >
                        <Spacing marginBottom={"20px"}/>
                        <Typography sx={styles.label}>{t("phoneNumber")}</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <CountrySelect
                                    label=""
                                    {...register("countryCode", {
                                        required: t("physicalCards.countryCodeError"),
                                    })}
                                    defaultValue={countryService.removeSignCountryCode(watch("countryCode"))}
                                />
                                {errors.countryCode && (
                                    <p style={styles.errorText}>{errors.countryCode.message}</p>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <KTextField
                                    validation={register("phoneNumber", {
                                        required: t("physicalCards.phoneNumberError"),
                                        pattern: {
                                            value: /^(?=[0-9]).{7,20}$/,
                                            message: t("physicalCards.phonePatternError"),
                                        },
                                    })}
                                    placeholder="XXXXXXXXXX"
                                    fullWidth
                                />
                                {errors.phoneNumber && (
                                    <p style={styles.errorText}>{errors.phoneNumber.message}</p>
                                )}
                            </Grid>
                        </Grid>
                        <Spacing marginBottom={"30px"}/>
                        <Grid container columns={12} spacing={4}>
                            <Grid item xs={7} md={7} lg={7}>
                                <Typography sx={styles.label}>{t("lastName")}</Typography>
                                <KTextField
                                    validation={register("lastName", {
                                        required: t("physicalCards.lastNameError"),
                                        pattern: {
                                            value: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/,
                                            message: "Seuls les caractères alphanumériques sont autorisés",
                                        },
                                    })}
                                    placeholder="John"
                                    fullWidth
                                />
                                {errors.lastName && (
                                    <p style={styles.errorText}>{errors.lastName.message}</p>
                                )}
                            </Grid>
                            <Grid item xs={5} md={5} lg={5}>
                                <Typography sx={styles.label}>{t("firstName")}</Typography>
                                <KTextField
                                    validation={register("firstName", {
                                        required: t("physicalCards.firstNameError"),
                                        pattern: {
                                            value: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/,
                                            message: "Seuls les caractères alphanumériques sont autorisés",
                                        },
                                    })}
                                    placeholder="Doe"
                                    fullWidth
                                />
                                {errors.firstName && (
                                    <p style={styles.errorText}>{errors.firstName.message}</p>
                                )}
                            </Grid>
                        </Grid>
                        <Spacing marginBottom={"30px"}/>
                        <Box>
                            <Typography sx={styles.label}>
                                Email ({t("optional")}){" "}
                            </Typography>
                            <KTextField
                                validation={register(
                                    "email"
                                )}
                                placeholder="Ex: johndoe@gmail.com"
                                fullWidth
                            />
                            {errors.email && <p>{errors.email.message}</p>}
                        </Box>
                        <Spacing marginBottom={"50px"}/>

                        <RoundGradientButton
                            fullWidth
                            type="submit"
                            variant={"contained"}
                            color={"inherit"}
                            style={{
                                background: isSubmitting
                                    ? "linear-gradient(to right, #C471ED, #4A90ED)"
                                    : "",
                                color: isSubmitting ? "#FFFFFF" : "",
                            }}
                        >
                            {isSubmitting ? (
                                <>
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "#FFFFFF",
                                            marginRight: "5%",
                                        }}
                                    />
                                    {t("loading")} {"..."}
                                </>
                            ) : state ?
                                t('update')
                                : t("recipients.addRecipientLabel")
                            }
                        </RoundGradientButton>
                    </Box>
                </Box>
                {/*    </CardContent>*/}
                {/*</Card>*/}
            </Box>
            {content && (
                <Popup
                    title=""
                    open={popup}
                    onClose={handleCloseDialog}
                    // handleDialogAfterSuccess={handleDialogAfterSuccess}
                    additionalStyle={{backgroundColor: "#FFFFFF"}}
                >
                    {content}
                </Popup>
            )}
        </Box>
    );
};

export default PayQinRecipient;

/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
    label: {
        fontWeight: 500,
        fontSize: "13px",
        color: "#959595",
        textTransform: "none",
        marginBottom: "2%",
    },
    container: {
        width: {
            sm: "70%",
            md: "60%",
            lg: "40%",
        },
        margin: "auto",
    },
    errorText: {
        color: "red",
        fontSize: "14px",
    },
};
