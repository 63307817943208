import React, {useEffect, useState} from 'react';
import GoogleMapReact from 'google-map-react';
import {Box} from '@mui/material';

import markerImage from '../assets/img/map-marker.png';
import AppConfig from "../config/config";

export interface MarkerProps {
    lat: number|null;
    lng: number|null;
}

export const Marker: React.FC<MarkerProps> = ({lat, lng}) => {

    useEffect(() => {
    }, [lat, lng])
    return (
        <img
            src="https://res.cloudinary.com/payqin-ltd/image/upload/v1662651880/main%20website/Products/Q-2-small_rhzfdh.png"
        />
        // <Box
        //     component={'img'}
        //     // src={markerImage}
        //     src="https://res.cloudinary.com/payqin-ltd/image/upload/v1662651880/main%20website/Products/Q-2-small_rhzfdh.png"
        //     width={'100%'}
        //     // style={{position:"relative", top:"-32px" , left:"-16px", zIndex: 99999}}
        // />
    )
}

interface RetailerLocationMapProps {
    centerLat: number | null;
    centerLng: number | null;
    markerLat: number | null;
    markerLng: number | null;
    onClick: (event: any) => void;
}

const RetailerLocationMap: React.FC<RetailerLocationMapProps> = ({
                                                                     centerLat,
                                                                     centerLng,
                                                                     markerLat,
                                                                     markerLng,
                                                                     onClick,
                                                                 }) => {
    const [lat, setLat] = useState<number|null>(null)
    const [lng, setLng] = useState<number|null>(null)

    useEffect(() => {
        setLat(markerLat)
        setLng(markerLng)
    }, [markerLat, markerLng])

    return (
        <Box style={{ height: '450px', width: '100%' }}>
            <GoogleMapReact
                onClick={onClick}
                bootstrapURLKeys={{ key: AppConfig.googleMapKey }}
                center={{
                    lat: centerLat || 5.317613,
                    lng: centerLng || -4.006326,
                }}
                defaultZoom={16}
            >

                <Marker lat={lat} lng={lng}/>

            </GoogleMapReact>
        </Box>
    );
};

export default RetailerLocationMap;
