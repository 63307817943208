import { I18n } from "i18n-js";
import en from './en.json';
import fr from './fr.json';

const i18n = new I18n({
  ...en,
  ...fr
});

// i18n.fallbacks = true;

i18n.translations = {
  en,
  fr,
};

i18n.defaultLocale = 'fr';

i18n.locale = navigator.language;

export default i18n;
