import Box from "@mui/material/Box";
import {
  AddNewCardlinearGradientIcon,
  DangerIcon,
  MasterCardIcon,
} from "../../../../shared/_utils/constants/icon";
import "./index.css";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import { ChangeEvent, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import Spacing from "../../../../components/Spacing";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import RoundGradientButton from "../../../../components/RoundGradientButton";
import CircularProgress from "@mui/material/CircularProgress";
import { physicalCardsRepository } from "../../repository/physicalCard.repository";
import { CountrySelect } from "../../../Recipients/components/CountrySelect";
import { phoneService } from "../../../../shared/services";
import Popup from "../../../../components/Popup";
import { IResponse } from "../../../../shared/types/transfer.response";
import { t } from "i18next";
import KTextField, {
  inputStyles,
  rootStyles,
} from "../../../../components/KTextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { DialogFunction } from "../../../../shared/types/types";

const bgImgUrl = process.env.PUBLIC_URL + "/physical-card-front0.png";
const payqinImgUrl = process.env.PUBLIC_URL + "/payqinLogo.svg";
const chipImgUrl = process.env.PUBLIC_URL + "/Chip.svg";
const subtractImgUrl = process.env.PUBLIC_URL + "/subtract.svg";

export interface IActivationDataPhysicalCardForm {
  orderId: string;
  lastName: string;
  firstName: string;
  email: string;
  phoneNumber: string;
  phoneCode: string;
  phone: string;
  cardId: string;
  retailerCode: string;
  last4digits: string;
  birthDate: string;
  address: string;
  postalCode: string;
  region: string;
  idType: number;
  idCardNumber: string;
  country: string;
}

export default function CompleteForm() {
  const navigate = useNavigate();
  const [isCardFlipped, setIsCardFlipped] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [response, setResponse] = useState<IResponse | null>(null);
  const [last4Digits, setLast4Digits] = useState("");
  const balance = 1000;
  const cardPan = "**** **** ****";
  const expiration = "12/26";
  const [kError, setKError] = useState<string>("");
  const [dialogContent, setDialogContent] = useState<JSX.Element | null>(null);
  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<JSX.Element>();
  const [handleCloseDialog, setHandleCloseDialog] =
    useState<DialogFunction>(null);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IActivationDataPhysicalCardForm>();

  useEffect(() => {}, []);

  const handleLastDigitsInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, "").slice(-4);
    setLast4Digits(input);
  };

  const handleDoubleClick = () => {
    setIsCardFlipped((prevState) => !prevState);
  };

  const handleTypeOfIdentificationChange = (event: SelectChangeEvent) => {
  };

  const HandleCompleteForm: SubmitHandler<
    IActivationDataPhysicalCardForm
  > = async (activationDataPhysicalCardData) => {
    setIsSubmitting(true);

    let phone;

    if (
      activationDataPhysicalCardData.phoneCode &&
      activationDataPhysicalCardData.phoneNumber
    ) {
      phone = phoneService.normalize(
        activationDataPhysicalCardData.phoneCode,
        activationDataPhysicalCardData.phoneNumber
      );
      activationDataPhysicalCardData.phone = phone;
    }

    const { data, error } = await physicalCardsRepository.activatePhysicalCard(
      activationDataPhysicalCardData
    );

    setIsSubmitting(false);

    if (data) {
      if (data.success) {
        dialogHandler(
          true,
          <>
            <Spacing marginBottom={"20px"} />
            <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
              {t("physicalCards.cardActivated")}
            </Typography>
            <Spacing marginBottom={"20px"} />
            <Typography sx={{ fontWeight: 400, fontSize: "16px" }}>
              {t("physicalCards.cardActivatedInstructions").replace(
                "<:cardDigit>",
                `${activationDataPhysicalCardData.cardId}`
              )}
            </Typography>
          </>,
          () => navigate("/cards")
        );
      } else {
        setKError(data.errorMessage || t("errorOccurred"));
      }
    } else {
      setKError(error || t("errorOccurred"));
    }
  };

  const dialogHandler = (
    result: boolean,
    msg: string | JSX.Element,
    onClose: any
  ) => {
    setDialog(true);
    setDialogTitle(
      result ? (
        <CheckCircleIcon color={"success"} fontSize={"large"} />
      ) : (
        <CancelIcon color={"error"} fontSize={"large"} />
      )
    );
    setDialogContent(
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: "0 2",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {typeof msg === "string" ? (
          <Typography
            color={result ? "#000000" : "red"}
            variant={"h6"}
            marginTop={2}
          >
            {msg}
          </Typography>
        ) : (
          msg
        )}
      </Box>
    );
    setHandleCloseDialog(() => onClose);
  };

  return (
    <Box sx={styles.container}>
      <div className="scene">
        <Box
          className={`card-item${isCardFlipped ? " active" : ""}`}
          onDoubleClick={handleDoubleClick}
          sx={{
            ...styles.cardItem,
            ...(isCardFlipped ? styles.cardItemFlipped : {}),
          }}
        >
          <Card
            className={`card-item__side -front`}
            sx={{ ...styles.paperContainer, ...{ transform: "rotateY(0deg)" } }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: {
                  lg: "360px",
                  sm: "340px",
                  xs: "320px",
                },
                justifyContent: "space-between",
                width: "360px",
                maxHeight: "240px",
                height: "240px",
              }}
            >
              <Box sx={styles.virtualCardLogo}>
                <Typography
                  sx={{
                    color: "#F9F9F9",
                    fontSize: "1rem",
                    fontWeight: "400px",
                  }}
                >
                  {t("physicalCards.title")}
                </Typography>
                <Box sx={styles.payqinLogo}></Box>
              </Box>
              <Box sx={styles.chip}></Box>
              <Typography sx={styles.cardNumber}>
                {cardPan} {last4Digits}
              </Typography>
              <Box sx={styles.balanceAndValid}>
                <Box sx={styles.balance}>
                  <Typography
                    sx={{
                      fontSize: ".7em",
                      fontWeight: "500",
                      color: "#FFFFFF",
                    }}
                  >
                    {t("physicalCards.balance")}
                  </Typography>
                  {/*<Typography sx={{*/}
                  {/*    fontSize: '1em',*/}
                  {/*    fontWeight: '700',*/}
                  {/*    color: '#FFFFFF'*/}
                  {/*}}>*/}
                  {/*    ${balance}*/}
                  {/*</Typography>*/}
                </Box>
                <Box sx={styles.visa}>
                  <MasterCardIcon />
                  {/*<Box sx={styles.validity}>*/}
                  {/*    <Typography sx={{*/}
                  {/*        fontSize: '.7em',*/}
                  {/*        fontWeight: '500',*/}
                  {/*        color: '#FFFFFF'*/}
                  {/*    }}>*/}
                  {/*        Valid Thru*/}
                  {/*    </Typography>*/}
                  {/*    <Typography sx={{*/}
                  {/*        fontSize: '1em',*/}
                  {/*        fontWeight: '700',*/}
                  {/*        color: '#FFFFFF'*/}
                  {/*    }}>*/}
                  {/*        {expiration}*/}
                  {/*    </Typography>*/}
                  {/*</Box>*/}
                  {/*<Box sx={styles.logos}>*/}
                  {/*    <MasterCardIcon/>*/}
                  {/*    <Box sx={styles.logo}>*/}
                  {/*    </Box>*/}
                  {/*</Box>*/}
                </Box>
              </Box>
            </CardContent>
          </Card>

          <Card
            className={`card-item__side -back`}
            sx={{
              ...styles.backPaperContainer,
              ...{ transform: "rotateY(180deg)" },
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: {
                  lg: "360px",
                  sm: "340px",
                  xs: "320px",
                },
                justifyContent: "space-around",
                width: "360px",
                maxHeight: "240px",
                height: "240px",
              }}
            >
              <Box sx={{ backgroundColor: "#000000", lineHeight: "1em" }}></Box>
              <Typography sx={styles.cardCvv}>CVV</Typography>
              <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "4px" }}>
                <Typography
                  sx={{
                    height: "34px",
                    left: "24px",
                    top: "136px",
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "26px",
                    lineHeight: "34px",
                    color: "#000000",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "auto 0px",
                  }}
                >
                  ****
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <MasterCardIcon />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </div>

      <Box
        sx={{ pt: 0, p: 1 }}
        component={"form"}
        noValidate
        onSubmit={handleSubmit(HandleCompleteForm)}
      >
        <Spacing marginBottom={"30px"} />
        <Typography sx={styles.titleField}>
          {t("payQinVisaCardDetail")}
        </Typography>
        <Spacing marginBottom={"20px"} />
        <Typography sx={styles.label}>{t("cardId")}</Typography>
        <KTextField
          validation={register("cardId", {
            required: "Le numéro de la carte.",
          })}
          placeholder="12345678909361"
          fullWidth
        />
        <Spacing marginBottom={"20px"} />
        <Grid container columns={12} spacing={4}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography sx={styles.label}>{t("last4Digit")}</Typography>
            <KTextField
              validation={register("last4digits", {
                required: t("requiredField"),
                maxLength: {
                  value: 4,
                  message: "Le champ doit contenir 4 chiffres.",
                },
              })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleLastDigitsInputChange(e);
              }}
              placeholder="1234"
              fullWidth
            />
            {/*<Controller*/}
            {/*    name="last4digits"*/}
            {/*    control={control}*/}
            {/*    defaultValue=""*/}
            {/*    rules={{*/}
            {/*        required: "Ce champ est obligatoire.",*/}
            {/*        maxLength: { value: 4, message: "Le champ doit contenir 4 chiffres." },*/}
            {/*    }}*/}
            {/*    render={({ field }) => (*/}
            {/*        <TextField*/}
            {/*            required*/}
            {/*            fullWidth*/}
            {/*            {...field}*/}
            {/*            onChange={(e: ChangeEvent<HTMLInputElement>) => {*/}
            {/*                handleLastDigitsInputChange(e);*/}
            {/*                field.onChange(e);*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    )}*/}
            {/*/>*/}
            {errors.last4digits && <p>{errors.last4digits.message}</p>}
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography sx={styles.label}>{t("distributorCode")}</Typography>
            <KTextField
              validation={register("retailerCode", {
                required: t("requiredField"),
              })}
              placeholder="XXXXX"
              fullWidth
            />
            {errors.retailerCode && <p>{errors.retailerCode.message}</p>}
          </Grid>
        </Grid>

        <Spacing marginBottom={"30px"} />
        <hr />
        <Spacing marginBottom={"50px"} />

        <Typography sx={styles.titleField}>
          {t("personalInformation")}
        </Typography>

        <Spacing marginBottom={"20px"} />
        <Grid container columns={12} spacing={4}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography sx={styles.label}>{t("firstName")}</Typography>
            <KTextField
              validation={register("firstName", {
                required: t("physicalCards.firstNameError"),
              })}
              placeholder="John"
              fullWidth
            />
            {errors.lastName && <p>{errors.lastName.message}</p>}
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography sx={styles.label}>{t("lastName")}</Typography>
            <KTextField
              validation={register("lastName", {
                required: t("physicalCards.lastNameError"),
              })}
              placeholder="Doe"
              fullWidth
            />
            {errors.firstName && <p>{errors.firstName.message}</p>}
          </Grid>
        </Grid>
        <Spacing marginBottom={"20px"} />
        <Grid container columns={12} spacing={4}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography sx={styles.label}>Email</Typography>
            <KTextField
              validation={register("email", {
                required: t("physicalCards.emailError"),
              })}
              placeholder="johndoe@email.com"
              fullWidth
            />
            {errors.email && <p>{errors.email.message}</p>}
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography sx={styles.label}>{t("birthDate")}</Typography>
            <KTextField
              validation={register("birthDate", {
                required: t("physicalCards.emailError"),
              })}
              sx={{
                sx: { ...rootStyles },
              }}
              inputProps={{
                ...{ sx: { ...inputStyles } },
                ...{ max: "2013-12-31" },
              }}
              type="date"
              // inputProps={{max: "2013-12-31"}}
              fullWidth
            />
            {errors.birthDate && <p>{errors.birthDate.message}</p>}
          </Grid>
        </Grid>
        <Spacing marginBottom={"20px"} />
        <Typography sx={styles.label}>{t("phoneNumber")}</Typography>
        <Grid container columns={12} spacing={4} mb={3}>
          <Grid item xs={4} md={4} lg={3}>
            <CountrySelect
              label=""
              {...register("phoneCode", {
                required: t("physicalCards.countryCodeError"),
              })}
            />
            {errors.phoneCode && (
              <Typography sx={{ color: "red" }}>
                {errors.phoneCode && errors.phoneCode.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={8} md={8} lg={9}>
            <KTextField
              validation={register("phoneNumber", {
                required: t("physicalCards.phoneNumberError"),
                pattern: {
                  value: /^(?=[0-9]).{8,12}$/,
                  message: t("physicalCards.phonePatternError"),
                },
              })}
              placeholder="Ex: XXXXXXXXXXX"
              fullWidth
            />
            {errors.phoneNumber && (
              <Typography sx={{ color: "red" }}>
                {errors.phoneNumber && errors.phoneNumber.message}
              </Typography>
            )}
          </Grid>
        </Grid>
        <hr />
        <Spacing marginBottom={"50px"} />

        <Typography sx={styles.titleField}>{t("nationalId")}</Typography>
        <Spacing marginBottom={"10px"} />
        <FormControl fullWidth>
          <Controller
            name="idType"
            control={control}
            shouldUnregister={false}
            rules={{ required: t("physicalCards.idNumberError") }}
            render={({ field }) => (
              <Select
                {...field}
                {...register("idType")}
                sx={{
                  sx: { ...rootStyles },
                }}
                inputProps={{
                  sx: { ...inputStyles },
                }}
              >
                <MenuItem value={1}>{t("physicalCards.passportType")}</MenuItem>
                <MenuItem value={2}>{t("physicalCards.idCardType")}</MenuItem>
                <MenuItem value={3}>
                  {t("physicalCards.driverLicenseType")}
                </MenuItem>
                <MenuItem value={4}>{t("physicalCards.otherType")}</MenuItem>
              </Select>
            )}
          />
          <Typography sx={{ color: "red" }}>
            {errors.idType && errors.idType.message}
          </Typography>
        </FormControl>
        <Spacing marginBottom={"20px"} />
        <Typography sx={styles.label}>{t("numberOfNationalId")}</Typography>
        <KTextField
          validation={register("idCardNumber", {
            required: t("requiredField"),
          })}
          placeholder="Ex: XXXXXXXXXXX"
          fullWidth
        />
        {errors.idCardNumber && <p>{errors.idCardNumber.message}</p>}

        <Spacing marginBottom={"30px"} />
        {kError && (
          <Typography
            style={{
              color: "#F94144",
              fontSize: "1rem",
              fontWeight: 400,
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {kError}
          </Typography>
        )}

        <Grid item xs={12} mb={3} mt={5}>
          <RoundGradientButton
            disabled={!isValid || isSubmitting}
            fullWidth
            type="submit"
          >
            {isSubmitting && (
              <>
                <Typography>{t("loading")}...</Typography>
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#FFFFFF",
                    marginRight: "5%",
                  }}
                />
              </>
            )}
            <Typography sx={{ textTransform: "revert" }}>
              {t("proceedToActivate")}
            </Typography>
          </RoundGradientButton>
        </Grid>
      </Box>

      <Popup
        title={dialogTitle}
        open={dialog}
        onClose={handleCloseDialog}
        additionalStyle={{ backgroundColor: "#FFFFFF" }}
      >
        {dialogContent}
      </Popup>

      {/*<Popup*/}
      {/*    title=""*/}
      {/*    open={openPopUp}*/}
      {/*    onClose={setOpenPopUp}*/}
      {/*    additionalStyle={{backgroundColor: "#FFFFFF", marginTop: '-20%'}}*/}
      {/*>*/}
      {/*    <Box sx={{*/}
      {/*        display: "flex",*/}
      {/*        flexDirection: 'column',*/}
      {/*        minWidth: "266px",*/}
      {/*        width: {*/}
      {/*            'sm': '100px', 'md': '400px'*/}
      {/*        },*/}
      {/*        alignItems: "center",*/}
      {/*        mb: 4*/}
      {/*    }}>*/}
      {/*        {response?.success*/}
      {/*            ? <>*/}
      {/*                <Box sx={{backgroundColor: "#E6F4FD", padding: 1, borderRadius: "100%"}}>*/}
      {/*                    <AddNewCardlinearGradientIcon width={48} height={48}/>*/}
      {/*                </Box>*/}
      {/*                <Spacing marginBottom={"20px"}/>*/}
      {/*                <Typography sx={{fontWeight: 700, fontSize: '20px'}}>*/}
      {/*                    Card Activated*/}
      {/*                </Typography>*/}
      {/*                <Spacing marginBottom={"20px"}/>*/}
      {/*                <Typography sx={{fontWeight: 400, fontSize: '16px'}}>*/}
      {/*                    Card ending with 1234 activated successfully.*/}
      {/*                    Fund your card before you make any purchase.*/}
      {/*                </Typography>*/}
      {/*            </>*/}
      {/*            : <>*/}
      {/*                <DangerIcon width={48} height={48}/>*/}
      {/*                <Spacing marginBottom={"20px"}/>*/}
      {/*                <Typography  sx={{fontWeight: 400, fontSize: '16px'}}>*/}
      {/*                    {response?.message ?? response?.errorMessage ?? response?.extra?.message}*/}
      {/*                </Typography>*/}
      {/*            </>*/}
      {/*        }*/}

      {/*    </Box>*/}
      {/*</Popup>*/}
    </Box>
  );
}

/** @type{import("@mui/material").SxProps}*/
const styles = {
  titleField: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#000000",
    marginBottom: 3,
  },
  input: { backgroundColor: "rgb(249, 249, 249)", borderRadius: "8px" },
  label: {
    fontWeight: 500,
    fontSize: "13px",
    color: "#959595",
  },
  addCardButton: {
    display: "flex",
    justifyContent: "space-around",
  },

  // STYLE DE LA CARTE
  "@keyframes flipCard": {
    from: {
      transform: "rotateY(0deg)",
    },
    to: {
      transform: "rotateY(180deg)",
    },
  },
  cardItem: {
    width: "100%",
    height: "100%",
    transition: "transform 0.6s",
    transformStyle: "preserve-3d",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  cardItemFlipped: {
    animation: "$flipCard 0.6s",
    transform: "rotateY(180deg)",
  },
  cardCvv: {
    fontSize: "16px",
    color: "#FFFFFF",
    fontWeight: 700,
    display: "flex",
    justifyContent: "flex-end",
  },
  logo: {
    width: "18.42px",
    height: "23.29px",
    transform: "rotate(-0.31deg)",
    flex: "none",
    order: 1,
    flexGrow: 0,
    backgroundImage: `url(${subtractImgUrl})`,
  },
  visaLogo: {
    width: "48.77px",
    height: "23.96px",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
  logos: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px",
    gap: "7.7px",
    width: "79px",
    height: "24px",
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
  validity: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "4px",
    // position: "absolute",
    // width: "51px",
    height: "38px",
    order: 0,
    flexGrow: 0,
  },
  visa: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: "0px",
    top: "178px",
  },
  balance: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "4px",
    // position: "absolute",
    width: "48px",
    height: "38px",
    left: "24px",
    top: "178px",
  },
  balanceAndValid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardNumber: {
    // position: "absolute",
    height: "34px",
    left: "24px",
    top: "136px",
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "26px",
    lineHeight: "34px",
    color: "#FFFFFF",
  },
  chip: {
    // position: "absolute",
    width: "37.22px",
    height: "28.36px",
    left: "24px",
    top: "67px",
    opacity: "0.7",
    backgroundImage: `url(${chipImgUrl})`,
  },
  virtualCardLogo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
    // gap: "152px",

    // position: "absolute",
    // width: "311.85px",
    height: "27px",
    // left: "24px",
    // top: "24px",
  },
  payqinLogo: {
    width: "71.85px",
    height: "20px",
    background: `url(${payqinImgUrl})`,
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
  backPaperContainer: {
    backgroundImage: `url(${bgImgUrl})`,
    backgroundSize: "cover",
    // position: "absolute",
    maxWidth: {
      lg: "360px",
      sm: "340px",
      xs: "320px",
    },
    width: "360px",
    maxHeight: "240px",
    height: "240px",
    // top: 0,
    // right: 0,
    borderRadius: "24px",
  },
  paperContainer: {
    backgroundImage: `url(${bgImgUrl})`,
    backgroundSize: "cover",
    position: "relative",
    maxWidth: {
      lg: "360px",
      sm: "340px",
      xs: "320px",
    },
    width: "360px",
    maxHeight: "240px",
    height: "240px",
    // left: "0px",
    // top: "0px",
    borderRadius: "24px",
  },
  container: {
    width: {
      sm: "70%",
      md: "45%",
      xl: "40%",
    },
    margin: "auto",
  },
};
