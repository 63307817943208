import { virtualCardsService } from "../../../shared/services/virtualsCards.service";
import {
  IActivateCardResponse,
  ICitiesByCountryResponse,
  IOrderPhysicalCardResponse,
  IPhysicalCardResponse,
  ISuccess,
  ISupportedCountriesResponse,
  ITransactionsResponse,
} from "../models/physicalCard.response";
import { physicalCardService } from "../../../shared/services/physicalCard.service";
import {
  IOrderPhysicalCardData,
  IOrderPhysicalCardForm,
} from "../OrderPhysicalCard/CompleteForm";
import { IActivationDataPhysicalCardForm } from "../ActivatePhysicalCard/CompleteForm";
import { ITransactions } from "../../Wallet/models/transactionsResponse";
import { IApiResponse } from "../../../shared/types/IApiResponse";
import { ITopUpPhysicalCardFees } from "../../../shared/types/ITopUpPhysicalCardFees";
import { AxiosError, AxiosResponse, isAxiosError } from "axios";
import { IDataResponse } from "../../../shared/types/IDataResponse";

const topUp = async (
  cardId: string,
  amount: string
): Promise<IApiResponse<IDataResponse | null>> => {
  try {
    const { data, status, statusText } = await physicalCardService.topUp(
      cardId,
      amount
    );
    return Promise.resolve({
      data: data as IDataResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      const axiosResponse: AxiosResponse = axiosError.response!;

      const { data, status, statusText } = axiosResponse;

      return {
        data: null,
        error: data.errorMessage || data.statusText || data.message,
      };
    } else {
      return {
        data: null,
        error: "An error occurred",
      };
    }
  }
};

const getFundDetails = async (
  amount: string
): Promise<IApiResponse<ITopUpPhysicalCardFees | null>> => {
  try {
    const { data, status, statusText } =
      await physicalCardService.getFundDetails(amount);
    return Promise.resolve({
      data: data as ITopUpPhysicalCardFees,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return {
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        };
      } else {
        const message = axiosError.message;
        return {
          data: null,
          error: message,
        };
      }
    } else {
      return {
        data: null,
        error: "An error occurred",
      };
    }
  }
};

const activatePhysicalCard = async (
  activationData: IActivationDataPhysicalCardForm
): Promise<IActivateCardResponse> => {
  try {
    const { data, status, statusText } =
      await physicalCardService.activatePhysicalCard(activationData);

    return Promise.resolve({
      data: data,
      error: null,
    } as IActivateCardResponse);
  } catch (error) {
    return Promise.resolve({
      data: null,
      error: "Une erreur est survenu lors de l'activation de la carte",
    });
  }
};

const getCitiesByCountry = async (
  countryId: string
): Promise<ICitiesByCountryResponse> => {
  try {
    const { data, status, statusText } =
      await physicalCardService.getCitiesByCountry(countryId);

    return Promise.resolve({
      data: data,
      error: null,
    } as ICitiesByCountryResponse);
  } catch (error) {
    return Promise.resolve({
      data: null,
      error: "impossible de recuperer les pays",
    } as ICitiesByCountryResponse);
  }
};

const getSupportedCountries =
  async (): Promise<ISupportedCountriesResponse> => {
    try {
      const { data, status, statusText } =
        await physicalCardService.getSupportedCountries();

      return Promise.resolve({
        data: data,
        error: null,
      } as ISupportedCountriesResponse);
    } catch (error) {
      return Promise.resolve({
        data: null,
        error: "impossible de recuperer les pays",
      } as ISupportedCountriesResponse);
    }
  };

const orderPhysicalCard = async (
  dataOrderPhysicalCard: IOrderPhysicalCardData
): Promise<IApiResponse<IDataResponse | null>> => {
  try {
    const { data, status, statusText } =
      await physicalCardService.orderPhysicalCard(dataOrderPhysicalCard);

    return Promise.resolve({
      data: data as IDataResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;
        return Promise.resolve({
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        });
      } else {
        const message = axiosError.message;
        return Promise.resolve({
          data: null,
          error: message,
        });
      }
    } else {
      return Promise.resolve({
        data: null,
        error: "An error occurred",
      });
    }
  }
};

const getPhysicalCard = async (): Promise<IPhysicalCardResponse> => {
  try {
    const { data, status, statusText } =
      await physicalCardService.getPhysicalCard();

    return Promise.resolve({
      data: data,
      error: null,
    } as IPhysicalCardResponse);
  } catch (error) {
    return Promise.resolve({
      data: null,
      error: "impossible de recuperer les details de la carte",
    } as IPhysicalCardResponse);
  }
};

const getCardTransactions = async (cardId: string) => {
  try {
    const { data, status, statusText } =
      await physicalCardService.getCardTransactions(cardId);
    return Promise.resolve({
      data: data as ITransactions,
      error: null,
    });
  } catch (error) {
    return Promise.resolve({
      data: null,
      error: "impossible les transactions de la cartes",
    });
  }
};

export const physicalCardsRepository = {
  getPhysicalCard,
  getCardTransactions,
  orderPhysicalCard,
  getSupportedCountries,
  getCitiesByCountry,
  activatePhysicalCard,
  getFundDetails,
  topUp,
};
