import { uploadService } from "../services/upload.service";
import {IUploadFile} from "../types/IUploadFile";

const uploadFile = async ({ file, folder }: IUploadFile) => {

    const formData = new FormData();
    formData.append("file", file);
    formData.append("folder", folder);
    

    try {
        const {data} = await uploadService.uploadFile(formData);
        return Promise.resolve({
            data : data,
            error: null
        });
    } catch
        (error: any) {
        return Promise.resolve({
            data: null,
            error: error.response.data.message,
        });
    }


  };

export const uploadRepository = {uploadFile}