import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import IForm from "../../../shared/types/IForm";
import {UseFormRegister} from "react-hook-form";
import {countryService} from "../../../shared/services"
import React, {useEffect, useState} from "react";
import {inputStyles, rootStyles} from "../../../components/KTextField";
import {ICountry} from "../../../shared/types/IRecipient";
import {register} from "echarts/types/src/component/axisPointer/globalListener";

export const CountrySelect = React.forwardRef<HTMLSelectElement,
    {
        label?: string, data?: ICountry[],
        customRootInputStyles?: React.CSSProperties
        customInputStyles?: React.CSSProperties,
        defaultValue?: string
    } & ReturnType<UseFormRegister<IForm>>>(({
                                                 onChange,
                                                 onBlur,
                                                 name,
                                                 label,
                                                 data,
                                                 customRootInputStyles,
                                                 customInputStyles,
                                                                                              defaultValue
                                             }, ref) => {

    const [countries, setCountries] = useState<ICountry[]>();

    useEffect(() => {
        if (data) {
            setCountries(data)
        }
    }, [])


    return (

        <FormControl fullWidth size="small">
            <InputLabel id="country-select-label">{label}</InputLabel>
            <Select
                name={name} ref={ref} onChange={onChange} onBlur={onBlur}
                defaultValue={defaultValue}
                placeholder="Pays"
                displayEmpty
                sx={{...rootStyles, ...customRootInputStyles}}
                inputProps={{
                    sx: {...customInputStyles}
                }}
            >
                {countries ?
                    countries.map((country) => (
                        <MenuItem key={country.countryCode} value={country.countryCode}>
                            <Box sx={{display: 'flex', alignItems: 'center', fontSize: '16px'}}>
                                <img
                                    src={country.flag}
                                    alt=""
                                    style={{marginRight: "8px", width: "20px"}}
                                />
                                {country.countryCode}
                            </Box>
                        </MenuItem>
                    )) :
                    countryService.countries.map((country) => (
                        <MenuItem key={country.phone} value={country.phone}>
                            <Box sx={{display: 'flex', alignItems: 'center', fontSize: '16px'}}>
                                <img
                                    src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                                    alt=""
                                    style={{marginRight: '8px'}}
                                />
                                {countryService.normalizeCountryCode(country.phone)}
                            </Box>
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
});