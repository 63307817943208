import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NewCardButton from "./components/NewCardButton";
import Carousel from "./components/Carousel";
import {
  ActivateCardGradientColorIcon,
  AddNewCardIcon,
  AddNewCardlinearGradientIcon,
  ArrowUpRightIcon,
  CardIsFilledFalse,
  FreezeCardIcon,
  MoreIcon,
  TransactionsIcon,
} from "../../shared/_utils/constants/icon";
import { ICard } from "./models/physicalCard.response";
import { virtualCardsRepository } from "./repository/virtualCards.repository";
import { IVirtualCardDetail } from "./models/virutalCards.response";
import { isEmpty } from "../../shared/_utils/isEmpty";
import VirtualCard from "../../components/VirtualCard";
import PhysicalCard from "../../components/PhysicalCard";
import { physicalCardsRepository } from "./repository/physicalCard.repository";
import Spacing from "../../components/Spacing";
import Popup from "../../components/Popup";
import RoundGradientButton from "../../components/RoundGradientButton";
import OrderActivateCardPopUpContent from "./components/OrderActivateCardPopUpContent";
import { useNavigate } from "react-router-dom";
import {
  ITransactions,
  Transaction as TransactionsDto,
} from "../Wallet/models/transactionsResponse";
import { ITransactionView } from "../Wallet/Wallet";
import { IStatusItem } from "../Wallet/models/transactionStatics.response";
import EnhancedTable from "../Wallet/components/EnhancedTable";
import KTabPanel from "./components/KTabPanel";
import { SubmitHandler, useForm } from "react-hook-form";
import AmountField from "../../components/AmountField";
import CircularProgress from "@mui/material/CircularProgress";
import { IApiResponse } from "../../shared/types/IApiResponse";
import { ITransactionsVirtualCardFees } from "../../shared/types/ITransactionsVirtualCardFees";
import { transactionsRepository } from "../../shared/repository/transactions.repository";
import BuildViewTransactions from "../../shared/_utils/_helpers/BuildViewTransactions";
import { IDataResponse } from "../../shared/types/IDataResponse";
import GradientCircularProgress from "../../components/GradientCircularProgress";
import { DialogFunction } from "../../shared/types/types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { t } from "i18next";
import { walletRepository } from "../Wallet/repository/wallet.repository";
import AmountFormatter from "../../shared/_utils/_helpers/AmountFormatter";
import { CountrySelect } from "../Recipients/components/CountrySelect";
import KTextField from "../../components/KTextField";

export interface ICardView {
  cardId: string;
  balance: string;
  cardPan: string;
  cardType: string;
  expiration: string;
  isVirtual: boolean;
  city?: string;
  cvv?: string;
  country?: string;
  state?: string;
}

const Cards = () => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.up("md"));
  const chipImgUrl = "./../assets/PayQin-Cards.png";
  const navigate = useNavigate();

  const [physicalCard, setPhysicalCard] = useState<ICard[] | []>([]);
  const [virtualCards, setVirtualCards] = useState<
    (IVirtualCardDetail | null)[]
  >([]);
  const [cards, setCards] = useState<JSX.Element[]>([]);
  const [cardsIds, setCardsIds] = useState<string[] | null>([]);
  const [loadingCards, setLoadingCards] = useState<boolean>(true);
  const [loadingVirtualsCards, setLoadingVirtualsCards] =
    useState<boolean>(true);
  const [loadingTransactions, setLoadingTransactions] = useState<boolean>(true);
  const [openPopUpOrderCard, setOpenPopUpOrderCard] = useState(false);
  const [transactions, setTransactions] = useState<ITransactionView[]>([]);
  const [types, setTypes] = useState<IStatusItem[] | null>([]);
  const [currentCard, setCurrentCard] = useState<ICardView>({} as ICardView);

  useEffect(() => {
    setLoadingCards(true);
    loadInitialData().then(() => {});
  }, []);

  useEffect(() => {
    const combinedCards = combineCards(physicalCard, virtualCards);
    setCards([...combinedCards]);
  }, [physicalCard, virtualCards]);

  const getCards = async (cardsIds: string[]) => {
    return await Promise.all(
      cardsIds.map(async (cardId) => {
        const res = await virtualCardsRepository.cardDetails(cardId);
        const data = res.data;
        if (null != data) {
          return data.card;
        } else {
          return null;
        }
      })
    );
  };

  const loadInitialData = async () => {
    const res = await physicalCardsRepository.getPhysicalCard();
    if (res.data) {
      setPhysicalCard(res.data.cards);
    }

    const result = await virtualCardsRepository.cardsIds();
    const data = result.data;

    if (null != data && null != data.cards) {
      const cardsIds = data.cards;
      setCardsIds(cardsIds);
      setLoadingVirtualsCards(true);
      const resultVirtualCards = await getCards(cardsIds);
      if (resultVirtualCards) {
        setVirtualCards(resultVirtualCards);
      }
      setLoadingVirtualsCards(false);
    }
    setLoadingCards(false);
  };

  const combineCards = (
    physicalCards: ICard[] | [],
    virtualCards: (IVirtualCardDetail | null)[]
  ): JSX.Element[] => {
    const combinedList: JSX.Element[] = [];

    if (physicalCards.length > 0) {
      const physicalCardsView = createPhysicalCardView(physicalCards);
      combinedList.push(...physicalCardsView);
    }

    if (loadingVirtualsCards) {
      combinedList.push(
        <Skeleton
          variant="rectangular"
          style={{
            borderRadius: "10px",
            maxWidth: "360px",
            width: "360px",
            paddingTop: "65%",
          }}
        ></Skeleton>
      );
    } else {
      if (!isEmpty(virtualCards)) {
        const virtualCardsView = createVirtualCardView(virtualCards);
        combinedList.push(...virtualCardsView);
      }
    }

    return combinedList;
  };

  const handleSlideChange = async (index: any) => {
    setLoadingTransactions(true);

    const cardView: ICardView = index.props;
    setCurrentCard(cardView);
  };

  const handlePhysicalCardButton = () => {
    setOpenPopUpOrderCard(true);
  };

  const addVirtualCard = () => {
    navigate("/cards/virtuals-cards/add");
  };

  const navigateTo = (url: string) => {
    navigate(url);
  };

  const updateCard = async (cardData: ICardView) => {
    if (!cardData.cardPan) {
      return;
    }

    let upToDateCard: any;

    if (cardData.isVirtual) {
      const { data, error } = await virtualCardsRepository.cardDetails(
        cardData.cardId
      );

      if (data && data.card) {
        const virtualCard = formatVirtualCardDataView(data.card);
        upToDateCard = BuildVirtualCardView(virtualCard);
      } else {
        return error;
      }
    } else {
      const { data, error } = await physicalCardsRepository.getPhysicalCard();

      if (data && data.cards[0]) {
        const physicalCard = formatPhysicalCardDataView(data.cards[0]);
        upToDateCard = BuildPhysicalCardView(physicalCard);
      } else {
        return error;
      }
    }

    if (typeof upToDateCard === "object") {
      const indexOfCardToUpdate = cards.findIndex(
        (card) => card.props.cardPan === upToDateCard.props.cardPan
      );

      if (indexOfCardToUpdate !== -1) {
        const updatedCards = [...cards];
        updatedCards[indexOfCardToUpdate] = upToDateCard;
        setCards(updatedCards);
      }
    }
  };

  return (
    <Box mt={2}>
      {loadingCards && isEmpty(physicalCard) && isEmpty(virtualCards) ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GradientCircularProgress />
        </div>
      ) : cardsIds && cardsIds.length == 0 && isEmpty(physicalCard) ? (
        <Box sx={styles.containerNoCard}>
          <Box
            component="img"
            src={
              "https://res.cloudinary.com/payqin-ltd/image/upload/v1699461192/web-app/PayQin_Cards_3_dy66la.png"
            }
            sx={{ width: "70%", display: "flex", margin: "auto" }}
          />
          <Spacing marginBottom={"50px"} />
          <Box sx={styles.title}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {t("dontHaveAnycards")}
            </Typography>
            <Spacing marginBottom={"20px"} />
            <Typography
              style={{
                fontWeight: "400",
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {t("createPhyscialVirtualCard")}
            </Typography>
          </Box>
          <Spacing marginBottom={"20px"} />
          <Grid
            container
            spacing={3}
            style={{ justifyContent: "space-around" }}
          >
            <Grid item xs={5} sm={5}>
              <RoundGradientButton
                startIcon={<AddNewCardlinearGradientIcon />}
                onClick={addVirtualCard}
                variant="contained"
                color="inherit"
              >
                <Typography sx={{ textTransform: "none" }}>
                  {t("addNewCard")}
                </Typography>
              </RoundGradientButton>
            </Grid>
            <Grid item xs={5} sm={5}>
              <RoundGradientButton
                startIcon={<AddNewCardlinearGradientIcon />}
                onClick={() => handlePhysicalCardButton()}
                variant="contained"
                color="inherit"
              >
                <Typography sx={{ textTransform: "revert" }}>
                  {t("orderActivateCard")}
                </Typography>
              </RoundGradientButton>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box>
          <Box sx={styles.firstSection}>
            <Box sx={styles.newCard}>
              <NewCardButton
                action={addVirtualCard}
                additionalStyle={{
                  height: isSmScreen ? "108px" : "120px",
                  width: isSmScreen ? "266px" : "133px",
                  marginRight: isSmScreen ? "0px" : "10px",
                  marginBottom: isSmScreen ? "0px" : "10px",
                }}
                title={t("addNewCard")}
                children={<AddNewCardIcon />}
              />
              <br />
              <NewCardButton
                action={() => handlePhysicalCardButton()}
                additionalStyle={{
                  background: "#FFFFFF",
                  height: isSmScreen ? "108px" : "120px",
                  width: isSmScreen ? "266px" : "133px",
                }}
                title={t("orderActivateCard")}
                children={<ActivateCardGradientColorIcon />}
              />
            </Box>
            {cards.length > 0 ? (
              cards.length === 1 ? (
                <Box sx={{ margin: "auto" }}>{cards}</Box>
              ) : (
                <Box sx={styles.carousel}>
                  <Carousel
                    items={cards}
                    onSlideChange={handleSlideChange}
                    initialSlide={0}
                  />
                </Box>
              )
            ) : null}
          </Box>

          <CardManagement currentCard={currentCard} updateCardFn={updateCard} />
        </Box>
      )}

      <Popup
        title=""
        open={openPopUpOrderCard}
        onClose={setOpenPopUpOrderCard}
        additionalStyle={{ backgroundColor: "#FFFFFF" }}
      >
        <OrderActivateCardPopUpContent navigateTo={navigateTo} />
      </Popup>
    </Box>
  );
};

export default Cards;

const formatPhysicalCardDataView = (card: ICard) => {
  const cardView = {} as ICardView;
  cardView.cardId = card.cardId;
  cardView.balance = card.balance.balance.toString();
  cardView.cardPan = `**** **** **** ${card.last4digits}`;
  cardView.cardType = "NA";
  cardView.expiration = "NA";
  cardView.isVirtual = false;
  return cardView;
};

const createPhysicalCardView = (physicalsCards: ICard[]) => {
  return physicalsCards.map((card) => {
    const cardView = formatPhysicalCardDataView(card);

    return BuildPhysicalCardView(cardView);
  });
};

const formatVirtualCardDataView = (card: IVirtualCardDetail | null) => {
  const cardView = {} as ICardView;
  cardView.cardId = card?.id || "";
  cardView.balance = card?.amount ?? "";
  cardView.cardPan = card?.cardpan ?? "";
  cardView.cardType = card?.card_type ?? "";
  cardView.expiration = card?.expiration ?? "";
  cardView.isVirtual = true;
  cardView.city = card?.city ?? "";
  cardView.state = card?.state ?? "";
  cardView.cvv = card?.cvv ?? "";
  cardView.city = card?.city ?? "";
  cardView.country = card?.country ?? "";
  return cardView;
};

const createVirtualCardView = (virtualCards: (IVirtualCardDetail | null)[]) => {
  return virtualCards.map((card) => {
    const cardView = formatVirtualCardDataView(card);

    return BuildVirtualCardView(cardView);
  });
};

const BuildPhysicalCardView = (cardView: ICardView) => {
  return (
    <PhysicalCard
      balance={cardView.balance}
      cardPan={cardView.cardPan}
      cardType={cardView.cardType}
      expiration={cardView.expiration}
      cardId={cardView.cardId}
      isVirtual={cardView.isVirtual}
    />
  );
};

const BuildVirtualCardView = (cardView: ICardView) => {
  return (
    <VirtualCard
      balance={cardView.balance}
      cardPan={cardView.cardPan}
      cardType={cardView.cardType}
      expiration={cardView.expiration}
      cardId={cardView.cardId}
      isVirtual={cardView.isVirtual}
      city={cardView.city}
      cvv={cardView.cvv}
      country={cardView.country}
      state={cardView.state}
    />
  );
};

interface IPropsCardManagement {
  currentCard: ICardView;
  updateCardFn: (prams: any) => Promise<any>;
}

interface ICardData {
  cardId: string;
  transactions: ITransactionView[];
}

export interface IAmountFieldForm {
  amount: string;
}

export type CardManagementIndex =
  | "TOP_UP"
  | "TRANSACTIONS"
  | "FREEZE"
  | "WITH_DRAW";
const PhysicalCardIndexes = ["TOP_UP", "TRANSACTIONS"];

export const CardManagement = ({
  currentCard,
  updateCardFn,
}: IPropsCardManagement) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    clearErrors,
    setValue,
    reset,
  } = useForm<IAmountFieldForm>({ mode: "onChange" });
  const [selectedIndex, setSelectedIndex] =
    useState<CardManagementIndex>("TRANSACTIONS");
  const [checked, setChecked] = useState(["freeze"]);

  const [loadingTransactions, setLoadingTransactions] = useState<boolean>(true);
  const [transactions, setTransactions] = useState<ITransactionView[]>([]);
  const [types, setTypes] = useState<IStatusItem[]>([]);
  const [balance, setBalance] = useState<string>("");
  const [kError, setKError] = useState<string>("");
  const [balanceError, setBalanceError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingFeeCalculation, setLoadingFeeCalculation] =
    useState<boolean>(false);
  const [errorLoadingFeeCalculation, setErrorLoadingFeeCalculation] = useState<
    string | null
  >(null);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [convertedAmount, setConvertedAmount] = useState<number | null>(null);
  const [fee, setFee] = useState<number | null>(null);
  const [convertedCurrency, setConvertedCurrency] = useState<string | null>(
    null
  );
  const [amount, setAmount] = useState<string | null>(null);
  const [feeRatePhysicalCard, setFeeRatePhysicalCard] = useState<number | null>(
    null
  );
  const [update, setUpdate] = useState<boolean>(false);
  const [initialAmount, setInitialAmount] = useState<string>("");

  // GESTION DU DIALOG
  const [dialogContent, setDialogContent] = useState<JSX.Element | null>(null);
  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<JSX.Element>();
  const [handleCloseDialog, setHandleCloseDialog] =
    useState<DialogFunction>(null);

  const getFeesPhysicalCard = async (amount: string = "5000") => {
    const { data, error } = await physicalCardsRepository.getFundDetails(
      amount
    );


    try {
      if (null !== data) {
        setFeeRatePhysicalCard(data.percentage);
        return data.percentage;
      } else {
        if (error) setKError(error);
        // setError('amount', {type: 'custom', message: error})
      }
    } catch (e: any) {
      setKError(e.toString);
      // setError('amount', {type: 'custom', message: e.toString()})
    }
  };

  const calculateTopUpFeePhysicalCard = async (
    amount: string
  ): Promise<void> => {
    if (isEmpty(amount)) {
      setFee(null);
      setConvertedAmount(null);
      return;
    }

    setValue("amount", amount);
    setAmount(amount);
    setLoadingFeeCalculation(true);

    let fees = 0;
    let finalAmount = 0;

    if (null != feeRatePhysicalCard) {
      fees = (feeRatePhysicalCard * +amount) / 100;
      finalAmount = +amount - fees;
    } else {
      const percentage = await getFeesPhysicalCard(amount);
      if (percentage) {
        fees = (percentage * +amount) / 100;
        finalAmount = +amount - fees;
      }
    }

    setConvertedAmount(finalAmount);
    setFee(fees);
    setErrorLoadingFeeCalculation(null);

    setLoadingFeeCalculation(false);
  };

  const calculateFeeVirtualCard = async (amount: string): Promise<void> => {
    if (isEmpty(amount)) {
      setFee(null);
      setConvertedAmount(null);
      return;
    }
    setValue("amount", amount);

    setAmount(amount);
    setLoadingFeeCalculation(true);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(async () => {
      let res: IApiResponse<ITransactionsVirtualCardFees | null>;
      if (selectedIndex == "WITH_DRAW") {
        res = await virtualCardsRepository.getFundDetails(amount, false);
      } else {
        res = await virtualCardsRepository.getFundDetails(amount);
      }

      const data = res.data;

      if (null !== data) {
        setErrorLoadingFeeCalculation(null);
        setConvertedAmount(data.convertedAmount + data.commission);
        setFee(data.fee);
        setConvertedCurrency(data.convertedTo);
      } else {
        if (res.error) {
          setKError(res.error);
          // setError('amount', {type: 'custom', message: res.error})
          setErrorLoadingFeeCalculation(res.error);
        }
      }
      setLoadingFeeCalculation(false);
    }, 4000);

    setTimer(newTimer);
  };

  const kReset = (): void => {
    setInitialAmount("");
    setErrorLoadingFeeCalculation(null);
    setConvertedAmount(null);
    setFee(null);
    setConvertedCurrency(null);
    clearErrors("amount");
    setAmount(null);
    setKError("");
    reset();
  };

  const topUpPhysicalCard = async (amountField: IAmountFieldForm) => {
    const resp = await physicalCardsRepository.topUp(
      currentCard.cardId,
      amountField.amount
    );

    return resp;
  };

  const topUpVirtualCard = async (amountFieldData: IAmountFieldForm) => {
    const resp = await virtualCardsRepository.topUp(
      currentCard.cardId,
      amountFieldData.amount
    );
    return resp;
  };

  const withDrawVirtualCard = async (amountData: IAmountFieldForm) => {
    const resp = await virtualCardsRepository.withDraw(
      currentCard.cardId,
      amountData.amount
    );
    return resp;
  };

  const checkResult = (resp?: IApiResponse<IDataResponse | null>) => {
    if (!resp) {
      setKError(t("errorOccurred"));
      return;
    }

    const { data, error } = resp;

    if (data) {
      if (data.success) {
        dialogHandler(
          true,
          data.message || t("operationCompletedSuccessfully"),
          setDialog
        );
        setUpdate(true);
      } else {
        setKError(data.errorMessage || data.message || t("errorOccurred"));
      }
    } else {
      setKError(error || t("errorOccurred"));
    }
  };

  const dialogHandler = (result: boolean, msg: string, onClose: any) => {
    setDialog(true);
    setDialogTitle(
      result ? (
        <CheckCircleIcon color={"success"} fontSize={"large"} />
      ) : (
        <CancelIcon color={"error"} fontSize={"large"} />
      )
    );
    setDialogContent(
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: "0 2",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          color={result ? "#000000" : "red"}
          variant={"h6"}
          marginTop={2}
        >
          {msg}
        </Typography>
      </Box>
    );
    setHandleCloseDialog(() => onClose);
  };

  const handleTransactionForm: SubmitHandler<IAmountFieldForm> = async (
    data
  ) => {
    if (isEmpty(data.amount)) {
      setKError(t("enterAmount"));
      return;
    }

    setIsSubmitting(true);
    let resp;
    switch (selectedIndex) {
      case "TOP_UP":
        if (currentCard.isVirtual) {
          resp = await topUpVirtualCard(data);
        } else {
          resp = await topUpPhysicalCard(data);
        }
        break;
      case "WITH_DRAW":
        resp = await withDrawVirtualCard(data);
        break;
    }

    checkResult(resp);
    setIsSubmitting(false);
  };

  const getBalance = async (): Promise<void> => {
    const { data, error } = await walletRepository.getBalance();
    if (null != data) {
      setBalance(data.balance.toString());
      setBalanceError(null);
    } else {
      setBalance("");
      setBalanceError(error);
    }
  };

  useEffect(() => {
    getBalance().then().catch();
    getFeesPhysicalCard().then((r) => {});
    transactionsRepository.getTransactionStatics().then((result) => {
      const data = result.data;
      if (null != data) {
        setTypes(data.types);
      }
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(currentCard)) {
      setLoadingTransactions(true);
      loadingData(currentCard).then((r) => {
        if (r) setKError(r);
        setLoadingTransactions(false);
      });
    }
  }, [currentCard]);

  useEffect(() => {
    const updateFn = async () => {
      setLoadingTransactions(true);
      return await Promise.all([
        updateCardFn(currentCard),
        loadingData(currentCard),
      ]);
    };
    if (update) {
      updateFn().then((r) => {
        setUpdate(false);
        setLoadingTransactions(false);
        const error = r.find((message) => {
          return typeof message === "string";
        });
        if (error) {
          setKError(error);
        } else {
          setSelectedIndex("TRANSACTIONS");
        }
      });
    }
  }, [update]);

  const loadingData = async (card: ICardView) => {
    kReset();
    return await getTransactions(card);
  };

  const updateNavigationCardManagement = () => {
    const isPhysicalCard = !currentCard.isVirtual;

    if (isPhysicalCard && !PhysicalCardIndexes.includes(selectedIndex)) {
      handleListItemClick("TRANSACTIONS");
    }

    return (
      <>
        {currentCard.cardPan && (
          <Box sx={styles.navigationCardsManagement}>
            <List
              component="nav"
              sx={styles.cardManagement}
              subheader={
                <ListItem
                  sx={{
                    backgroundColor: "#001021",
                    color: "#FFFFFF",
                    border: 1,
                    borderColor: "#C7C7C7",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    height: "70px",
                  }}
                >
                  <ListItemIcon>
                    <CardIsFilledFalse />
                  </ListItemIcon>
                  <ListItemText primary={t("cardsManagement")} />
                  <ArrowUpRightIcon />
                </ListItem>
              }
            >
              <ListItemButton
                selected={selectedIndex == "TOP_UP"}
                onClick={(event) => handleListItemClick("TOP_UP")}
              >
                <ListItemIcon>
                  <MoreIcon />
                </ListItemIcon>
                <ListItemText primary={t("topUp")} />
                <ArrowUpRightIcon />
              </ListItemButton>
              <ListItemButton
                selected={selectedIndex === "TRANSACTIONS"}
                onClick={(event) => handleListItemClick("TRANSACTIONS")}
              >
                <ListItemIcon>
                  <TransactionsIcon />
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-bluetooth"
                  primary="Transactions"
                />
                <ArrowUpRightIcon />
              </ListItemButton>

              {currentCard.isVirtual && (
                <>
                  <ListItemButton
                    selected={selectedIndex === "FREEZE"}
                    onClick={(event) => handleListItemClick("FREEZE")}
                  >
                    <ListItemIcon>
                      <FreezeCardIcon />
                    </ListItemIcon>
                    <ListItemText
                      id="switch-list-label-bluetooth"
                      primary={t("freezeCard")}
                    />
                    <Switch
                      edge="end"
                      onChange={handleToggle("")}
                      checked={checked.indexOf("") !== -1}
                      inputProps={{
                        "aria-labelledby": "switch-list-label-bluetooth",
                      }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex == "WITH_DRAW"}
                    onClick={(event) => handleListItemClick("WITH_DRAW")}
                  >
                    <ListItemIcon>
                      <MoreIcon />
                    </ListItemIcon>
                    <ListItemText
                      id="switch-list-label-bluetooth"
                      primary={t("withdrawFunds")}
                    />
                    <ArrowUpRightIcon />
                  </ListItemButton>
                </>
              )}
            </List>
          </Box>
        )}
      </>
    );
  };

  const [cardsData, setCardsData] = useState<ICardData[]>([]);

  const getTransactions = async (card: ICardView) => {
    const cardData = cardsData.find(
      (cardData) => cardData.cardId === card.cardId
    );

    if (cardData && !update) {
      setTransactions(cardData.transactions);
      return;
    }

    let response: IApiResponse<ITransactions | null>;

    if (card.isVirtual) {
      response = await virtualCardsRepository.getCardTransactions(card.cardId);
    } else {
      response = await physicalCardsRepository.getCardTransactions(card.cardId);
    }

    const { data, error } = response;

    if (data === null) {
      return error;
    }

    let listTransactionsView: ITransactionView[] = [];

    if (!isEmpty(data.transactions)) {
      listTransactionsView = buildTransactionsView(data.transactions);
    }

    setTransactions(listTransactionsView);
    const newCardData = {} as ICardData;
    newCardData.cardId = card.cardId;
    newCardData.transactions = [...listTransactionsView];
    const cpCardsData = [...cardsData];
    cpCardsData.push(newCardData);
    setCardsData(cpCardsData);
  };

  const buildTransactionsView = (transactionsResponse: TransactionsDto[]) => {
    return BuildViewTransactions(transactionsResponse, types);
  };

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleListItemClick = (index: CardManagementIndex) => {
    setSelectedIndex(index);
  };

  return (
    <Box sx={styles.secondSection}>
      {updateNavigationCardManagement()}
      <Box sx={styles.contentCardsManagement}>
        <KTabPanel value={selectedIndex} index={"TOP_UP"}>
          <Card
            component={"form"}
            noValidate
            onSubmit={handleSubmit(handleTransactionForm)}
          >
            <CardContent>
              <Box style={{ margin: "auto" }}>
                {currentCard.isVirtual ? (
                  <AmountField
                    initialAmount={initialAmount}
                    control={control}
                    errors={errors}
                    balance={AmountFormatter(balance).formattedAmount}
                    onChangeAmount={calculateFeeVirtualCard}
                    currency={"$"}
                  />
                ) : (
                  <AmountField
                    initialAmount={initialAmount}
                    control={control}
                    errors={errors}
                    balance={AmountFormatter(balance).formattedAmount}
                    onChangeAmount={calculateTopUpFeePhysicalCard}
                    currency={"CFA"}
                  />
                )}
                {kError && (
                  <Typography
                    style={{
                      color: "#F94144",
                      fontSize: "1rem",
                      fontWeight: 400,
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {kError}
                  </Typography>
                )}
                <Spacing marginBottom={"20px"} />
                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} sx={{ textAlign: "end" }}>
                      <Typography>{t("topUpFees")}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} sx={{ textAlign: "start" }}>
                      {loadingFeeCalculation ? (
                        <CircularProgress
                          size={24}
                          sx={{
                            color:
                              "linear-gradient(to right, #C471ED, #4A90ED)",
                            marginRight: "5%",
                          }}
                        />
                      ) : (
                        <Typography>
                          {!errorLoadingFeeCalculation && null != fee
                            ? `${fee} ${convertedCurrency || "CFA"}`
                            : ""}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} sx={{ textAlign: "end" }}>
                      {currentCard.isVirtual ? (
                        <Typography>{t("convertedAmount")}</Typography>
                      ) : (
                        <Typography>{t("amountToReceive")}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ textAlign: "start" }}>
                      {loadingFeeCalculation ? (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: "#FFFFFF",
                            marginRight: "5%",
                          }}
                        />
                      ) : (
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            fontWeight: 700,
                          }}
                        >
                          {null != convertedAmount &&
                          !errorLoadingFeeCalculation
                            ? `${convertedAmount.toFixed(2)} ${
                                convertedCurrency || "CFA"
                              }`
                            : ""}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Spacing marginBottom={"50px"} />
                <RoundGradientButton
                  disabled={
                    !isValid || isSubmitting || loadingFeeCalculation || update
                  }
                  type={"submit"}
                  variant="contained"
                  color="inherit"
                  style={{
                    background: isSubmitting
                      ? "linear-gradient(to right, #C471ED, #4A90ED)"
                      : "",
                    display: "flex",
                    width: "60%",
                  }}
                >
                  {isSubmitting ? (
                    <>
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "#FFFFFF",
                          marginRight: "5%",
                        }}
                      />
                      <Typography sx={{ color: "#FFFFFF" }}>
                        {t("loading")}...
                      </Typography>
                    </>
                  ) : (
                    <Typography>{t("topUp")}</Typography>
                  )}
                </RoundGradientButton>
              </Box>
            </CardContent>
          </Card>
        </KTabPanel>
        <KTabPanel value={selectedIndex} index={"FREEZE"}>
          <div></div>
        </KTabPanel>
        <KTabPanel value={selectedIndex} index={"TRANSACTIONS"}>
          {loadingTransactions ? (
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "10px",
                paddingTop: "30%",
              }}
            ></Skeleton>
          ) : (
            <EnhancedTable transactions={transactions} />
          )}
        </KTabPanel>
        {currentCard.isVirtual && (
          <KTabPanel value={selectedIndex} index={"WITH_DRAW"}>
            <Card
              component={"form"}
              noValidate
              onSubmit={handleSubmit(handleTransactionForm)}
            >
              <CardContent>
                <Box style={{ margin: "auto" }}>
                  <AmountField
                    initialAmount={initialAmount}
                    control={control}
                    errors={errors}
                    balance={AmountFormatter(balance).formattedAmount}
                    onChangeAmount={calculateFeeVirtualCard}
                    currency={"$"}
                  />
                  <Spacing marginBottom={"20px"} />
                  <Box>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} sx={{ textAlign: "end" }}>
                        <Typography>{t("withdrawFees")}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} sx={{ textAlign: "start" }}>
                        {loadingFeeCalculation ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              color:
                                "linear-gradient(to right, #C471ED, #4A90ED)",
                              marginRight: "5%",
                            }}
                          />
                        ) : (
                          <Typography>
                            {!errorLoadingFeeCalculation && null != fee
                              ? `${fee} ${convertedCurrency}`
                              : ""}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} sx={{ textAlign: "end" }}>
                        <Typography>{t("amountToReceive")}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} sx={{ textAlign: "start" }}>
                        {loadingFeeCalculation ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: "#FFFFFF",
                              marginRight: "5%",
                            }}
                          />
                        ) : (
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: 700,
                            }}
                          >
                            {null != convertedAmount &&
                            !errorLoadingFeeCalculation
                              ? `${convertedAmount.toFixed(2)} ${
                                  convertedCurrency || "CFA"
                                }`
                              : ""}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Spacing marginBottom={"50px"} />
                  <RoundGradientButton
                    disabled={
                      !isValid ||
                      isSubmitting ||
                      loadingFeeCalculation ||
                      update
                    }
                    type={"submit"}
                    variant="contained"
                    color="inherit"
                    style={{
                      background: isSubmitting
                        ? "linear-gradient(to right, #C471ED, #4A90ED)"
                        : "",
                      display: "flex",
                      width: "60%",
                    }}
                  >
                    {isSubmitting ? (
                      <>
                        <CircularProgress
                          size={24}
                          sx={{
                            color: "#FFFFFF",
                            marginRight: "5%",
                          }}
                        />
                        <Typography sx={{ color: "#FFFFFF" }}>
                          {t("loading")}...
                        </Typography>
                      </>
                    ) : (
                      <Typography>{t("withdraw")}</Typography>
                    )}
                  </RoundGradientButton>
                </Box>
              </CardContent>
            </Card>
          </KTabPanel>
        )}
      </Box>

      <Popup
        title=""
        open={update}
        additionalStyle={{ backgroundColor: "#FFFFFF" }}
      >
        <GradientCircularProgress />
      </Popup>

      <Popup
        title={dialogTitle}
        open={dialog}
        onClose={handleCloseDialog}
        additionalStyle={{ backgroundColor: "#FFFFFF" }}
      >
        {dialogContent}
      </Popup>
    </Box>
  );
};

/**
 * @type {import("@mui/material").SxProps}
 */
const styles = {
  addCardButton: {
    display: "flex",
    justifyContent: "space-around",
  },
  title: {
    margin: "auto",
    textAlign: "center",
  },
  containerNoCard: {
    width: {
      sm: "70%",
      md: "60%",
      lg: "40%",
    },
    margin: "auto",
  },
  listing: {
    statusColumn: {
      display: "flex",
      alignItems: "center",
    },
    statusColumnText: {
      ml: 1,
      fontSize: "0.9rem",
    },
    date: {
      fontSize: "0.7rem",
      color: "neutral.normal",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    transactionLabel: {
      fontSize: "0.8rem",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    transactionDetails: {
      ml: 2,
    },
    logo: {
      width: 120,
    },
    transaction: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  cardManagement: {
    background: "#FFFFFF",
    border: 1,
    borderColor: "#C7C7C7",
    borderRadius: "8px",
    // width: '%',
  },
  carousel: {
    overflow: "hidden",
    margin: "0px 20px",
    // display: "flex",
    // justifyContent: "center",
    // margin: "auto",
  },
  availableBalance: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "22px",
    alignItems: "center",
    textAlign: "center",
    color: "#959595",
  },
  amount: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    // width: 'px',
    order: 1,
    flexGrow: 0,
  },
  balance: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "26px",
    lineHeight: "51px",
    alignItems: "center",
    textAlign: "center",
    color: "#959595",
  },
  contentCardsManagement: {},
  navigationCardsManagement: {},
  secondSection: {
    display: "grid",
    gridTemplateColumns: { xs: "1fr", sm: "1fr", md: "1fr", lg: "1fr 2fr" },
    gap: 2,
    width: "100%",
    borderBottomRightRadius: 0,
    borderLeftRightRadius: 0,
    borderBottomLeftRadius: 0,
    mt: 2,
  },
  cards: {},
  newCard: {
    display: { lg: "block", md: "block", sm: "block", xs: "flex" },
  },
  firstSection: {
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
  },
  divider: {
    m: 2,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
};
