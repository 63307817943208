import React, { useEffect } from "react";

const CrispChatScript: React.FC = () => {
    useEffect(() => {

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.innerHTML = `
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "27f636d9-521f-413c-99b4-269663b2f156";
      (function () {
        var d = document;
        var s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    `;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null;
};

export default CrispChatScript;
