import {IApiResponse} from "../../../shared/types/IApiResponse";
import {IDataResponse} from "../../../shared/types/IDataResponse";
import {AxiosError, AxiosResponse, isAxiosError} from "axios";
import {accountService} from "../../../shared/services";
import {IAuthenticatedUserResponse, IAuthResponse,} from "../models/auth.response";
import ICredential from "../../../shared/types/ICredential";
import {displayError, handleAPICommonResp} from "../../../shared/_utils/_helpers/error";
import {recipientsService} from "../../../shared/services/recipients.service";

const registration = async (
  email: string,
  firstName: string,
  lastName: string,
  token: string
): Promise<IApiResponse<any | null>> => {
  try {
    const { data, status, statusText } = await accountService.registration(
      email,
      firstName,
      lastName,
      token
    );
    handleAPICommonResp(data)
    return Promise.resolve({
      data: data,
      error: null,
    });
  } catch (error) {
    return displayError(error)
  }
};

const confirmationEmail = async (
  email: string,
  token: string
): Promise<IApiResponse<any | null>> => {
  try {
    const { data, status, statusText } =
      await accountService.confirmationEmail(email, token);
    handleAPICommonResp(data)
    return Promise.resolve({
      data: data,
      error: null,
    });
  } catch (error) {
    return displayError(error)
  }
};

const checkConfirmationEmail = async (
  email: string,
  code: string,
  token: string
): Promise<IApiResponse<IAuthenticatedUserResponse | null>> => {
  try {
    const { data, status, statusText } =
      await accountService.checkConfirmationEmail(email, code, token);

    return Promise.resolve({
      data: data as IAuthenticatedUserResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return Promise.resolve({
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        });
      } else {
        const message = axiosError.message;
        return Promise.resolve({
          data: null,
          error: message,
        });
      }
    } else {
      return Promise.resolve({
        data: null,
        error: "An error occurred",
      });
    }
  }
};

const verificationPhoneNumberByEmail = async (
  email: string,
  phoneNumber: string
): Promise<IApiResponse<IDataResponse | null>> => {
  try {
    const { data, status, statusText } = await accountService.verificationPhoneNumberByEmail(
      email,
      phoneNumber
    );
    handleAPICommonResp(data)
    return Promise.resolve({
      data: data,
      error: null,
    });
  } catch (error) {
    return displayError(error)
  }
};

const checkOtpVerificationCodePhone = async (
  phone: string,
  code: string
): Promise<IApiResponse<IAuthenticatedUserResponse | null>> => {
  try {
    const { data, status, statusText } =
      await accountService.checkOtpVerificationCodePhone(phone, code);

    return Promise.resolve({
      data: data as IAuthenticatedUserResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return Promise.resolve({
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        });
      } else {
        const message = axiosError.message;
        return Promise.resolve({
          data: null,
          error: message,
        });
      }
    } else {
      return Promise.resolve({
        data: null,
        error: "An error occurred",
      });
    }
  }
};

const sendVerificationCodePhone = async (
  credentials: ICredential, captchaResponse: string
): Promise<IApiResponse<IAuthResponse | null>> => {
  try {
    const { data, status, statusText } =
      await accountService.sendVerificationCodePhone(credentials, captchaResponse);
    return Promise.resolve({
      data: data as IAuthResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return Promise.resolve({
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        });
      } else {
        const message = axiosError.message;
        return Promise.resolve({
          data: null,
          error: message,
        });
      }
    } else {
      return Promise.resolve({
        data: null,
        error: "An error occurred",
      });
    }
  }
};

const checkOtp = async (
    email: string,
    code: string
): Promise<IApiResponse<IAuthenticatedUserResponse | null>> => {
  try {
    const { data, status, statusText } = await accountService.checkOtp(
        email,
        code
    );

    return Promise.resolve({
      data: data as IAuthenticatedUserResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return Promise.resolve({
          data: null,
          error:
              data.errorMessage ??
              data.statusText ??
              data.message ??
              data.statusText,
        });
      } else {
        const message = axiosError.message;
        return Promise.resolve({
          data: null,
          error: message,
        });
      }
    } else {
      return Promise.resolve({
        data: null,
        error: "An error occurred",
      });
    }
  }
};

const sendVerificationCode = async (
    email: string, captchaResponse : string
): Promise<IApiResponse<IAuthResponse | null>> => {
  try {
    const { data, status, statusText } = await accountService.sendVerificationCode(email, captchaResponse);
    handleAPICommonResp(data)
    return Promise.resolve({
      data: data as IAuthResponse,
      error: null,
    });
  } catch (error) {
    return displayError(error)
  }
};

export const userRepository = {
  confirmationEmail,
  checkConfirmationEmail,
  registration,
  sendVerificationCode,
  checkOtp,
  sendVerificationCodePhone,
  checkOtpVerificationCodePhone,
  verificationPhoneNumberByEmail,
};
