import React, { useRef } from "react";
import { Box, IconButton, Button, Grid, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  useFieldArray,
  Controller,
  Control,
  FieldValues,
} from "react-hook-form";
import styled from "@emotion/styled";
import { IBankTransferData } from "../index";
import { HiddenInput } from "../../../../../components/HiddenInput";
import { t } from "i18next";

const InputFilesForm = ({ control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "documents",
    keyName: "documentId",
  });

  const hiddenFileInput = useRef(null);

  const onAddDocuments = () => {
    hiddenFileInput.current.click();
  };

  const handleAddDocuments = (event) => {
    const uploadedFiles = Array.from(event.target.files);

    const files = uploadedFiles.map((file) => ({
      file,
    }));

    append(files);

    hiddenFileInput.current.value = "";
  };

  return (
    <>
      <HiddenInput
        ref={hiddenFileInput}
        type="file"
        multiple
        onChange={handleAddDocuments}
      />

      <Box>
        {fields.map(({ documentId, file }, index) => (
          <Box
            key={documentId}
            sx={{ borderRadius: "8px", border: "dashed 1px red", mb: 2 }}
          >
            <Controller
              control={control}
              name={`documents.${index}`}
              render={() => (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>{file.name}</Typography>
                  <IconButton aria-label="Remove" onClick={() => remove(index)}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Box>
              )}
            />
          </Box>
        ))}

        <Button
          variant="contained"
          onClick={onAddDocuments}
          sx={{
            marginTop: "5%",
            p: 2,
            textTransform: "none",
            fontSize: "16px",
          }}
        >
          {t("recipients.addDocumentsLabel")}
        </Button>
      </Box>
    </>
  );
};

export default InputFilesForm;
