import React from 'react';
import {Box, Button} from "@mui/material";
import {t} from "i18next";
import Timer from "../../../shared/_utils/_helpers/timer";

interface IRenderTimerProps {
    showTimer: boolean
    resendCode: () => void
    handleTimerComplete: () => any
}

const RenderTimerText = ({showTimer, resendCode, handleTimerComplete} : IRenderTimerProps) => {
    if (showTimer) {
        return (
            <Box>
                {t("timerDelay")}{" "}
                <Timer initialSeconds={30} onComplete={handleTimerComplete}/> {t("second")}
            </Box>
        );
    } else {
        return (
            <Box>
                {t("dontReceivedCode")}{" "}
                <Button
                    onClick={resendCode}
                    sx={{textTransform: "revert"}}
                    variant={"text"}
                >
                    {t("resendCode")}
                </Button>
            </Box>
        );
    }
};

export default RenderTimerText;