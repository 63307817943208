import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  AddNewCardlinearGradientIcon,
  ArrowUpRightIcon,
  BankTransferIcon,
  CardAddIcon,
  CardCheckIcon,
  EyeOffFilledIcon,
  EyeOnIsFilledIcon,
  MoneyDollarIcon,
  PaymentLinkIcon,
  PayqinVoucherIcon,
  SendIsFilledFalse,
  SendMobileCreditIcon,
  SendTopUpPayqinTransferIcon,
  TopUpBankCardIcon,
  TopUpIsFilledFalse,
  TopUpMobileMoneyIcon,
} from "../../shared/_utils/constants/icon";
import Popup from "../../components/Popup";
import ShowOperationsPopup from "../../components/ShowOperationsPopup";
import SubOperation from "../../shared/types/SubOperation";
import Spacing from "../../components/Spacing";
import { useNavigate } from "react-router-dom";
import { BarChart, BarChatWhileLoading } from "./components/Charts";
import EnhancedTable from "./components/EnhancedTable";
import { Transaction as TransactionsDto } from "./models/transactionsResponse";
import { IStatusItem } from "./models/transactionStatics.response";
import theme from "../../config/theme";
import { walletRepository } from "./repository/wallet.repository";
import { transactionsRepository } from "../../shared/repository/transactions.repository";
import BuildViewTransactions from "../../shared/_utils/_helpers/BuildViewTransactions";
// import {bankTopUpTypes, subOperationsTopUp, transferProviders} from "../../shared/_utils/constants/Providers";
import useProviderMobileMoney from "../../shared/hook/useProviderMobileMoney";
import GradientCircularProgress from "../../components/GradientCircularProgress";
import { IUser } from "../Auth/models/auth.response";
import RoundGradientButton from "../../components/RoundGradientButton";
import { t } from "i18next";
import AmountFormatter from "../../shared/_utils/_helpers/AmountFormatter";
import OrderActivateCardPopUpContent from "../Cards/components/OrderActivateCardPopUpContent";
import {Period, VertoBeneficiary} from "../../shared/types/types";
import {validVertoBeneficiary} from "../Recipients/BankRecipient/Forms/PersonalInfoForm";
import {isEmpty} from "../../shared/_utils/isEmpty";
import { DateCalendar } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

export const PeriodValues =
    {
      YEAR: 'year',
      MONTH: 'month',
      WEEK: 'week',
      CUSTOM:'custom'
    }

export const validPeriod : Period[] = [
  "year", "month", "week","custom"
]

export interface ITransactionView {
  serviceTransactionRef: string | null;
  date: Date;
  logo: string;
  narration: string;
  message: string | null;
  status: string;
  type: string;
  amount: string;
  color?: string;
  isRefill?: boolean;
  id: string;
  amountConverted: number;
  fee: number;
  phone: string | null;
  user?: IUser | number;
  recipient?: string | null;
  orderStatus: string | null;
  last4digits?: string | null;
  orderNumber: string | null;
}

export interface IFrequentUserView {
  id: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  phone: string;
  picture: string | null;
  bankAccountType?: string;
  accountNumber?: string;
}

const Wallet = () => {
  const navigate = useNavigate();

  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [operation, setOperation] = useState<"TRANSFER" | "TOP_UP">();
  const [openPopupTransfer, setOpenPopupTransfer] = useState(false);
  const [openPopupTopUp, setOpenPopupTopUp] = useState(false);
  const [popupProviderMobileMoney, setPopupProviderMobileMoney] =
    useState(false);
  const [balance, setBalance] = useState<number | null>(null);
  const [balanceError, setBalanceError] = useState<string | null>(null);
  const [transactions, setTransactions] = useState<ITransactionView[]>([]);
  const [transactionsError, setTransactionsError] = useState<string | null>(
    null
  );
  const [providerMobileMoney, setProviderMobileMoney] = useState<
    SubOperation[]
  >([]);
  const [errorTopUpProviders, setErrorTopUpProviders] = useState<string | null>(
    null
  );
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showBalance, setShowBalance] = useState(false);
  const [stats, setStats] = useState<any[]>([]);
  const [isStatLoading, setIsStatLoading] = useState<boolean>(false);
  const [openPopUpOrderCard, setOpenPopUpOrderCard] = useState<boolean>(false);
  const [openCUstomDatePopUp, setOpenCUstomDatePopUp] = useState<boolean>(false);
  const [startDate, setStartDate] = useState< Dayjs|null>(null);
  const [endDate, setEndDate] = useState<Dayjs|null>(null);
  const [dateError, setDateError] = useState<boolean>(false);
  const [period, setPeriod] = useState<Period>("year");
  const [transaction, setTransaction] = useState("");

  const getBalance = async (): Promise<void> => {
    const { data, error } = await walletRepository.getBalance();
    if (null != data) {
      setBalance(data.balance);
      setBalanceError(null);
    } else {
      setBalance(null);
      setBalanceError(error);
    }
  };

  const getWalletTransactions = async (): Promise<void> => {
    const { data, error } = await walletRepository.getTransactions();

    if (null !== data) {
      await buildTransactionsView(data.transactions);
    } else {
      setTransactionsError(error);
    }
  };

  const getTransactionsStatistics = async (period: string): Promise<void> => {
    setIsStatLoading(true);
    const { data, error } = await walletRepository.getWalletsStatistics(period);

    setIsStatLoading(false);
    if (null !== data) {

      setStats(data.txs);
    } else {
      setStats([]);
    }
  };

  const getMobileMoneyProviders = async (): Promise<void> => {
    useProviderMobileMoney()
      .then((res) => setProviderMobileMoney(res))
      .catch((err) => setErrorTopUpProviders(err));
  };

  const loadInitialData = async () => {
    getBalance();
    getWalletTransactions();
    getMobileMoneyProviders();
    getTransactionsStatistics("year");
  };

  const buildTransactionsView = async (
    transactionsResponse: TransactionsDto[]
  ) => {
    // const res  = await getTransactionStatics();
    const { data, error } =
      await transactionsRepository.getTransactionStatics();
    // if (null == types)
    //     return

    const listTransactionsView: ITransactionView[] = BuildViewTransactions(
      transactionsResponse,
      data?.types
    );

    setTransactions(listTransactionsView);
  };

  useEffect(() => {
    loadInitialData().then(() => {});
  }, []);

  const handleChannelOperation = (subOperation: SubOperation | undefined) => {
    if (subOperation) {
      // setOpenPopupTopUp(false)
      switch (subOperation.type) {
        case "BANK_TOP_UP":
          // setOperation("TOP_UP");
          // setOpenDialog(true);
          // navigate("/wallet/top-up")
          navigate(`/wallet/top-up/bank-deposit`);
          break;
        case "MOBILE_MONEY_TOP_UP":
          setOperation("TOP_UP");
          setPopupProviderMobileMoney(true);
          break;
      }
    }
  };

  const handleBankTopUp = (subOperation: SubOperation | undefined) => {
    if (!subOperation) return;

    if (operation == "TOP_UP") {
      if (subOperation.type == "BANK_CARD_TOP_UP") {
        navigate(`/wallet/top-up/bank-card`);
      } else if (subOperation.type == "BANK_DEPOSIT_TOP_UP") {
        navigate(`/wallet/top-up/bank-deposit`);
      }
    }
  };

  const transferProviders: SubOperation[] = [
    {
      id: 1234567895,
      icon: <SendMobileCreditIcon />,
      text: t("mobileMoney"),
      type: "MOBILE_MONEY_TRANSFER",
    },
    {
      id: 7654323456,
      icon: <BankTransferIcon />,
      text: t("bankTransfer"),
      type: "BANK_TRANSFER",
    },
    {
      id: 4567809871,
      icon: <SendTopUpPayqinTransferIcon />,
      text: t("payqinTransfer"),
      type: "PAYQIN_TRANSFER",
    },
  ];

  const bankTopUpTypes: SubOperation[] = [
    {
      id: 1203876362,
      icon: <TopUpBankCardIcon />,
      text: `${t("bankCard")}`,
      type: "BANK_CARD_TOP_UP",
    },
    {
      id: 1203876362,
      icon: <TopUpBankCardIcon />,
      text: `${t("bankDeposit")}`,
      type: "BANK_DEPOSIT_TOP_UP",
    },
  ];

  const subOperationsTopUp: SubOperation[] = [
    {
      id: 9008765431,
      icon: <TopUpMobileMoneyIcon />,
      text: `${t("mobileMoney")} / ${t("wave")}`,
      type: "MOBILE_MONEY_TOP_UP",
    },
    {
      id: 1203876362,
      icon: <TopUpBankCardIcon />,
      text: `${t("bankDeposit")}`,
      // text: `${t("bankCard")} / ${t("bankDeposit")}`,
      type: "BANK_TOP_UP",
    },
    {
      id: 5678932197,
      icon: <PayqinVoucherIcon />,
      text: t("payqinVoucher"),
      type: "VOUCHER_TOP_UP",
      action: () => {
        navigate("/wallet/top-up/voucher");
      },
    },
  ];

  const handleMobileMoney = (subOperation: SubOperation | undefined) => {
    if (!subOperation) return;

    if (operation == "TRANSFER") {
      if (subOperation.code == "cinetpay") {
        subOperation.type = "CINETPAY_TRANSFER";
      } else if (subOperation.code == "wave") {
        subOperation.type = "WAVE_TRANSFER";
      }
      navigate(`/recipients?transaction=${subOperation.type?.toLowerCase()}`);
    }

    if (operation == "TOP_UP") {
      // if (subOperation.code == "cinetpay") {
      //   subOperation.type = "CINETPAY_TOP_UP";
      // } else if (subOperation.code == "wave") {
      //   subOperation.type = "WAVE_TOP_UP";
      // }
      navigate(
        `/wallet/top-up?transaction=${operation.toLowerCase()}&provider=${subOperation.code?.toLowerCase()}&country=${subOperation.country?.toLowerCase()}`
      );
    }
  };

  const goToBankTransfer = () => {
    navigate(
        `/recipients?transaction=bank_transfer`
    );
  }

  const handleTransferOperation = (subOperation: SubOperation | undefined) => {
    if (subOperation) {
      switch (subOperation.type) {
        case "MOBILE_MONEY_TRANSFER":
          setOperation("TRANSFER");
          setOpenPopupTransfer(false);
          setPopupProviderMobileMoney(true);
          break;
        case "BANK_TRANSFER":
          navigate(
            `/recipients?transaction=${subOperation.type?.toLowerCase()}`
          );
          break;
        case "PAYQIN_TRANSFER":
          navigate(
            `/recipients?transaction=${subOperation.type?.toLowerCase()}`
          );
          break;
      }
    }
  };

  const handleChangePeriod = async (event: SelectChangeEvent) => {
    const selectedPeriod = event.target.value as Period;
    if (validPeriod.includes(selectedPeriod) && selectedPeriod!="custom" ) {
      await getTransactionsStatistics(selectedPeriod)
      setPeriod(selectedPeriod);
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleChangeTransaction = (event: SelectChangeEvent) => {
    setTransaction(event.target.value);
  };

  const handlePhysicalCardButton = () => {
    setOpenPopUpOrderCard(true);
  };

  const navigateTo = (url: string) => {
    navigate(url);
  };

  const goToTransactions = () => {
    navigate("/transactions");
  };

  const addVirtualCard = () => {
    navigate("/cards/virtuals-cards/add");
  };

  const addPhysicalCard = () => {
    alert;
  };

  const handleCustomDate=async(selectedPeriod:Period)=>{
    if(startDate==null || endDate==null || startDate.isAfter(endDate)){
      setDateError(true);
      return;
    }
    if (validPeriod.includes(selectedPeriod)) {
      setOpenCUstomDatePopUp(false);
      setDateError(false);
      await getTransactionsStatistics(`${selectedPeriod}-${startDate.toDate().toDateString()}-${endDate.toDate().toDateString()}`)
      setPeriod(selectedPeriod);
    }
  }
  const displayTranslatedDate=(startDate:Date, endDate:Date):string =>{
    const locale=localStorage.getItem("language") as string || "fr";
    const translatedStartDate = startDate.toLocaleString(locale, {
      weekday: "short",
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
    const translatedEndDate = endDate.toLocaleString(locale, {
      weekday: "short",
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
    return `${translatedStartDate} - ${translatedEndDate}`;
  }

  useEffect(()=>{
    if(dateError){
      setStartDate(null);
      setEndDate(null);
    }
  },[dateError])

  return (
    <Box>
      <Card sx={styles.columnsContainer}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={styles.balanceSection}>
            <Typography sx={styles.balanceLabelSection}>
              {t("availableBalance")}
            </Typography>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setShowBalance(!showBalance)}
            >
              {showBalance ? (
                <EyeOnIsFilledIcon color="#000" />
              ) : (
                <EyeOffFilledIcon color="#000" />
              )}
            </span>
          </Box>
          {balance === null ?
              balanceError === null ?
                  <GradientCircularProgress size={20}/>
                  : <Typography variant="h6">{"--------"}</Typography>
              : showBalance ?
                  <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={styles.amount}>
                      {AmountFormatter(balance.toFixed(2)).formattedAmount}
                    </Typography>
                    <Typography
                        sx={{
                          display: "flex",
                          fontSize: "16px",
                          padding: "12px 10px 0",
                          fontWeight: 700,
                        }}
                    >
                      CFA
                    </Typography>
                  </Box>
                  : <Typography variant="h4">{"******"} </Typography>
          }
          {/*{balance !== null && showBalance ? (*/}
          {/*  <Box sx={{ display: "flex" }}>*/}
          {/*    <Typography variant="h5" sx={styles.amount}>*/}
          {/*      {AmountFormatter(balance.toFixed(2)).formattedAmount}*/}
          {/*    </Typography>*/}
          {/*    <Typography*/}
          {/*      sx={{*/}
          {/*        display: "flex",*/}
          {/*        fontSize: "16px",*/}
          {/*        padding: "12px 10px 0",*/}
          {/*        fontWeight: 700,*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      CFA*/}
          {/*    </Typography>*/}
          {/*  </Box>*/}
          {/*) : balanceError ? (*/}
          {/*  <Typography variant="h6">{"--------"}</Typography>*/}
          {/*) : (*/}
          {/*  <Typography variant="h4">{"******"} </Typography>*/}
          {/*)}*/}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: "center",
          }}
        >
          <Button
            sx={styles.operationSend}
            variant="outlined"
            startIcon={<SendIsFilledFalse color="#B349EB" />}
            onClick={() => setOpenPopupTransfer(true)}
          >
            {t("send")}
          </Button>
          {isMdScreen ? (
            <Spacing marginRight={"30px"} />
          ) : (
            <Spacing marginBottom={"10px"} />
          )}
          <Button
            sx={styles.operationSend}
            variant="outlined"
            startIcon={<TopUpIsFilledFalse color="#B349EB" />}
            onClick={() => setOpenPopupTopUp(true)}
          >
            {t("topUp")}
          </Button>
        </Box>
      </Card>
      <Spacing marginTop={"40px"} />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { md: "2fr .8fr" },
          columnGap: "20px",
          gap: "20px",
        }}
      >
        <Card
          sx={{
            height: "328px",
            minHeight: "328px",
            border: "1px solid #C7C7C7",
            borderRadius: "8px",
            display: "block"
          }}
        >
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 20px 0",
              }}
            >
              <Box>
                <Typography sx={styles.overview}>{t("overview")}</Typography>
              </Box>
              <Box sx={{}}>
                <div>
                  <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                    <InputLabel id="periode-label">
                      {t("chartLabel")}
                    </InputLabel>
                    <Select
                      labelId="periode-label"
                      id="periode"
                      value={period}
                      label={t("chartLabel")}
                      onChange={handleChangePeriod}
                      autoWidth
                      defaultValue={t("chartYear")}
                    >
                      <MenuItem value={PeriodValues.WEEK}>{t("chartWeek")}</MenuItem>
                    <MenuItem value={PeriodValues.MONTH}>{t("chartMonth")}</MenuItem>
                      <MenuItem value={PeriodValues.YEAR}>{t("chartYear")}</MenuItem>
                      <MenuItem onClick={()=>setOpenCUstomDatePopUp(true)} value={PeriodValues.CUSTOM}>{startDate && endDate ? displayTranslatedDate(startDate.toDate(),endDate.toDate()) :"Custom"}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Box>
            </Box>
            {isStatLoading ? (
              <Box sx={{display: "flex", justifyContent: "center"}}>
                <GradientCircularProgress/>
              </Box>
            ) : (
              <BarChart data={stats} />
            )}
          </>
        </Card>
        <Card sx={styles.item}>
          <CardContent>
            <Typography sx={{ ...{ mt: 2 }, ...styles.overview }}>
              {t("quickLinks")}
            </Typography>

            <Box sx={styles.postStats}>
              <Box sx={styles.postAuthorSection}>
                <Button onClick={addVirtualCard}
                        sx={{gap: 1}}>
                  <Box
                    sx={{
                      color: "white",
                      backgroundColor: "#E6F4FD",
                      borderRadius: "100%",
                      display: "flex",
                      padding: "inherit",
                    }}
                  >
                    <CardAddIcon />
                  </Box>
                  <Typography sx={styles.postMeta}>
                    {t("addNewCard")}
                  </Typography>
                </Button>
              </Box>
              <Box sx={styles.postAuthorSection}>
                <Button
                  onClick={handlePhysicalCardButton}
                  sx={{gap: 1}}
                >
                  <Box
                    sx={{
                      color: "white",
                      backgroundColor: "#E6F4FD",
                      borderRadius: "100%",
                      display: "flex",
                      padding: "inherit",
                    }}
                  >
                    <CardCheckIcon />
                  </Box>
                  <Typography sx={styles.postMeta}>
                    {t("orderActivateCard")}
                  </Typography>
                </Button>
              </Box>
              <Box sx={styles.postAuthorSection}>

                <Button
                  onClick={goToBankTransfer}
                  sx={{gap: 1}}
                >
                  <Box
                    sx={{
                      color: "white",
                      backgroundColor: "#E6F4FD",
                      borderRadius: "100%",
                      display: "flex",
                      padding: "inherit",
                    }}
                  >
                    <MoneyDollarIcon />
                  </Box>
                  <Typography sx={styles.postMeta}>
                    {t("bankTransfer")}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Spacing marginTop={"40px"} />
      <Box>
        <EnhancedTable
          transactions={transactions}
          more={<More goToTransactions={goToTransactions} />}
          loading={isEmpty(transactions)}
          transactionsError={transactionsError}
        />
      </Box>
      <Popup
        title={t("bankTypeOperation")}
        open={openDialog}
        onClose={setOpenDialog}
      >
        <ShowOperationsPopup
          additionalStyle={{
            margin: 1,
            background: "#FFFFFF",
            border: "1px solid #C7C7C7",
          }}
          items={bankTopUpTypes}
          handleChannelOperation={handleBankTopUp}
        />
      </Popup>
      <Popup
        title={t("sendMoney")}
        open={openPopupTransfer}
        onClose={setOpenPopupTransfer}
      >
        <ShowOperationsPopup
          additionalStyle={{
            margin: 1,
            background: "#FFFFFF",
            border: "1px solid #C7C7C7",
          }}
          items={transferProviders}
          handleChannelOperation={handleTransferOperation}
        />
      </Popup>
      <Popup
        title={t("topUpWallet")}
        open={openPopupTopUp}
        onClose={setOpenPopupTopUp}
      >
        <ShowOperationsPopup
          additionalStyle={{
            margin: 1,
            background: "#FFFFFF",
            border: "1px solid #C7C7C7",
          }}
          items={subOperationsTopUp}
          handleChannelOperation={handleChannelOperation}
        />
      </Popup>
      <Popup
        title={t("selectProvider")}
        open={popupProviderMobileMoney}
        onClose={setPopupProviderMobileMoney}
      >
        {providerMobileMoney ? (
          providerMobileMoney.length > 0 ? (
            <ShowOperationsPopup
              additionalStyle={{
                margin: 1,
                background: "#FFFFFF",
                border: "1px solid #C7C7C7",
              }}
              items={providerMobileMoney}
              handleChannelOperation={handleMobileMoney}
            />
          ) : (
            <GradientCircularProgress />
          )
        ) : (
          <Typography>{errorTopUpProviders}</Typography>
        )}
      </Popup>

      <Popup
        title=""
        open={openPopUpOrderCard}
        onClose={setOpenPopUpOrderCard}
        additionalStyle={{ backgroundColor: "#FFFFFF" }}
      >
        <OrderActivateCardPopUpContent navigateTo={navigateTo} />
      </Popup>
      <Popup
        open={openCUstomDatePopUp}
        onClose={setOpenCUstomDatePopUp}
        additionalStyle={{ backgroundColor: "#FFFFFF",maxWidth: "fit-content",}}
      >
        <Box sx={{margin:"0 20px"}}>
          <Typography sx={{fontWeight:"bold", textAlign:"center"}}>{'Date de debut'}</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar defaultValue={startDate} onChange={(value)=>setStartDate(value)}  />
          </LocalizationProvider>
          {dateError && <Typography sx={{color:"red"}} >{dateError}</Typography>}
        </Box>
        <Box sx={{margin:"0 20px"}}>
          <Typography sx={{fontWeight:"bold",textAlign:"center"}} >{'Date de Fin'}</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar defaultValue={endDate} onChange={(value)=>setEndDate(value)}   />
          </LocalizationProvider>
          <Box sx={{display:"flex",justifyContent:"end"}}>
            <Button sx={{marginRight:"24px"}} onClick={()=>handleCustomDate("custom")}>
            Confirmer
            </Button>
          </Box>
        </Box>
          <Popup
          open={dateError}
          onClose={setDateError}
          additionalStyle={{ backgroundColor: "#FFFFFF",maxWidth: "fit-content",}}
        >
          <>
            <Typography >{"Une erreur est survenue lors de la selection de la date de debut et de fin"}</Typography>
          </>
        </Popup>
      </Popup>
    </Box>
  );
};
export default Wallet;

interface MoreProps {
  goToTransactions: () => void;
}

const More: React.FC<MoreProps> = ({ goToTransactions }) => {
  return (
    <Button
      endIcon={<ArrowUpRightIcon />}
      onClick={goToTransactions}
      style={{
        textTransform: "none",
        fontWeight: 400,
        fontSize: "16px",
        color: "#001021",
      }}
    >
      {t("seeAll")}
    </Button>
  );
};

/**
 * @type import("@mui/material".Sx.props)
 */
const styles = {
  transactions: {
    logo: {
      width: "32px",
      height: "32px",
      borderRadius: "100%",
    },
    videoTitle: {
      fontSize: "0.8rem",
      width: 490,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    videoDescription: {
      fontSize: "0.7rem",
      color: "neutral.normal",
      width: 490,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    videoDetails: {
      ml: 2,
    },
    videoColumn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    iconColumn: {
      display: "flex",
      alignItems: "center",
    },
    iconColumnText: {
      fontSize: "16px",
      fontWeight: "700",
    },
  },
  overview: {
    fontSize: "16px",
    fontWeight: "700",
  },
  pageTitle: {
    mb: 2,
  },
  columnsContainer: {
    display: "flex",
    flexDirection: { xs: "row" },
    justifyContent: "space-between",
    borderRadius: "8px",
    border: "1px solid #C7C7C7",
    padding: "10px 10px",
  },
  operationSend: {
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: { md: "27px", xs: "20px" },
    display: "flex",
    alignItems: "center",
    minWidth: {
      xs: "120px",
      sm: "150px",
    },
    height: "48px",
    borderRadius: "8px",
    borderColor: "linear-gradient(to right, #C471ED, #4A90ED)",
    background: "linear-gradient(95.08deg, #B349EB 0%, #4A90E2 100%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    p: "10px",
  },
  operationTopUp: {
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "27px",
    display: "flex",
    alignItems: "center",
    minWidth: "150px",
    height: "48px",
    borderRadius: "8px",
    color: "#FFFFFF",
    width: "100%",
    p: "10px",
    background: "linear-gradient(to right, #C471ED, #4A90ED)",
    boxShadow: "5px #000",
  },
  item: {
    height: "328px",
    minHeight: "328px",
    borderRadius: "8px",
    border: "1px solid #C7C7C7",
  },
  postAuthorSection: {
    display: "flex",
    alignItems: "center",
    my: 3,
  },
  postMeta: {
    mr: 1,
    fontSize: "0.8rem",
    color: "neutral.normal",
    textAlign: "start"
  },
  divider: {
    my: 2,
  },
  postStats: {
    // display: "grid",
    display: "flex",
    flexDirection: "column"
    // gridTemplateColumns: { md: "1fr", lg: "1fr 1fr" },
  },
  cardTitle: {
    mt: 2,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "32px",
    display: "flex",
    alignItems: "center",
    color: "#001021",
  },
  amount: {
    color: "#000",
    fontWeight: 700,
    fontSize: "33px",
  },
  balanceSection: {
    display: "flex",
    alignItems: "center",
    color: "#000",
  },
  balanceLabelSection: {
    mr: 1,
    color: "#000",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "32px",
    display: "flex",
    alignItems: "center",
  },
};
