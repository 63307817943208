import {IStatusItem} from "../../../containers/Wallet/models/transactionStatics.response";
import {Transaction} from "../../../containers/Wallet/models/transactionsResponse";
import {Colors} from "../constants/Colors";
import {ITransactionView} from "../../../containers/Wallet/Wallet";
import {isEmpty} from "../isEmpty";
import i18n from "../../../i18n";
import {IconUrl} from "../constants/IconUrl";

const ELLIPSIS_MAX_CHARS = 120;

type TransactionType =
    'top-up'
    | 'withdraw'
    | 'transfer'
    | 'subscription-payment'
    | 'card-payment'
    | 'card-fund'
    | 'card-withdraw'
    | 'card-refund'
    | 'card-transfer'
    | 'merchant-transaction';

interface ITransactionIcons {
    [key: string]: string;
}

const TransactionIcon: ITransactionIcons = {
    'top-up': IconUrl.transactionTopUp,
    'withdraw': IconUrl.transactionWithdraw,
    'transfer': IconUrl.transactionWallet,
    'subscription-payment': IconUrl.transactionPlan,
    'card-payment': IconUrl.transactionCard,
    'card-fund': IconUrl.transactionCard,
    'card-withdraw': IconUrl.transactionCard,
    'card-refund': IconUrl.transactionCard,
    'card-transfer': IconUrl.transactionCard,
    'merchant-transaction': IconUrl.transactionWallet,
};

const getIconUrl = (type: TransactionType|string): string => {
    return TransactionIcon[type] || IconUrl.transactionCard;
};

function getTransactionAmountLabel(transaction: ITransactionView) {
    switch (transaction.type) {
        case 'top-up':
            return i18n.t("withdrawScreen.withdrawFunds");
        case 'withdraw':
            return i18n.t("withdrawScreen.withdrawFunds");
        case 'subscription-payment':
        case 'card-payment':
        case 'card-fund':
            return i18n.t("withdrawScreen.withdrawFunds");
        case 'card-refund':
            return i18n.t("withdrawScreen.withdrawFunds");
        case 'card-transfer':
        case 'merchant-transaction':
        case 'transfer':
            return transaction.isRefill === true
                ? i18n.t("withdrawScreen.withdrawFunds")
                : i18n.t("withdrawScreen.withdrawFunds");
        case 'airtime-transfer':
            return i18n.t("withdrawScreen.withdrawFunds");
        case 'billing-service-payment':
            return i18n.t("withdrawScreen.withdrawFunds");
        default:
            return i18n.t("withdrawScreen.withdrawFunds");
    }
}

function ellipsisNarration(narration: string): string {
    return narration.length > ELLIPSIS_MAX_CHARS
        ? narration.substring(0, ELLIPSIS_MAX_CHARS) + "..."
        : narration;
}

function getStatusColor(status: string): string {
    switch (status) {
        case 'processed':
            return Colors.colorGreen;
        case 'pending':
            return Colors.colorOrange;
        case 'rejected':
            return Colors.colorRed;
        default:
            return Colors.colorGreen;
    }
}

const getLabelsTransaction = (types: IStatusItem[], tx: ITransactionView) => {
    const type = types.find((type) => type.name === tx.type);

    if (isEmpty(tx.narration)) {
        tx.narration = type?.translated || tx.message || tx.type;
    }

    if (isEmpty(tx.amount) && type) {
        switch (type.sign?.type) {
            case 'static':
                tx.amount = type.sign?.sign + "" + tx.amount
                break
            case 'dynamic':
                if (type.sign?.refillCheck) {
                    const sign = tx.isRefill ? '+' : '-';
                    tx.amount = sign + "" + tx.amount
                }
                break
        }
    }
    tx.amount = tx.amount + " CFA"

    return tx;
}

function containsUppercaseOrUnderscore(text: string | null): boolean {
    if (text) {

        const regex = /[A-Z_]/;

        return regex.test(text);
    } else {
        return true
    }
}

export const TransactionHelpers = {
    getIconUrl,
    getTransactionAmountLabel,
    ellipsisNarration,
    getStatusColor,
    getLabelsTransaction
}