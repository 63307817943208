import React from 'react';
import {Checkbox, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableRow} from "@mui/material";
import Contact from "./Contact";
import IRecipient from "../../../shared/types/IRecipient";

interface KTableContainerProps {
    recipients: IRecipient[],
    isSelected: (params?: any) => any,
    selected: readonly string[]
    handleClick: (params: any) => any,
    handleUpdateBeneficiary: (params: any) => any,
    onclickContactItem: (params: any) => any,
}

const KTableContainer = ({recipients, isSelected, selected, handleClick, handleUpdateBeneficiary, onclickContactItem}: KTableContainerProps) => {

    const kisSelected = (recipientId: any) => isSelected(recipientId)

    const khandleClick = (recipientId: any) => handleClick(recipientId)

    const khandleUpdateBeneficiary = (recipient: IRecipient) => handleUpdateBeneficiary(recipient)

    const konclickContactItem = (recipient: IRecipient) => onclickContactItem(recipient)

    return (
        <TableContainer>
            <Table
                aria-labelledby="tableTitle"
                size={'small'}
                sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                    },
                }}
            >
                <TableBody>
                    {recipients.map((recipient, index) => {
                        const isItemSelected = kisSelected(recipient.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                            <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={recipient.id}
                                selected={isItemSelected}
                                sx={{cursor: 'pointer', justifyContent: "center"}}
                            >
                                {selected.length > 0 &&
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                        onClick={() => khandleClick(recipient.id)}
                                    />
                                </TableCell>}
                                <TableCell
                                    component="th"
                                    scope="row"
                                    padding="none"
                                >
                                    <Contact
                                        handleDeleteBeneficiary={() => khandleClick(recipient.id)}
                                        handleUpdateBeneficiary={() => khandleUpdateBeneficiary(recipient)}
                                        onClickCard={() => konclickContactItem(recipient)}
                                        recipient={recipient}
                                        numSelected={selected.length}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default KTableContainer;
