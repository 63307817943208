import particulier from '../../../assets/img/Group-1.gif'
import entreprise from '../../../assets/img/Group-2.gif'
import transfert from '../../../assets/img/Group-3.gif'

const annonces = [
    {
        "id": 1,
        "image": particulier
    },
    {
        "id": 2,
        "image": entreprise
    },
    {
        "id": 3,
        "image": transfert
    }
]

export default annonces;