import React, {useEffect, useState} from "react";
import { Card, CardContent, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Spacing from "../../../../components/Spacing";
import { IBankTransferData } from "./index";
import { accountService } from "../../../../shared/services";
import { t } from "i18next";

const ReviewBankTransfer: React.FC<{ data: IBankTransferData }> = (
  formData
) => {

  return (
    <Box sx={styles.container}>
      <Card>
        <CardContent>
          <Spacing marginBottom={"10px"} />

          {/* <Typography sx={styles.title}>
            {t("recipients.SendReviewTitle")}
          </Typography> */}
          <Spacing marginBottom={"10px"} />
          <Box sx={styles.row}>
            <Typography sx={styles.label}>{t("beneficiary")}</Typography>
            <Typography sx={styles.data}>
              {formData.data.recipient.firstName
                ? `${formData.data.recipient.firstName} ${formData.data.recipient.lastName}`
                : formData.data.recipient.companyName}
            </Typography>
          </Box>
          <Box sx={styles.row}>
            <Typography sx={styles.label}>
              {t("withdrawScreen.accountNumber")}
            </Typography>
            <Typography sx={styles.data}>
              {formData.data.recipient.accountNumber}
            </Typography>
          </Box>
          <Box sx={styles.row}>
            <Typography sx={styles.label}>{t("country")}</Typography>
            <Box sx={{ display: "flex" }}>
              <Box
                component={"img"}
                src={`https://flagcdn.com/w40/${formData.data.recipient.country.toLowerCase()}.png`}
              />
            </Box>
          </Box>
          <Box sx={styles.row}>
            <Typography sx={styles.label}>{t("phoneNumber")}</Typography>

            <Typography sx={styles.data}>
              {formData.data.recipient.phoneNumber}
            </Typography>
          </Box>
          <Box sx={styles.row}>
            <Typography sx={styles.label}>{t("recipients.youSend")}</Typography>
            <Typography sx={styles.data}>
              {accountService.getUser()?.currency} {formData.data.amount}
            </Typography>
          </Box>
          <Box sx={styles.row}>
            <Typography sx={styles.label}>
              {t("recipients.totalFee")}
            </Typography>
            <Typography sx={styles.data}>{formData.data.fee}</Typography>
          </Box>
          <Box sx={styles.row}>
            <Typography sx={styles.label}>
              {t("recipients.recipientGets")}
            </Typography>
            <Typography sx={styles.data}>
              {formData.data.recipient.currency} {formData.data.amountToReceive}
            </Typography>
          </Box>
          {/* <Box sx={styles.row}>
            <Typography sx={styles.label}>
              {t("recipients.marketRate")}
            </Typography>
            <Typography sx={styles.data}>
              {formData.data.currencyFrom} = {formData.data.rate} {formData.data.currencyTo}
            </Typography>
          </Box> */}
          <Box sx={styles.row}>
            <Typography sx={styles.label}>
              {t("recipients.requiredDocumentsLabel")}
            </Typography>
            <Box>
              {/*{*/}
              {/*    formData.data.documents.length > 0*/}
              {/*    ? formData.data.documents.map(())*/}
              {/*}*/}
              {formData.data.documents.length > 0 ? (
                formData.data.documents.map((document: any, index: any) => (
                  <Typography key={index} sx={styles.data}>
                    {document.file.name}
                  </Typography>
                ))
              ) : (
                <Typography sx={styles.data}>
                  {t("recipients.noFileUploaded")}
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={styles.row}>
            <Typography sx={styles.label}>
              {t("recipients.transferReasonRecap")}
            </Typography>
            <Typography sx={styles.data}>{formData.data.noteTitle}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ReviewBankTransfer;

/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
  data: {
    fontWeight: 400,
    fontSize: "15px",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    mt: 2,
    mb: 2,
    ml: 1,
    mr: 1,
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: "700",
    display: "flex",
    justifyContent: "start",
  },
  label: {
    fontWeight: 500,
    fontSize: "15px",
    color: "#959595",
    textTransform: "none",
  },
  container: { borderRadius: "8px" },
};
