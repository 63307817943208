import React from "react";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Input,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import IRecipient from "../../../shared/types/IRecipient";
import Spacing from "../../../components/Spacing";
import { useNavigate } from "react-router-dom";
import { SendFastIcon } from "../../../shared/_utils/constants/icon";
import SendMoneyForm from "./SendMoneyForm";
import Popup from "../../../components/Popup";

interface IAmount {
  amount?: number;
  recipient?: IRecipient | null;
}
const Successful = ({ amount, recipient }: IAmount) => {
  const navigate = useNavigate();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        <Box sx={styles.title}>
          <SendFastIcon />
          <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
            Payment Successful
          </Typography>
        </Box>
        <Spacing marginBottom={"20px"} />
        <Box sx={styles.message}>
          <Typography>
            {amount} CFA has been transferred to {recipient?.firstName}{" "}
            {recipient?.lastName}. Your recipient will receive an message once
            they received the funds.
          </Typography>
        </Box>
        <Spacing marginBottom={"50px"} />
        <Button
          onClick={() => {}}
          sx={{ ...styles.button, ...styles.underline }}
        >
          Transaction Details
        </Button>
      </Box>
    </Box>
  );
};

export default Successful;

/**
 * @type {import("@mui/material").SxProps}
 */
const styles = {
  button: {
    textTransform: "none",
  },
  underline: {
    textDecoration: "underline",
  },
  note: {
    width: "full",
  },
  availableBalance: {
    border: "1px solid red",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "22px",
    alignItems: "center",
    textAlign: "center",
    color: "#959595",
  },
  amount: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    width: "202px",
    order: 1,
    flexGrow: 0,
  },
  message: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "26px",
    lineHeight: "51px",
    alignItems: "center",
    textAlign: "center",
    color: "#959595",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 0px",
    gap: "8px",
    width: "auto",
    height: "134px",
    background: "#FFFFFF",
    borderRadius: "8px",
    justifyItems: "center",
    alignContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    minWidth: "266px",
    padding: "0px",
    width: {
      sm: "100px",
      md: "400px",
    },
    height: "387px",
    alignItems: "center",
    mr: 2,
    mb: 4,
  },
  container: {
    padding: "0 48px",
  },
  avatar: {
    display: "flex",
    flexDirection: "row",
    width: "64px",
    height: "64px",
  },
  userName: {
    display: "flex",
    flexDirection: "row",
    mt: 1,
  },
};
