import { httpService } from "./http.service";
import {
  IOrderPhysicalCardData,
  IOrderPhysicalCardForm,
} from "../../containers/Cards/OrderPhysicalCard/CompleteForm";
import { IActivationDataPhysicalCardForm } from "../../containers/Cards/ActivatePhysicalCard/CompleteForm";

const topUp = (cardId: string, amount: string) => {
  const data = {
    cardId: cardId,
    amount: amount,
  };
  return httpService.Axios.post(`/physical-card/top-up-card`, data);
};

const getFundDetails = (amount: string) => {
  const data = {
    amount: +amount,
  };
  return httpService.Axios.post("/physical-card/top-up-fees", data);
};

const activatePhysicalCard = (
  activationData: IActivationDataPhysicalCardForm
) => {
  return httpService.Axios.post(
    "physical-card/request-activation",
    activationData
  );
};

const getCitiesByCountry = (countryId: string) => {
  return httpService.Axios.get(
    `physical-card/supported-cities?country=${countryId}`
  );
};

const getSupportedCountries = () => {
  return httpService.Axios.get("physical-card/supported-countries");
};

const orderPhysicalCard = (data: IOrderPhysicalCardData) => {
  return httpService.Axios.post("physical-card/order-card", data);
};

const getPhysicalCard = () => {
  return httpService.Axios.get("physical-card/cards");
};

const getCardTransactions = (id: string) => {
  let page = 1;
  let limit = 5;
  let startDate = "2017-01-01";
  let endDate = new Date().toISOString().split("T")[0];
  let transactionDirection = "";

  return httpService.Axios.get(
    `physical-card/card-transactions?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}${
      transactionDirection
        ? `&transactionDirection=${transactionDirection}`
        : ""
    }`
  );
};

export const physicalCardService = {
  getPhysicalCard,
  getCardTransactions,
  getSupportedCountries,
  orderPhysicalCard,
  getCitiesByCountry,
  activatePhysicalCard,
  getFundDetails,
  topUp,
};
