import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Spacing from "../../../components/Spacing";
import { useNavigate } from "react-router-dom";
import { AddRecipentGrandientIcon } from "../../../shared/_utils/constants/icon";
import { t } from "i18next";

interface IRecipientPopup {
  lastName: string;
  firstName: string;
  fullName: string;
}

const SuccessfulAddRecipient: React.FC<IRecipientPopup> = ({
  lastName,
  firstName,
  fullName,
}) => {
  const navigate = useNavigate();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        <Box sx={styles.title}>
          <AddRecipentGrandientIcon />
        </Box>
        <Spacing marginBottom={"10px"} />
        <Box sx={styles.message}>
          <Typography>
            {t("recipients.addNewRecipientSuccessMessage").replace(
              "<:recipientName>",
              `${fullName}`
            )}{" "}
          </Typography>
        </Box>
        <Spacing marginBottom={"30px"} />
        {/* <Button
          onClick={() => {}}
          sx={{ ...styles.button, ...styles.underline }}
        >
          View Recipient
        </Button> */}
      </Box>
    </Box>
  );
};

export default SuccessfulAddRecipient;

/**
 * @type {import("@mui/material").SxProps}
 */
const styles = {
  button: {
    textTransform: "none",
  },
  underline: {
    textDecoration: "underline",
  },
  note: {
    width: "full",
  },
  availableBalance: {
    border: "1px solid red",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "22px",
    alignItems: "center",
    textAlign: "center",
    color: "#959595",
  },
  amount: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    width: "202px",
    order: 1,
    flexGrow: 0,
  },
  message: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "26px",
    lineHeight: "51px",
    alignItems: "center",
    textAlign: "center",
    color: "#959595",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 0px",
    gap: "8px",
    width: "auto",
    // height: '134px',
    background: "#FFFFFF",
    borderRadius: "8px",
    justifyItems: "center",
    alignContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    // minWidth: "266px",
    padding: "0px",
    width: {
      sm: "100px",
      md: "400px",
    },
    // height: '387px',
    alignItems: "center",
    mr: 2,
  },
  container: {
    // padding: "0 8px"
  },
  avatar: {
    display: "flex",
    flexDirection: "row",
    width: "64px",
    height: "64px",
  },
  userName: {
    display: "flex",
    flexDirection: "row",
    mt: 1,
  },
};
