import React from 'react';
import {walletRepository} from "../../containers/Wallet/repository/wallet.repository";
import {isEmpty} from "../_utils/isEmpty";
import {Box} from "@mui/material";
import SubOperation from "../types/SubOperation";
import {t} from "i18next";

const useProviderMobileMoney = async (): Promise<SubOperation[]> => {
        const res = await walletRepository.getMobileMoneyProviders("top-up");

        let providersMobileMoney: SubOperation[] = [];

        if (null != res.data) {

            if (!isEmpty(res.data.providers)) {
                providersMobileMoney = [...res.data.providers.map((topUpProvider) => {
                    return {
                        id: +topUpProvider.code,
                        icon: <Box sx={{width: "75px"}} component={"img"}
                                   src={topUpProvider.image}/>,
                        text: topUpProvider.name,
                        code: topUpProvider.code,
                        img: topUpProvider.image,
                        country: topUpProvider.country
                    } as SubOperation
                })];
            }


            if (!isEmpty(res.data.operators)) {
                providersMobileMoney = [...providersMobileMoney, ...res.data.operators.map((topUpProvider) => {
                    return {
                        id: +topUpProvider.code,
                        icon: <Box sx={{width: "75px"}} component={"img"}
                                   src={topUpProvider.image}/>,
                        text: topUpProvider.name,
                        code: topUpProvider.code,
                        img: topUpProvider.image,
                        country: topUpProvider.country
                    } as SubOperation
                })];
            }

            return providersMobileMoney;

        } else {
            throw new Error(res?.error || t('errorOccurred'))
        }
};

export default useProviderMobileMoney;
