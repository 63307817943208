import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Spacing from "../../../../components/Spacing";
import {
  ActivateCardIcon,
  CheckIcon,
} from "../../../../shared/_utils/constants/icon";
import RoundGradientButton from "../../../../components/RoundGradientButton";
import { useNavigate } from "react-router-dom";
import { MailOutlineOutlined } from "@mui/icons-material";
import { t } from "i18next";

const GetStared = () => {
  const navigate = useNavigate();

  const [isRoundGradientButtonClicked, setIsRoundGradientButtonClicked] =
    useState(false);

  const handleActivateCardButton = () => {
    // setIsRoundGradientButtonClicked(true);
    navigate("/card/activate-card/complete-form");
  };

  return (
    <Box>
      <Box sx={styles.container}>
        <Spacing marginBottom={"50px"} />
        <Box
          component={"img"}
          src={
            "https://res.cloudinary.com/payqin-ltd/image/upload/v1690599656/web-app/Physical_Card-illustration_xe6wnu.png"
          }
          style={{
            width: "60%",
            transform: "rotate(-10deg)",
            display: "flex",
            margin: "auto",
          }}
        ></Box>
        <Spacing marginBottom={"50px"} />
        <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
          {t("physicalCards.cardActivationTitle")}
        </Typography>
        <Spacing marginBottom={"20px"} />
        <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
          {t("physicalCards.cardActivationDescription")}
        </Typography>
        <Spacing marginBottom={"20px"} />
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box>
            <CheckIcon />
          </Box>
          <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
            {t("physicalCards.cardActivationAsset1")}
          </Typography>
        </Box>
        <Spacing marginBottom={"20px"} />
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box>
            <CheckIcon />
          </Box>
          <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
            {t("physicalCards.cardActivationAsset2")}
          </Typography>
        </Box>
        <Spacing marginBottom={"20px"} />
        <Box>
          <RoundGradientButton
            onClick={handleActivateCardButton}
            fullWidth
            variant="contained"
            color="inherit"
          >
            <Typography>
              {" "}
              {t("physicalCards.cardActivationButtonActivate")}
            </Typography>
          </RoundGradientButton>
        </Box>
        <hr />

        <Spacing marginBottom={"30px"} />
        <Box sx={styles.content}>
          <Typography sx={styles.titleField}>
            {" "}
            {t("physicalCards.cardActivationHelp")}
          </Typography>
          <Spacing marginBottom={"20px"} />
          <Box sx={styles.row}>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <MailOutlineOutlined />
              <Typography sx={styles.label}>Support@payqin.com</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <ActivateCardIcon />
              <Typography sx={styles.label}>+225 2722598435</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GetStared;

/** @type{import("@mui/material").SxProps}*/
const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  orderData: { fontSize: "16px", fontWeight: 400, color: "#001021" },
  titleField: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#000000",
  },
  label: {
    margin: "auto 0px",
    fontWeight: 400,
    fontSize: "16px",
    color: "#959595",
  },
  content: {
    border: "1px solid #C7C7C7",
    borderRadius: "8px",
    padding: "24px 16px",
    backgroundColor: "#E6F4FD",
  },
  addCardButton: {
    display: "flex",
    justifyContent: "space-around",
  },
  container: {
    width: {
      sm: "70%",
      md: "60%",
      lg: "40%",
    },
    margin: "auto",
  },
};
