import React, {useEffect, useRef, useState} from 'react';
import {Box, Dialog, IconButton, Slide, Toolbar, Typography} from "@mui/material";
import {CloseIcon} from "../../../shared/_utils/constants/icon";
import {TransitionProps} from "@mui/material/transitions";
import {useNavigate} from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface IWaveBasketProps {
    url: string,
    open: boolean,
    onClose: (params: boolean) => any
}
const WaveBasketDialog = ({url, open, onClose}: IWaveBasketProps) => {

    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const kOnClose = () => {
        try {
            return iframeRef.current?.contentWindow?.location.href?.includes("success") ? onClose(true) : onClose(false)
        } catch (e) {
            return onClose(false)
        }
    }

    return (
        <Dialog
            open={open}
            onClose={kOnClose}
            TransitionComponent={Transition}
        >
            <Toolbar sx={{backgroundColor: "#1DC8FF", justifyContent: "flex-end"}}>
                <IconButton
                    edge="start"
                    sx={{color: "#FFFFFF", backgroundColor: "#FFFFFF"}}
                    onClick={kOnClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <iframe
                ref={iframeRef}
                style={{margin: "auto"}}
                src={url}
                width="500"
                height="500"
                frameBorder="0"
                allowFullScreen
            ></iframe>
        </Dialog>
    );
};

export default WaveBasketDialog;


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

