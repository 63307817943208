import React from 'react';
import {t} from "i18next";
import Popup from "../../../components/Popup";
import {Box, Button, Typography} from "@mui/material";
import Spacing from "../../../components/Spacing";
import {AddRecipentGrandientIcon} from "../../../shared/_utils/constants/icon";

interface ISuccessfullyRegistrationProps {
    openSuccessfullyRegistrationDialog: boolean
    handleDialogAfterSuccess: () => any
}

const SuccessfullyRegistrationDialog = ({openSuccessfullyRegistrationDialog, handleDialogAfterSuccess}: ISuccessfullyRegistrationProps) => {

    return (
        <Popup
            open={openSuccessfullyRegistrationDialog}
            handleDialogAfterSuccess={handleDialogAfterSuccess}
        >
            <Box sx={styles.container}>
                <Box sx={styles.content}>
                    <Box component="img" sx={styles.title} src="https://res.cloudinary.com/payqin-ltd/image/upload/v1700757789/web-app/Vector_yxcdqx.png"/>
                    <Spacing marginBottom={"10px"} />
                    <Box>
                        <Typography sx={{fontSize: {
                            xs: '1.5rem', md: '2.5rem'
                            }, fontWeight: 700,
                            alignItems: "center",
                            textAlign: "center",}}>
                            {t("congratulations")}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography sx={styles.message}>
                            {t("congratulationsMsg")}
                        </Typography>
                        <Typography sx={styles.enjoy}>
                            {t("enjoy")}
                        </Typography>
                    </Box>
                    <Spacing marginBottom={"30px"} />
                    <Button
                        onClick={handleDialogAfterSuccess}
                        sx={{ ...styles.button, ...styles.underline }}
                    >
                        <Typography sx={styles.accessYourAccount}>
                            {t("accessYourAccount")}
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Popup>
    );
};

export default SuccessfullyRegistrationDialog;


/**
 * @type {import("@mui/material").SxProps}
 */
const styles = {
    accessYourAccount: {
        fontWeight: 700,
    },
    enjoy: {
        fontStyle: 'italic',
        alignItems: "center",
        textAlign: "center",
        color: "#000000",
        fontWeight: 700
    },
    button: {
        textTransform: "none",
    },
    underline: {
        textDecoration: "underline",
    },
    note: {
        width: "full",
    },
    availableBalance: {
        border: "1px solid red",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "22px",
        alignItems: "center",
        textAlign: "center",
        color: "#959595",
    },
    amount: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px",
        width: "202px",
        order: 1,
        flexGrow: 0,
    },
    message: {
        fontStyle: "normal",
        fontWeight: "700",
        alignItems: "center",
        textAlign: "center",
        color: "#959595",
    },
    title: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 0px",
        gap: "8px",
        width: "auto",
        // height: '134px',
        background: "#FFFFFF",
        borderRadius: "8px",
        justifyItems: "center",
        alignContent: "center",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        // minWidth: "266px",
        padding: "0px",
        width: {
            sm: "100px",
            md: "400px",
        },
        // height: '387px',
        alignItems: "center",
        mr: 2,
    },
    container: {
        // padding: "0 8px"
    },
    avatar: {
        display: "flex",
        flexDirection: "row",
        width: "64px",
        height: "64px",
    },
    userName: {
        display: "flex",
        flexDirection: "row",
        mt: 1,
    },
};
