const colorBlueAccent = "#448AFFFF"
const colorGrey = "#9E9E9EFF"
const colorGreenBold = "#4CAF50FF";

const colorBlue = "#41ACEFFF"
const colorPurple = "#EB6CD1FF"
const colorGreen = "#7ED321FF";
const colorOrange = "#F5A623FF";
const colorRed = "#D0021BFF";

const bgGradientColor1 = "#BF56F3";
const bgGradientColor2 = "#4A90E2";


export const Colors = {colorBlueAccent, colorGrey, colorGreenBold, colorBlue, colorPurple, colorGreen, colorOrange, colorRed, bgGradientColor1, bgGradientColor2}