import {Box, Button, CircularProgress, Collapse, Grid, Step, StepLabel, Stepper, Typography,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {SubmitHandler, useForm,} from "react-hook-form";
import BankInfoForm from "./Forms/BankInfoForm";
import PersonalInfoForm from "./Forms/PersonalInfoForm";
import RoundGradientButton from "../../../components/RoundGradientButton";
import ReviewAdd from "./ReviewAdd";
import {ArrowBackIos} from "@mui/icons-material";
import Spacing from "../../../components/Spacing";
import {useLocation, useNavigate} from "react-router-dom";
import {DialogFunction, VertoBeneficiary} from "../../../shared/types/types";
import {recipientsRepository} from "../repository/recipients.repository";
import Popup from "../../../components/Popup";
import SuccessfulAddRecipient from "../components/SuccessfulAddRecipient";
import {ICountry} from "../../../shared/types/IRecipient";
import {TransitionGroup} from "react-transition-group";
import {t} from "i18next";
import {IApiResponse} from "../../../shared/types/IApiResponse";
import {IDataResponse} from "../../../shared/types/IDataResponse";
import toast from "react-hot-toast";

export interface IBankRecipientData {
    entity: VertoBeneficiary;
    lastName: string;
    firstName: string;
    companyName: string;
    country: string;
    icountryFlag: ICountry;
    currency: any;
    accountNumber: string;
    swiftCode: string;
    bankName: string;
    bankCode: string;
    email: string;
    phoneNumber: string;
    countryCode: string;
    address: string;
}

export interface IBankRecipientDto {
    isBankClient: boolean;
    entity: VertoBeneficiary;
    firstName: string;
    lastName: string;
    companyName: string;
    country: string;
    currency: string;
    accountNumber: string;
    swiftCode: string;
    bankName: string;
    bankCode: string;
    email: string;
    phoneNumber: string;
    countryCode: string;
    address: string;
}


export const VertoBeneficiaryValue = {
    INDIVIDUAL: 'individual',
    BUSINESS: 'company'
}

const BankRecipient = () => {
    const steps = [
        t("recipients.addNewFirstStepTitle"),
        t("recipients.addNewSecondStepTitle"),
    ];
    const [activeStep, setActiveStep] = useState<number>(0);
    const isLastStep = activeStep === steps.length;
    const [formData, setFormData] = useState<IBankRecipientData>();
    const navigate = useNavigate();
    const [content, setContent] = useState<JSX.Element | null>(null);
    const [error, setError] = useState<string>("");
    const [popup, setPopup] = useState<boolean>(false);
    const [handleCloseDialog, setHandleCloseDialog] =
        useState<DialogFunction>(null);

    const location = useLocation();
    const state = location.state;

    const bankRecipientForm = useForm<IBankRecipientData>({
        defaultValues: state ? state : null
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const renderStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <PersonalInfoForm
                        form={bankRecipientForm}
                        register={bankRecipientForm.register}
                        isValid={bankRecipientForm.formState.isValid}
                        errors={bankRecipientForm.formState.errors}
                        control={bankRecipientForm.control}
                        setValue={bankRecipientForm.setValue}
                        clearErrors={bankRecipientForm.clearErrors}
                    />
                );
            case 1:
                return (
                    <BankInfoForm
                        form={bankRecipientForm}
                        register={bankRecipientForm.register}
                        isValid={bankRecipientForm.formState.isValid}
                        errors={bankRecipientForm.formState.errors}
                        control={bankRecipientForm.control}
                        setValue={bankRecipientForm.setValue}
                        clearErrors={bankRecipientForm.clearErrors}
                    />
                );
            case 2:
                if (formData) return <ReviewAdd data={formData}/>;
                return;
            default:
                return (
                    <PersonalInfoForm
                        form={bankRecipientForm}
                        register={bankRecipientForm.register}
                        isValid={bankRecipientForm.formState.isValid}
                        errors={bankRecipientForm.formState.errors}
                        control={bankRecipientForm.control}
                        setValue={bankRecipientForm.setValue}
                        clearErrors={bankRecipientForm.clearErrors}
                    />
                );
        }
    };

    const buildBankRecipientDto = (bankRecipientData: IBankRecipientData) => {
        const bankRecipientDto = {} as IBankRecipientDto;
        bankRecipientDto.isBankClient = true;
        bankRecipientDto.entity = bankRecipientData.entity;
        bankRecipientDto.country = bankRecipientData.country;
        bankRecipientDto.currency = bankRecipientData.currency;
        bankRecipientDto.accountNumber = bankRecipientData.accountNumber;
        bankRecipientDto.swiftCode = bankRecipientData.swiftCode;
        bankRecipientDto.countryCode = bankRecipientData.countryCode;

        if (bankRecipientData.bankCode) {
            bankRecipientDto.bankCode = bankRecipientData.bankCode;
        }

        bankRecipientDto.bankName = bankRecipientData.bankName;

        bankRecipientDto.phoneNumber = bankRecipientData.phoneNumber;
        bankRecipientDto.address = bankRecipientData.address;
        bankRecipientDto.email = bankRecipientData.email;

        if (bankRecipientData.entity == "individual") {
            bankRecipientDto.firstName = bankRecipientData.firstName;
            bankRecipientDto.lastName = bankRecipientData.lastName;
        } else {
            bankRecipientDto.companyName = bankRecipientData.companyName;
        }

        return bankRecipientDto;
    }

    const submitForm: SubmitHandler<IBankRecipientData> = async (
        bankRecipientData
    ) => {
        setIsSubmitting(true);

        const bankRecipientDto = buildBankRecipientDto(bankRecipientData)

        const name = bankRecipientData.firstName
            ? bankRecipientData.firstName + " " + bankRecipientData.lastName
            : bankRecipientData.companyName;

        const {data, error} = await recipientsRepository.createOrUpdateBeneficiary(bankRecipientDto, state?.id)
        setIsSubmitting(false);
        if (null !== data && data.success) {
            if (null !== state) {
                toast.success(data.message);
                handleUserConfirmation()
            } else {
                setPopup(true);
                setContent(
                    <SuccessfulAddRecipient
                        fullName={name}
                        lastName={bankRecipientData.lastName}
                        firstName={bankRecipientData.firstName}
                    />
                );
                setHandleCloseDialog(() => handleUserConfirmation);
            }
        } else {
            const msg = error;
            setPopup(true);
            setContent(
                <Typography sx={{fontSize: "16px", color: "red"}}>{msg}</Typography>
            );
            setHandleCloseDialog(() => setPopup);
        }
    };

    const handleUserConfirmation = (): any => {
        navigate("/recipients");
    };

    const handleBankRecipientSubmit: SubmitHandler<IBankRecipientData> = async (
        data
    ) => {
        setFormData({...data});
        if (isLastStep) {
            submitForm(data);
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <Box sx={styles.container}>
            {/*<Card>*/}
            {/*    <CardContent>*/}

            <Box m={1}>
                <Box>
                    {activeStep !== 0 && (
                        <Button
                            onClick={handleBack}
                            startIcon={<ArrowBackIos htmlColor={"#959595"}/>}
                        >
                            <Typography sx={styles.label}>{t("back")} </Typography>
                        </Button>
                    )}
                    <Typography sx={{...styles.title, ...{fontWeight: 700}}}>
                        {isLastStep
                            ? t("recipients.addNewReviewTitle")
                            : state
                                ? t('recipients.updateBeneficiaryTitle')
                                : t("recipients.addNewTitle")}
                    </Typography>
                </Box>
                <Spacing marginBottom={"30px"}/>
                {!isLastStep && (
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                )}

                <Box
                    sx={{p: 3}}
                    component={"form"}
                    onSubmit={bankRecipientForm.handleSubmit(handleBankRecipientSubmit)}
                >
                    <TransitionGroup>
                        <Collapse key={activeStep} orientation={"vertical"} in={false}>
                            {renderStepContent(activeStep)}
                        </Collapse>
                    </TransitionGroup>
                    {/*{renderStepContent(activeStep)}*/}
                    <Grid container columns={12} mt={1} spacing={4}>
                        <Grid item xs={12}>
                            <RoundGradientButton
                                // disabled={!bankRecipientForm.formState.isValid || isSubmitting}
                                fullWidth
                                type="submit"
                                variant={"contained"}
                                color={"inherit"}
                                style={{
                                    background: isSubmitting
                                        ? "linear-gradient(to right, #C471ED, #4A90ED)"
                                        : "",
                                }}
                            >
                                {isSubmitting ? (
                                    <>
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: "#FFFFFF",
                                                marginRight: "5%",
                                            }}
                                        />

                                        <Typography sx={{color: "#FFFFFF"}}>
                                            {t("loading")}
                                        </Typography>
                                    </>
                                ) :
                                    <Typography sx={{color: "#000000"}}>
                                        {
                                            isLastStep ?
                                            state ?
                                                t("update")
                                            : t("recipients.addRecipientLabel")
                                            : t("recipients.nextStepButtonLabel")
                                        }
                                    </Typography>
                                }
                            </RoundGradientButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {/*    </CardContent>*/}
            {/*</Card>*/}
            <Popup
                title=""
                open={popup}
                onClose={handleCloseDialog}
                additionalStyle={{backgroundColor: "#FFFFFF"}}
            >
                {content}
            </Popup>
        </Box>
    );
};

export default BankRecipient;

/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
    title: {
        fontSize: "1rem",
        fontWeight: "400",
        display: "flex",
        justifyContent: "center",
    },
    label: {
        fontWeight: 500,
        fontSize: "13px",
        color: "#959595",
        textTransform: "none",
    },
    container: {
        width: {
            sm: "70%",
            md: "60%",
            lg: "50%",
        },
        margin: "auto",
    },
};
