import React from 'react';
import {Transaction as TransactionsDto} from "../../../containers/Wallet/models/transactionsResponse";
import {format} from "date-fns";
import {TransactionHelpers} from "./Transaction";
import {ITransactionView} from "../../../containers/Wallet/Wallet";
import {IStatusItem} from "../../../containers/Wallet/models/transactionStatics.response";

const BuildViewTransactions = (transactionsResponse: TransactionsDto[], types?: IStatusItem[]) => {

    return transactionsResponse.map((transaction) => {
        const date = new Date(transaction.createdAt);
        let transactionView: ITransactionView = {
            serviceTransactionRef: transaction.serviceTransactionRef,
            date: date,
            logo: transaction.brandLogo,
            narration: transaction.narration,
            message: transaction.message,
            status: transaction.status,
            amount: "" + transaction.amount,
            type: transaction.type,
            id: transaction.id,
            amountConverted: transaction.convertedAmount,
            fee: transaction.fee,
            phone: transaction.phone,
            user: transaction.user,
            recipient: transaction.recipient,
            orderStatus: transaction.orderStatus,
            last4digits: transaction.last4digits,
            orderNumber: transaction.orderNumber
        };

        if (types) {
            transactionView = TransactionHelpers.getLabelsTransaction(types, transactionView)
        }

        if (transaction.status) {
            transactionView.color = TransactionHelpers.getStatusColor(transaction.status)
        }

        return transactionView;
    });
}

export default BuildViewTransactions;
