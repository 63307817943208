import { Menu, MenuItem, Sidebar, useProSidebar } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import {
  CardIsFilledFalse,
  LogOutIcon,
  MerchantsIsFilledFalse,
  RecipientsIsFilledFalse,
  ServicesIsFilledFalse,
  TransactionsIsFilledFalse,
  WalletIsFilledFalse,
} from "../../shared/_utils/constants/icon";
import React, { useEffect, useState } from "react";
import "./style.css";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useSidebarContext } from "../SidebarContext";
import { accountService } from "../../shared/services";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const KSideBar = () => {
  const { collapsed, collapseSidebar, toggleSidebar, broken } = useProSidebar();
  const location = useLocation();
  const navigate = useNavigate();

  const { isVisible } = useSidebarContext();

  const [breakPoint, setBreakPoint] = useState<boolean>(false);
  const [isActive, setIsActive] = useState(false);
  const business = localStorage.getItem("Business")
  useEffect(() => {
    setBreakPoint(isVisible);
    toggleSidebar(isVisible);
  }, [isVisible]);

  const kCollapseSidebar = () => {
    collapseSidebar();
  };

  const logout = () => {
    accountService.logout();
    toast.success("Vous êtes deconnecté");
    navigate("/login");
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar
        style={{
          height: "100vh",
          // top: 'auto',
        }}
        rootStyles={{
          overflowY: "visible",
          overflowX: "visible",
        }}
        breakPoint="md"
        // breakPoint={breakPoint ? 'xs' : 'lg'}
        // backgroundColor='#001021'
        backgroundColor="#ebedef"
        collapsed={collapsed}
      >
        <Menu
          menuItemStyles={{
            icon: ({ level, active }) => {
              return {
                color: "red",
              };
            },
            button: ({ level, active }) => {
              // if (active)
              //     setIsActive(true)
              return {
                borderRadius: "4px",
                color: active ? "#FFFFFF" : "#636f83",
                background: active
                  ? "linear-gradient(95.08deg, #B349EB 0%, #4A90E2 100%)"
                  : undefined,
              };
            },
          }}
        >
          <MenuItem className="menuLogo">
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: 1,
                marginTop: 2,
                textAlign: "center",
              }}
            >
              {!collapsed && (
                <Box
                  component="img"
                  // src="https://res.cloudinary.com/payqin-ltd/image/upload/v1688489579/web-app/payqin-white_1_gs9nkd.png"
                  src="https://res.cloudinary.com/payqin-ltd/image/upload/v1692967362/web-app/te%CC%81le%CC%81chargement_xdn9t4.png"
                  sx={{
                    // marginLeft: 1,
                    // marginTop: 2,
                    width: "60%",
                  }}
                />
              )}
              <Box onClick={kCollapseSidebar}>
                <MenuOutlinedIcon />
              </Box>
            </Box>
          </MenuItem>
          <br />
          <br />
          <br />
          <MenuItem
            active={location.pathname === "/"}
            component={<Link to="/" />}
            icon={<WalletIsFilledFalse color={"#636F83"} />}
            style={{ fontWeight: "700" }}
          >
            {!collapsed ? (
              <Typography sx={styles.text}>
                {t("navigations.wallet")}
              </Typography>
            ) : null}
          </MenuItem>
          <MenuItem
            active={location.pathname === "/cards"}
            component={<Link to="/cards" />}
            icon={<CardIsFilledFalse color={"#636F83"} />}
          >
            {!collapsed ? (
              <Typography sx={styles.text}>
                {t("navigations.cards")}{" "}
              </Typography>
            ) : null}
          </MenuItem>
          <MenuItem
            active={location.pathname === "/transactions"}
            component={<Link to="/transactions" />}
            icon={<TransactionsIsFilledFalse color={"#636F83"} />}
          >
            {!collapsed ? (
              <Typography sx={styles.text}>
                {t("navigations.transactions")}
              </Typography>
            ) : null}
          </MenuItem>
          <MenuItem
            active={location.pathname === "/recipients"}
            component={<Link to="/recipients" />}
            icon={<RecipientsIsFilledFalse color={"#636F83"} />}
          >
            {!collapsed ? (
              <Typography sx={styles.text}>
                {t("navigations.recipients")}{" "}
              </Typography>
            ) : null}
          </MenuItem>
          {business && <MenuItem
            active={location.pathname === "/business/business-request"}
            component={<Link to="/business/business-request" />}
            icon={<WalletIsFilledFalse color={"#636F83"} />}
            style={{ fontWeight: "700" }}
          >
            {!collapsed ? (
              <Typography sx={styles.text}>
                {t("navigations.BusinessRequest")}
              </Typography>
            ) : null}
          </MenuItem>}

          {!business && <MenuItem
            active={location.pathname === "/business/business-request"}
            component={<Link to="/business/business-request" />}
            icon={<WalletIsFilledFalse color={"#636F83"} />}
            style={{ fontWeight: "700" }}
          >
            {!collapsed ? (
              <Typography sx={styles.text}>
                {t("navigations.becomeBusiness")}
              </Typography>
            ) : null}
          </MenuItem>}
          {/* <MenuItem icon={<MerchantsIsFilledFalse color={"#636F83"} />}>
            {!collapsed ? (
              <Typography sx={styles.text}>
                {" "}
                {t("navigations.merchant")}{" "}
              </Typography>
            ) : null}
          </MenuItem> */}
          {/* <MenuItem icon={<ServicesIsFilledFalse color={"#636F83"} />}>
            {!collapsed ? (
              <Typography sx={styles.text}>Services </Typography>
            ) : null}
          </MenuItem> */}
          <div onClick={logout}>
            <MenuItem
              icon={<LogOutIcon color={"#636F83"} />}
              rootStyles={{
                position: "absolute",
                bottom: "5%",
              }}
            >
              {!collapsed ? (
                <Typography sx={styles.text}>
                  {t("navigations.logout")}{" "}
                </Typography>
              ) : null}
            </MenuItem>
          </div>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default KSideBar;

const styles = {
  text: {
    fontWeight: 700,
  },
  divider: {
    mb: 7,
  },
};
