import {Box, IconButton, InputBase, Paper, Tabs, Typography,} from "@mui/material";
import React, {useEffect, useState} from "react";
import TabPanel from "../shared/TabPanel";
import {
    BankTransferIcon,
    CloseIcon,
    SearchIcon,
    SendMobileCreditIcon,
    SendTopUpPayqinTransferIcon,
} from "../../shared/_utils/constants/icon";
import CustomTab from "./components/CustomTab";
import ContactCard from "./components/ContactCard";
import {useLocation, useNavigate} from "react-router-dom";
import Popup from "../../components/Popup";
import SendMoneyForm, {ITransferForm} from "./components/SendMoneyForm";
import Spacing from "../../components/Spacing";
import {IWalletResponse} from "../Wallet/models/wallet.response";
import {accountService, transactionService, walletService,} from "../../shared/services";
import {ITransactionsFrequentsResponse} from "../Wallet/models/transactionsFrequents.response";
import {IUser} from "../Auth/models/auth.response";
import {isEmpty} from "../../shared/_utils/isEmpty";
import Successful from "./components/Successful";
import {IDataResponse} from "../../shared/types/IDataResponse";
import NewCardButton from "../Cards/components/NewCardButton";
import ContactCardLoader from "./components/ContactCardLoader";
import {IBankRecipientData} from "./BankRecipient";
import SubOperation, {validOperations} from "../../shared/types/SubOperation";
import {BeneficiaryTypes, DialogFunction, Operation} from "../../shared/types/types";
import ShowOperationsPopup from "../../components/ShowOperationsPopup";
// import {transferProviders} from "../../shared/_utils/constants/Providers";
import IRecipient, {IPayqinRecipient, IRecipientsResponse, IVertoRecipient,} from "../../shared/types/IRecipient";
import {recipientsRepository} from "./repository/recipients.repository";
import {IRecipientFormData} from "./PayQinRecipient";
import ContactLoader from "./components/ContactLoader";
import useProviderMobileMoney from "../../shared/hook/useProviderMobileMoney";
import GradientCircularProgress from "../../components/GradientCircularProgress";
import {IApiResponse} from "../../shared/types/IApiResponse";
import {walletRepository} from "../Wallet/repository/wallet.repository";
import {t} from "i18next";
import RoundGradientButton from "../../components/RoundGradientButton";
import ConfirmationCode from "./components/ConfirmationCode";
import EnhancedTableToolbar from "./components/EnhancedTableToolbar";
import toast from "react-hot-toast";
import GradientCircularProgressWithBackdrop from "../../components/CircularProgressWithBackdrop";
import KTableContainer from "./components/KTableContainer";

const Recipients = () => {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const [recipient, setRecipient] = useState<IRecipient | null>(null);
    const [balance, setBalance] = useState<number | null>(null);
    const [amount, setAmount] = useState<number>();
    const [balanceError, setBalanceError] = useState<string | null>(null);
    const [frequentsUsers, setFrequentsUsers] = useState<IRecipient[]>([]);
    const [banksRecipients, setBanksRecipients] = useState<IRecipient[]>([]);
    const [payqinRecipients, setPayqinRecipients] = useState<IRecipient[]>([]);
    const [recipients, setRecipients] = useState<IRecipient[]>([]);
    const [errorRecipients, setErrorRecipients] = useState<string>();
    const [loadingRecipients, setLoadingRecipients] = useState<boolean>(false);
    const [errorFrequentsUsers, setErrorFrequentsUsers] = useState<string | null>();
    const [transaction, setTransaction] = useState<Operation | null>(null);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [openPopUpSendMoney, setOpenPopUpSendMoney] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [addRecipientPopup, setAddRecipientPopup] = useState(false);
    const [popupProviderMobileMoney, setPopupProviderMobileMoney] =
        useState(false);
    const [popupTransfer, setPopupTransfer] = useState(false);
    const [providersMobileMoney, setProvidersMobileMoney] = useState<SubOperation[] | null>(null);
    const [providerMobileMoney, setProviderMobileMoney] =
        useState<SubOperation | null>(null);

    const [handleCloseDialog, setHandleCloseDialog] =
        useState<DialogFunction>(null);
    const [popup, setPopup] = useState<boolean>(false);

    const [errorProviders, setErrorProviders] = useState<string | null>(null);

    const [content, setContent] = useState<JSX.Element | null>(null);
    const [currency, setCurrency] = useState<string>("");

    const [chooseRecipientTypePopup, setChooseRecipientTypePopup] =
        useState(false);

    const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
    const [transferData, setTransferData] = useState<ITransferForm>();

    const [searchText, setSearchText] = useState("");
    const [transferLoading, setTransferLoading] = useState<boolean>(false);
    const [confirmationCodeError, setConfirmationCodeError] = useState<string>("");
    const [deleting, setDeleting] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const location = useLocation();
    let state: IBankRecipientData | IRecipientFormData | null = location.state;
    const queryParams = new URLSearchParams(location.search);
    const data = queryParams.get("transaction");

    const payqinProvider: SubOperation = {} as SubOperation;
    payqinProvider.type = "PAYQIN_TRANSFER";
    payqinProvider.text = "payqin";
    payqinProvider.img =
        "https://res.cloudinary.com/payqin-ltd/image/upload/v1688577749/web-app/Top-up_s9clhm.png";

    useEffect(() => {
        if (!recipients) setLoadingRecipients(true);
        loadInitialData().then(() => {
        });
    }, []);

    const _refresh = () => {
        setLoadingRecipients(true);
        loadInitialData().then(() => {
        });
    }

    const transferProviders: SubOperation[] = [
        {
            id: 1234567895,
            icon: <SendMobileCreditIcon/>,
            text: t("mobileMoney"),
            type: "MOBILE_MONEY_TRANSFER",
        },
        {
            id: 7654323456,
            icon: <BankTransferIcon/>,
            text: t("bankTransfer"),
            type: "BANK_TRANSFER",
        },
        {
            id: 4567809871,
            icon: <SendTopUpPayqinTransferIcon/>,
            text: t("payqinTransfer"),
            type: "PAYQIN_TRANSFER",
        },
    ];

    const handleTransferOperation = async (subOperation?: SubOperation) => {

        if (null === recipient) return;

        if (subOperation) {
            switch (subOperation.type) {
                case "MOBILE_MONEY_TRANSFER":
                    setPopupTransfer(false);
                    setPopupProviderMobileMoney(true);
                    break;
                case "BANK_TRANSFER":
                    setPopupTransfer(false);
                    goToBankTransfer(recipient);
                    break;
                case "PAYQIN_TRANSFER":
                    // console.log('1')
                    // const payQinUser = await isPayQinUser(recipient);
                    // console.log('3')
                    // console.log({payQinUser})
                    // if (!payQinUser) {
                    //     setPopup(true)
                    //     setContent(<Box sx={{display: "flex", justifyContent: "center"}}>
                    //         <Warning/>
                    //         <Typography>
                    //             Ce contact n'est pas un contact payqin
                    //         </Typography>
                    //     </Box>)
                    //     break;
                    // }
                    setProviderMobileMoney(payqinProvider);
                    handleSendMoney();
                    break;
            }
        }
    };

    const onclickContactItem = async (data: IRecipient) => {
        setRecipient(data);
        const result = isOperationSelected();

        if (null == transaction) {
            setPopupTransfer(true);
        } else if ("BANK_TRANSFER" == transaction) {
            return goToBankTransfer(data);
        } else if ("PAYQIN_TRANSFER" == transaction) {
            setProviderMobileMoney(payqinProvider);
            return handleSendMoney();
        } else {
            handleMobileMoney(result);
        }
    };

    const handleUpdateBeneficiary = async (recipient: IRecipient) => {
        if (recipient.beneficiaryType === "payqin") {
            navigate(`/payqin-beneficiaries/${recipient.id}/update`, {state: recipient})
        } else if (recipient.beneficiaryType === "verto" || recipient.beneficiaryType === "vertofx" || recipient.beneficiaryType === "gtbank") {
            navigate(`/bank-beneficiaries/${recipient.id}/update`, {state: recipient})
        } else {
            toast.error(t("errorOccurred"))
        }
    }

    const handleDeleteBeneficiaries = async () => {

        const isConfirm = await confirm(t("deleteBeneficiaries"))

        if (!isConfirm) {
            return;
        }

        setDeleting(true)

        const beneficiariesIds = new URLSearchParams();
        selected.forEach((id) => beneficiariesIds.append('ids', id));

        const {data, error} = await recipientsRepository.deleteBeneficiaries(beneficiariesIds.toString())
        setSelected([])
        setDeleting(false)

        if (null !== data && data.message) {
            toast.success(data.message)
            _refresh()
        }
    }

    const handleMobileMoney = (subOperation?: SubOperation | null) => {
        if (!subOperation) return;

        if (subOperation.code == "cinetpay") {
            subOperation.type = "CINETPAY_TRANSFER";
        } else if (subOperation.code == "wave") {
            subOperation.type = "WAVE_TRANSFER";
        }
        setPopupProviderMobileMoney(false);
        setProviderMobileMoney(subOperation);
        return handleSendMoney();
    };

    const isPayQinUser = async (
        recipient?: IRecipient
    ): Promise<IUser | null> => {
        if (undefined == recipient) return null;

        const {data, error} = await recipientsRepository.searchUser(
            recipient.phoneNumber
        );

        return data && data.length > 0 ? data[0] : null;
    };

    const handleSendMoney = () => {
        setOpenPopUpSendMoney(true);
    };

    const submitOperationToConfirmation = async (code: string) => {
        let res: IApiResponse<IDataResponse | null> | null;
        if (transferData === undefined) {
            return;
        }

        transferData.code = code;

        setTransferLoading(true);
        if (transferData.provider.type === "CINETPAY_TRANSFER") {
            res = await performMobileMoneyTransfer(transferData);
        } else if (transferData.provider.type === "WAVE_TRANSFER") {
            res = await performWaveTransfer(transferData);
        } else if (transferData.provider.type === "PAYQIN_TRANSFER") {
            res = await performPayqinTransfer(transferData);
        } else {
            res = null;
        }

        if (null == res) {
            return "La transaction n'a pas abouti";
        }

        const {data, error} = res;

        setTransferLoading(false);

        if (data) {
            if (data.success || data.status) {
                setConfirmationModal(false);
                setOpenPopUpSendMoney(false);
                setShowSuccessPopup(true);
            } else {
                const msg = data.errorMessage || data.message || data.statusText
                setConfirmationCodeError(msg)
                return msg;
            }
        } else {
            setConfirmationCodeError(error || t("errorOccurred"))
            return error || t("errorOccurred");
        }
    };

    const handleFormSubmit = async (formData: ITransferForm) => {
        await transactionService.getOperationVerificationCode();
        setAmount(+formData.amount);
        setTransferData(formData);
        setConfirmationModal(true);
    };

    const performPayqinTransfer = async (
        formData: ITransferForm
    ): Promise<IApiResponse<IDataResponse | null>> => {
        if (!recipient)
            return {
                data: null,
                error: "Le contact n'est pas trouvé",
            } as IApiResponse<null>;

        const payQinUser = await isPayQinUser(recipient);

        if (!payQinUser) {
            return {
                data: null,
                error: "Ce contact n'a pas de compte payqin",
            } as IApiResponse<null>;
        }

        const resp = await walletRepository.performPayqinTransfer(
            formData.amount,
            payQinUser.id,
            formData.note,
            formData.code
        );

        return resp;
    };

    const performMobileMoneyTransfer = async (
        formData: ITransferForm
    ): Promise<IApiResponse<IDataResponse | null>> => {
        const resp = await walletRepository.postMobileMoneyTransfer(
            formData.amount,
            recipient?.internationalPhoneNumber ?? "",
            formData.note,
            formData.code
        );

        // setOpenPopUpSendMoney(false)
        // setShowSuccessPopup(true);
        return resp;
    };

    const performWaveTransfer = async (formData: ITransferForm) => {
        return await walletRepository.performWaveTransfer(
            formData.amount,
            recipient?.internationalPhoneNumber ?? "",
            currency,
            formData.code
        );
    };

    const getBalance = async (): Promise<IWalletResponse> => {
        try {
            const {data, status, statusText} = await walletService.getBalance();

            return Promise.resolve({
                data: data.data,
                error: null,
            } as IWalletResponse);
        } catch (error) {
            return Promise.resolve({
                data: null,
                error: "Solde indisponible",
            } as IWalletResponse);
        }
    };

    const getFrequentRecipients =
        async (): Promise<ITransactionsFrequentsResponse> => {
            try {
                const {data, status, statusText} =
                    await transactionService.getFrequentRecipients();

                return Promise.resolve({
                    data: data,
                    error: null,
                } as ITransactionsFrequentsResponse);
            } catch (error) {
                return Promise.resolve({
                    data: null,
                    error: t("recipients.loadingFrequentRecipientsError"),
                } as ITransactionsFrequentsResponse);
            }
        };

    const getRecipients = async () => {
        setLoadingRecipients(true);
        recipientsRepository.getRecipients().then(async (r) => {
            const data = r.data;
            if (null !== data && data.success) {
                await buildRecipients(data);
            }
        });
    };

    const loadInitialData = async () => {
        useProviderMobileMoney()
            .then((res) => setProvidersMobileMoney(res))
            .catch((err) => setErrorProviders(err));
        getBalance().then((res) => {
            if (res.data) {
                setBalance(res.data.balance);
                setBalanceError(null);
            } else {
                setBalance(null);
                setBalanceError(res.error);
            }
        });
        getFrequentRecipients().then((result) => {
            if (result.data) {
                buildTransactionsFrequents(result.data.data);
            } else {
                setErrorFrequentsUsers(result.error);
            }
        });
        await getRecipients();
        const currency = accountService.getUser()!.currency;
        setCurrency(currency);
        isOperationSelected();
    };

    const buildRecipients = async (recipientsResponse: IRecipientsResponse) => {
        const banksRecipients = await buildBanksRecipients(recipientsResponse.bank);
        if (banksRecipients) setBanksRecipients(banksRecipients);
        const payqinRecipients = await buildPayqinRecipients(
            recipientsResponse.payqin
        );
        if (payqinRecipients) setPayqinRecipients(payqinRecipients);
        if (banksRecipients && payqinRecipients)
            setRecipients([...banksRecipients, ...payqinRecipients]);

        setLoadingRecipients(false);
    };

    const buildBanksRecipients = (datas: IVertoRecipient[]) => {
        if (!isEmpty(datas)) {
            return datas.map((data) => {
                const bankRecipient = {} as IRecipient;
                bankRecipient.id = data._id;
                bankRecipient.entity = data.entity;
                bankRecipient.firstName = data.firstName;
                bankRecipient.lastName = data.lastName;
                bankRecipient.companyName = data.companyName;
                bankRecipient.phoneNumber = data.phoneNumber;
                bankRecipient.countryCode = data.countryCode;
                bankRecipient.internationalPhoneNumber = (data.countryCode ?? "") + (data.phoneNumber ?? "");
                bankRecipient.picture = "";
                bankRecipient.bankName = data.bankName;
                bankRecipient.beneficiaryType = data.beneficiaryType as BeneficiaryTypes;
                bankRecipient.accountNumber = data.accountNumber;
                bankRecipient.swiftCode = data.swiftCode;
                bankRecipient.currency = data.currency;
                bankRecipient.email = data.email;
                bankRecipient.country = data.country;
                bankRecipient.address = data.address;

                return bankRecipient;
            });
        } else {
            return [];
        }
    };

    const buildPayqinRecipients = (datas: IPayqinRecipient[]) => {
        if (!isEmpty(datas)) {
            return datas.map((data) => {
                const payqinRecipient = {} as IRecipient;
                payqinRecipient.id = data._id;
                payqinRecipient.entity = data.entity;
                payqinRecipient.beneficiaryType = data.beneficiaryType as BeneficiaryTypes;
                payqinRecipient.client = data.client;
                payqinRecipient.firstName = data.firstName;
                payqinRecipient.lastName = data.lastName;
                payqinRecipient.phoneNumber = data.phoneNumber;
                payqinRecipient.countryCode = data.countryCode;
                payqinRecipient.internationalPhoneNumber = (data.countryCode ?? "") + (data.phoneNumber ?? "");
                payqinRecipient.email = data.email;
                payqinRecipient.picture = "";

                return payqinRecipient;
            });
        } else {
            return [];
        }
    };

    const buildTransactionsFrequents = (frequentsUsers: IUser[]) => {
        const listFrequentsUsersView: IRecipient[] = frequentsUsers.map((user) => {
            const frequentUser = {} as IRecipient;
            frequentUser.id = user.id;
            frequentUser.firstName = user.firstName;
            frequentUser.lastName = user.lastName;
            frequentUser.phoneNumber = user.phone;
            frequentUser.internationalPhoneNumber = user.phone;
            frequentUser.picture = user.picture;

            return frequentUser;
        });

        setFrequentsUsers(listFrequentsUsersView);
    };

    const addRecipient = () => {
        if (null == transaction) {
            setChooseRecipientTypePopup(true);
        } else if (transaction == "BANK_TOP_UP" || transaction == "BANK_TRANSFER") {
            return goToBankRecipient();
        } else {
            return goToMobileRecipient();
        }
    };

    const goToBankTransfer = (data: IRecipient) => {
        if (!data.accountNumber) {
            setPopup(true);
            setContent(
                <Box>
                    <Typography>{t("recipients.bankRecipientError")}</Typography>
                </Box>
            );
            return;
        }
        navigate(`/recipients/${data.id}/transactions/new`, {state: data});
    };

    const goToBankRecipient = () => {
        navigate("/bank-beneficiaries/create");
    };

    const goToMobileRecipient = () => {
        navigate("/payqin-beneficiaries/create");
    };

    const isOperationSelected = (): SubOperation | undefined => {
        if (null == data) return;

        if (!validOperations.includes(data.toUpperCase() as Operation)) {
            return;
        }
        setTransaction(data.toUpperCase() as Operation);

        if (!providersMobileMoney) {
            return;
        }

        let provider;

        if (data.toUpperCase() == "CINETPAY_TRANSFER") {
            provider = providersMobileMoney.find(
                (providersMobileMoney) => providersMobileMoney.code == "cinetpay"
            );
            if (provider) provider.type = "CINETPAY_TRANSFER";
        } else if (data.toUpperCase() == "WAVE_TRANSFER") {
            provider = providersMobileMoney.find(
                (providersMobileMoney) => providersMobileMoney.code == "wave"
            );
            if (provider) provider.type = "WAVE_TRANSFER";
        }

        if (provider) {
            setProviderMobileMoney(provider);
        }

        return provider;
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const handleClick = (id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    return (
        <div>
            <Box>
                <Box sx={styles.firstLine}>
                    <Box sx={styles.firstContent}>
                        <Typography
                            style={{
                                fontSize: "16px",
                                fontWeight: 400,
                            }}
                        >
                            {t("recipients.frequentRecipientsLabel")}
                        </Typography>
                    </Box>
                    <Box sx={styles.secondContent}>
                        <RoundGradientButton
                            onClick={addRecipient}
                            disabled={false}
                            type="submit"
                            variant={"contained"}
                            color={"inherit"}
                            sx={{
                                background: "linear-gradient(to right, #C471ED, #4A90ED)",
                                textTransform: "none",
                                paddingLeft: 5,
                                paddingRight: 5,
                                mt: 5,
                            }}
                            fullWidth
                        >
                            <Typography sx={{color: "#FFFFFF" || ""}}>
                                {t("recipients.recipientAddButtonLabel")}
                            </Typography>
                        </RoundGradientButton>
                    </Box>
                </Box>

                <Box sx={styles.frequentsContacts}>
                    {!isEmpty(frequentsUsers) ? (
                        frequentsUsers.map((recipient) => (
                            <ContactCard
                                onClickCard={() => onclickContactItem(recipient)}
                                recipient={recipient}
                            />
                        ))
                    ) : errorFrequentsUsers ? (
                        <Typography m={5} sx={{fontSize: "1em"}}>
                            {errorFrequentsUsers}
                        </Typography>
                    ) : (
                        <>
                            <ContactCardLoader/>
                            <ContactCardLoader/>
                            <ContactCardLoader/>
                        </>
                    )}
                </Box>

                <Spacing marginBottom={"2em"}/>

                <Box sx={styles.firstLine}>
                    <Box sx={styles.firstContent}>
                        <Typography
                            variant="h5"
                            style={{
                                fontWeight: 700,
                                fontSize: "20px",
                            }}
                        >
                            {t("recipients.recipientsLabel")}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={styles.pageContent}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor={"secondary"}
                    >
                        <CustomTab label={t("recipients.allRecipientsTitle")}/>
                        <CustomTab label={t("recipients.payqinRecipientsTitle")}/>
                        <CustomTab label={t("recipients.bankRecipientsTitle")}/>
                    </Tabs>
                    <Paper sx={styles.searchForm} component="form">
                        <IconButton sx={{p: "10px"}} aria-label="search">
                            <SearchIcon/>
                        </IconButton>
                        <InputBase
                            sx={{ml: 0, flex: 1}}
                            placeholder={t("recipients.searchRecipientPlaceholder")}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <IconButton
                            type="button"
                            sx={{p: "0px"}}
                            aria-label="search"
                            onClick={() => setSearchText("")}
                        >
                            <CloseIcon width={20} height={20}/>
                        </IconButton>
                    </Paper>
                </Box>
                {selected.length > 0 &&
                <Box mt={2}>
                    <EnhancedTableToolbar numSelected={selected.length}
                                                                  handleDeleteBeneficiary={handleDeleteBeneficiaries}/>
                </Box>
                }
                <TabPanel value={value} index={0}>
                    {loadingRecipients ? (
                        <>
                            <div style={{marginBottom: "2%"}}>
                                <ContactLoader/>
                            </div>
                            <ContactLoader/>
                        </>
                    ) : !isEmpty(recipients) && !loadingRecipients ?

                        <Box sx={{width: '100%'}}>

                            <KTableContainer isSelected={isSelected} recipients={recipients} handleClick={handleClick}
                                             handleUpdateBeneficiary={handleUpdateBeneficiary}
                                             onclickContactItem={onclickContactItem} selected={selected}/>
                        </Box>
                        : errorRecipients ? (
                            <Typography m={5} sx={{fontSize: "1em"}}>
                                {errorRecipients}
                            </Typography>
                        ) : (
                            <Typography variant="h6">
                                {t("recipients.noRecipientAtAll")}
                            </Typography>
                        )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {payqinRecipients && payqinRecipients.length > 0 ? (
                        <KTableContainer isSelected={isSelected} recipients={payqinRecipients} handleClick={handleClick}
                                         handleUpdateBeneficiary={handleUpdateBeneficiary}
                                         onclickContactItem={onclickContactItem} selected={selected}/>
                    ) : (
                        <Typography variant={"h6"}>
                            {t("recipients.noPayQinRecipients")}
                        </Typography>
                    )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {banksRecipients && banksRecipients.length > 0 ? (
                        <KTableContainer isSelected={isSelected} recipients={banksRecipients} handleClick={handleClick}
                                         handleUpdateBeneficiary={handleUpdateBeneficiary}
                                         onclickContactItem={onclickContactItem} selected={selected}/>
                    ) : (
                        <Typography variant={"h6"}>
                            {t("recipients.noBankRecipients")}
                        </Typography>
                    )}
                </TabPanel>
            </Box>

            <GradientCircularProgressWithBackdrop open={deleting} setOpen={setDeleting}/>

            <Popup
                title={t("selectProvider")}
                open={popupProviderMobileMoney}
                onClose={setPopupProviderMobileMoney}
            >
                {providersMobileMoney ? (
                    providersMobileMoney.length > 0 ? (
                        <ShowOperationsPopup
                            additionalStyle={{
                                margin: 1,
                                background: "#FFFFFF",
                                border: "1px solid #C7C7C7",
                            }}
                            items={providersMobileMoney}
                            handleChannelOperation={handleMobileMoney}
                        />
                    ) : (
                        <GradientCircularProgress/>
                    )
                ) : (
                    <Typography>{errorProviders}</Typography>
                )}
            </Popup>

            <Popup
                title={t("recipients.sendMoneyLabel")}
                open={popupTransfer}
                onClose={setPopupTransfer}
                additionalStyle={{backgroundColor: "#FFFFFF"}}
            >
                <ShowOperationsPopup
                    additionalStyle={{
                        margin: 1,
                        background: "#FFFFFF",
                        border: "1px solid #C7C7C7",
                    }}
                    items={transferProviders}
                    handleChannelOperation={handleTransferOperation}
                    recipient={recipient}
                />
            </Popup>

            <Popup
                open={openPopUpSendMoney}
                onClose={setOpenPopUpSendMoney}
                additionalStyle={{backgroundColor: "#FFFFFF"}}
            >
                <SendMoneyForm
                    balance={+balance!}
                    user={recipient}
                    onSubmit={handleFormSubmit}
                    provider={providerMobileMoney}
                    transferLoading={transferLoading}
                />
            </Popup>

            <Popup
                title=""
                open={popup}
                onClose={setPopup}
                additionalStyle={{backgroundColor: "#FFFFFF"}}
            >
                {content}
            </Popup>

            <Popup
                title=""
                open={showSuccessPopup}
                onClose={setShowSuccessPopup}
                additionalStyle={{backgroundColor: "#FFFFFF"}}
            >
                <Successful amount={amount} recipient={recipient}/>
            </Popup>

            <Popup
                title={t("recipients.confirmationCodeLabel")}
                open={confirmationModal}
                onClose={setConfirmationModal}
                additionalStyle={{backgroundColor: "#FFFFFF"}}
            >
                <ConfirmationCode
                    error={confirmationCodeError}
                    isLoading={transferLoading}
                    handleCompleted={(e) => submitOperationToConfirmation(e)}
                />
            </Popup>

            <Popup
                title={t("recipients.recipientChoiceTitle")}
                open={chooseRecipientTypePopup}
                onClose={setChooseRecipientTypePopup}
                additionalStyle={{backgroundColor: "#FFFFFF"}}
            >
                <Box
                    sx={{
                        padding: {
                            sx: "5 2",
                            sm: 5,
                        },
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: {
                            xs: "wrap",
                            sm: "noWrap",
                        },
                    }}
                >
                    <NewCardButton
                        additionalStyle={{margin: 1}}
                        title={t("recipients.BankChoiceLabel")}
                        children={<BankTransferIcon/>}
                        action={goToBankRecipient}
                    />
                    <NewCardButton
                        additionalStyle={{margin: 1}}
                        title={t("recipients.MobileRecipientLabel")}
                        children={<SendMobileCreditIcon/>}
                        action={goToMobileRecipient}
                    />
                </Box>
            </Popup>
        </div>
    );
};

export default Recipients;

/**
 * @type {import("@mui/material").SxProps}
 */

const styles = {
    button: {
        textTransform: "none",
        color: "#4A90E2",
        fontSize: "16px",
        fontWeight: 400,
    },
    underline: {
        textDecoration: "underline",
    },
    frequentsContacts: {
        "&::-webkit-scrollbar": {
            display: "none",
        },
        /* Hide scrollbar for IE, Edge and Firefox */
        "-ms-overflow-style": "none" /* IE and Edge */,
        scrollbarWidth: "none" /* Firefox */,
        display: "flex",
        flexDirection: "row",
        flexWrap: "no-wrap",
        justifyContent: "normal",
        alignItems: "start",
        overflowX: "scroll",
        mt: 2,
    },
    searchForm: {
        p: "0px 2px",
        m: {
            xs: "10px 0 0",
            md: "0",
        },
        width: {
            xs: "50%",
            md: "40%",
        },
        display: "flex",
        alignItems: "center",
        border: 0.5,
        borderRadius: "8px",
        borderColor: "#C7C7C7",
    },
    pageContent: {
        display: "flex",
        flexDirection: {
            xs: "column",
            md: "row",
        },
        width: "100%",
        justifyContent: {
            xs: "flex-start",
            md: "space-between",
        },
        alignItems: {
            xs: "flex-start",
            md: "center",
        },
    },
    pageTitle: {
        mb: 2,
    },
    firstContent: {
        display: "flex-inline",
        justifyContent: "start",
    },
    secondContent: {
        display: "flex",
        justifyContent: "end",
        color: "#001021",
        fontSize: "16px",
        fontWeight: 400,
    },
    firstLine: {
        columns: 2,
        mb: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
};
