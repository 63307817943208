import {IBankRecipientDto,} from "../../containers/Recipients/BankRecipient";
import {httpService} from "./http.service";

const deleteBeneficiaries = (beneficiariesIds: string) => {
  return httpService.Axios.delete(`/wallets/transfer/beneficiary?${beneficiariesIds}`);
};

const updateBeneficiary = (recipientDto: IBankRecipientDto, beneficiaryId?: string) => {
  return httpService.Axios.put(`/wallets/transfer/beneficiary/${beneficiaryId}`, recipientDto);
};

const getBankCodes = () => {
  return httpService.Axios.get("/wallets/transfer/bank-code");
};

const createBeneficiary = (recipientDto: IBankRecipientDto) => {
  return httpService.Axios.post("/wallets/transfer/beneficiary", recipientDto);
};

const addBankRecipient = (bankRecipientDto: IBankRecipientDto) => {
  return httpService.Axios.post(
    "/wallets/verto/payment-beneficiaries",
    bankRecipientDto
  );
};

const getRecipients = () => {
  return httpService.Axios.get("/wallets/transfer/beneficiary-list");
};

const getAvailableCountriesForBankTransfer = () => {
  return httpService.Axios.get("/wallets/countries-and-currencies");
};

export const recipientsService = {
  updateBeneficiary,
  deleteBeneficiaries,
  getRecipients,
  addBankRecipient,
  createBeneficiary,
  getBankCodes,
  getAvailableCountriesForBankTransfer,
};
