import {Operation} from "./types";

export default interface SubOperation {
    id: number
    icon: JSX.Element | string
    text: string
    code?: string
    action?: (params: any) => any
    type?: Operation
    img?: string|null
    country?: string|null
}

export const validOperations: Operation[] = [
    'MOBILE_MONEY_TRANSFER',
    'CINETPAY_TRANSFER',
    'WAVE_TRANSFER',
    'BANK_TRANSFER',
    'PAYQIN_TRANSFER',
    'MOBILE_MONEY_TOP_UP',
    'CINETPAY_TOP_UP',
    'WAVE_TOP_UP',
    'BANK_TOP_UP',
    'BANK_CARD_TOP_UP',
    'BANK_DEPOSIT_TOP_UP',
    'VOUCHER_TOP_UP',
];