import ICountry from "../types/ICountry";
import {isEmpty} from "../_utils/isEmpty";

const getCountry = (phone: string) => {
  return transferCountries.find((c) => c.phone === phone);
};

const getCountryByCode = (code: string) => {
  return transferCountries.find((c) => c.code === code);
};

const removeSignCountryCode = (countryCode: string) => {
  if (!isEmpty(countryCode) && countryCode.startsWith("+")) {
    return countryCode.substring(1);
  } else return countryCode
}

const normalizeCountryCode = (countryCode: string) => {
  if (!isEmpty(countryCode) && countryCode.startsWith("+")) {
    return countryCode
  } else return `+${countryCode}`
}

const countries: readonly ICountry[] = [
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "CM", label: "Cameroon", phone: "237" },
];

const transferCountries: ICountry[] = [
  ...countries,
  { code: "NG", label: "Nigeria", phone: "234" },
  {
    code: "US",
    label: "United States",
    phone: "1",
  },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "CN", label: "China", phone: "86" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
  },
];

export const countryService = {
  normalizeCountryCode,
  removeSignCountryCode,
  getCountry,
  countries,
  transferCountries,
  getCountryByCode,
};
