import * as React from "react";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Spacing from "../../../../components/Spacing";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { CountrySelect } from "../../../Recipients/components/CountrySelect";
import { ArrowBackIos, CallMadeOutlined, Edit } from "@mui/icons-material";
import DirectionsIcon from "@mui/icons-material/Directions";
import RoundGradientButton from "../../../../components/RoundGradientButton";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { physicalCardsRepository } from "../../repository/physicalCard.repository";
import { ICity, ISupportedCountry } from "../../models/physicalCard.response";
import { useNavigate } from "react-router-dom";
import { cardsRepository } from "../../repository/card.repository";
import AppConfig from "../../../../config/config";
import GoogleMapReact from "google-map-react";
import { Marker } from "../../../../components/RetailerLocationMap";
import KTextField, {
  inputStyles,
  rootStyles,
} from "../../../../components/KTextField";
import { accountService, phoneService } from "../../../../shared/services";
import { IUser } from "../../../Auth/models/auth.response";
import { ActivateCardIcon } from "../../../../shared/_utils/constants/icon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Popup from "../../../../components/Popup";
import { t } from "i18next";
import CircularProgress from "@mui/material/CircularProgress";

export interface IOrderPhysicalCardForm {
  country: string | null;
  phoneNumber: string | null;
  phoneCode: string | null;
  id: string;
  orderNumber: string | null;
  date: string | null;
  retailerId: string | null;
  delivered: boolean | null;
  name: string | null;
  email: string | null;
  street: string | null;
  cityTown: string | null;
  status: string | null;
  fullName: string;
}

export interface IOrderPhysicalCardData {
  delivered: boolean;
  name: string;
  email: string;
  country: string;
  street: string;
  city: string;
  phone: string;
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function CompleteForm() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(0);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<IOrderPhysicalCardForm>();
  const [countries, setCountries] = useState<ISupportedCountry[] | null>([]);
  const [selectedCities, setSelectedCities] = useState<ICity[] | null>([]);
  const [countriesError, setCountriesError] = useState<string | null>(null);
  const [citiesError, setCitiesError] = useState<string | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [cities, setCities] = useState<string[]>([]);
  const [retailers, setRetailers] = useState([]);
  const [selectedRetailer, setSelectedRetailer] = useState(null);
  const [kError, setKError] = useState<string>("");
  const [user, setUser] = useState<IUser>();
  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<JSX.Element | null>(null);
  const [dialogTitle, setDialogTitle] = useState<JSX.Element>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const user = accountService.getUser();
    setUser(user);
  }, []);

  const handleTabChange = (e: React.SyntheticEvent, tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
  };

  const selectedTabStyle: React.CSSProperties = {
    borderRadius: "8px",
    background: "linear-gradient(to right, #C471ED, #4A90ED)",
    color: "#FFFFFF",
  };

  useEffect(() => {
    getRetailers().then((r) => {});
    fetchCountries().then((res) => {});
  }, []);

  const fetchCountries = async () => {
    physicalCardsRepository.getSupportedCountries().then((data) => {

      if (data.data) {
        setCountries(data.data.countries);
      } else {
        setCountriesError(data.error);
      }
    });
  };

  const handleCountryChange = (event: SelectChangeEvent) => {
    const countryId = event.target.value;
    setSelectedCountry(countryId);
    fetchCities(countryId).then((data) => {
      if (data.data) {
        setSelectedCities(data.data.cities);
      } else {
        setCitiesError(data.error);
      }
    });
  };

  const fetchCities = async (countryId: string) => {
    return physicalCardsRepository.getCitiesByCountry(countryId);
  };

  const submitForm: SubmitHandler<IOrderPhysicalCardForm> = async (
    orderPhysicalCard
  ) => {
    const cityName =
      selectedCities &&
      selectedCities.find((city) => city._id == getValues("cityTown"))?.name;
    const countryCode =
      countries &&
      countries.find((country) => country._id == getValues("country"))?.code;

    if (
      !cityName ||
      !countryCode ||
      !orderPhysicalCard.phoneCode ||
      !orderPhysicalCard.phoneNumber
    )
      return;

    const orderPhysicalCardData: IOrderPhysicalCardData = {
      delivered: true,
      name: orderPhysicalCard.fullName,
      email: orderPhysicalCard.email,
      country: countryCode,
      street: orderPhysicalCard.street,
      city: cityName,
      phone: phoneService.normalize(
        orderPhysicalCard.phoneCode,
        orderPhysicalCard.phoneNumber
      ),
    } as IOrderPhysicalCardData;

    setIsSubmitting(true);

    const { data, error } = await physicalCardsRepository.orderPhysicalCard(
      orderPhysicalCardData
    );

    if (data) {
      if (data.success) {
        dialogHandler(
          true,
          data.extra?.message || "Votre commande a bien été soumise"
        );
      } else {
        setKError(data.errorMessage || t("errorOccurred"));
      }
    } else {
      setKError(error || t("errorOccurred"));
    }

    setIsSubmitting(false);
  };

  const dialogHandler = (result: boolean, msg: string) => {
    setDialog(true);
    setDialogTitle(
      result ? (
        <CheckCircleIcon color={"success"} fontSize={"large"} />
      ) : (
        <CancelIcon color={"error"} fontSize={"large"} />
      )
    );
    setDialogContent(
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: "0 2",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography color={"red"} variant={"h6"} marginTop={2}>
          {msg}
        </Typography>
      </Box>
    );
  };

  const HandleCompleteForm: SubmitHandler<IOrderPhysicalCardForm> = async (
    data
  ) => {
    if (!data.country || !data.phoneNumber) return;
    setCurrentTabIndex(2);
  };

  const getRetailers = async () => {
    const { data, error } = await cardsRepository.getRetailersLocations();
    if (data.success) {
      setRetailers(data.retailers);
    } else {
      if (error) setKError(error);
    }
  };

  useEffect(() => {
    getRetailers().then((r) => {});
  }, []);

  return (
    <Box sx={styles.container}>
      {currentTabIndex === 2 ? (
        <Card>
          <CardContent>
            <Box
              sx={{ p: 3 }}
              component={"form"}
              noValidate
              onSubmit={handleSubmit(submitForm)}
            >
              <Box sx={styles.content}>
                <Box>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ position: "relative" }}>
                      <Button
                        onClick={() => setCurrentTabIndex(1)}
                        startIcon={<ArrowBackIos htmlColor={"#959595"} />}
                      >
                        <Typography sx={{ ...styles.label }}>
                          {t("back")}
                        </Typography>
                      </Button>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        display: "flex",
                        justifyContent: "center",
                        margin: "auto",
                      }}
                    >
                      {t("reviewDetails")}
                    </Typography>
                  </Box>
                  <Spacing marginBottom={"50px"} />
                  <Typography sx={styles.titleField}>
                    {t("shippingAddress")}
                  </Typography>
                  <Spacing marginBottom={"20px"} />
                  <Box>
                    <Box sx={styles.row}>
                      <Typography sx={styles.label}>{t("address")}</Typography>
                      <Box>
                        <Typography sx={styles.orderData}>
                          {getValues("street")}
                        </Typography>
                        <Button
                          sx={styles.editField}
                          startIcon={<Edit style={{ fontSize: "15px" }} />}
                          onClick={() => setCurrentTabIndex(1)}
                        >
                          <Typography sx={styles.editField}>
                            {t("changeAddress")}
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Spacing marginBottom={"20px"} />
                  <Box sx={styles.row}>
                    <Typography sx={styles.label}>{t("city")}</Typography>
                    <Typography sx={styles.orderData}>
                      {(selectedCities &&
                        selectedCities.find(
                          (city) => city._id == getValues("cityTown")
                        )?.name) ||
                        "NA"}
                    </Typography>
                  </Box>
                  <Spacing marginBottom={"20px"} />
                  <Box sx={styles.row}>
                    <Typography sx={styles.label}>{t("country")}</Typography>
                    <Typography sx={styles.orderData}>
                      {(countries &&
                        countries.find(
                          (country) => country._id == getValues("country")
                        )?.name) ||
                        "NA"}
                    </Typography>
                  </Box>
                </Box>
                <Spacing marginBottom={"20px"} />
                <hr />
                <Spacing marginBottom={"20px"} />
                <Box>
                  <Typography sx={styles.titleField}>
                    {t("personalInformation")}
                  </Typography>
                  <Spacing marginBottom={"20px"} />
                  <Box sx={styles.row}>
                    <Typography sx={styles.label}>Email</Typography>
                    <Typography sx={styles.orderData}>
                      {getValues("email")}
                    </Typography>
                  </Box>
                  <Spacing marginBottom={"20px"} />
                  <Box sx={styles.row}>
                    <Typography sx={styles.label}>{t("nameOnCard")}</Typography>
                    <Typography sx={styles.orderData}>
                      {getValues("fullName")}
                    </Typography>
                  </Box>
                  <Spacing marginBottom={"20px"} />
                  <Box sx={styles.row}>
                    <Typography sx={styles.label}>
                      {t("phoneNumber")}
                    </Typography>
                    <Typography sx={styles.orderData}>
                      +{getValues("phoneCode")}
                      {getValues("phoneNumber")}
                    </Typography>
                  </Box>
                  <Spacing marginBottom={"20px"} />
                </Box>
              </Box>
              <Spacing marginBottom={"30px"} />
              <Box sx={styles.content}>
                <Typography sx={styles.titleField}>
                  {t("payQinPhysicalCard")}
                </Typography>
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: 500,
                    backgroundColor: "#ffebee",
                    borderRadius: "8px",
                    padding: 1,
                  }}
                >
                  {t("shippingFeesPhysicalCard")}
                </Typography>
                <Spacing marginBottom={"20px"} />
                <Box sx={styles.row}>
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <ActivateCardIcon />
                    <Typography sx={styles.label}>{t("cardPrice")}</Typography>
                  </Box>
                  <Typography sx={{ fontWeight: 700 }}>6 000 CFA</Typography>
                </Box>
              </Box>
              <Spacing marginBottom={"30px"} />
              {kError && (
                <Typography
                  style={{
                    color: "#F94144",
                    fontSize: "1rem",
                    fontWeight: 400,
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {kError}
                </Typography>
              )}

              <Box
                sx={{
                  ...styles.content,
                  ...{ border: "none", padding: "revert" },
                }}
              >
                <RoundGradientButton
                  disabled={!isValid || isSubmitting}
                  fullWidth
                  type="submit"
                  variant={"contained"}
                  color={"inherit"}
                  style={{
                    background: isSubmitting
                      ? "linear-gradient(to right, #C471ED, #4A90ED)"
                      : "",
                  }}
                >
                  {isSubmitting ? (
                    <>
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "#FFFFFF",
                          marginRight: "5%",
                        }}
                      />
                      <Typography
                        sx={{
                          color: isSubmitting ? "#FFFFFF" : "",
                        }}
                      >
                        {t("loading")}...
                      </Typography>
                    </>
                  ) : (
                    <Typography>{t("confirmOrder")}</Typography>
                  )}
                </RoundGradientButton>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <>
          <Tabs
            sx={{ border: "solid 1px #959595", borderRadius: "8px" }}
            value={currentTabIndex}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              label={t("selfPickup")}
              style={currentTabIndex === 0 ? selectedTabStyle : {}}
              {...a11yProps(0)}
            />
            <Tab
              label={t("getdDelivered")}
              style={currentTabIndex === 1 ? selectedTabStyle : {}}
              {...a11yProps(1)}
            />
          </Tabs>
          {currentTabIndex === 0 && (
            <Box sx={{ p: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <GoogleMapReact
                  onClick={() => {}}
                  bootstrapURLKeys={{ key: AppConfig.googleMapKey }}
                  center={{
                    lat: 5.317613,
                    lng: -4.006326,
                  }}
                  defaultZoom={16}
                >
                  <Marker lat={Number(5.317613)} lng={Number(-4.006326)} />
                </GoogleMapReact>
              </Box>
              <Spacing marginBottom={"50px"} />
              <Grid
                container
                component="form"
                columns={12}
                mt={1}
                spacing={4}
                noValidate
              >
                <Grid item xs={6} md={6} lg={6}>
                  <RoundGradientButton
                    startIcon={<CallMadeOutlined />}
                    fullWidth
                  >
                    {t("call")}
                  </RoundGradientButton>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <RoundGradientButton startIcon={<DirectionsIcon />} fullWidth>
                    {t("road")}
                  </RoundGradientButton>
                </Grid>
              </Grid>
            </Box>
          )}

          {currentTabIndex === 1 && (
            <Box
              sx={{ p: 3 }}
              component={"form"}
              noValidate
              onSubmit={handleSubmit(HandleCompleteForm)}
            >
              <Spacing marginBottom={"50px"} />
              <Typography sx={styles.titleField}>
                {t("personalInformation")}
              </Typography>
              <Typography sx={styles.label}>{t("nameOnCard")}</Typography>
              <KTextField
                validation={register("fullName", {
                  required: "Votre nom complet est obligatoire",
                })}
                value={`${user?.lastName ?? "-"} ${user?.firstName ?? "-"}`}
                placeholder="Ex: john doe"
                fullWidth
              />
              {errors.fullName && <p>{errors.fullName.message}</p>}
              <Spacing marginBottom={"20px"} />
              <Typography sx={styles.label}>Email</Typography>
              <KTextField
                validation={register("email", {
                  required: "Votre email est obligatoire",
                })}
                value={user?.email}
                placeholder="Ex: johndoe@email.com"
                fullWidth
              />
              {errors.fullName && <p>{errors.fullName.message}</p>}
              <Spacing marginBottom={"20px"} />
              <Typography sx={styles.label}>{t("phoneNumber")}</Typography>
              <Grid container columns={12} spacing={4}>
                <Grid item xs={4} md={4} lg={3}>
                  <CountrySelect
                    label=""
                    {...register("phoneCode", {
                      required: t("physicalCards.countryCodeError"),
                    })}
                  />
                  {errors.phoneCode && (
                    <Typography sx={{ color: "red" }}>
                      {errors.phoneCode && errors.phoneCode.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={8} md={8} lg={9}>
                  <KTextField
                    validation={register("phoneNumber", {
                      required: t("physicalCards.phoneNumberError"),
                      pattern: {
                        value: /^(?=[0-9]).{8,12}$/,
                        message:
                          "Le numero de telephone ne contient que des chiffres",
                      },
                    })}
                    placeholder="Ex: XXXXXXXXXXX"
                    fullWidth
                  />
                  {errors.phoneNumber && (
                    <Typography sx={{ color: "red" }}>
                      {errors.phoneNumber && errors.phoneNumber.message}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <hr />

              <Spacing marginBottom={"50px"} />
              <Typography sx={styles.titleField}>
                {t("shippingAddress")}
              </Typography>
              <Typography sx={styles.label}>{t("selectCountry")}</Typography>
              <FormControl fullWidth>
                <Controller
                  name={"country"}
                  control={control}
                  shouldUnregister={false}
                  defaultValue={null}
                  rules={{ required: t("selectCountryError") }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      {...register("country")}
                      value={selectedCountry ?? ""}
                      onChange={handleCountryChange}
                      sx={{
                        sx: { ...rootStyles },
                      }}
                      inputProps={{
                        sx: { ...inputStyles },
                      }}
                    >
                      {countries &&
                        countries.map((country) => (
                          <MenuItem key={country._id} value={country._id}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "16px",
                              }}
                            >
                              <img
                                src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                                alt=""
                                style={{ marginRight: "8px" }}
                              />
                              {country.name}
                            </Box>
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
                <Typography sx={{ color: "red" }}>
                  {errors.country && errors.country.message}
                </Typography>
              </FormControl>
              <Spacing marginBottom={"20px"} />
              <Typography sx={styles.label}>{t("selectCity")}</Typography>
              <FormControl fullWidth>
                <Controller
                  name="cityTown"
                  control={control}
                  defaultValue={null}
                  shouldUnregister={false}
                  rules={{ required: t("selectCityError") }}
                  render={({ field }) => (
                    <Select
                      onBlur={field.onBlur}
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                      sx={{
                        sx: { ...rootStyles },
                      }}
                      inputProps={{
                        sx: { ...inputStyles },
                      }}
                    >
                      {selectedCities &&
                        selectedCities.map((city) => (
                          <MenuItem key={city._id} value={city._id}>
                            {city.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
                <Typography sx={{ color: "red" }}>
                  {errors.cityTown && errors.cityTown.message}
                </Typography>
              </FormControl>

              <Spacing marginBottom={"20px"} />
              <Typography sx={styles.label}>{t("address")}</Typography>
              <KTextField
                validation={register("street")}
                placeholder="Ex: Rue 19eme"
                fullWidth
              />

              <Spacing marginBottom={"50px"} />

              <Grid item xs={12} marginBottom={5}>
                <RoundGradientButton
                  // disabled={!isValid}
                  endIcon={<CallMadeOutlined />}
                  fullWidth
                  type="submit"
                >
                  {t("next")}
                </RoundGradientButton>
              </Grid>
            </Box>
          )}
        </>
      )}

      <Popup title={dialogTitle} open={dialog} onClose={setDialog}>
        {dialogContent}
      </Popup>
    </Box>
  );
}

/** @type{import("@mui/material").SxProps}*/
const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  orderData: { fontSize: "16px", fontWeight: 400, color: "#001021" },
  editField: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#4A90E2",
    textTransform: "none",
    textDecoration: "underline",
  },
  content: {
    border: "1px solid #C7C7C7",
    borderRadius: "8px",
    margin: "24px 16px",
    padding: "24px 16px",
  },
  titleField: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#000000",
    marginBottom: 5,
  },
  input: { backgroundColor: "rgb(249, 249, 249)", borderRadius: "8px" },
  label: {
    fontWeight: 500,
    fontSize: "13px",
    color: "#959595",
  },
  addCardButton: {
    display: "flex",
    justifyContent: "space-around",
  },
  container: {
    width: {
      sm: "70%",
      md: "50%",
      xl: "40%",
    },
    margin: "auto",
  },
};
