import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ProSidebarProvider } from "react-pro-sidebar";
import { Provider } from "react-redux";
import rootReducer from "./shared/reducers";
import { configureStore } from "@reduxjs/toolkit";
import { Toaster } from "react-hot-toast";
import { AuthUserProvider } from "./shared/context/AuthUserContext";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18next from "i18next";
import en from "./i18n/en/translation.json";
import fr from "./i18n/fr/translation.json";
import IdleTimerContainer from "./components/IdleTimer";
// import { useIdleTimer } from "react-idle-timer/legacy";

const lang = localStorage.getItem("language") || navigator.language;

i18next.use(initReactI18next).init({
  lng: lang,
  debug: true,
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_USE_DEV_TOOL === "true",
});

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <AuthUserProvider>
        <IdleTimerContainer></IdleTimerContainer>
        <Toaster />
        <Provider store={store}>
          <ProSidebarProvider>
            <App />
          </ProSidebarProvider>
        </Provider>
      </AuthUserProvider>
    </I18nextProvider>
  </React.StrictMode>
);
