import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  selectClasses,
  Typography,
  Card,
} from "@mui/material";
import Spacing from "../../components/Spacing";
import { inputStyles, rootStyles } from "../../components/KTextField";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

interface ISettingsForm {
  language: string;
}

const Settings = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ISettingsForm>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");

  const handleSettingsForm: SubmitHandler<ISettingsForm> = async (
    settingsData
  ) => {
    setIsSubmitting(true);
  };

  const { i18n } = useTranslation("translation");

  const handleLanguageChange = (event: SelectChangeEvent) => {
    const language = event.target.value as string;
    localStorage.setItem("language", language);
    setSelectedLanguage(language);
    i18n.changeLanguage(language).then((r) => window.location.reload());
  };

  useEffect(() => {
    let language = i18n.language;
    language = language.split("-")[0];
    setSelectedLanguage(language);
  }, []);

  return (
    <Box>
      <Box sx={styles.container}>
        <Box m={1}>
          <Card sx={{ padding: "5%" }}>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "1.25rem",
                fontWeight: 700,
              }}
            >
              {t("account.accountPreferencesLabel")}
            </Typography>
            <Spacing marginBottom={"20px"} />
            {/* <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "1rem",
                fontWeight: 500,
              }}
            >
              {t("account.accountPrefDescription")}
            </Typography> */}
            <Spacing marginBottom={"50px"} />
            <Box component={"form"} onSubmit={handleSubmit(handleSettingsForm)}>
              <Typography sx={{ ...styles.label, ...{ color: "#000000" } }}>
                {t("account.accountLangDesc")}
              </Typography>
              <Spacing marginBottom={"10px"} />
              <Typography sx={styles.label}>
                {t("account.accountLangLabel")}
              </Typography>
              <FormControl sx={{ width: "50%" }}>
                <Controller
                  name="language"
                  control={control}
                  shouldUnregister={false}
                  render={({ field }) => (
                    <Select
                      {...field}
                      {...register("language")}
                      defaultValue={"fr"}
                      value={selectedLanguage || "fr"}
                      onChange={handleLanguageChange}
                      sx={{ ...rootStyles }}
                      inputProps={{
                        sx: { ...inputStyles },
                      }}
                    >
                      <MenuItem key={"fr"} value={"fr"} selected>
                        {t("french")}
                      </MenuItem>
                      <MenuItem key={"en"} value={"en"}>
                        {t("english")}
                      </MenuItem>
                    </Select>
                  )}
                />
                <Typography sx={{ color: "red" }}>
                  {errors.language && errors.language.message}
                </Typography>
              </FormControl>
              <Spacing marginBottom={"30px"} />
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;

/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
  form: {
    width: {
      sm: "70%",
      md: "60%",
      lg: "40%",
    },
    margin: "auto",
  },
  title: {
    fontSize: "1rem",
    fontWeight: "400",
    display: "flex",
    justifyContent: "center",
  },
  label: {
    fontWeight: 500,
    fontSize: "13px",
    color: "#959595",
    textTransform: "none",
  },
  container: {
    width: {
      sm: "70%",
      md: "60%",
      lg: "40%",
    },
    margin: "auto",
  },
};
