import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import RetailerLocationMap from "./RetailerLocationMap";
import AppConfig from "../config/config";
import { MapOutlined } from "@mui/icons-material";
import { t } from "i18next";

interface LocationDataFormProps {
  onLocationChange: (location: {
    lat: number | null;
    lng: number | null;
  }) => void;
  locationError: string | null;
}

const LocationDataForm: React.FC<LocationDataFormProps> = ({
  onLocationChange,
  locationError,
}) => {
  const [address, setAddress] = useState<any>(null);
  const [addrLat, setAddrLat] = useState<number | null>(null);
  const [addrLng, setAddrLng] = useState<number | null>(null);
  const [geolocLoading, setGeolocLoading] = useState(false);

  useEffect(() => {
    onLocationChange({ lat: addrLat, lng: addrLng });
  }, [addrLat, addrLng]);

  const retrieveLatLng = async () => {
    if (null !== address) {
      const [location, ...rest] = await geocodeByPlaceId(
        address.value.place_id
      );
      const { lat, lng } = await getLatLng(location);
      setAddrLat(lat);
      setAddrLng(lng);
    }
  };

  const isGeolocationSupported = () => "geolocation" in navigator;
  const geolocate = () => {
    setGeolocLoading(true);

    const _successHandler = (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      setAddrLat(latitude);
      setAddrLng(longitude);
      setAddress(null);
      setGeolocLoading(false);
    };

    const _errorHandler = (error: GeolocationPositionError) => {
      alert(
        "Echec lors de la geolocalisation. Consultez les détails techniques dans la console"
      );
      setGeolocLoading(false);
    };

    navigator.geolocation.getCurrentPosition(_successHandler, _errorHandler);
  };

  useEffect(() => {
    retrieveLatLng();
  }, [address]);

  return (
    // <Container>
    <Box>
      <Box sx={styles.container}>
        {locationError && (
          <Typography variant="body2" color="error">
            {locationError}
          </Typography>
        )}

        <Typography sx={styles.label}>
          {t("business.searchByAdress")}
        </Typography>
        <Box sx={{ margin: "10px 0" }}>
          <GooglePlacesAutocomplete
            apiKey={AppConfig.googleMapKey}
            selectProps={{
              placeholder: "Ex: Abidjan, Cocody Angré",
              value: address,
              onChange: setAddress,
            }}
          />
        </Box>

        {isGeolocationSupported() && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => geolocate()}
            style={{
              marginRight: "10px",
              textTransform: "revert",
              marginBottom: "2%",
              marginTop: "2%",
            }}
            startIcon={<MapOutlined />}
          >
            {t("business.useLocation")}
          </Button>
        )}

        {geolocLoading && <CircularProgress color="secondary" size={20} />}

        <Typography sx={styles.label}>{t("business.clickToChoose")}</Typography>
      </Box>
      <RetailerLocationMap
        onClick={({ x, y, lat, lng, event }) => {
          setAddrLat(lat);
          setAddrLng(lng);
        }}
        centerLat={addrLat}
        centerLng={addrLng}
        markerLat={addrLat}
        markerLng={addrLng}
      />
    </Box>
    // </Container>
  );
};

export default LocationDataForm;

/** @type{import("@mui/material").SxProps}*/
const styles = {
  map: {
    width: {
      sm: "70%",
      md: "70%",
      lg: "80%",
    },
    margin: "auto",
  },
  container: {
    width: {
      sm: "70%",
      md: "70%",
      lg: "70%",
    },
    margin: "auto",
  },
  label: {
    fontWeight: 500,
    fontSize: "13px",
    color: "#959595",
    marginBottom: "2%",
  },
};
