import React from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import Wallet from "../containers/Wallet/Wallet";
import Transactions from "../containers/Transactions/Transactions";
import Cards from "../containers/Cards/Cards";
import Recipients from "../containers/Recipients/Recipients";
import PayQinRecipient from "../containers/Recipients/PayQinRecipient";
import GetStaredOrderPhysicalCard from "../containers/Cards/OrderPhysicalCard/GetStarted";
import GetStaredActivatePhysicalCard from "../containers/Cards/ActivatePhysicalCard/GetStarted";
import CompleteFormActivatePhysicalCard from "../containers/Cards/ActivatePhysicalCard/CompleteForm";
import CompleteForm from "../containers/Cards/OrderPhysicalCard/CompleteForm";
import Voucher from "../containers/Wallet/top-up/voucher";
import MobileMoney from "../containers/Wallet/top-up/mobile-money";
import BankRecipient from "../containers/Recipients/BankRecipient";
import BankTransfer from "../containers/Transactions/Transfer/bank";
import RequestBusiness from "../containers/Business/Request";
import BankCard from "../containers/Wallet/top-up/bank/bank-card";
import BankDeposit from "../containers/Wallet/top-up/bank/bank-deposit";
import VirtualCard from "../containers/Cards/VirtualCard";
import Settings from "../containers/Settings";
import AirtimeMoney from "../containers/Wallet/top-up/send-airtime";

const KRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Wallet/>}/>
            <Route path="/wallet" element={<Wallet/>}/>
            <Route path="/wallet/top-up" element={<MobileMoney/>}/>
            {/*<Route path="/wallet/top-up/bank-card" element={<BankCard />} />*/}
            <Route path="/wallet/top-up/airtime-money" element={<AirtimeMoney/>}/>
            <Route path="/wallet/top-up/bank-deposit" element={<BankDeposit/>}/>
            <Route path="/wallet/top-up/voucher" element={<Voucher/>}/>

            <Route path="/cards" element={<Cards/>}/>
            <Route path="/cards/virtuals-cards/add" element={<VirtualCard/>}/>
            <Route
                path="/cards/get-started/complete-form"
                element={<CompleteForm/>}
            />
            <Route
                path="/cards/get-started"
                element={<GetStaredOrderPhysicalCard/>}
            />
            <Route
                path="/card/activate-card/complete-form"
                element={<CompleteFormActivatePhysicalCard/>}
            />

            <Route
                path="/card/activate-card"
                element={<GetStaredActivatePhysicalCard/>}
            />
            <Route path="/transactions" element={<Transactions/>}/>
            <Route path="/recipients" element={<Recipients/>}/>
            <Route path="/payqin-beneficiaries/create" element={<PayQinRecipient/>}/>
            <Route path="/payqin-beneficiaries/:id/update" element={<PayQinRecipient/>}/>
            <Route path="/bank-beneficiaries/create" element={<BankRecipient/>}/>
            <Route path="/bank-beneficiaries/:id/update" element={<BankRecipient/>}/>
            <Route
                path="/recipients/:recipientId/transactions/new"
                element={<BankTransfer/>}
            />

            <Route path="/business/business-request" element={<RequestBusiness/>}/>
            <Route path="/settings" element={<Settings/>}/>

            <Route path="/*" element={<Navigate replace to={"/"}/>}/>
        </Routes>
    );
};

export default KRoutes;
