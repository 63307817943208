import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {accountService} from "../../shared/services";
import {useNavigate} from "react-router-dom";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import toast from "react-hot-toast";
import {useAuthUserContext} from "../../shared/context/AuthUserContext";
import CircularProgress from "@mui/material/CircularProgress";
import Timer from "../../shared/_utils/_helpers/timer";
import emailRegex from "../../shared/_utils/_helpers/emailRegex";
import {userRepository} from "./repository/user.repository";
import RenderTimerText from "./components/RenderTimerText";
import RoundGradientButton from "../../components/RoundGradientButton";
import Spacing from "../../components/Spacing";
import KTextField from "../../components/KTextField";
import PinField from "react-pin-field";
import "./style.scss";
import {ThreeBounce} from "better-react-spinkit";
import {t} from "i18next";
import CrispChatScript from "../../components/CrispChatScript";
import Popup from "src/components/Popup";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import loginImage from '../../assets/img/Frame.png'

interface ISignInForm {
    email: string;
    country?: string;
    phoneNumber?: string;
    code?: string;
}

const SignIn = () => {
    const navigate = useNavigate();
    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        formState: {errors, isValid},
        setError,
    } = useForm();
    const {setUser} = useAuthUserContext();
    const [formState, setFormState] = useState("PHONE_NUMBER_FORM");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [dialogContent, setDialogContent] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(null);
    const [handleCloseDialog, setHandleCloseDialog] = useState(null);
    const [emailLoading, setEmailLoading] = useState(false);
    const [codeLoading, setCodeLoading] = useState(false);
    const [numberOfSmsCodeRequestAttempts, setNumberOfSmsCodeRequestAttempts] = useState(0)
    const [showTimer, setShowTimer] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [code, setCode] = useState("");
    const [captchaResponse, setCaptchaResponse] = useState()
    const [captchaError, setCaptchaError] = useState()
    const [notFoundUser, setNotFoundUser] = useState(false);
    const ref = useRef([]);


    const captchaRef = useRef(null);

    const renderTimerText = () => {

        return <form>
            <HCaptcha
                languageOverride={navigator.language.includes("fr") ? "fr" : "en"}
                size="invisible"
                sitekey="4fa9e89e-70ff-4808-9ca0-b27ccc43c171"
                onVerify={setCaptchaResponse}
                ref={captchaRef}
            />

            <RenderTimerText showTimer={showTimer} resendCode={resendCode}
                                    handleTimerComplete={handleTimerComplete}/>
        </form>

    };

    const handleTimerComplete = () => {
        setShowTimer(false);
    };

    useEffect(() => {
        if (accountService.isLogged()) navigate("/");
    });

    const goToSignUp = () => {
        navigate("/sign-up");
    };

    const handleClose = () => {
        setDialog(false)
        setShowTimer(false)
    };

    const reset = () => {
        setCodeLoading(false)
        setIsSubmitting(false)
        setEmailLoading(false)
        setNotFoundUser(false)
    }

    const dialogHandler = ( onClose: any) => {
        setDialog(true);
        setDialogTitle(
            
            <Box
            component="img"
            src="https://res.cloudinary.com/payqin-ltd/image/upload/v1700237457/web-app/Frame_som1gi.png"
                sx={{
                width: {
                    xs: "30%",
                    md: "30%",
                },
            }}
        />
            
        );
        setHandleCloseDialog(() => onClose);
    };

    const handleEmailForm: SubmitHandler<ISignInForm> = async (signInData, captchaResponseFromResendCode) => {
        reset()
        
        if (!captchaResponse) {
            setCaptchaError("Invalid captcha");
            return;
        }

        setIsSubmitting(true);
        setEmailLoading(true);
        
        if (!signInData.email) {
            setError("email", {
                type: "custom",
                message: t('invalidField'),
            });
            setIsSubmitting(false);
            setEmailLoading(false);
            setFormState("PHONE_NUMBER_FORM");
            return;
        }
        const _captchaResponse = captchaResponse ?? captchaResponseFromResendCode

        if(numberOfSmsCodeRequestAttempts < 3){
            const {data, error, statusCode} = await userRepository.sendVerificationCode(
                signInData.email , _captchaResponse
            );
    
            setIsSubmitting(false);
            setEmailLoading(false);
    
            if (data) {
                if (data.success) {
                    setShowTimer(true);
                    setNumberOfSmsCodeRequestAttempts(numberOfSmsCodeRequestAttempts + 1)
                } else {
                    setError("email", {type: "custom", message: data.errorMessage});
                }
            } else {
                if (404 === statusCode)
                    setNotFoundUser(true)
                setError("email", {type: "custom", message: t("sendCodeAgain") || ""});
            }
        }else{
            setDialog(true)
            setIsSubmitting(false)
            setEmailLoading(false)
        }
        
    };

    useEffect(()=> {
        if(showTimer){
            dialogHandler(handleClose)
        }
    }, [showTimer])

    const resendCode = async() => {
        const _captchaResponse = await captchaRef.current.execute({async: true});
        return handleEmailForm(getValues(), _captchaResponse.response);
    };

    const handleOtpForm = async (signInData) => {
        const input = signInData || code;

        if (input.length < 6) return;

        setIsSubmitting(true);
        setCodeLoading(true);
        const email = getValues("email");

        if (!input || !email) {
            setIsSubmitting(false);
            setCodeLoading(false);
            setFormState("PHONE_NUMBER_FORM");
            return;
        }

        const {data, error} = await userRepository.checkOtp(email, input);

        setIsSubmitting(false);
        setCodeLoading(false);

        if (data) {
            if (data.success) {
                toast.success(t("loginSuccess"));

                setUser(data.user);
                accountService.saveToken(data.token);
                accountService.saveUser(data.user);

                navigate("/", {replace: true});
            } else {
                setError("code", {
                    type: "manuel",
                    message: data.errorMessage || t("errorOccurred"),
                });
            }
        } else {
            setError("code", {
                type: "manuel",
                message: error || t("errorOccurred"),
            });
        }
    };

    const emailFormRender = () => (
        <Box
            component="form"
            mt={0}
            noValidate
            onSubmit={handleSubmit(handleEmailForm)}
            sx={styles.form}
        >
            <Typography>{t("emailLegend")}</Typography>
            <Box mt={3} ml={0} sx={{width: {xs: "70%", md: "50%"}}}>
                {emailLoading ? (
                    <div className="mt-5">
                        <ThreeBounce size={35} color="#797DF9"/>
                    </div>
                ) : (
                    <>
                        <KTextField
                            validation={register("email", {
                                required: t('requiredField'),
                                pattern: {
                                    value: emailRegex,
                                    message: t('invalidField'),
                                },
                            })}
                            placeholder="johndoe@gmail.com"
                            fullWidth
                        />
                        {errors.email && (
                            <Typography color={"red"}>{errors.email.message}</Typography>
                        )}
                        <Spacing marginBottom={"30px"}/>
                        <div style={{display: "revert", justifyContent: "start", marginTop: 30}}>
                            <HCaptcha
                                languageOverride={navigator.language.includes("fr") ? "fr" : "en"}
                                size="normal"
                                sitekey="4fa9e89e-70ff-4808-9ca0-b27ccc43c171"
                                onVerify={setCaptchaResponse}
                            />
                            {captchaError && <Typography sx={styles.errors}>{captchaError}</Typography>}
                        </div>
                        <Spacing marginBottom={"30px"}/>
                        <RoundGradientButton
                            disabled={!isValid || isSubmitting}
                            type="submit"
                            variant={"contained"}
                            color={"inherit"}
                            sx={{
                                background: isSubmitting
                                    ? "linear-gradient(to right, #C471ED, #4A90ED)"
                                    : "",
                            }}
                            size="large"
                            fullWidth
                        >
                            {isSubmitting && (
                                <>
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "#FFFFFF",
                                            marginRight: "5%",
                                        }}
                                    />
                                </>
                            )}
                            <Typography sx={{color: isSubmitting ? "#FFFFFF" : ""}}>
                                {t("next")}
                            </Typography>
                        </RoundGradientButton>
                    </>
                )}
            </Box>

            {
                notFoundUser && <Box mt={3}>
                    <Typography>
                        {t("introMsgForNotFoundUser")}
                    </Typography>
                    <Box mt={2} sx={{display: "flex"}}>
                        <Typography sx={{display: "contents", fontWeight: "700"}}>
                            {t("msgKeyForPayQinUser")}
                        </Typography>
                        <Typography sx={{display: "contents"}}>
                            {t("msgForPayQinUser")}
                        </Typography>
                    </Box>
                    <Box mt={1}>
                        <Typography sx={{display: "contents", fontWeight: "700"}}>
                            {t("msgKeyForNoPayQinUser")}
                        </Typography>
                        <Typography sx={{display: "contents"}}>
                            {t("msgForNoPayQinUser")}
                        </Typography>
                    </Box>
                    <Typography mt={3}>
                        {t("finalMsgForNotFoundUser")}
                    </Typography>
                </Box>
            }
        </Box>
    );

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid
                container
                component="main"
                sx={{
                    height: "100vh",
                }}
            >
                <Grid
                    item
                    xs={false}
                    sm={7}
                    md={7}
                    display={{xs: "none", sm: "flex", background: 'linear-gradient(0deg, rgba(68,149,249,0.125) 0%, rgba(144,101,244,0.125) 100%);'}}
                    sx={{
                        overflow:'clip',
                        marginTop:-5,
                        border: "none",
                        borderTopRightRadius:450,
                        borderBottomRightRadius:450,
                        marginLeft: -10,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                        }}
                    >
                            <Box
                                width="100%"
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems:'center',
                                    marginTop: 10,
                                }}
                            >
                                    <Box
                                    component="img"
                                    src={loginImage}
                                    sx={{
                                        width: {
                                            xs: "30%",
                                            md: "30%",
                                        },
                                        marginBottom:10
                                    }}
                                />
                            </Box>

                        <Box
                            sx={{
                                alignSelf:'center',
                                alignItems: "center",
                                margin:2,
                                display:'flex',
                                flexDirection:'column'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "2.5rem", fontWeight: 'bold',fontFamily:`Roboto, sans-serif`, color: "black", display: {
                                        xs: "none",
                                        sm: "initial"
                                    },
                                    '@media (min-width:1000px)': {
                                        fontSize: '5rem',
                                      },
                                }}
                            >
                                Bienvenue
                            </Typography>
                            <Box
                            sx={{
                                alignSelf:'center',
                                alignItems: "center",
                                justifyContent:'center',
                                display:'flex',
                                flexDirection:'row'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "2rem", fontWeight: 'bold', color: "black", display: {
                                        xs: "none",
                                        sm: "initial"
                                    },
                                }}
                                >
                                sur
                            </Typography>

                            <Box
                                component="img"
                                src="https://res.cloudinary.com/payqin-ltd/image/upload/v1692967362/web-app/te%CC%81le%CC%81chargement_xdn9t4.png"
                                sx={{
                                    width: {
                                        xs: "30%",
                                        md: "20%",
                                    },
                                    marginLeft:2
                                }}
                            />

                        </Box>
                            
                        </Box>

                        <Box
                            sx={{
                                alignSelf: "center",
                                alignItems: "center",
                                marginBottom: "1%",
                            }}
                        >
                            <Copyright/>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={5}
                    md={5}
                    marginLeft={5}
                >
                    <Box sx={styles.formSection}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "space-between",
                                    sm: "right"
                                },
                                alignItems: "center",
                            }}
                        >
                            <Box
                                xs="false"
                                component="img"
                                src="https://res.cloudinary.com/payqin-ltd/image/upload/v1692967362/web-app/te%CC%81le%CC%81chargement_xdn9t4.png"
                                sx={{
                                    display: {
                                        sm: "none"
                                    },
                                    width: "30%",
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: "1.1rem", fontWeight: 400, color: "#001021", display: {
                                        xs: "none",
                                        sm: "initial"
                                    },
                                }}
                            >
                                {t("newToPayqin")}
                            </Typography>
                            <Button
                                onClick={goToSignUp}
                                variant={"contained"}
                                color={"inherit"}
                                sx={{
                                    fontSize: "1.1rem",
                                    color: "#001021",
                                    textTransform: "revert",
                                    ml: 3,
                                }}
                            >
                                {t("createAccount")}
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                mt: 10,
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <Typography sx={{fontSize: "2.2rem", fontWeight: 700}}>
                                {t("loginToPayqin")}
                            </Typography>
                            {emailFormRender()}
                        </Box>

                        <Popup
                            title={dialogTitle}
                            open={dialog}
                            onClose={handleCloseDialog}
                            additionalStyle={{backgroundColor: "#FFFFFF"}}
                        >
                            <Box
                                component="form"
                                mt={0}
                                noValidate
                                onSubmit={handleSubmit(handleOtpForm)}
                            >
                                <Typography>
                                    {t("signInScreen.codeLegend")}
                                    {getValues("email")}
                                </Typography>
                                <Box mt={3}>
                                    {codeLoading ? (
                                        <div className="mt-5">
                                            <ThreeBounce size={35} color="#797DF9"/>
                                        </div>
                                    ) : (
                                        <>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Box>
                                                    <PinField
                                                        className="pin-field"
                                                        length={6}
                                                        validate={/^[0-9]$/}
                                                        onChange={(e) => {
                                                            setCode(e);
                                                            handleOtpForm(e);
                                                        }}
                                                        onComplete={handleOtpForm}
                                                    />
                                                    {errors.code && (
                                                        <p style={{color: "red"}}>{errors.code.message}</p>
                                                    )}
                                                </Box>
                                                <Box mt={5}>{renderTimerText()}</Box>
                                            </Box>
                                        </>
                                    )}
                                </Box>

                            </Box>
                        </Popup>

                    </Box>
                </Grid>
            </Grid>
            <CrispChatScript/>
        </ThemeProvider>
    );
};

function Copyright(props: any) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://payqin.com/">
                PayQin
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const defaultTheme = createTheme();

export default SignIn;

// <Box sx={{display: "flex", height: '70vh', flexDirection: 'column', ml:10, mr:10, mt: 5}}>

/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
    form: {
        display: "flex",
        flexDirection: "column",
        mt: 5,
    },
    errors: {
        color: "red"
    },
    formSection: {
        display: "flex",
        height: "75vh",
        flexDirection: "column",
        ml: {
            md: 10,
            xs: 5,
        },
        mr: {
            md: 10,
            xs: 5,
        },
        mt: 5,
    },
};
