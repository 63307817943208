import {styled, Theme} from "@mui/material/styles";
import {Button, useTheme} from "@mui/material";

const RoundGradientButton = styled(Button)(() => {
    return {
        // padding: ".9em",
        margin: "auto",
        position: 'relative',
        border: '1px solid transparent',
        backgroundClip: 'padding-box',
        borderRadius: "8px",
        textTransform: 'capitalize',
        color: `linear-gradient(to right, #C471ED, #4A90ED)`,
        fontSize: '16px',
        fontWeight: 400,
        background: '#FFFFFF',
        '&:after': {
            position: 'absolute',
            top: -1,
            left: -1,
            right: -1,
            bottom: -1,
            background: `linear-gradient(to right, #C471ED, #4A90ED)`,
            content: '""',
            zIndex: -1,
            borderRadius: "8px",
        },
        '&:active': {
            background: `linear-gradient(to right, #C471ED, #4A90ED)`,
            color: '#FFFFFF'
        },
        '&:hover': {
            background: `linear-gradient(to right, #C471ED, #4A90ED)`,
            color: '#FFFFFF'
        },
    }
});

export default RoundGradientButton;
