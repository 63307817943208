import React, {useEffect, useState} from "react";
import Spacing from "../../../../components/Spacing";
import {Button, FormControl, MenuItem, Select, SelectChangeEvent, Typography,} from "@mui/material";
import Box from "@mui/material/Box";
import {IBankRecipientData} from "../index";
import {IFormProps,} from "../../../../shared/types/IUseFormsProps";
import {recipientsRepository} from "../../repository/recipients.repository";
import {IBank} from "../../../../shared/types/IRecipient";
import {Controller} from "react-hook-form";
import KTextField, {inputStyles, rootStyles,} from "../../../../components/KTextField";
import {t} from "i18next";

const BankInfoForm: React.FC<IFormProps<IBankRecipientData>> = ({ form }) => {
  const [isUemoaRecipient, setIsUemoaRecipient] = useState<boolean>();
  const [bankList, setBankList] = useState<IBank[]>([]);
  const [selectedBank, setSelectedBank] = useState<IBank>();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    setError,
    getValues,
      watch,
    clearErrors,
  } = form;

  useEffect(() => {

    const currency = getValues("currency");


    if (!currency) return;

    const resp = "XOF" === currency;
    setIsUemoaRecipient(resp);

    if (resp) {
      recipientsRepository
        .getBankCodes()
        .then((resp) => {
          if (resp.data && resp.data.bankList) {
            const defaultbank = resp.data?.bankList.data.find(bank => bank.Name === watch("bankName"))
            if (defaultbank)
              setSelectedBank(defaultbank)
            setBankList(resp.data.bankList.data);
          }
        })
        .catch();
    }
  }, []);

  const handleBankChange = (event: SelectChangeEvent) => {
    const bankCodeSelected = event.target.value;

    const bank = bankList.find((bank) => bank.Code == bankCodeSelected);

    if (bank) {
      setSelectedBank(bank);
      setValue("bankCode", bank.Code);
      setValue("bankName", bank.Name);
    }
  };

  return (
    <Box sx={{ p: 1 }}>
      <Spacing marginBottom={"10px"} />

      {isUemoaRecipient && isUemoaRecipient ? (
        <FormControl fullWidth>
          <Controller
            name="bankCode"
            control={control}
            shouldUnregister={false}
            rules={{
              required: t("requiredField"),
            }}
            render={({ field }) => (
              <>
                <Typography sx={styles.label}>
                  {t("withdrawScreen.selectBank")}
                </Typography>

                <Select
                  {...field}
                  {...register("bankCode")}
                  value={selectedBank?.Code ?? ""}
                  onChange={handleBankChange}
                  sx={{
                    ...rootStyles,
                    ...{
                      borderRadius: "4px",
                    },
                  }}
                  inputProps={{
                    sx: { ...inputStyles },
                  }}
                >
                  {bankList &&
                    bankList.map((bank: IBank) => (
                      <MenuItem key={bank.Code} value={bank.Code}>
                        <Typography>{bank.Name}</Typography>
                      </MenuItem>
                    ))}
                </Select>
              </>
            )}
          />
          <Typography sx={{ color: "red" }}>
            {errors.entity &&
              errors.entity.message}
          </Typography>
        </FormControl>
      ) : (
        <Box>
          <Typography sx={styles.label}>
            {t("withdrawScreen.bankName")}
          </Typography>
          {/*<TextField*/}
          {/*    required*/}
          {/*    fullWidth*/}
          {/*    {...register('bankName', {*/}
          {/*        required: "Veuillez indiquer la banque",*/}
          {/*    })}*/}
          {/*/>*/}
          <KTextField
            validation={register("bankName", {
              required: t("requiredField"),
            })}
            fullWidth
            // required
            placeholder="Ex: Société Générale"
          />
          {errors.bankName && <p>{errors.bankName.message}</p>}
        </Box>
      )}

      <Spacing marginBottom={"20px"} />
      <Box>
        <Typography sx={styles.label}>
          {t("withdrawScreen.accountNumber")}
        </Typography>
        {/*<TextField*/}
        {/*    required*/}
        {/*    fullWidth*/}
        {/*    {...register('accountNumber', {*/}
        {/*        required: "Ce champ est obligatoire.",*/}
        {/*    })}*/}
        {/*/>*/}

        <KTextField
          validation={register("accountNumber", {
            required: t("requiredField"),
          })}
          fullWidth
          // required
          placeholder="Ex: CI96CI006500200"
        />
        {errors.accountNumber && (
          <p style={styles.errorLabel}>{errors.accountNumber.message}</p>
        )}
      </Box>
      <Spacing marginBottom={"20px"} />
      <Box>
        <Typography sx={styles.label}>
          {t("withdrawScreen.swiftCode")}
          <Button sx={{ textTransform: "revert" }} variant={"text"}>
            {t("withdrawScreen.whatThis")}
          </Button>
        </Typography>
        <KTextField
          validation={register("swiftCode", {
            required: t("requiredField"),
          })}
          fullWidth
          placeholder="Ex: BIAOXXXX"
        />
        {errors.swiftCode && (
          <p style={styles.errorLabel}>{errors.swiftCode.message}</p>
        )}
      </Box>
      <Spacing marginBottom={"20px"} />

      {/* <Box>
        <Typography sx={{ fontWeight: "700", fontSize: "13px" }}>
          PayQin accepts the following formats:
        </Typography>
        <Typography sx={{ color: "#959595", fontSize: "13px" }}>
          1. Bank Account Number (e.g. CI 06 500 200 1000 0123 4567 890 )
        </Typography>
        <Typography sx={{ color: "#959595", fontSize: "13px" }}>
          2. IBAN (e.g. CI96CI006500200100001234567890)
        </Typography>
      </Box> */}
    </Box>
  );
};

export default BankInfoForm;

/** @type{import("@mui/material").SxProps}*/
const styles = {
  titleField: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#000000",
    marginBottom: 5,
  },
  input: { backgroundColor: "rgb(249, 249, 249)", borderRadius: "8px" },
  errorLabel: {
    fontSize: "14px",
    color: "red",
  },
  label: {
    fontWeight: 500,
    fontSize: "13px",
    color: "#959595",
    marginBottom: "1%",
  },
};
