import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import KSideBar from "./components/sidebar/KSideBar";
import theme from "./config/theme";
import KRoutes from "./router/KRoutes";
import SignIn from "./containers/Auth/SignIn";
import AuthGuard from "./shared/_utils/_helpers/AuthGuard";
import KHeader from "./components/header/KHeader";
import { SidebarProvider } from "./components/SidebarContext";
import SignUp from "./containers/Auth/SignUp";
import "./index.css";
import WaveBasketResponse from "./containers/Wallet/WaveBasketResponse";

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={styles.container}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<SignIn />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/wallet/top-up/basket-wave-response" element={<WaveBasketResponse/>} />
              <Route
                path={"/*"}
                element={
                  <AuthGuard>
                    <Dashboard />
                  </AuthGuard>
                }
              />
            </Routes>
          </BrowserRouter>
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
}

const Dashboard = () => {
  return (
    <React.Fragment>
      {/*<div style={{border: "solid red"}}>*/}
      <SidebarProvider>
        <KSideBar />
        <Box component={"main"} sx={styles.mainSection}>
          <KHeader />
          <Box sx={styles.contentSection}>
            <KRoutes />
          </Box>
        </Box>
      </SidebarProvider>
      {/*</div>*/}
    </React.Fragment>
  );
};

/** @type {import("@mui/material").SxProps} */
const styles = {
  container: {
    display: "flex",
    bgColor: "neural.light",
    height: "calc(100% - 64px)",
    backgroundColor: "#ebedef",
  },
  mainSection: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "#ffffff",
  },
  contentSection: {
    // justifyContent: "center",
    p: 2,
  },
};

export default App;
