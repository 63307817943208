import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  IconButton, ListItemIcon, Menu, MenuItem, Toolbar,
  Typography,
} from "@mui/material";
import {
  AvatarGradientIcon,
  CardCheckIcon,
  CloseIcon,
  MenuPayQinIcon,
  MoreIcon,
  MoreVertIcon,
} from "../../../shared/_utils/constants/icon";
import Popup from "../../../components/Popup";
import IRecipient from "../../../shared/types/IRecipient";
import IPropsContactItem from "../Common/types/IPropsContactItem";
import {useNavigate} from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Logout, Settings} from "@mui/icons-material";
import {t} from "i18next";
import toast from "react-hot-toast";
import {recipientsRepository} from "../repository/recipients.repository";

const Contact = (props: IPropsContactItem) => {
  const { onClickCard, handleUpdateBeneficiary, handleDeleteBeneficiary, recipient, numSelected } = props;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleCardClick = () => {
    onClickCard();
  };

  const _handleUpdateBeneficiary = () => {
    if (handleUpdateBeneficiary) {
      handleUpdateBeneficiary()
    }
  }

  const _handleDeleteBeneficiary = async () => {
    if (handleDeleteBeneficiary) {
      handleDeleteBeneficiary()
    }
  }

  const onClickMore = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (numSelected && numSelected > 0)
      handleClose()
  }, [numSelected])

  return (
    <Box>
      <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{horizontal: "right", vertical: "top"}}
          anchorOrigin={{horizontal: "right", vertical: "bottom"}}
      >
        <MenuItem onClick={_handleUpdateBeneficiary}>
          <Typography>
            {t("update")}
          </Typography>
        </MenuItem>
        <MenuItem onClick={_handleDeleteBeneficiary}>
          <Typography>
            {t("delete")}
          </Typography>
        </MenuItem>
      </Menu>


      <Card sx={styles.contact}>
        <CardContent sx={styles.contentContact}>
          <Box sx={styles.infos} onClick={handleCardClick}>
            <Avatar sx={styles.photo} src={recipient?.picture || ""} />
            <Box sx={styles.info}>
              <Typography sx={styles.name}>
                {recipient.firstName
                  ? `${recipient.lastName} ${recipient.firstName}`
                  : recipient.companyName}{" "}
              </Typography>
              {recipient.accountNumber ? (
                <Typography sx={styles.phoneNumber}>
                  {recipient.accountNumber}
                </Typography>
              ) : (
                <Typography sx={styles.phoneNumber}>
                  {recipient.internationalPhoneNumber}
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={styles.others}>
            <Box component="img" sx={styles.logoAccountType} />
            { !(numSelected && numSelected > 0) && <IconButton
                type="button"
                sx={{ p: "0px" }}
                aria-label="search"
                onClick={onClickMore}
            >
              <MoreVertIcon width={20} height={20}/>
            </IconButton>}

          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Contact;

const styles = {
  photo: {
    width: 40,
    height: 40,
  },
  name: {
    fontSize: {
      xs: "1rem",
      md: "1rem",
    },
    width: 490,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  phoneNumber: {
    fontSize: {
      xs: "0.8rem",
      md: "1rem",
    },
    color: "neutral.normal",
    width: 490,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  info: {
    ml: 2,
  },
  logoAccountType: {},
  others: {
    display: "flex",
    alignItems: "center",
  },
  infos: {
    display: "flex",
    alignItems: "center",
  },
  contentContact: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ":last-child": {
      paddingBottom: 1.5,
      paddingTop: 1.5,
    },
  },
  contact: {
    mb: 2,
    border: "1px solid #C7C7C7",
    ":hover": {
      border: 2,
      borderColor: "#B349EB",
      borderRadius: "8px",
    },
  },
};
