import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    CssBaseline,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {accountService, countryService, phoneService,} from "../../shared/services";
import ICredential from "../../shared/types/ICredential";
import {useNavigate} from "react-router-dom";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import {createTheme, styled, ThemeProvider} from "@mui/material/styles";
import {IAuthResponse} from "./models/auth.response";
import toast from "react-hot-toast";
import {useAuthUserContext} from "../../shared/context/AuthUserContext";
import CircularProgress from "@mui/material/CircularProgress";
import {userRepository} from "./repository/user.repository";
import RoundGradientButton from "../../components/RoundGradientButton";
import Spacing from "../../components/Spacing";
import KTextField, {rootInputStyles} from "../../components/KTextField";
import PinField from "react-pin-field";
import "./style.scss";

import {ThreeBounce} from "better-react-spinkit";
import {CountrySelect} from "../Recipients/components/CountrySelect";
import emailRegex from "../../shared/_utils/_helpers/emailRegex";
import {t} from "i18next";
import RenderTimerText from "./components/RenderTimerText";
import VerificationCodeEmailDialog, {IVerificationCodeEmailForm} from "./components/VerificationCodeEmailDialog";
import {isEmpty} from "../../shared/_utils/isEmpty";
import SuccessfullyRegistrationDialog from "./components/SuccessfullyRegistrationDialog";
import phoneNumberRegex from "../../shared/_utils/_helpers/phoneNumberRegex";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import CarouselComponent from "./components/CarouselComponent";

interface ISignUpForm {
    email: string;
    country?: string;
    phoneCode?: string;
    phoneNumber?: string;
    code?: string;
    firstName: string;
    lastName: string;
    acceptTerms: boolean;
}

const SignUp = () => {
    const navigate = useNavigate();
    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
        clearErrors,
        formState: {errors, isValid},
        setError,
    } = useForm();
    const {setUser} = useAuthUserContext();
    const [formState, setFormState] = useState("PHONE_NUMBER_FORM");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);
    const [verificationPhoneNumberLoading, setVerificationPhoneNumberLoading] =
        useState(false);
    const [codeLoading, setCodeLoading] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [token, setToken] = useState("");
    const [activeStep, setActiveStep] = useState(0);
    const [phoneVerificationStep, setPhoneVerificationStep] = useState(false);
    const [flag, setFlag] = useState();
    const [openSuccessfullyRegistrationDialog, setOpenSuccessfullyRegistrationDialog] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [expanded, setExpanded] = useState(false)
    const [numberOfSmsCodeRequestAttempts, setNumberOfSmsCodeRequestAttempts] = useState(0)
    const [captchaResponse, setCaptchaResponse] = useState()
    const [captchaError, setCaptchaError] = useState()
    const [credentials, setCredentials] = useState()

    const steps = [
        t("registerStepOne"),
        t("registerStepTwo"),
        t("registerStepThree")
    ];

    const isLastStep = activeStep === steps.length - 1;

    const handleTimerComplete = () => {
        setShowTimer(false);
    };

    const renderStepContent = (step: number) => {
        switch (step) {
            case 0:
                return phoneNumberStep();
            case 1:
                return registrationFormRender();
            case 2:
                return emailVerificationFormRender();
            default:
                return phoneNumberFormRender();
        }
    };

    const renderStepTitle = (step: number) => {
        switch (step) {
            case 0:
                return <Box>
                    {phoneVerificationStep ?
                        <Box
                            component="img"
                            src="https://res.cloudinary.com/payqin-ltd/image/upload/v1700237457/web-app/Frame_som1gi.png"
                            sx={{
                                display: "flex",
                                margin: 'auto',
                                width: {
                                    xs: "40%",
                                },
                            }}
                        /> :
                        <CarouselComponent/>
                    }
                </Box>;
            case 1:
                return <Box>
                    <Typography sx={{fontSize: "2.5rem", fontWeight: 700}}>{t("letsGo")}</Typography>
                    <Typography
                        sx={{fontSize: "1.2rem", fontWeight: 500}}>{t("basicInformationToCreateAccount")}</Typography>
                </Box>;
            case 2:
                return <Box>
                    <Typography sx={{fontSize: "2.5rem", fontWeight: 700}}>{t("almostFinished")}</Typography>
                    <Typography sx={{fontSize: "1.2rem", fontWeight: 500}}>{t("validCodeEmailInscription")}</Typography>
                </Box>;
            default:
                return <Box>
                    {phoneVerificationStep ?
                        <Box
                            component="img"
                            src="https://res.cloudinary.com/payqin-ltd/image/upload/v1700237457/web-app/Frame_som1gi.png"
                            sx={{
                                display: "flex",
                                margin: 'auto',
                                width: {
                                    xs: "40%",
                                },
                            }}
                        /> :
                        <CarouselComponent/>
                        // <Typography sx={{fontSize: "2.5rem", fontWeight: 700}}>{t("welcomeToPayQin")}</Typography>
                    }
                </Box>;
        }
    };

    const phoneNumberStep = () => {
        return !phoneVerificationStep ? phoneNumberFormRender() : otpFormRender()
    }

    useEffect(() => {
        try {
            const foundCountry = countryService.getCountry(getValues("phoneCode"))
            if (foundCountry) {
                setFlag(<img
                    src={`https://flagcdn.com/w20/${foundCountry.code.toLowerCase()}.png`}
                    alt=""
                    style={{marginRight: "8px"}}
                />)
            }
        } catch (e) {
            toast.error("notFoundCountryFlag")
        }
    }, [getValues("phoneCode")])

    const goToSignIn = () => {
        navigate("/login", {replace: true});
    };

    const handleVerificationPhoneNumberByEmail: SubmitHandler<IVerificationCodeEmailForm> = async (verificationCodeEmailData) => {
        let _email = email;

        if (verificationCodeEmailData && verificationCodeEmailData.email) {
            console.log({verificationCodeEmailData})
            setEmail(verificationCodeEmailData.email)
            _email = verificationCodeEmailData.email
        }

        if (openDialog)
            setOpenDialog(false)

        setCodeLoading(true)

        const {
            data,
            error
        } = await userRepository.verificationPhoneNumberByEmail(_email, phoneNumber);

        setCodeLoading(false)

        if (data) {
            if (data.success) {
                return true;
            } else {
                setError("code", {
                    type: "manuel",
                    message: data.errorMessage || data.message,
                });
            }
        } else {
            setError("code", {
                type: "manuel",
                message: error,
            });
        }
    }

    const sendVerificationCodePhone = async (userData, captchaResponseFromResendCode) => {
        setIsSubmitting(true);

        const _credentials = credentials ?? userData
        const _captchaResponse = captchaResponseFromResendCode ?? captchaResponse

        const {data, error} = await userRepository.sendVerificationCodePhone(
            _credentials, _captchaResponse
        );

        setIsSubmitting(false);
        setVerificationPhoneNumberLoading(false);

        if (data) {
            if (data.success) {
                setPhoneVerificationStep(true)
                setShowTimer(true);
                setNumberOfSmsCodeRequestAttempts(numberOfSmsCodeRequestAttempts + 1)
            } else {
                toast.error(t(data.errorMessage))
            }
        } else {
            setError("phoneNumber", {
                type: "custom",
                message: error || t('errorOccurred'),
            });
            toast.error(error || t('errorOccurred'))
        }
    }

    const handlePhoneNumberForm: SubmitHandler<ISignUpForm> = async (
        signUpData,
    ) => {

        if (!signUpData.phoneCode || !signUpData.phoneNumber) {
            setError("phoneNumber", {
                type: "custom",
                message: t('invalidField'),
            });
            return;
        }

        if (!captchaResponse) {
            setCaptchaError("Invalid captcha");
            return;
        }

        setIsSubmitting(true);
        setVerificationPhoneNumberLoading(true);

        const country = countryService.getCountry(signUpData.phoneCode);

        if (!country) {
            toast.error(t("payqinNotAvailableForThisCountry"))
            return;
        }

        const res = phoneService.normalize(country.phone, signUpData.phoneNumber);
        setPhoneNumber(res);

        const _credentials: ICredential = {
            phone: res,
            country: country.code,
        };

        setCredentials(_credentials)

        return sendVerificationCodePhone(_credentials)
    };

    const resendCodeForEmailConfirmation = async () => {
        const res = await confirmationEmail()

        if (res && res.data && res.data.success)
            toast.success("signInScreen.codeLegend")
    }

    const resendCode = async () => {

        try {

            if (activeStep === 2) {
                await resendCodeForEmailConfirmation();
                return;
            }

            if (numberOfSmsCodeRequestAttempts < 3) {
                const _captchaResponse = await captchaRef.current.execute({async: true});
                return sendVerificationCodePhone(null, _captchaResponse.response);
            } else {
                if (email) {
                    return handleVerificationPhoneNumberByEmail();
                } else {
                    setOpenDialog(true)
                }
            }
        } catch (e) {
            console.log({e})
        }
    };

    const handleOtp = async (opt) => {
        const inputCode = opt || code;
        if (inputCode.length < 6) return;

        if (isEmpty(inputCode) || isEmpty(getValues("phoneCode")) || isEmpty(getValues("phoneCode"))) {
            toast.error("invalidPhoneNumberOrCode")
            setIsSubmitting(false);
            setCodeLoading(false);
            setPhoneVerificationStep(false)
            return;
        }

        const phone = phoneService.normalize(
            getValues("phoneCode"),
            getValues("phoneNumber")
        );

        setIsSubmitting(true);
        setCodeLoading(true);

        const {data, error} = await userRepository.checkOtpVerificationCodePhone(
            phone,
            inputCode
        );

        setIsSubmitting(false);
        setCodeLoading(false);

        if (data) {
            if (data.user) {
                if (data.user.isRegistered) {
                    toast(t("userAlreadyPayqinAccount"), {
                        duration: 6000,
                    });
                    return goToSignIn();
                } else {
                    setToken(data.token);
                    setPhoneVerificationStep(false)
                    setActiveStep(activeStep + 1);
                }
            } else {
                if (data.error) {
                    setError("code", {
                        type: "manuel",
                        message: data.errorMessage || "",
                    });
                } else {
                    setError("code", {
                        type: "manuel",
                        message: t('errorOccurred'),
                    });
                }
            }
        } else {
            setError("code", {
                type: "manuel",
                message: error || "",
            });
        }
    };

    const handleDialogAfterSuccess = () => {
        toast.success(t("loginSuccess"));
        navigate("/", {replace: true});
    }

    const confirmationEmail = async (inputEmail) => {

        const dataEmail = email || inputEmail;

        if (isEmpty(dataEmail) || isEmpty(token)) {
            toast.error("Les étapes précédentes ne sont pas validées")
            return;
        }

        return userRepository.confirmationEmail(
            dataEmail,
            token
        );
    }

    const handleEmailConfirmation = async (inputCode: string): boolean => {

        if (inputCode.length < 6) return;

        if (isEmpty(inputCode) || isEmpty(email)) {
            toast.error(t('invalidField'));
            return;
        }

        setIsSubmitting(true);
        setCodeLoading(true);

        const {data, error} = await userRepository.checkConfirmationEmail(
            email,
            inputCode,
            token
        );

        setIsSubmitting(false);
        setCodeLoading(false);

        if (data) {
            if (data.success) {

                setUser(data.user);
                accountService.saveToken(token);
                accountService.saveUser(data.user);

                setOpenSuccessfullyRegistrationDialog(true)
            } else {
                const msg = data.errorMessage;
                toast.error(msg);
            }
        } else {
            toast.error(error);
        }
    };

    const handleRegistrationForm = async (signUpData) => {

        const dataEmail = email || signUpData.email;

        if (isEmpty(dataEmail) ||
            isEmpty(signUpData.firstName) ||
            isEmpty(signUpData.lastName) ||
            isEmpty(signUpData.acceptedTerms)
        ) {
            toast.error("les champs ne sont pas correctement remplis");
            return;
        }

        setEmail(dataEmail)

        setIsSubmitting(true);
        setEmailLoading(true);

        // const res = await confirmationEmail(signUpData.email)

        const {data, error} = await userRepository.registration(
            dataEmail,
            getValues("firstName"),
            getValues("lastName"),
            token
        );

        setIsSubmitting(false);
        setEmailLoading(false);

        if (data) {
            if (data.success) {
                toast.success("signInScreen.codeLegend")
                setActiveStep(activeStep + 1);
            } else {
                toast.error(t('errorOccurred'));
            }
        } else {
            toast.error(error);
        }
    };

    const emailVerificationFormRender = () => (
        <Box
            component="form"
            mt={0}
            noValidate
            onSubmit={handleSubmit(handleEmailConfirmation)}
            sx={styles.form}
        >
            <Box mt={3}
                 sx={{width: {xs: "90%", md: "80%"}, display: "flex", flexDirection: "column", alignItems: "start"}}>

                <Box sx={{display: "flex", flexDirection: "column", alignItems: "start"}}>
                    <Typography
                        sx={{fontSize: "2.2rem", fontWeight: 700, mb: 2, mt: 5}}
                    >
                        {t("emailConfirmationTitle")}
                    </Typography>
                    <Typography sx={{mb: 2}}>{t("emailConfirmationSubTitleTitle")} {getValues("email")}</Typography>
                </Box>

                <Box mt={3}>
                    {codeLoading ? (
                        <div className="mt-5">
                            <ThreeBounce size={35} color="#797DF9"/>
                        </div>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                }}
                            >
                                <Box>
                                    <PinField
                                        className="pin-field"
                                        length={6}
                                        validate={/^[0-9]$/}
                                        onChange={(e) => {
                                            setCode(e);
                                        }}
                                        onComplete={handleEmailConfirmation}
                                    />
                                    {errors.code && (
                                        <Typography sx={styles.errors}>{errors.code.message}</Typography>
                                    )}
                                </Box>
                                <Box mt={5}>{renderTimerText()}</Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );

    const changePhoneNumber = (data) => {
        clearErrors("phoneNumber")
        if (!phoneNumberRegex.test(data.target.value)) {
            setError("phoneNumber", {
                type: "custom",
                message: t('invalidField'),
            });
            return;
        }
        setValue("phoneNumber", data.target.value)
    }


    const phoneNumberFormRender = () => (
        <Box
            component="form"
            mt={0}
            noValidate
            onSubmit={handleSubmit(handlePhoneNumberForm)}
            sx={styles.form}
        >
            <Box mt={3}
                 sx={{width: {xs: "90%", md: "80%"}, display: "flex", flexDirection: "column", alignItems: "start"}}>

                <Typography
                    sx={{fontSize: "2.2rem", fontWeight: 700, mb: 2, mt: 1}}
                >
                    {t("startPayQin")}
                </Typography>

                <Typography sx={{mb: 5}}>{t("phoneNumberRegistration")}</Typography>

                {verificationPhoneNumberLoading ? (
                    <div className="mt-5">
                        <ThreeBounce size={35} color="#797DF9"/>
                    </div>
                ) : (
                    <Box sx={{
                        // width: {xs: "73%"},
                        maxWidth: "300px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "flex-end"
                    }}>
                        <KTextField
                            onChange={changePhoneNumber}
                            label={t('phoneNumber')}
                            placeholder="XXXXXXXXXX"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CountrySelect
                                            {...register("phoneCode")}
                                            customRootInputStyles={{
                                                "& fieldset": {
                                                    borderLeft: "none",
                                                    borderTop: "none",
                                                    borderBottom: "none",
                                                    borderRadius: 0,
                                                },
                                            }}
                                            customInputStyles={{
                                                padding: '0',
                                            }}
                                            fullWidth
                                            // onChange={changeCountry}
                                        />
                                    </InputAdornment>
                                )
                            }}
                        />
                        {errors.phoneCode && <Typography sx={styles.errors}>{errors.phoneCode.message}</Typography>}
                        {errors.phoneNumber && <Typography sx={styles.errors}>{errors.phoneNumber.message}</Typography>}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}

                        <div style={{display: "revert", justifyContent: "start", marginTop: 30}}>
                            <HCaptcha
                                languageOverride={navigator.language.includes("fr") ? "fr" : "en"}
                                size="normal"
                                sitekey="4fa9e89e-70ff-4808-9ca0-b27ccc43c171"
                                onVerify={setCaptchaResponse}
                            />
                            {captchaError && <Typography sx={styles.errors}>{captchaError}</Typography>}
                        </div>

                        <Box mt={2} sx={{}}>
                            <RoundGradientButton
                                // disabled={!isValid || isSubmitting}
                                type="submit"
                                variant={"contained"}
                                color={"inherit"}
                                sx={{
                                    background: isSubmitting
                                        ? "linear-gradient(to right, #C471ED, #4A90ED)"
                                        : "",
                                    width: "100%",
                                    display: "flex",
                                    margin: "revert",
                                    marginLeft: "auto"
                                }}
                                fullWidth
                            >
                                {isSubmitting && (
                                    <>
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: "#FFFFFF",
                                                marginRight: "5%",
                                            }}
                                        />
                                    </>
                                )}
                                <Typography sx={{color: isSubmitting ? "#FFFFFF" : ""}}>
                                    {t("next")}
                                </Typography>
                            </RoundGradientButton>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );

    const registrationFormRender = () => (
        <Box
            component="form"
            mt={0}
            mb={10}
            noValidate
            onSubmit={handleSubmit(handleRegistrationForm)}
            sx={styles.form}
        >

            <Box mt={3}
                 sx={{
                     width: {xs: "90%", md: "80%"},
                 }}
            >

                <Box sx={{display: "flex", flexDirection: "column", alignItems: "start"}}>
                    <Typography
                        sx={{fontSize: "2.2rem", fontWeight: 700, mb: 2, mt: 5}}
                    >
                        {t('createAnPayQinAccount')}
                    </Typography>

                    <Typography sx={{mb: 5}}>{t('justOneMinute')}</Typography>
                </Box>

                <Box sx={{width: {xs: "100%", md: "100%"}}}>
                    {emailLoading ? (
                        <div className="mt-5">
                            <ThreeBounce size={35} color="#797DF9"/>
                        </div>
                    ) : (
                        <>
                            <Box>
                                <KTextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" sx={{marginLeft: 0}}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    {flag}
                                                    {phoneNumber}
                                                </Box>
                                            </InputAdornment>
                                        ),
                                        ...rootInputStyles,
                                        ...{
                                            border: "none",
                                        },
                                    }}
                                    value=""
                                    placeholder=""
                                    disabled
                                    fullWidth
                                />
                                {errors.phoneNumber &&
                                <Typography sx={styles.errors}>{errors.phoneNumber.message}</Typography>}
                            </Box>
                            <Spacing marginBottom={"30px"}/>
                            <Box>
                                <Typography sx={styles.label}>Email</Typography>
                                <KTextField
                                    validation={register("email", {
                                        required: false,
                                        pattern: {
                                            value: emailRegex,
                                            message: t("invalidField"),
                                        },
                                    })}
                                    defaultValue={!isEmpty(email) ? email : ''}
                                    placeholder="Ex: johndoe@gmail.com"
                                    disabled={!!email}
                                    fullWidth
                                />
                                {errors.email && <Typography sx={styles.errors}>{errors.email.message}</Typography>}
                            </Box>
                            <Spacing marginBottom={"30px"}/>
                            <Box>
                                <Typography sx={styles.label}>{t("firstName")}</Typography>
                                <KTextField
                                    validation={register("firstName", {
                                        required: t("requiredField"),
                                    })}
                                    placeholder="Doe"
                                    fullWidth
                                />
                                {errors.lastName &&
                                <Typography sx={styles.errors}>{errors.lastName.message}</Typography>}
                            </Box>
                            <Spacing marginBottom={"30px"}/>
                            <Box>
                                <Typography sx={styles.label}>{t("lastName")}</Typography>
                                <KTextField
                                    validation={register("lastName", {
                                        required: t("requiredField"),
                                    })}
                                    placeholder="John"
                                    fullWidth
                                />
                                {errors.firstName &&
                                <Typography sx={styles.errors}>{errors.firstName.message}</Typography>}
                            </Box>
                            <Spacing marginBottom={"30px"}/>
                            <Box>
                                <FormControlLabel
                                    id={"acceptTerms"}
                                    control={
                                        <Checkbox
                                            {...register("acceptedTerms", {
                                                required: t("requiredField"),
                                            })}
                                            defaultChecked={watch("acceptedTerms")}
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Typography>
                                            {t("business.acceptTermFirst")}{" "}
                                            <a
                                                href="https://policies.payqin.com/terms#/terms"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t("terms")}
                                            </a>
                                            {" "}
                                            {t("ofUse")}
                                            {" "}
                                            <a
                                                href="https://policies.payqin.com/terms#/privacy-policy"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t("privacyPolicy")}
                                            </a>.
                                        </Typography>
                                    }
                                />
                                {errors.acceptedTerms &&
                                <Typography sx={styles.errors}>{errors.acceptedTerms.message}</Typography>}
                            </Box>
                            <Spacing marginBottom={"20px"}/>

                            <Box mt={5}>
                                <RoundGradientButton
                                    // disabled={!isValid || isSubmitting}
                                    type="submit"
                                    variant={"contained"}
                                    color={"inherit"}
                                    sx={{
                                        background: isSubmitting
                                            ? "linear-gradient(to right, #C471ED, #4A90ED)"
                                            : "",
                                    }}
                                    size="large"
                                    fullWidth
                                >
                                    {isSubmitting && (
                                        <>
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: "#FFFFFF",
                                                    marginRight: "5%",
                                                }}
                                            />
                                        </>
                                    )}
                                    <Typography sx={{color: isSubmitting ? "#FFFFFF" : ""}}>
                                        {t("next")}
                                    </Typography>
                                </RoundGradientButton>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );

    const captchaRef = useRef(null);

    const renderTimerText = () => {

        return <form>
            <HCaptcha
                languageOverride={navigator.language.includes("fr") ? "fr" : "en"}
                size="invisible"
                sitekey="4fa9e89e-70ff-4808-9ca0-b27ccc43c171"
                onVerify={setCaptchaResponse}
                ref={captchaRef}
            />

            <RenderTimerText showTimer={showTimer} resendCode={resendCode}
                                    handleTimerComplete={handleTimerComplete}/>
        </form>

    };

    const otpFormRender = () => (
        <Box component="form" mt={3}
             sx={styles.form}
             noValidate onSubmit={handleSubmit(handleOtp)}>

            <Box mt={3}
                 sx={{width: {xs: "90%", md: "80%"}, display: "flex", flexDirection: "column", alignItems: "start"}}>

                    <Typography
                        sx={{fontSize: "2.2rem", fontWeight: 700, mb: 2, mt: 5}}
                    >
                        {t("phoneNumberConfirmationTitle")}
                    </Typography>

                    <Typography sx={{mb: 3}}>
                        {t("codeLegend")} {email ?
                        email :
                        phoneNumber
                    }</Typography>

                <Box mt={3} sx={{display: "flex", flexDirection: "column", alignItems: "start"}}>
                    {codeLoading ? (
                        <div className="mt-5">
                            <ThreeBounce size={35} color="#797DF9"/>
                        </div>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                }}
                            >
                                <Box>
                                    <PinField
                                        className="pin-field"
                                        length={6}
                                        validate={/^[0-9]$/}
                                        onComplete={handleOtp}
                                    />
                                    {errors.code && (
                                        <Typography sx={styles.errors}>{errors.code.message}</Typography>
                                    )}
                                </Box>
                                <Box mt={5}>{renderTimerText()}</Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Box>

            <ThemeProvider theme={defaultTheme}>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline/>
                    <Grid
                    item
                    xs={false}
                    sm={7}
                    md={7}
                    display={{xs: "none", sm: "flex", background: "linear-gradient(0deg, rgba(68,149,249,0.125) 0%, rgba(144,101,244,0.125) 100%);"}}
                    sx={{
                        overflow:'clip',
                        marginTop:-5,
                        border: "none",
                        borderTopRightRadius:450,
                        borderBottomRightRadius:450,
                        marginLeft: -10,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                        }}
                    >

                        <CarouselComponent/>
                        
                        <Box
                            sx={{
                                alignSelf:'center',
                                alignItems: "center",
                                margin:2,
                                display:'flex',
                                flexDirection:'column'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "2.5rem", fontWeight: 'bold',fontFamily:`Roboto, sans-serif`, color: "black", display: {
                                        xs: "none",
                                        sm: "initial"
                                    },
                                    '@media (min-width:1000px)': {
                                        fontSize: '5rem',
                                      },
                                }}
                            >
                                Bienvenue
                            </Typography>
                            <Box
                            sx={{
                                alignSelf:'center',
                                alignItems: "center",
                                justifyContent:'center',
                                display:'flex',
                                flexDirection:'row'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "2rem", fontWeight: 'bold', color: "black", display: {
                                        xs: "none",
                                        sm: "initial"
                                    },
                                }}
                                >
                                sur
                            </Typography>

                            <Box
                                component="img"
                                src="https://res.cloudinary.com/payqin-ltd/image/upload/v1692967362/web-app/te%CC%81le%CC%81chargement_xdn9t4.png"
                                sx={{
                                    width: {
                                        xs: "30%",
                                        md: "20%",
                                    },
                                    marginLeft:2
                                }}
                            />

                        </Box>
                            
                        </Box>

                        <Box
                            sx={{
                                alignSelf: "center",
                                alignItems: "center",
                                marginBottom: "1%",
                            }}
                        >
                            <Copyright/>
                        </Box>
                    </Box>
                </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        md={5}
                    >
                        <Box sx={styles.formSection}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: {
                                        xs: "space-between",
                                        sm: "right"
                                    },
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    xs="false"
                                    component="img"
                                    src="https://res.cloudinary.com/payqin-ltd/image/upload/v1692967362/web-app/te%CC%81le%CC%81chargement_xdn9t4.png"
                                    sx={{
                                        display: {
                                            sm: "none"
                                        },
                                        width: "30%",
                                    }}
                                />
                                <Typography
                                    sx={{fontSize: "1.1rem", fontWeight: 400, color: "#001021", display: {
                                            xs: "none",
                                            sm: "initial"
                                        },}}
                                >
                                    {t("alreadyToPayqin")}
                                </Typography>
                                <Button
                                    onClick={goToSignIn}
                                    variant={"contained"}
                                    color={"inherit"}
                                    sx={{
                                        fontSize: "1.1rem",
                                        color: "#001021",
                                        textTransform: "revert",
                                        ml: 3,
                                    }}
                                >
                                    {t("login")}
                                </Button>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "auto",
                                    mt: 10,
                                    width: "100%"
                                }}
                            >
                                <Box sx={{width: '100%'}}>
                                    <Stepper activeStep={activeStep} alternativeLabel>
                                        {steps.map((label, index) => (
                                            <Step key={index}>
                                                <StepLabel>
                                                    {label.split('\n').map((text, i) => (
                                                        <div key={i}>{text}</div>
                                                    ))}
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Box>

                                {renderStepContent(activeStep)}
                            </Box>
                        </Box>
                    </Grid>

                    <SuccessfullyRegistrationDialog openSuccessfullyRegistrationDialog={openSuccessfullyRegistrationDialog}
                                                    handleDialogAfterSuccess={handleDialogAfterSuccess}/>
                    <VerificationCodeEmailDialog openDialog={openDialog} setOpenDialog={setOpenDialog} expanded={expanded}
                                                 handleExpandClick={handleExpandClick}
                                                 onSubmit={handleVerificationPhoneNumberByEmail}/>
                </Grid>


            </ThemeProvider>
        </Box>
    );
};

function Copyright(props: any) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://payqin.com/">
                PayQin
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const defaultTheme = createTheme();

export default SignUp;


interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

// <Box sx={{display: "flex", height: '70vh', flexDirection: 'column', ml:10, mr:10, mt: 5}}>

/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
    errors: {
        color: "red"
    },
    form: {display: "flex", flexDirection: "column", alignItems: "center"},
    formSection: {
        display: "flex",
        flexDirection: "column",
        ml: {
            md: 5,
            xs: 5,
        },
        mr: {
            md: 5,
            xs: 5,
        },
        mt: 5,
    },
};
