import { httpService } from "./http.service";
import { IBankTransferDto } from "../../containers/Transactions/Transfer/bank";

const postTransfer = (bankTransferData: IBankTransferDto) => {
  return httpService.Axios.post(
    "/wallets/web-app/init-transfer",
    bankTransferData
  );
};

const getPurposeCodes = () => {
  return httpService.Axios.get("wallets/transfer/purposes-codes");
};

let getTransactionStatics = () => {
  return httpService.Axios.get("resources/transaction-statics");
};

let getWalletTransactions = () => {
  let page = 1;
  let limit = 5;
  let fromDate = "2017-01-01";
  let toDate = new Date().toISOString().split("T")[0];
  let transactionDirection = "ALL";
  return httpService.Axios.post(
    `wallets/sort-transactions?transactionDirection=${transactionDirection}&page=${page}&limit=${limit}`,
    {
      dateDebut: fromDate,
      dateFin: toDate,
    }
  );
};

const getOperationVerificationCode = () => {
  return httpService.Axios.get("wallets/web-app/operation-code");
};

let getFrequentRecipients = () => {
  return httpService.Axios.get("wallets/frequent");
};

export const transactionService = {
  getTransactionStatics,
  getWalletTransactions,
  getFrequentRecipients,
  getPurposeCodes,
  postTransfer,
  getOperationVerificationCode,
};
