import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import Spacing from "../../../../components/Spacing";
import AmountField from "../../../../components/AmountField";
import RoundGradientButton from "../../../../components/RoundGradientButton";
import Popup from "../../../../components/Popup";
import { EditNoteOutlined } from "@mui/icons-material";
import { useState } from "react";
import KTextField from "../../../../components/KTextField";
import { CountrySelect } from "../../../Recipients/components/CountrySelect";
import { useForm } from "react-hook-form";

export interface IRecipientAirtime {
  phoneCode: string;
  phoneNumber: string;
  amount: string;
}

const AirtimeMoney = () => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    setError,
    setValue,
  } = useForm<IRecipientAirtime>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmitAirtime = () => {
  };

  const handleAmount = (amount: string) => {
    setValue("amount", amount);
  };

  return (
    <Box>
      <Box sx={styles.container}>
        <Typography
          sx={{ textAlign: "center", fontSize: "22px", fontWeight: "bold" }}
        >
          {t("airtimeMoneyTitle")}
        </Typography>
        <Box m={2} p={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <CountrySelect
                label=""
                {...register("phoneCode", {
                  required: t("physicalCards.countryCodeError"),
                })}
              />
              {/* {errors.phoneCode && (
                  <p style={styles.errorText}>{errors.phoneCode.message}</p>
                )} */}
            </Grid>
            <Grid item xs={12} sm={8}>
              <KTextField
                validation={register("phoneNumber", {
                  required: t("physicalCards.phoneNumberError"),
                  pattern: {
                    value: /^(?=[0-9]).{7,20}$/,
                    message: t("physicalCards.phonePatternError"),
                  },
                })}
                placeholder="XXXXXXXXXX"
                fullWidth
              />
              {/* {errors.phoneNumber && (
                  <p style={styles.errorText}>{errors.phoneNumber.message}</p>
                )} */}
            </Grid>
          </Grid>

          <Spacing marginBottom={"50px"} />
          <Box component={"form"} onSubmit={handleSubmitAirtime}>
            <Box sx={styles.voucher}>
              <AmountField
                currency={"XOF"}
                fullWidth={true}
                onChangeAmount={handleAmount}
              />
            </Box>
            <Spacing marginBottom={"50px"} />
            <RoundGradientButton
              variant="contained"
              color="inherit"
              disabled={!isValid || isSubmitting}
              type="submit"
              fullWidth
              sx={{
                background: isSubmitting
                  ? "linear-gradient(to right, #C471ED, #4A90ED)"
                  : "",
                mt: 3,
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
              }}
            >
              {isSubmitting ? (
                <>
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "#FFFFFF",
                      marginRight: "5%",
                    }}
                  />
                  <Typography sx={{ color: isSubmitting ? "#FFFFFF" : "" }}>
                    {t("loading")}
                  </Typography>
                </>
              ) : (
                <Typography sx={{ color: isSubmitting ? "#FFFFFF" : "" }}>
                  {t("withdrawScreen.submitButton")}
                </Typography>
              )}
            </RoundGradientButton>
          </Box>
        </Box>
      </Box>
      {/* <Popup title={dialogTitle} open={dialog} onClose={setDialog}>
        {dialogContent}
      </Popup> */}
    </Box>
  );
};

export default AirtimeMoney;

/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
  voucher: {
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: {
      sm: "70%",
      md: "60%",
      lg: "40%",
    },
    margin: "auto",
  },
};
