import React from "react";
import { Box } from "@mui/material";
import NewCardButton from "../containers/Cards/components/NewCardButton";
import SubOperation from "../shared/types/SubOperation";
import IRecipient from "../shared/types/IRecipient";

interface PopupProps {
  items: SubOperation[];
  handleChannelOperation: (subOperation: SubOperation | undefined) => any;
  additionalStyle?: React.CSSProperties;
  recipient?: IRecipient | null;
}

const ShowOperationsPopup = ({
  items,
  handleChannelOperation,
  additionalStyle,
  recipient,
}: PopupProps) => {
  return (
    <Box sx={styles.content}>
      {items.map((item, index) => {
        return (
          <NewCardButton
            additionalStyle={additionalStyle}
            title={item.text}
            children={item.icon}
            action={item.action || (() => handleChannelOperation(item))}
          />
        );
      })}
    </Box>
  );
};

export default ShowOperationsPopup;

/**
 * @type import("@mui/material".Sx.props)
 */
const styles = {
  content: {
    padding: {
      sx: "5 2",
      sm: 5,
    },
    display: "flex",
    justifyContent: "center",
    flexWrap: {
      xs: "wrap",
      sm: "noWrap",
    },
  },
};
