import {Colors} from "../constants/Colors";

function buildOrderStatus(status: string) {
    switch (status.toLowerCase()) {
        case 'pending':
            return {label: 'Pending', color: Colors.colorOrange};
        case 'rejected':
            return {label: 'Rejected', color: Colors.colorRed};
        case 'in_delivery':
            return {label: 'En cours de livraison', color: Colors.colorBlue};
        case 'waiting_for_pickup':
            return {label: 'En attente du client', color: Colors.colorBlue};
        case 'delivered':
            return {label: 'Livré au client', color: Colors.colorGreenBold};
        default:
            return {label: status, color: Colors.colorGrey};
    }
}

export const PhysicalCardHelpers = {buildOrderStatus}