import React, { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { accountService } from "../shared/services";
import toast from "react-hot-toast";
import { t } from "i18next";

const IdleTimerContainer = () => {
  const { getElapsedTime } = useIdleTimer({
    onIdle: () => {
      if (process.env.NODE_ENV === "development") {
        return;

      }

      const token = accountService.getToken();

      if (token !== null) {
        accountService.logout();
        window.location.replace("https://web.payqin.com/login");
        toast.success(t("idleLogout"));
      } else {
        return;
      }
    },
    timeout: 60 * 10 * 1000,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const elapsed = Math.ceil(getElapsedTime() / 1000);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return <></>;
};

export default IdleTimerContainer;
