import * as React from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";

interface BarChartProps {
  data?: any[];
}

const BarChatWhileLoading = () => {
  const option = {
    xAxis: {
      type: "category",
      data: [
        "Nov",
        "Dec",
        "Jan",
        "Fev",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Aug",
        "Sep",
        "Oct",
      ],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        type: "line",
        smooth: true,
      },
    ],
  };
  return (
    <ReactECharts
      style={{
        width: "100%",
        height: "90%",
      }}
      option={option}
    />
  );
};

const BarChart: React.FC<BarChartProps> = ({ data }) => {
  const option = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ['Solde', 'Montant ajouté', 'Montant retranché']
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: data?.map((item) => item.period),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: 'Solde',
        data: data?.map((item) => item.walletValue?.toFixed(2)??0),
        type: "line",
        smooth: true,
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#C471ED",
            },
            {
              offset: 1,
              color: "#4A90ED",
            },
          ]),
        },
      },
      {
        name: 'Montant ajouté',
        data: data?.map((item) => item.addedAmount?.toFixed(2)??0),
        type: "line",
        smooth: true,
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#C471ED",
            },
            {
              offset: 1,
              color: "#4A90ED",
            },
          ]),
        },
      },
      {
        name: 'Montant retranché',
        data: data?.map((item) => item.substractedAmount?.toFixed(2)??0),
        type: "line",
        smooth: true,
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#C471ED",
            },
            {
              offset: 1,
              color: "#4A90ED",
            },
          ]),
        },
      },
    ],
  };

  return (
    <ReactECharts
      style={{
        width: "100%",
        height: "80%",
      }}
      option={option}
    />
  );
};

export { BarChart, BarChatWhileLoading };
