import {
    ITransactionStatics,
} from "../../containers/Wallet/models/transactionStatics.response";
import {transactionService} from "../services";
import {IApiResponse} from "../types/IApiResponse";

const getTransactionStatics = async (): Promise<IApiResponse<ITransactionStatics|null>> => {
    try {
        const {data, status, statusText} = await transactionService.getTransactionStatics();
        return Promise.resolve({
            data: data as ITransactionStatics,
            error: null,
        });
    } catch
        (error) {
        return Promise.resolve({
            data: null,
            error: 'Impossible de charger les types',
        });
    }
}

export const transactionsRepository = {getTransactionStatics}