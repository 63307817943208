import * as React from 'react';
import {useState} from 'react';
// @ts-ignore
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";
import KTextField from "../../../components/KTextField";
import {t} from "i18next";
import "./kdateRange.css"
import {IconButton, InputAdornment} from "@mui/material";
import {CloseIcon} from "../../../shared/_utils/constants/icon";
import {isEmpty} from "../../../shared/_utils/isEmpty";

export interface IFilterTransactionsProps {
    filterFn: (params?: IDateRangeProps) => any
}

export interface IDateRangeProps {
    endDate: string,
    startDate: string
}

export default function KDateRange({filterFn} : IFilterTransactionsProps) {

    let now = new Date();
    let startt = moment(
        new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );

    let ends = moment(startt)
        .add(1, "days")
        .subtract(1, "seconds");

    let [start, setStart] = useState(startt);
    let [end, setEnd] = useState(ends);
    let [dateRange, setDateRange] = useState<string>("");

    let ranges = {
        [t("today")]: [moment(start), moment(end)],
        [t("yesterday")]: [
            moment(start).subtract(1, "days"),
            moment(end).subtract(1, "days")
        ],
        [t("lastSevenDays")]: [moment(start).subtract(7, "days"), moment(end)],
        [t("thisMonth")]: [moment(start).startOf("month"), moment(end).endOf("month")],
        [t("lastMonth")]: [
            moment(start).subtract(1, "month").startOf("month"),
            moment(end).subtract(1, "month").endOf("month")
        ]
    };
    let local = {
        "format": "DD-MM-YYYY",
        "sundayFirst": false,
        smartMode: true,
        noMobileMode: true
    };
    let maxDate = moment(start).add(24, "hour");

    const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
        setStart(startDate);
        setEnd(endDate);
        const fullDate = `${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}`
        setDateRange(fullDate)

        const dataRange = {} as IDateRangeProps;
        dataRange.startDate = startDate.format("YYYY-MM-DD");
        dataRange.endDate = endDate.format("YYYY-MM-DD");
        filterFn(dataRange)
    };

    const handleClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setDateRange("")
        filterFn()
    }

    return (
        <DateTimeRangeContainer
            ranges={ranges}
            start={start}
            end={end}
            local={local}
            applyCallback={applyCallback}
        >
            <KTextField
                value={dateRange}
                style={{ cursor: "pointer" }}
                autoComplete={"off"}
                name={"date"}
                fullWidth
                sx={{ m: 1 }}
                label={t("period")}
                InputProps={{
                    endAdornment: !isEmpty(dateRange) && (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={e => handleClickClear(e)}
                                onMouseDown={e => handleClickClear(e)}
                                edge="end"
                            >
                                <CloseIcon width={20} height={20} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            >
            </KTextField>
        </DateTimeRangeContainer>
    );
}