import React from 'react'
import {Box, Typography} from "@mui/material";

const CarouselItem = ({item}: any) => {
    return(
        <Box
            width="100%"
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems:'center',
                marginTop: 10,
            }}
        >
                <Box
                component="img"
                src={item?.image}
                sx={{
                    width: {
                        xs: "30%",
                        md: "40%",
                    },
                    marginBottom:15
                }}
            />
        </Box>
    )
}

export default CarouselItem;