import React from "react";
import { styled, Tab } from "@mui/material";

const CustomTab = styled(Tab)(({ theme }) => ({
  border: ".5px solid #C7C7C7",
  color: "#959595",
  paddingTop: 0.5,
  paddingBottom: 0.5,
  textTransform: "none",
  marginLeft: 1,
  marginRight: 10,
  borderRadius: "8px",
  "&.Mui-selected": {
    background: "linear-gradient(to right, #C471ED, #4A90ED)",
    color: "#FFFFFF",
  },
}));

export default CustomTab;
