import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Spacing from "../../../../components/Spacing";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import RoundGradientButton from "../../../../components/RoundGradientButton";
import KTextField from "../../../../components/KTextField";
import { t } from "i18next";

interface IVoucherForm {
  voucher: string;
}

const Voucher = () => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    setError,
  } = useForm<IVoucherForm>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleVoucherForm: SubmitHandler<IVoucherForm> = async (data) => {
    // setIsSubmitting(true);
    // alert(data.voucher)
  };

  return (
    <Box>
      <Box sx={styles.container}>
        <Box>
          <Box
            component={"img"}
            src={
              "https://res.cloudinary.com/payqin-ltd/image/upload/v1691269700/web-app/image_43_kno7fb.png"
            }
            style={{
              width: "40%",
              transform: "rotate(-10deg)",
              display: "flex",
              margin: "auto",
            }}
          ></Box>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: "1.7em",
              fontWeight: 700,
            }}
          >
            {t("redeemVoucher")}
          </Typography>

          <Spacing marginBottom={"50px"} />
          <Box component={"form"} onSubmit={handleSubmit(handleVoucherForm)}>
            <Box sx={styles.voucher}>
              <KTextField
                validation={register("voucher", {
                  required: t("voucherRequired"),
                })}
                placeholder="e.g. 1F456PAQ"
                fullWidth
              />
              <hr />
              {errors.voucher && (
                <span
                  style={{
                    color: "#F94144",
                    fontSize: "1rem",
                    fontWeight: 400,
                  }}
                >
                  {errors.voucher.message}
                </span>
              )}
            </Box>
            <Spacing marginBottom={"50px"} />
            <RoundGradientButton
              disabled={!isValid || isSubmitting}
              type="submit"
              color="inherit"
              fullWidth
              variant="contained"
              sx={{
                // height: "48px",
                background: isSubmitting
                  ? "linear-gradient(to right, #C471ED, #4A90ED)"
                  : "",
                mt: 3,
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
              }}
            >
              {isSubmitting ? (
                <>
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "#FFFFFF",
                      marginRight: "5%",
                    }}
                  />
                  <Typography sx={{ color: isSubmitting ? "#FFFFFF" : "" }}>
                    {t("loading")}...
                  </Typography>
                </>
              ) : (
                <Typography sx={{ color: isSubmitting ? "#FFFFFF" : "" }}>
                  {t("withdrawScreen.submitButton")}
                </Typography>
              )}

              {/*{isSubmitting ? (*/}
              {/*    <Typography sx={{color: isSubmitting ? '#FFFFFF' : '',}}>*/}
              {/*        <CircularProgress*/}
              {/*            size={24}*/}
              {/*            sx={{*/}
              {/*                color: '#FFFFFF',*/}
              {/*                marginRight: '5%',*/}
              {/*            }}*/}
              {/*        />*/}
              {/*        Chargement...*/}
              {/*    </Typography>*/}
              {/*) : ('Validate')}*/}
            </RoundGradientButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Voucher;

/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
  voucher: {
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: {
      sm: "60%",
      md: "50%",
      lg: "30%",
    },
    margin: "auto",
  },
};
