import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  tableCellClasses,
  TablePagination,
  Typography,
} from "@mui/material";
import {
  ArrowUpRightIcon,
  TransactionsIcon,
} from "../../../shared/_utils/constants/icon";
import { Transaction as TransactionDto } from "../models/transactionsResponse";
import { ITransactionView } from "../Wallet";
import { TransitionProps } from "@mui/material/transitions";
import GradientCircularProgress from "../../../components/GradientCircularProgress";
import { format } from "date-fns";
import { TransactionHelpers } from "../../../shared/_utils/_helpers/Transaction";
import Spacing from "../../../components/Spacing";
import { PhysicalCardHelpers } from "../../../shared/_utils/_helpers/PhysicalCard";
import { t } from "i18next";
import { isEmpty } from "../../../shared/_utils/isEmpty";
import { IconUrl } from "../../../shared/_utils/constants/IconUrl";

interface Data {
  calories: string;
  carbs: string;
  fat: string;
  name: string;
  protein: string;
}

function createData(
  name: string,
  calories: string,
  fat: string,
  carbs: string,
  protein: string
): Data {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData(
    "17-03-2023",
    "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
    "Online Purchase at Ebay.com",
    "Completed",
    "+10000 FCFA"
  ),
  createData(
    "17-03-2023",
    "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
    "Online Purchase at Ebay.com",
    "Completed",
    "+10000 FCFA"
  ),
  createData(
    "17-03-2023",
    "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
    "Online Purchase at Ebay.com",
    "Completed",
    "+10000 FCFA"
  ),
  createData(
    "17-03-2023",
    "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
    "Online Purchase at Ebay.com",
    "Completed",
    "+10000 FCFA"
  ),
  createData(
    "17-03-2023",
    "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
    "Online Purchase at Ebay.com",
    "Completed",
    "+10000 FCFA"
  ),
  createData(
    "17-03-2023",
    "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
    "Online Purchase at Ebay.com",
    "Completed",
    "+10000 FCFA"
  ),
  createData(
    "17-03-2023",
    "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
    "Online Purchase at Ebay.com",
    "Completed",
    "+10000 FCFA"
  ),
  createData(
    "17-03-2023",
    "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
    "Online Purchase at Ebay.com",
    "Completed",
    "+10000 FCFA"
  ),
  createData(
    "17-03-2023",
    "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
    "Online Purchase at Ebay.com",
    "Completed",
    "+10000 FCFA"
  ),
  createData(
    "17-03-2023",
    "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
    "Online Purchase at Ebay.com",
    "Completed",
    "+10000 FCFA"
  ),
  createData(
    "17-03-2023",
    "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
    "Online Purchase at Ebay.com",
    "Completed",
    "+10000 FCFA"
  ),
  createData(
    "17-03-2023",
    "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
    "Online Purchase at Ebay.com",
    "Completed",
    "+10000 FCFA"
  ),
  createData(
    "17-03-2023",
    "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
    "Online Purchase at Ebay.com",
    "Completed",
    "+10000 FCFA"
  ),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Date",
    // label: "Date et Heure",
  },
  {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "Transactions",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    // label: `${t("amount")}`,
    label: "Montant",
  },
  // {
  //     id: 'protein',
  //     numeric: true,
  //     disablePadding: false,
  //     label: 'Protein (g)',
  // },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={index === headCells.length - 1 ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              backgroundColor: "#F9F9F9",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{
                fontWeight: "500",
                fontSize: "13px",
                color: "#959595",
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  download?: JSX.Element;
  more?: JSX.Element;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, download, more } = props;

  return (
    <Toolbar
      sx={{
        width: "100%",
        // maxWidth: 900,
        border: 1,
        borderColor: "#C7C7C7",
        backgroundColor: "#E6F4FD",
        borderRadius: "8px",
        borderBottomRightRadius: 0,
        borderLeftRightRadius: 0,
        borderBottomLeftRadius: 0,
        // mt: 2,
        // p: 2,
        display: "flex",
        justifyContent: "space-between",
        // alignItems: 'flex-start'
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Box>
        <Button
          startIcon={<TransactionsIcon />}
          style={{
            textTransform: "none",
            fontWeight: 700,
            fontSize: "16px",
            color: "#001021",
          }}
        >
          {t("recentsTransactions")}
        </Button>
      </Box>
      <Box>
        {more && more}
      </Box>
    </Toolbar>
  );
}

interface ITransactionsProps {
  transactions?: ITransactionView[];
  children?: JSX.Element;
  download?: JSX.Element;
  more?: JSX.Element;
  pagination?: boolean;
  loading?: boolean;
  transactionsError?: string|null
}

export default function EnhancedTable(props: ITransactionsProps) {
  const { transactions, children, download, more, pagination, loading, transactionsError } = props;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("calories");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [selectedTransaction, setSelectedTransaction] =
    React.useState<ITransactionView>();
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [dialog, setDialog] = React.useState<boolean>(false);

  const handleClickOpen = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    transaction: ITransactionView
  ) => {
    setSelectedTransaction(transaction);
    setDialog(true);
    // const selectedIndex = selected.indexOf(transaction.serviceTransactionRef);
    // let newSelected: readonly string[] = [];

    // if (selectedIndex === -1) {
    //     newSelected = newSelected.concat(selected, [name]);
    // } else if (selectedIndex === 0) {
    //     newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //     newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //     newSelected = newSelected.concat(
    //         selected.slice(0, selectedIndex),
    //         selected.slice(selectedIndex + 1),
    //     );
    // }
    //
    // setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getTranslatedStatus = (status: string) => {
    let newStatus;
    switch (status) {
      case "processed":
        newStatus = t("partner.process");
        break;
      case "pending":
        newStatus = t("partner.pending");
        break;
      case "rejected":
        newStatus = t("partner.reject");
        break;
      case "refunded":
        newStatus = t("partner.refunded");
        break;
    }

    return newStatus;
  };

  // const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setDense(event.target.checked);
  // };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          more={more}
          download={download}
        />
        {children && children}
        <TableContainer>
          <Table
            sx={{ minWidth: 750, border: "#C7C7C7 1px solid" }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {
                transactions && transactions.length > 0
                    ? transactions.map((row, index) => {
                      const isItemSelected = isSelected(
                          row.serviceTransactionRef as string
                      );
                      const labelId = `enhanced-table-checkbox-${index}`;

                      let avatarSrc = "";

                      if (row.type === "transfer") {
                        if (
                            row.user !== null &&
                            typeof row.user == "object" &&
                            row.user.picture
                        ) {
                          avatarSrc = row.user.picture;
                        } else {
                          avatarSrc = IconUrl.transferPayQinImg;
                        }
                      } else {
                        avatarSrc =
                            row.logo || TransactionHelpers.getIconUrl(row.type);
                      }

                      return (
                          <TableRow
                              hover
                              onClick={(event) => handleClick(event, row)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.serviceTransactionRef}
                              selected={isItemSelected}
                              sx={{ cursor: "pointer" }}
                          >
                            <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="normal"
                            >
                              {format(row.date, "dd-MM-yyyy")}
                            </TableCell>
                            <TableCell align="right">
                              <Box sx={styles.transactionData}>
                                {/*<Box*/}
                                {/*    component="img"*/}
                                {/*    sx={styles.servicePicture}*/}
                                {/*    src={(row.type == 'transfer' && typeof row.user == "object")*/}
                                {/*        : isEmpty(row.user.picture)*/}
                                {/*        ? IconUrl.transferPayQinImg*/}
                                {/*            : row.user.picture*/}
                                {/*            :*/}
                                {/*    }*/}
                                {/*/>*/}
                                <Avatar
                                    src={avatarSrc}
                                    alt="Transaction Avatar"
                                    sx={styles.servicePicture}
                                />
                                <Box>
                                  <Typography sx={styles.transactionLabel}>
                                    {row.narration}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell align="right">
                              <Box
                                  sx={{
                                    ...styles.transactionStatus,
                                    ...{
                                      color: row.color,
                                      textTransform: "none",
                                    },
                                  }}
                              >
                                <Typography>
                                  {getTranslatedStatus(row.status)}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align="right">{row.amount}</TableCell>
                          </TableRow>
                      );
                    })
                    : <TableRow
                        aria-colspan={4}
                        sx={{
                          margin: "auto",
                          textAlign: "center"
                        }}
                    >
                      <Typography
                          sx={{color: "red", fontSize: "1rem", fontWeight: 700, m: 5 }}
                      >
                        {transactionsError}
                      </Typography>
                    </TableRow>
              }
              {loading && (
                  <TableRow
                  >
                    <TableCell colSpan={4} sx={{textAlign: "center"}}>
                      <GradientCircularProgress />
                    </TableCell>
                  </TableRow>
              )}
              {transactionsError && <TableRow
                  aria-colspan={4}
                  sx={{
                    margin: "auto",
                    textAlign: "center"
                  }}
              >
                <Typography
                    sx={{color: "red", fontSize: "1rem", fontWeight: 700, m: 5 }}
                >
                  {transactionsError}
                </Typography>
              </TableRow>}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/*{transactions && transactions.length > 0 && (*/}
        {/*  <Box>*/}
        {/*    <Typography*/}
        {/*      sx={{*/}
        {/*        color: "#4A90E2",*/}
        {/*        display: "flex",*/}
        {/*        flexDirection: "row",*/}
        {/*        justifyContent: "center",*/}
        {/*        alignItems: "center",*/}
        {/*        textDecoration: "underline",*/}
        {/*        fontWeight: 500,*/}
        {/*        fontSize: "13px",*/}
        {/*        padding: "10px 0 20px",*/}
        {/*        borderLeft: "#C7C7C7 1px solid",*/}
        {/*        borderRight: "#C7C7C7 1px solid",*/}
        {/*        borderBottom: "none",*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {t("loadMoreTransactions")}*/}
        {/*    </Typography>*/}
        {/*  </Box>*/}
        {/*)}*/}

        {/*{pagination && (*/}
        {/*  <TablePagination*/}
        {/*    rowsPerPageOptions={[5, 10, 25]}*/}
        {/*    component="div"*/}
        {/*    count={rows.length}*/}
        {/*    rowsPerPage={rowsPerPage}*/}
        {/*    page={page}*/}
        {/*    onPageChange={handleChangePage}*/}
        {/*    onRowsPerPageChange={handleChangeRowsPerPage}*/}
        {/*    labelRowsPerPage={t("rowPerPages")}*/}
        {/*  />*/}
        {/*)}*/}
      </Paper>
      {/*<FormControlLabel*/}
      {/*    control={<Switch checked={dense} onChange={handleChangeDense} />}*/}
      {/*    label="Dense padding"*/}
      {/*/>*/}

      <Dialog
        open={dialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            sx={{
              fontSize: "1.25rem",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              fontWeight: 700,
              marginTop: 3,
            }}
          >
            {t("transactionDetails")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {selectedTransaction && (
              <>
                <Box ml={2}>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        // borderBottom: "none",
                        margin: "0px",
                        padding: 2,
                      },
                    }}
                  >
                    {ReceiptItem(
                      t("transactionType"),
                      <Box>{selectedTransaction.narration} </Box>
                    )}
                    {ReceiptItem(
                      t("transactionReference"),
                      <Box>{selectedTransaction.id} </Box>
                    )}
                    {ReceiptItem(
                      "Date",
                      <Box>{selectedTransaction.date.toLocaleString()}</Box>
                    )}

                    {selectedTransaction.user &&
                    typeof selectedTransaction.user === "object" ? (
                      ReceiptItem(
                        `${t("to")}`,
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            src={selectedTransaction.user?.picture || ""}
                            alt={selectedTransaction.user.firstName}
                          />
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography>
                              {selectedTransaction.user.firstName}{" "}
                              {selectedTransaction.user.lastName}
                            </Typography>
                            <Typography>
                              {selectedTransaction.user.phone}
                            </Typography>
                          </Box>
                        </Box>
                      )
                    ) : typeof selectedTransaction.phone &&
                      selectedTransaction.type !== "bank-transfer" &&
                      selectedTransaction.phone !== null &&
                      selectedTransaction.logo ? (
                      ReceiptItem(
                        t("phoneNumber"),
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            src={selectedTransaction.logo}
                            alt={
                              selectedTransaction.phone || "numéro de téléphone"
                            }
                            sx={{ marginRight: "2%" }}
                          />{" "}
                          {selectedTransaction.phone}
                        </Box>
                      )
                    ) : (
                      <></>
                    )}
                    {ReceiptItem(
                      "Status",
                      <Box>
                        {getTranslatedStatus(selectedTransaction.status)}
                      </Box>
                    )}
                    {selectedTransaction.orderStatus &&
                      ReceiptItem(
                        t("orderStatus"),
                        <Box
                          sx={{ backgroundColor: selectedTransaction.color }}
                        >
                          <Typography sx={{ color: selectedTransaction.color }}>
                            {
                              PhysicalCardHelpers.buildOrderStatus(
                                selectedTransaction.orderStatus
                              ).label
                            }
                          </Typography>
                        </Box>
                      )}
                    {selectedTransaction.recipient &&
                    selectedTransaction.logo ? (
                      ReceiptItem(
                        t("beneficiary"),
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            src={selectedTransaction.logo}
                            alt={
                              selectedTransaction.recipient ||
                              "numéro de téléphone"
                            }
                            sx={{ marginRight: "2%" }}
                          />{" "}
                          {selectedTransaction.recipient}
                        </Box>
                      )
                    ) : (
                      <></>
                    )}

                    {selectedTransaction.last4digits &&
                      ReceiptItem(
                        t("usedCard"),
                        <Box>
                          <Typography>
                            {selectedTransaction.last4digits}
                          </Typography>
                        </Box>
                      )}
                    {selectedTransaction.serviceTransactionRef &&
                      ReceiptItem(
                        t("operatorId"),
                        <Box>
                          <Typography>
                            {selectedTransaction.serviceTransactionRef}
                          </Typography>
                        </Box>
                      )}
                    {selectedTransaction.orderNumber &&
                      ReceiptItem(
                        t("orderNumber"),
                        <Box>
                          <Typography>
                            {selectedTransaction.orderNumber}
                          </Typography>
                        </Box>
                      )}
                    {/* {selectedTransaction.amountConverted &&
                      ReceiptItem(
                        t("convertedAmount"),
                        <Box>
                          <Typography>
                            {selectedTransaction.amountConverted} CFA
                          </Typography>
                        </Box>
                      )} */}
                    <TableRow key="autres-details">
                      <TableCell
                        scope="row"
                        sx={{
                          fontSize: "1rem",
                          fontWeight: 500,
                          color: "#000000",
                          textDecoration: "underline",
                          borderBottom: "none",
                        }}
                      >
                        {t("otherDetails")}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "700",
                          fontSize: "1rem",
                          borderBottom: "none",
                        }}
                      >
                        {selectedTransaction.narration}
                      </TableCell>
                    </TableRow>
                  </Table>
                </Box>
                <Spacing marginBottom={"50px"} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  {selectedTransaction.amount && (
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "1.25rem",
                        color: "#000000",
                      }}
                    >
                      {selectedTransaction.amount}
                    </Typography>
                  )}
                  <Spacing marginBottom={"10px"} />
                  <Typography>{t("securelyVia")}</Typography>
                  <Spacing marginBottom={"10px"} />
                  <Box
                    sx={{
                      width: {
                        xs: "30%",
                        md: "20%",
                      },
                    }}
                    component="img"
                    src="https://res.cloudinary.com/payqin-ltd/image/upload/v1692967362/web-app/te%CC%81le%CC%81chargement_xdn9t4.png"
                  />
                  <Spacing marginBottom={"30px"} />
                </Box>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        {/*<DialogActions>*/}
        {/*    <Button onClick={handleClose}>Disagree</Button>*/}
        {/*    <Button onClick={handleClose}>Agree</Button>*/}
        {/*</DialogActions>*/}
      </Dialog>
    </Box>
  );
}

const ReceiptItem = (title: string, description: JSX.Element) => {
  return (
    <TableRow key={title}>
      <TableCell component="th" scope="row">
        {title}
      </TableCell>
      <TableCell
        sx={{
          fontWeight: "700",
          fontSize: "1rem",
        }}
      >
        {description}
      </TableCell>
    </TableRow>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @type {import("@mui/material").SxProps}
 */

const styles = {
  avatar: {
    width: 70,
    height: 70,
    borderRadius: "50%",
    backgroundColor: (transaction: ITransactionView) =>
      transaction.type === "top-up" ||
      transaction.type === "withdraw" ||
      transaction.type === "subscription-payment"
        ? "#1976D2" // Couleur de fond personnalisée pour certains types de transaction
        : "transparent",
  },
  transactionAmount: {
    fontSize: 26,
    fontWeight: "bold",
    color: "white",
  },
  currencySymbol: {
    color: "white",
    fontSize: 14,
  },
  transactionData: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  transactionStatus: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  servicePicture: {
    width: "32px",
    height: "32px",
    borderRadius: "100%",
  },
  transactionLabel: {
    color: "#000000",
    fontSize: "13px",
    fontWeight: "400",
    marginLeft: "10px",
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // '&:hover': {
    //     textDecoration: 'underline',
    //     cursor: 'pointer'
    // }
  },
};
