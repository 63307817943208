import React, { useState, useEffect } from 'react';

interface TimerProps {
    initialSeconds: number;
    onComplete: () => void;
}

const Timer = ({ initialSeconds, onComplete }: TimerProps): JSX.Element => {
    const [seconds, setSeconds] = useState(initialSeconds);

    useEffect(() => {
        if (seconds <= 0) {
            onComplete();
            return;
        }

        const interval = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [seconds, onComplete]);

    return <>{seconds}</>;
};

export default Timer;
