import { walletService } from "../../../shared/services";
import { IFeeCalculationResponse } from "../top-up/mobile-money";
import axios, { AxiosError, AxiosResponse, isAxiosError } from "axios";
import {
  IPaymentMethodResponse,
  IStatisticsResponse,
  ITopUpResponse,
  IWallet,
} from "../models/wallet.response";
import { IDataResponse } from "../../../shared/types/IDataResponse";
import { ITransactions } from "../models/transactionsResponse";
import { IApiResponse } from "../../../shared/types/IApiResponse";
import { ITopUpWaveResponse } from "../../../shared/types/ITopUpWaveResponse";
import {
  ICardBankData,
  ICardPaymentResponse,
  ICompleteTopUpBankCardResponse,
} from "../../../shared/types/ICardPaymentResponse";
import { ITopUpBankCardDto } from "../top-up/bank/bank-card";
import {displayError, handleAPICommonResp} from "../../../shared/_utils/_helpers/error";

const performWaveTransfer = async (
  amount: string,
  recipientPhoneNumber: string,
  currency: string,
  code?: string
): Promise<IApiResponse<any | null>> => {
  try {
    const { data, status, statusText } =
      await walletService.performWaveTransfer(
        amount,
        recipientPhoneNumber,
        currency,
        code
      );

    return Promise.resolve({
      data: data,
      error: null,
    });
  } catch (error) {

    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;


        return {
          data: null,
          error: data.errorMessage || data.message || data.statusText,
        };
      } else {
        const message = axiosError.message;
        return {
          data: null,
          error: message,
        };
      }
    } else {
      return {
        data: null,
        error: "An error occurred",
      };
    }
  }
};

const postMobileMoneyTransfer = async (
  amount: string,
  recipientPhoneNumber: string,
  note: string | null,
  code?: string
): Promise<IApiResponse<IDataResponse | null>> => {
  try {
    const { data, status, statusText } =
      await walletService.postMobileMoneyTransfer(
        amount,
        recipientPhoneNumber,
        note,
        code
      );

    return Promise.resolve({
      data: data as IDataResponse,
      error: null,
    });
  } catch (error) {

    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return {
          data: null,
          error: data.errorMessage || data.message || data.statusText,
        };
      } else {
        const message = axiosError.message;
        return {
          data: null,
          error: message,
        };
      }
    } else {
      return {
        data: null,
        error: "An error occurred",
      };
    }
  }
};

const performPayqinTransfer = async (
  amount: string,
  payQinUserId: string,
  note: string | null,
  code?: string
): Promise<IApiResponse<IDataResponse | null>> => {
  try {
    const { data, status, statusText } = await walletService.postPayQinTransfer(
      amount,
      payQinUserId,
      note,
      code
    );

    return Promise.resolve({
      data: data as IDataResponse,
      error: null,
    });
  } catch (error) {

    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return {
          data: null,
          error: data.errorMessage || data.message || data.statusText,
        };
      } else {
        const message = axiosError.message;
        return {
          data: null,
          error: message,
        };
      }
    } else {
      return {
        data: null,
        error: "An error occurred",
      };
    }
  }
};

const completeTopUpBankCard = async (
  cardBankData: ICardBankData,
  pinCode: string,
  suggestedAuth: string
): Promise<IApiResponse<ICompleteTopUpBankCardResponse | null>> => {
  try {
    const { data, status, statusText } =
      await walletService.completeTopUpBankCard(
        cardBankData,
        pinCode,
        suggestedAuth
      );

    return Promise.resolve({
      data: data as ICompleteTopUpBankCardResponse,
      error: null,
    });
  } catch (error) {

    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return {
          data: null,
          error: data.errorMessage || data.message || data.statusText,
        };
      } else {
        const message = axiosError.message;
        return {
          data: null,
          error: message,
        };
      }
    } else {
      return {
        data: null,
        error: "An error occurred",
      };
    }
  }
};

const initializeTopUpBankCard = async (
  amount: number,
  txRef: string
): Promise<IApiResponse<any | null>> => {
  try {
    const { data, status, statusText } =
      await walletService.initializeTopUpBankCard(amount, txRef);

    return Promise.resolve({
      data: data,
      error: null,
    });
  } catch (error) {

    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return {
          data: null,
          error:
            data.errorMessage ||
            data.message ||
            data.statusText ||
            `${status} ${statusText}`,
        };
      } else {
        const message = axiosError.message;
        return {
          data: null,
          error: message,
        };
      }
    } else {
      return {
        data: null,
        error: "An error occurred",
      };
    }
  }
};

const bankTopUpRequest = async (
  amount: number,
  receiptUrl: string
): Promise<IApiResponse<IDataResponse | null>> => {
  try {
    const { data, status, statusText } = await walletService.bankTopUpRequest(
      amount,
      receiptUrl
    );

    return Promise.resolve({
      data: data as IDataResponse,
      error: null,
    });
  } catch (error) {

    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return {
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        };
      } else {
        const message = axiosError.message;
        return {
          data: null,
          error: message,
        };
      }
    } else {
      return {
        data: null,
        error: "An error occurred",
      };
    }
  }
};

const getBalance = async (): Promise<IApiResponse<IWallet | null>> => {
  try {
    const { data, status, statusText } = await walletService.getBalance();

    return Promise.resolve({
      data: data.data as IWallet,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return {
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        };
      } else {
        const message = axiosError.message;
        return {
          data: null,
          error: message,
        };
      }
    } else {
      return {
        data: null,
        error: "An error occurred",
      };
    }
  }
};

const getTransactions = async (params?: {
  page?: number;
  limit?: number;
  fromDate?: string;
  toDate?: string;
  transactionDirection?: string;
}) => {
  try {
    const { data, status, statusText } = await walletService.getTransactions(
      params
    );
    return Promise.resolve({
      data: data as ITransactions,
      error: null,
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const errorData = error.response.data as IDataResponse;

      return {
        data: null,
        error:
          errorData.errorMessage ??
          errorData.statusText ??
          errorData.statusText,
      };
    } else {
      return {
        data: null,
        error: "An error occurred",
      };
    }
  }
};

const postTopUp = async (
  amount: string,
  phone: string,
  providerCode: string
) => {
  try {
    const { data, status, statusText } = await walletService.postTopUp(
      amount,
      phone,
        providerCode
    );
    handleAPICommonResp(data)
    return Promise.resolve({
      data: data as ITopUpResponse,
      error: null,
    });
  } catch (error) {
    return displayError(error)
  }
};

const postTopUpWave = async (
  clientReference: string,
  amount: string,
  currency: string,
  errorUrl?: string,
  successUrl?: string
): Promise<IApiResponse<ITopUpWaveResponse | null>> => {
  try {
    const { data, status, statusText } = await walletService.postTopUpWave(
      clientReference,
      amount,
      currency
    );

    return Promise.resolve({
      data: data as ITopUpWaveResponse,
      error: null,
    });
  } catch (error) {

    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return {
          data: null,
          error: data.errorMessage || data.message || data.statusText,
        };
      } else {
        const message = axiosError.message;
        return {
          data: null,
          error: message,
        };
      }
    } else {
      return {
        data: null,
        error: "An error occurred",
      };
    }
  }
};

const postTopUpFee = async (
  amount: string,
  isTopUp: string,
  phone: string,
  operatorName: string | null
) => {
  try {
    const { data, status, statusText } = await walletService.postTopUpFee(
      amount,
      isTopUp,
      phone,
      operatorName
    );
    return Promise.resolve({
      data: data as IFeeCalculationResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      const axiosResponse: AxiosResponse = axiosError.response!;

      const { data, status, statusText } = axiosResponse;

      return {
        data: null,
        error:
          data.errorMessage ??
          data.statusText ??
          data.message ??
          data.statusText,
      };
    } else {
      return {
        data: null,
        error: "An error occurred",
      };
    }
  }
};

const getMobileMoneyProviders = async (operation: string) => {
  try {
    const { data, status, statusText } =
      await walletService.getMobileMoneyProviders(operation);

    return Promise.resolve({
      data: data as IPaymentMethodResponse,
      error: null,
    });
  } catch (error) {
    return Promise.resolve({
      data: null,
      error: "Une erreur est survenu lors de la recupération des operateurs,",
    });
  }
};

const getWalletsStatistics = async (period: string) => {
  try {
    const { data, status, statusText } = await walletService.walletStatistics(
      period
    );

    return Promise.resolve({
      data: data as IStatisticsResponse,
      error: null,
    });
  } catch (error) {
    return Promise.resolve({
      data: null,
      error: "Une erreur est survenu lors de la recupération des operateurs,",
    });
  }
};

const sendMoneyByAirtime = async (
  amount: string,
  phoneCode: string,
  phoneNumber: string
) => {
  try {
  } catch (error) {
    return Promise.resolve({
      data: null,
      error: "Une erreur est survenu lors de la recupération des operateurs,",
    });
  }
};

export const walletRepository = {
  performWaveTransfer,
  postMobileMoneyTransfer,
  performPayqinTransfer,
  postTopUp,
  postTopUpWave,
  postTopUpFee,
  getMobileMoneyProviders,
  getTransactions,
  getBalance,
  bankTopUpRequest,
  initializeTopUpBankCard,
  completeTopUpBankCard,
  getWalletsStatistics,
};
