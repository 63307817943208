import React from "react";
import {Box, Typography,} from "@mui/material";
import Spacing from "../../../components/Spacing";
import {AddNewCardlinearGradientIcon,} from "../../../shared/_utils/constants/icon";
import RoundGradientButton from "../../../components/RoundGradientButton";
import {t} from "i18next";

interface IOderActivateProps {
    navigateTo: (url: string) => any;
}

const OrderActivateCardPopUpContent = ({navigateTo}: IOderActivateProps) => {
    let handlePhysicalCardButton = (url: string) => {
        return navigateTo(url);
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.content}>
                <Box
                    component={"img"}
                    src={
                        "https://res.cloudinary.com/payqin-ltd/image/upload/v1699461187/web-app/carte_payqin_16_avril_Plan_de_travail_1_vbmrxp.png"
                    }
                    style={{width: "50%"}}
                />
                <Spacing marginBottom={"20px"}/>
                <Typography sx={styles.title}>
                    {t("order")} / {t("activate")} {t("physicalCard")}
                </Typography>
                <Spacing marginBottom={"30px"}/>
                <RoundGradientButton
                    fullWidth
                    startIcon={<AddNewCardlinearGradientIcon/>}
                    onClick={() => handlePhysicalCardButton("/cards/get-started")}
                    variant="contained"
                    color="inherit"
                >
                    <Typography>{t("order")}</Typography>
                </RoundGradientButton>
                <RoundGradientButton
                    fullWidth
                    startIcon={<AddNewCardlinearGradientIcon/>}
                    onClick={() => handlePhysicalCardButton("/card/activate-card")}
                    variant="contained"
                    color="inherit"
                >
                    <Typography>{t("activate")}</Typography>
                </RoundGradientButton>
            </Box>
        </Box>
    );
};

export default OrderActivateCardPopUpContent;

/**
 * @type {import("@mui/material").SxProps}
 */
const styles = {
    button: {
        textTransform: "none",
    },
    underline: {
        textDecoration: "underline",
    },
    note: {
        width: "full",
    },
    availableBalance: {
        border: "1px solid red",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "22px",
        alignItems: "center",
        textAlign: "center",
        color: "#959595",
    },
    amount: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px",
        width: "202px",
        order: 1,
        flexGrow: 0,
    },
    title: {
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "51px",
        alignItems: "center",
        textAlign: "center",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        minWidth: "266px",
        padding: "0px",
        width: {
            sm: "100px",
            md: "400px",
        },
        height: "387px",
        alignItems: "center",
        mr: 2,
        mb: 4,
    },
    container: {
        padding: "0 48px",
    },
    avatar: {
        display: "flex",
        flexDirection: "row",
        width: "64px",
        height: "64px",
    },
    userName: {
        display: "flex",
        flexDirection: "row",
        mt: 1,
    },
};
