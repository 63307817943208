import { Card, CardContent, CardHeader, Skeleton } from "@mui/material";
import React from "react";

const ContactCardLoader = () => {
  return (
    <Card
      sx={{
        cursor: "pointer",
        minWidth: { lg: "266px", md: "200px", sm: "150px" },
        minHeight: { lg: "188px", md: "144px", sm: "110px" },
        mr: 2,
      }}
    >
      <CardHeader
        sx={{ display: "flex", flexDirection: "column" }}
        avatar={
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
        }
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "80%",
          justifyItems: "center",
          margin: "auto",
        }}
      >
        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={10} />
      </CardContent>
    </Card>
  );
};

export default ContactCardLoader;
