import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Avatar,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import { Controller, DeepPartial } from "react-hook-form";
import Box from "@mui/material/Box";
import { IBankTransferData } from "../index";
import { IUseFormProps } from "../../../../../shared/types/IUseFormsProps";
import Spacing from "../../../../../components/Spacing";
import { styled } from "@mui/material/styles";
import { ExchangeIcon } from "../../../../../shared/_utils/constants/icon";
import { accountService } from "../../../../../shared/services";
import { isEmpty } from "../../../../../shared/_utils/isEmpty";
import { IApiResponse } from "../../../../../shared/types/IApiResponse";
import { ITransactionsVirtualCardFees } from "../../../../../shared/types/ITransactionsVirtualCardFees";
import { virtualCardsRepository } from "../../../../Cards/repository/virtualCards.repository";
import { transactionsRepository } from "../../../repository/transactions.repository";
import { IUser } from "../../../../Auth/models/auth.response";
import IRecipient from "../../../../../shared/types/IRecipient";
import { VertoBeneficiary } from "../../../../../shared/types/types";
import { validVertoBeneficiary } from "../../../../Recipients/BankRecipient/Forms/PersonalInfoForm";
import { IPurposeCode } from "../../../../../shared/types/IPurposeCode";
import "./../style.css";
import AmountField from "../../../../../components/AmountField";
import {
  inputStyles,
  rootInputStyles,
  rootStyles,
} from "../../../../../components/KTextField";
import AmountFormatter from "../../../../../shared/_utils/_helpers/AmountFormatter";
import { t } from "i18next";

export interface IBankInfoFormData {
  bankAccountType: "UGBA" | "SOCGEN" | "BDA";
  accountNumber: string;
  addToRecipient?: boolean;
}

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#C7C7C7",
    backgroundColor: "#C7C7C7",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#C7C7C7",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#C7C7C7",
    },
    "&:hover fieldset": {
      borderColor: "#C7C7C7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#C7C7C7",
    },
  },
});

const AmountForm: React.FC<IUseFormProps<IBankTransferData>> = ({
  register,
  isValid,
  errors,
  control,
  setValue,
}) => {
  const [recipient, setRecipient] = useState<DeepPartial<IRecipient>>();
  const [currencyFrom, setCurrencyFrom] = useState<string>();
  const [currencyTo, setCurrencyTo] = useState<string>();
  const [countryTo, setCountryTo] = useState<string>();
  const [rate, setRate] = useState<string>();
  const [receiveAmount, setReceiveAmount] = useState(false);
  const [sendAmount, setSendAmount] = useState(false);
  const [fee, setFee] = useState<string | null>(null);
  const [amount, setAmount] = useState<string>();
  const [convertedAmount, setConvertedAmount] = useState<string>("");
  const [loadingFeeCalculation, setLoadingFeeCalculation] = useState<boolean>();
  const [errorLoadingFeeCalculation, setErrorLoadingFeeCalculation] = useState<
    string | null
  >(null);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [selectedPurposeCode, setSelectedPurposeCode] = useState<string>("");
  const [purposeCodes, setPurposeCodes] = useState<IPurposeCode[]>([]);
  const [purposeCodesError, setPurposeCodesError] = useState<string>();

  useEffect(() => {
    const getSettings = async () => {
      const user = await accountService.getUser();
      if (user) setCurrencyFrom(user.currency);
    };

    getSettings().then((r) => {});
    const data = control._defaultValues.recipient;

    if(amount==""){
      setReceiveAmount(false)
      setSendAmount(false)
    }


    if (data) setRecipient(data);
    setCurrencyTo(data?.currency);
    setCountryTo(data?.country);

    transactionsRepository
      .getPurposeCodes()
      .then((r) => {
        const data = r.data;
        if (data && data.success) setPurposeCodes(data.purposes);
        else if (r.error) setPurposeCodesError(r.error);
      })
      .finally();
  }, []);

  const calculateFees = async (amount: string): Promise<void> => {
    setReceiveAmount(true)
    if (isEmpty(amount)) {
      setFee(null);
      setConvertedAmount("");
      return;
    }

    setAmount(amount);
    setConvertedAmount("");
    setLoadingFeeCalculation(true);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(async () => {
      if (!currencyFrom || !currencyTo || !countryTo) return;
      const res = await transactionsRepository.getBankTransferFees(
        amount,
        currencyFrom,
        currencyTo,
        countryTo
      );
      const data = res.data;

      if (null !== data) {
        setErrorLoadingFeeCalculation(null);
        setConvertedAmount(data.amount);
        setFee(data.fee);
        setRate(data.rate);
        setValue("amount", amount);
        setValue("rate", data.rate);
        setValue("fee", data.fee);
        setValue("amountToReceive", data.amount);
      } else {
        if (res.error) setErrorLoadingFeeCalculation(res.error);
      }
      setLoadingFeeCalculation(false);
    }, 3000);

    setTimer(newTimer);
  };

  const addFeesToAmount = async (amount: string): Promise<void> => {
    setSendAmount(true)
    if (isEmpty(amount)) {
      setFee(null);
      setConvertedAmount("");
      return;
    }

    setAmount(amount);
    setConvertedAmount("");
    setLoadingFeeCalculation(true);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(async () => {
      if (!currencyFrom || !currencyTo || !countryTo) return;
      const res = await transactionsRepository.getBankTransferFeesFromDest(
        amount,
        currencyFrom,
        currencyTo,
        countryTo
      );
      const data = res.data;

      if (null !== data) {
        setErrorLoadingFeeCalculation(null);
        setConvertedAmount(data.amount);
        setFee(data.fee);
        setRate(data.rate);
        setValue("amount", data.amount);
        setValue("rate", data.rate);
        setValue("fee", data.fee);
        setValue("amountToReceive", amount);
      } else {
        if (res.error) setErrorLoadingFeeCalculation(res.error);
      }
      setLoadingFeeCalculation(false);
    }, 3000);

    setTimer(newTimer);
  };

  const handlePurposeCodesChange = (event: SelectChangeEvent) => {
    const purposeCodeId = event.target.value;

    const purposeCode = purposeCodes.find(
      (purposeCode) => purposeCode.id == +purposeCodeId
    );
    if (purposeCode) {
      setSelectedPurposeCode(purposeCodeId);
      setValue("note", purposeCode.id);
      setValue("noteTitle", purposeCode.title);
    }
  };

  return (
    <Box sx={{ p: 1 }}>
      <Spacing marginBottom={"10px"} />

      <Box sx={styles.infos}>
        <Avatar sx={styles.avatar} alt="Masoud" src={""} />
        <Typography sx={styles.userName}>
          {recipient?.companyName
            ? recipient?.companyName
            : `${recipient?.lastName} ${recipient?.firstName}`}
        </Typography>
        <Typography sx={styles.label}>{recipient?.bankName}</Typography>
      </Box>
      <Spacing marginBottom={"50px"} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={styles.label}>{t("recipients.youSend")}</Typography>
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            setSendAmount(false);
            setReceiveAmount(true);
            setAmount("");
            setConvertedAmount("");
          }}
        >
          <Typography sx={styles.label}>{t("recipients.fillamount")}</Typography>
        </Link>
      </Box>
      {!sendAmount && <Box sx={{ m: 1 }}>
        <AmountField
          currency={currencyFrom}
          fullWidth={true}
          onChangeAmount={calculateFees}
          placeholder="Saisir le montant à envoyer en"
        />
      </Box>}
      {errorLoadingFeeCalculation && !sendAmount &&  (
        <span
          style={{
            color: "#F94144",
            fontSize: "1rem",
            fontWeight: 400,
          }}
        >
          {errorLoadingFeeCalculation}
        </span>
      )}
      {errors.amount && !sendAmount && (
        <span
          style={{
            color: "#F94144",
            fontSize: "1rem",
            fontWeight: 400,
          }}
        >
          {errors.amount.message}
        </span>
      )}
      {sendAmount && <Controller
        name="amount"
        control={control}
        defaultValue={convertedAmount || ""}
        shouldUnregister={false}
        render={({ field, fieldState }) => (
          <div>
            <TextField
              fullWidth
              disabled={true}
              value={AmountFormatter(convertedAmount).formattedAmount}
              sx={{
                ...{
                  input: {
                    color: "#959595",

                    fontWeight: 700,
                  },
                  "& fieldset": { border: "solid #C7C7C7 1px" },
                },
                ...rootStyles,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                    <Typography
                      sx={{
                        color: "#959595",
                        fontSize: ".875em",
                        fontWeight: 700,
                      }}
                    >
                      {currencyFrom}
                    </Typography>
                  </InputAdornment>
                ),
                ...rootInputStyles,
                ...{
                  border: "none",
                },
              }}
              inputProps={{
                sx: {
                  ...inputStyles,
                },
              }}
              placeholder="0.00"
            />
          </div>
        )}
      />}

      <Spacing marginBottom={"20px"} />
      <Grid
        container
        spacing={2}
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Grid item xs={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Divider
              orientation="vertical"
              style={{
                backgroundColor: "#4A90E2",
                height: "240px",
                width: "2px",
              }}
            />
            <Box
              sx={{
                position: "relative",
                left: "-20px",
              }}
            >
              <div className={loadingFeeCalculation ? "rotate" : ""}>
                <ExchangeIcon height={40} width={40} />
              </div>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Box
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "12px",
              border: "solid 2px #4A90E2",
              p: 2,
              alignItems:"center",
              justifyContent:"center",
              textAlign:"center"
            }}
            className={loadingFeeCalculation ? "loading" : ""}
          >
            <Typography sx={{ fontSize: "13px" }}>
              {t("recipients.rateMarket")
                .replace("<:currencyFrom>", `${currencyFrom}`)
                .replace("<:currencyTo>", `${currencyTo}`)}
            </Typography>
            <Spacing marginBottom={"5px"} />
            <Typography>
              1 {currencyFrom} = {rate} {currencyTo}
            </Typography>
            <Spacing marginBottom={"5px"} />

            <Spacing marginBottom={"5px"} />
            <Table style={{ border: "none", padding: "0px" }}>
              <TableRow key="Total Fee">
                
                {fee !== null ? (
                  <TableCell align="center" sx={styles.feesAmount}>
                    {t("recipients.totalFee")}   :
                    {fee} {currencyFrom}
                  </TableCell>
                ) : (
                  <TableCell align="center" sx={styles.feesAmount}>
                    {t("recipients.totalFee")}    :
                    ../..
                  </TableCell>
                )}
              </TableRow>
            </Table>
          </Box>
        </Grid>
      </Grid>
      <Spacing marginBottom={"20px"} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography sx={styles.label}>{t("recipients.recipientGets")}</Typography>
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            setSendAmount(true);
            setReceiveAmount(false);
            setAmount("");
            setConvertedAmount("");
          }}
        >
          <Typography sx={styles.label}>{t("recipients.fillamount")}</Typography>
        </Link>
      </Box>
      {!receiveAmount && <Box sx={{ m: 1 }}>
        <AmountField
          currency={currencyTo}
          fullWidth={true}
          onChangeAmount={addFeesToAmount}
          placeholder="Saisir le montant à recevoir en"
        />
      </Box>}
      {errorLoadingFeeCalculation && !receiveAmount && (
        <span
          style={{
            color: "#F94144",
            fontSize: "1rem",
            fontWeight: 400,
          }}
        >
          {errorLoadingFeeCalculation}
        </span>
      )}
      {errors.amount && !receiveAmount && (
        <span
          style={{
            color: "#F94144",
            fontSize: "1rem",
            fontWeight: 400,
          }}
        >
          {errors.amount.message}
        </span>
      )}

      {receiveAmount && <Controller
        name="amountToReceive"
        control={control}
        defaultValue={convertedAmount || ""}
        shouldUnregister={false}
        render={({ field, fieldState }) => (
          <div>
            <TextField
              fullWidth
              disabled={true}
              value={AmountFormatter(convertedAmount).formattedAmount}
              sx={{
                ...{
                  input: {
                    color: "#959595",

                    fontWeight: 700,
                  },
                  "& fieldset": { border: "solid #C7C7C7 1px" },
                },
                ...rootStyles,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                    <Typography
                      sx={{
                        color: "#959595",
                        fontSize: ".875em",
                        fontWeight: 700,
                      }}
                    >
                      {recipient?.currency}
                    </Typography>
                  </InputAdornment>
                ),
                ...rootInputStyles,
                ...{
                  border: "none",
                },
              }}
              inputProps={{
                sx: {
                  ...inputStyles,
                },
              }}
              placeholder="0.00"
            />
          </div>
        )}
      />}
      <Spacing marginBottom={"50px"} />
      <Typography sx={styles.label}>
        {t("recipients.transferReason")} <span style={{ color: "red" }}>*</span>
      </Typography>
      <FormControl fullWidth>
        <Controller
          name="note"
          control={control}
          shouldUnregister={false}
          rules={{ required: "Veuillez sélectionner une raison du transfert" }}
          render={({ field }) => (
            <Select
              {...field}
              {...register("note")}
              value={selectedPurposeCode}
              onChange={handlePurposeCodesChange}
              sx={{
                sx: {
                  ...rootStyles,
                  ...{
                    height: "57px",
                    borderRadius: "4px",
                  },
                },
              }}
              inputProps={{
                sx: { ...inputStyles },
              }}
            >
              {purposeCodes.map((purposeCode) => (
                <MenuItem key={purposeCode.id} value={purposeCode.id}>
                  <Typography>{purposeCode.title}</Typography>
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      {errors.note && (
        <span
          style={{
            color: "#F94144",
            fontSize: "1rem",
            fontWeight: 400,
          }}
        >
          {errors.note.message}
        </span>
      )}
    </Box>
  );
};

export default AmountForm;

/** @type{import("@mui/material").SxProps}*/
const styles = {
  feesAmount: { fontSize: "16px", border: "none", textAlign: "center"},
  feesLabel: {
    fontSize: "16px",
    color: "#959595",
    border: "none",
    padding: "0px",
  },
  note: {
    // width: '400px',
    backgroundColor: "#F9F9F9",
    borderRadius: "8px",
    fontWeight: 500,
    fontSize: "13px",
  },
  availableBalance: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "22px",
    alignItems: "center",
    textAlign: "center",
    color: "#959595",
  },
  amount: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // order: 1,
    // flexGrow: 0,
  },
  balance: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "26px",
    lineHeight: "51px",
    alignItems: "center",
    textAlign: "center",
    color: "#959595",
  },
  infos: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 0px",
    gap: "8px",
    width: "auto",
    height: "134px",
    // background: '#FFFFFF',
    borderRadius: "8px",
    justifyItems: "center",
    alignContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    minWidth: "266px",
    // padding: '0px',
    width: {
      sm: "100px",
      md: "400px",
    },
    // height: '387px',
    alignItems: "center",
    // mr: 2,
    mb: 4,
  },
  container: {
    padding: "0 48px",
  },
  avatar: {
    display: "flex",
    flexDirection: "row",
    width: "64px",
    height: "64px",
  },
  userName: {
    display: "flex",
    flexDirection: "row",
    mt: 1,
  },
  titleField: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#000000",
    marginBottom: 5,
  },
  input: { backgroundColor: "rgb(249, 249, 249)", borderRadius: "8px" },
  label: {
    fontWeight: 500,
    fontSize: "13px",
    color: "#000",
    marginBottom: "1%",
  },
};
