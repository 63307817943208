import React, { createContext, useContext, useState } from 'react';

interface SidebarContextType {
    isVisible: boolean;
    toggleSidebar: () => void;
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const SidebarProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [isVisible, setIsVisible] = useState(true);

    const toggleSidebar = () => {
        setIsVisible(!isVisible);
    };

    return (
        <SidebarContext.Provider value={{ isVisible, toggleSidebar }}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebarContext = (): SidebarContextType => {
    const context = useContext(SidebarContext);

    if (!context) {
        throw new Error('SidebarContext return undefined');
    }

    return context;
};
