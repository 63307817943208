import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  Slide,
  Table,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { EditNoteOutlined, UploadFile } from "@mui/icons-material";
import "./style.css";
import Popup from "../../../../../components/Popup";
import Spacing from "../../../../../components/Spacing";
import { accountService } from "../../../../../shared/services";
import { walletRepository } from "../../../repository/wallet.repository";
import RoundGradientButton from "../../../../../components/RoundGradientButton";
import { HiddenInput } from "../../../../../components/HiddenInput";
import { Label } from "../../../../../components/Label";
import { cloudinaryService } from "../../../../../shared/services/cloudinary.service";
import { IUploadFile } from "../../../../../shared/types/IUploadFile";
import { useNavigate } from "react-router-dom";
import HelpButton from "../components/HelpButton";
import { CloseIcon } from "../../../../../shared/_utils/constants/icon";
import { TransitionProps } from "@mui/material/transitions";
import howMakeBankDepositImg from "./../../../../../assets/how_make_bank_deposit.png";
import AmountField from "../../../../../components/AmountField";
import { t } from "i18next";
import {isEmpty} from "../../../../../shared/_utils/isEmpty";
import { uploadRepository } from "src/shared/repository/upload.repository";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface ITopUpBankDepositForm {
  amount: string;
  document: File;
}

const BankDeposit = () => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    getValues,
    setError,
    clearErrors,
  } = useForm<ITopUpBankDepositForm>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [popup, setPopup] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const navigate = useNavigate();
  const [content, setContent] = useState<JSX.Element | null>(null);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [bank, setBank] = useState<string>("");
  const [payqinAccountNumber, setPayqinAccountNumber] = useState<string>("");
  const [iban, setIban] = useState<string>("");
  const [swiftCode, setSwiftCode] = useState<string>("");

  const img = "./";

  const numericRegExp = /^\d+$/;

  useEffect(() => {
    const user = accountService.getUser();
    if (!user) return;
    const country = user.country.replace(/\s/g, "");
    if ("CM" === country) {
      setBank("UBA");
      setPayqinAccountNumber("PayQin Cameroon");
      setIban("CM21 10033 05207 07011001110 37");
      setSwiftCode("UNAFCMCX");
    } else if ("SN" === country) {
      setBank("ECOBANK");
      setPayqinAccountNumber("PAYQIN SENEGAL SASU");
      setIban("SN094 01001 101578305001 25");
      setSwiftCode("ECOCSNDA");
    } else {
      setBank("ECOBANK");
      setPayqinAccountNumber("PayQin Cote d’Ivoire");
      setIban("CI93 CI05 9010 2612 1496 5560 0166");
      setSwiftCode("ECOCCIAB");
    }

    const isHidden = localStorage.getItem("dontShowAgain");
    if (isHidden === "true") {
      setDontShowAgain(true);
      setIsDialogOpen(false);
    } else {
      setIsDialogOpen(false);
      setIsDialogOpen(true);
    }
    const phone = user.phone;
    setPhoneNumber(phone);
  }, []);

  const uploadReceipt = async (file: File): Promise<string> => {
    const uploadFile: IUploadFile = {
      file: file,
      folder: "retailers",
    };

    const response: any = await uploadRepository.uploadFile(uploadFile);
    return response?.data?.secure_url;
  };

  const validateAmount = (amount: string) => {
    if (!amount) return false;

    return numericRegExp.test(amount);
  }

  const handleTopUpForm: SubmitHandler<ITopUpBankDepositForm> = async (
    topUpBankDepositData
  ) => {
    if (!topUpBankDepositData.document) {
      setError("document", {
        type: "custom",
        message: t("topupScreen.documentsRequired"),
      });
      return;
    }

    if (!validateAmount(topUpBankDepositData.amount)) {
      setError("amount", {
        type: "custom",
        message: t("incorrectAmount"),
      });
      return;
    }

    setIsSubmitting(true);


    const receiptUrl = await uploadReceipt(topUpBankDepositData.document);

    if(receiptUrl){
      const res = await walletRepository.bankTopUpRequest(
        +topUpBankDepositData.amount,
        receiptUrl
      );
      const data = res.data;
      setIsSubmitting(false);

      if (data && data.success) {
        setPopup(true);
        setContent(
          <Typography
            sx={{
              padding: {
                sx: "5 2",
                sm: 5,
              },
              display: "flex",
              justifyContent: "center",
              flexWrap: {
                xs: "wrap",
                sm: "noWrap",
              },
              m: 1,
            }}
          >
            {data.message}
          </Typography>
        );
      } else {
        const msg = data?.message || data?.errorMessage || res.error || "";
        setError("amount", { type: "custom", message: msg });
      }
    
    }else{
      setError("document", {
        type: "custom",
        message: t("errorFile"),
      });
      setIsSubmitting(false)
      return;
  }
    



    
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      clearErrors("document");
      setValue("document", selectedFile);
      setSelectedFileName(selectedFile.name);
    }
  };

  const handleUserConfirmation = (confirmed: boolean) => {
      navigate("/wallet");
  };

  const handleCloseDialog = () => {
    if (dontShowAgain) {
      localStorage.setItem("dontShowAgain", "true");
    }
    setIsDialogOpen(false);
  };

  const handleHelpButton = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleAmount = (amount: string) => {
    setValue("amount", amount);
  };

  return (
    <Box>
      <Box sx={styles.container}>
        <Typography
          sx={{ textAlign: "center", fontSize: "22px", fontWeight: "bold" }}
        >
          {t("bankDepositTitle")}
        </Typography>
        <Box m={2} p={2}>
          <Box className={"phoneNumber"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              >
                {phoneNumber}
              </Typography>
            </Box>
            <EditNoteOutlined fontSize="medium" />
          </Box>

          <Spacing marginBottom={"50px"} />
          <Box component={"form"} onSubmit={handleSubmit(handleTopUpForm)}>
            <Box sx={styles.voucher}>
              <AmountField
                currency={"XOF"}
                fullWidth={true}
                onChangeAmount={handleAmount}
              />

              {errors.amount && (
                <span
                  style={{
                    color: "#F94144",
                    fontSize: "1rem",
                    fontWeight: 400,
                  }}
                >
                  {errors.amount.message}
                </span>
              )}
            </Box>

            <Spacing marginBottom={"20px"} />

            <Box>
              <Button
                style={{
                  border: "1px dashed rgb(170, 170, 170)",
                  borderRadius: "4px",
                  padding: 20,
                  backgroundColor: "rgba(63, 81, 181, 0.04)",
                }}
                color={"primary"}
                fullWidth
                component="label"
                startIcon={<UploadFile />}
                sx={{ marginRight: "1rem" }}
              >
                <Label>
                  {selectedFileName
                    ? selectedFileName
                    : t("topupScreen.clickToSendDocuments")}
                </Label>

                <HiddenInput
                  onChange={(e) => handleFileUpload(e)}
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                />
                {errors.document && (
                  <span
                    style={{
                      color: "#F94144",
                      fontSize: "1rem",
                      fontWeight: 400,
                    }}
                  >
                    {errors.document.message}
                  </span>
                )}
              </Button>
              <HelpButton onClick={handleHelpButton} />
            </Box>

            <Spacing marginBottom={"50px"} />
            <RoundGradientButton
              disabled={isSubmitting}
              variant="contained"
              color="inherit"
              type="submit"
              fullWidth
              sx={{
                height: "48px",
                background: isSubmitting
                  ? "linear-gradient(to right, #C471ED, #4A90ED)"
                  : "",
                color: isSubmitting ? "#FFFFFF" : "",
                mt: 3,
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                textTransform: "none",
              }}
            >
              {isSubmitting ? (
                <>
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "#FFFFFF",
                      marginRight: "5%",
                    }}
                  />
                  {t("topupScreen.sendDemandLoading")}...
                </>
              ) : (
                t("topupScreen.sendDemandLabel")
              )}
            </RoundGradientButton>
          </Box>
        </Box>
      </Box>

      {content && (
        <Popup open={popup} onClose={() => handleUserConfirmation(true)}>
          {content}
        </Popup>
      )}

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            maxHeight: "50%",
            width: "100%",
            position: "fixed",
            bottom: "0px",
            right: "0px",
            borderRadius: "40px",
            backdropFilter: "blur(5px)",
            transition: "backdrop-filter 0.3s ease-in-out",
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        sx={{}}
      >
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "700",
                fontSize: "20px",
              }}
            >
              {t("topupScreen.modalTitle")}
            </Typography>
            <Box onClick={handleCloseDialog} sx={{ cursor: "pointer" }}>
              <CloseIcon />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{}}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <DialogContentText>
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    fontWeight: "400",
                    fontSize: "1rem",
                    fontStyle: "italic",
                  }}
                >
                  {t("topupScreen.importantMessage")}
                </Typography>
                <br />
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    fontWeight: "500",
                  }}
                >
                  <span style={{ fontWeight: "700" }}>1- </span>{" "}
                  {t("topupScreen.topupStep1").replace("<:bank>", bank)}
                </Typography>
                <Box ml={2}>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                        margin: "0px",
                        padding: 0.5,
                      },
                    }}
                  >
                    <TableRow key="PayQin Fee">
                      <TableCell component="th" scope="row">
                        {t("topupScreen.accountName")}
                      </TableCell>
                      <TableCell>:</TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "700",
                          fontSize: "1rem",
                          color: "#000000",
                        }}
                      >
                        {payqinAccountNumber}
                      </TableCell>
                    </TableRow>
                    <TableRow key="Bank Charges">
                      <TableCell component="th" scope="row">
                        IBAN
                      </TableCell>
                      <TableCell>:</TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "700",
                          fontSize: "1rem",
                          color: "#000000",
                        }}
                      >
                        {iban}
                      </TableCell>
                    </TableRow>
                    <TableRow key="Total Fee">
                      <TableCell scope="row">SWIFT</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "700",
                          fontSize: "1rem",
                          color: "#000000",
                        }}
                      >
                        {swiftCode}
                      </TableCell>
                    </TableRow>
                  </Table>
                </Box>

                <br />
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    fontWeight: "500",
                  }}
                >
                  <span style={{ fontWeight: "700" }}>2- </span>{" "}
                  {t("topupScreen.topupstep2")}
                </Typography>

                <br />
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    fontWeight: "500",
                  }}
                >
                  <span style={{ fontWeight: "700" }}>3- </span>{" "}
                  {t("topupScreen.topupstep3")}
                </Typography>
              </DialogContentText>
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dontShowAgain}
                    onChange={() => setDontShowAgain(!dontShowAgain)}
                    color="primary"
                  />
                }
                label={t("dontShowAgain")}
              />
            </Box>
            <Box>
              <img src="https://res.cloudinary.com/payqin-ltd/image/upload/v1699474022/web-app/how_make_bank_deposit_aqe9fw.png" width={"100%"} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <RoundGradientButton type="submit" onClick={handleCloseDialog}>
            Ok
          </RoundGradientButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BankDeposit;

/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
  voucher: {
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: {
      sm: "70%",
      md: "60%",
      lg: "40%",
    },
    margin: "auto",
  },
};
