import { httpService } from "./http.service";

let postPayQinTransfer = (
  amount: string,
  payQinUserId: string,
  note: string | null,
  code?: string | null
) => {
  const body = {
    amount: amount,
    recipient: payQinUserId,
    message: note,
    code,
  };
  return httpService.Axios.post("wallets/web-app/transfer", body);
};

export const transferService = {
  postPayQinTransfer,
};
