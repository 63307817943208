import {virtualCardsService} from "../../../shared/services/virtualsCards.service";
import {
    IActivateCardResponse,
    ICitiesByCountryResponse,
    IOrderPhysicalCardResponse,
    IPhysicalCardResponse,
    ISupportedCountriesResponse,
    ITransactionsResponse
} from "../models/physicalCard.response";
import {physicalCardService} from "../../../shared/services/physicalCard.service";
import {IOrderPhysicalCardForm} from "../OrderPhysicalCard/CompleteForm";
import {IActivationDataPhysicalCardForm} from "../ActivatePhysicalCard/CompleteForm";
import {ITransactions} from "../../Wallet/models/transactionsResponse";
import {IApiResponse} from "../../../shared/types/IApiResponse";
import {ITopUpPhysicalCardFees} from "../../../shared/types/ITopUpPhysicalCardFees";
import {AxiosError, AxiosResponse, isAxiosError} from "axios";
import {IDataResponse} from "../../../shared/types/IDataResponse";
import {cardsService} from "../../../shared/services";

const getRetailersLocations = async (): Promise<IApiResponse<any|null>> => {
    try {
        const {data, status, statusText} = await cardsService.getRetailersLocations();

        return Promise.resolve({
            data: data,
            error: null,
        });
    } catch(error) {
        if (isAxiosError(error)) {
            const axiosError = error as AxiosError;
            const axiosResponse: AxiosResponse = axiosError.response!;

            const {data, status, statusText} = axiosResponse;

            return {
                data: null,
                error: data.errorMessage || data.statusText || data.message,
            };
        } else {
            return {
                data: null,
                error: 'An error occurred',
            };
        }
    }
}

export const cardsRepository = {getRetailersLocations}