import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  PaperProps,
  Typography,
} from "@mui/material";
import { CloseIcon, TopUpIsFilledFalse } from "../shared/_utils/constants/icon";
import { DialogFunction } from "../shared/types/types";

interface PopupProps {
  additionalStyle?: React.CSSProperties;
  open: boolean;
  onClose?: DialogFunction;
  title?: string | JSX.Element;
  children?: React.ReactNode;
  handleDialogAfterSuccess?: DialogFunction;
}

const Popup = (props: PopupProps) => {
  const close = () => {
    if (onClose) {
      onClose(false);
    } else if (handleDialogAfterSuccess) {
      handleDialogAfterSuccess(true);
    } else {
      kHandleClose();
    }
  };

  const kHandleClose = () => {
    if (onClose) {
      onClose(false);
    }
  };

  const {
    open,
    onClose,
    title,
    children,
    additionalStyle,
    handleDialogAfterSuccess,
  } = props;
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: { borderRadius: "8px", bgcolor: "#F9F9F9", ...additionalStyle },
      }}
    >
      <DialogTitle>
        <Box
          onClick={close}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CloseIcon />
        </Box>

        {title && (
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "700",
              fontSize: "20px",
            }}
          >
            {title}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
