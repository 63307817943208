import { Navigate } from "react-router-dom";
import {accountService} from "../../services";
import React, {JSX} from "react";

interface AuthGuardProps {
    children: JSX.Element
}
const AuthGuard: React.FC<AuthGuardProps> = ({children}) => {

    if(!accountService.isLogged()){
        return <Navigate to="/login"/>
    }
   
    return children
};

export default AuthGuard;