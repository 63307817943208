import axios, { AxiosError } from "axios";
import { accountService } from "./account.service";
import i18n from "../../i18n";
import appVersion from "../../config/appVersion";
import i18next from "i18next";

const baseUrl = process.env.REACT_APP_API_URL;

const Axios = axios.create({
  baseURL: `${baseUrl}/api/`,
});

Axios.interceptors.request.use(async (request) => {
  request.headers["Accept-Language"] = i18next.language;
  request.headers["version"] = appVersion;
  request.headers["mode"] = "no-cors";

  if (accountService.isLogged()) {
    request.headers.Authorization = "Bearer " + accountService.getToken();
  }

  return request;
});

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      accountService.logout();
      window.location.assign("login");
    } else {
      return Promise.reject(error);
    }
  }
);

export const httpService = { Axios };
