import { IApiResponse } from "../../../shared/types/IApiResponse";
import { AxiosError, AxiosResponse, isAxiosError } from "axios";
import { IDataResponse } from "../../../shared/types/IDataResponse";
import { transactionService, walletService } from "../../../shared/services";
import {
  IOperationVerifCodeResponse,
  IPurposeCodeResponse,
} from "../../../shared/types/IPurposeCode";
import { IBankTransferDto } from "../Transfer/bank";

interface IBankTransferFeesResponse {
  rate: string;
  fee: string;
  amount: string;
}

const postTransfer = async (
  bankTransferDto: IBankTransferDto
): Promise<IApiResponse<IDataResponse | null>> => {
  try {
    const { data, status, statusText } = await transactionService.postTransfer(
      bankTransferDto
    );


    return Promise.resolve({
      data: data as IDataResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return Promise.resolve({
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        });
      } else {
        const message = axiosError.message;
        return Promise.resolve({
          data: null,
          error: message,
        });
      }
    } else {
      return Promise.resolve({
        data: null,
        error: "An error occurred",
      });
    }
  }
};

const getPurposeCodes = async (): Promise<
  IApiResponse<IPurposeCodeResponse | null>
> => {
  try {
    const { data, status, statusText } =
      await transactionService.getPurposeCodes();

    return Promise.resolve({
      data: data as IPurposeCodeResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return Promise.resolve({
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        });
      } else {
        const message = axiosError.message;
        return Promise.resolve({
          data: null,
          error: message,
        });
      }
    } else {
      return Promise.resolve({
        data: null,
        error: "An error occurred",
      });
    }
  }
};

const getBankTransferFees = async (
  amount: string,
  currencyFrom: string,
  currencyTo: string,
  countryTo: string
): Promise<IApiResponse<IBankTransferFeesResponse | null>> => {
  try {
    const { data, status, statusText } =
      await walletService.getBankTransferFees(
        amount,
        currencyFrom,
        currencyTo,
        countryTo
      );

    return Promise.resolve({
      data: data as IBankTransferFeesResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return Promise.resolve({
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        });
      } else {
        const message = axiosError.message;
        return Promise.resolve({
          data: null,
          error: message,
        });
      }
    } else {
      return Promise.resolve({
        data: null,
        error: "An error occurred",
      });
    }
  }
};


const getBankTransferFeesFromDest = async (
  withFeeAmount: string,
  currencyFrom: string,
  currencyTo: string,
  countryTo: string
): Promise<IApiResponse<IBankTransferFeesResponse | null>> => {
  try {
    const { data, status, statusText } =
      await walletService.getBankTransferFeesFromDest(
        withFeeAmount,
        currencyFrom,
        currencyTo,
        countryTo
      );

    return Promise.resolve({
      data: data as IBankTransferFeesResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return Promise.resolve({
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        });
      } else {
        const message = axiosError.message;
        return Promise.resolve({
          data: null,
          error: message,
        });
      }
    } else {
      return Promise.resolve({
        data: null,
        error: "An error occurred",
      });
    }
  }
};


const getOperationVerificationCode = async (): Promise<
  IApiResponse<IOperationVerifCodeResponse | null>
> => {
  try {
    const { data, status, statusText } =
      await transactionService.getOperationVerificationCode();

    return Promise.resolve({
      data: data as IOperationVerifCodeResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;

        return Promise.resolve({
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        });
      } else {
        const message = axiosError.message;
        return Promise.resolve({
          data: null,
          error: message,
        });
      }
    } else {
      return Promise.resolve({
        data: null,
        error: "An error occurred",
      });
    }
  }
};

export const transactionsRepository = {
  getBankTransferFees,
  getBankTransferFeesFromDest,
  getPurposeCodes,
  postTransfer,
  getOperationVerificationCode,
};
