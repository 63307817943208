import {businessService} from "../../shared/services";
import {AxiosError, AxiosResponse, isAxiosError} from "axios";
import {IBusinessRequestDto} from "./Request";
import {IDataResponse} from "../../shared/types/IDataResponse";
import {IApiResponse} from "../../shared/types/IApiResponse";
import {displayError, handleAPICommonResp} from "../../shared/_utils/_helpers/error";

const checkPendingRetailerRequest = async (): Promise<IApiResponse<any | null>> => {
    try {
        const {data, status, statusText} = await businessService.checkPendingRetailerRequest();
        handleAPICommonResp(data)
        return Promise.resolve({
            data: data as IDataResponse,
            error: null,
        });
    } catch (error) {
        return displayError(error)
    }
}

const accountVerification = async (): Promise<IApiResponse<IDataResponse | null>> => {
    try {
        const {data, status, statusText} = await businessService.accountVerification();
        handleAPICommonResp(data)
        return Promise.resolve({
            data: data as IDataResponse,
            error: null,
        });
    } catch (error) {
        return displayError(error)
    }
}

const registrationRequest = async (businessRequestDto: IBusinessRequestDto): Promise<IApiResponse<IDataResponse | null>> => {
    try {
        const {data, status, statusText} = await businessService.registrationRequest(businessRequestDto);
        handleAPICommonResp(data)
        return Promise.resolve({
            data: data as IDataResponse,
            error: null,
        });
    } catch (error) {
        return displayError(error)
    }
}

export const businessRepository = {checkPendingRetailerRequest, accountVerification ,registrationRequest}