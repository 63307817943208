import React from "react";
import { CircularProgress, makeStyles } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  "& .MuiCircularProgress-circle": {
    stroke: `url(#linearColors)`,
  },
}));

export default function GradientCircularProgress({size= 40}) {
  return (
    <>
      <svg width="1" height="1">
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stopColor="#C471ED" />
          <stop offset="100%" stopColor="#4A90ED" />
        </linearGradient>
      </svg>
      <StyledCircularProgress size={size} thickness={4} />
    </>
  );
}
