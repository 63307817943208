import React, { useState } from "react";
import Spacing from "../../../components/Spacing";
import {
  Avatar,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  SelectChangeEvent,
  tableCellClasses,
  Typography,
} from "@mui/material";
import BpRadio from "../../Recipients/Common/BpRadio";
import { TransitionGroup } from "react-transition-group";
import KTextField from "../../../components/KTextField";
import Box from "@mui/material/Box";
import { SubmitHandler, useForm } from "react-hook-form";
import RoundGradientButton from "../../../components/RoundGradientButton";
import CircularProgress from "@mui/material/CircularProgress";
import Popup from "../../../components/Popup";
import i18n from "../../../i18n";
import { useNavigate } from "react-router-dom";
import { CardBrand, DialogFunction } from "../../../shared/types/types";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { PhysicalCardHelpers } from "../../../shared/_utils/_helpers/PhysicalCard";
import Table from "@mui/material/Table";
import { t } from "i18next";

interface IVirtualCardForm {
  brand: string;
  code: string;
}

export const validCardBrand: CardBrand[] = ["visa_card", "master_card"];

const visaCard = process.env.PUBLIC_URL + "/visaCard.png";
const masterCard = process.env.PUBLIC_URL + "/masterCard.png";

const Row = (title: string, description: string) => {
  return (
    <TableRow key="PayQin Fee">
      <TableCell component="th" scope="row">
        {title}
      </TableCell>
      <TableCell
        sx={{
          fontWeight: "700",
          fontSize: "1rem",
        }}
      >
        {description}
      </TableCell>
    </TableRow>
  );
};

const VirtualCard = () => {
  const [selectedBrand, setSelectedBrand] = useState<CardBrand>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [popup, setPopup] = useState<boolean>(false);
  const [handleCloseDialog, setHandleCloseDialog] =
    useState<DialogFunction>(null);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    setError,
    getValues,
    clearErrors,
  } = useForm<IVirtualCardForm>();

  const handleVertoBeneficiaryChange = (event: SelectChangeEvent) => {
    const cardBrand = event.target.value as CardBrand;
    if (validCardBrand.includes(cardBrand)) setSelectedBrand(cardBrand);
  };

  const submitForm: SubmitHandler<IVirtualCardForm> = async (
    virtualCardData
  ) => {
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{ p: 2 }}
        component={"form"}
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            fontSize: "1.25rem",
            fontWeight: 700,
          }}
        >
          {t("virtualsCards.creationTitle")}
        </Typography>
        <Spacing marginBottom={"20px"} />
        <Box sx={{ p: 1 }}>
          <Spacing marginBottom={"10px"} />

          <FormControl fullWidth>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              sx={{
                ...styles.label,
                ...{ fontWeight: 500, fontSize: "1rem", color: "#000000" },
              }}
            >
              {t("virtualsCards.creationDescription")}
            </FormLabel>
            <Spacing marginBottom={"20px"} />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              {...register("brand")}
              sx={{ display: "flex", justifyContent: "center" }}
              value={selectedBrand}
              onChange={handleVertoBeneficiaryChange}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormControlLabel
                  style={{ width: "40%" }}
                  label={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box component={"img"} src="https://res.cloudinary.com/payqin-ltd/image/upload/v1699474278/web-app/masterCard_jazb2q.png" width={"95%"} />
                      <Typography>Master card</Typography>
                    </div>
                  }
                  value={"master_card"}
                  control={<BpRadio />}
                />
                <FormControlLabel
                  style={{ width: "40%" }}
                  label={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box component={"img"} src={"https://res.cloudinary.com/payqin-ltd/image/upload/v1699474190/web-app/visaCard_xkdmjk.png"} width={"95%"} />
                      <Typography>Visa card</Typography>
                    </div>
                  }
                  value={"visa_card"}
                  control={<BpRadio />}
                />
              </Box>
            </RadioGroup>
          </FormControl>
          <Spacing marginBottom={"20px"} />

          <TransitionGroup>
            <Collapse key={selectedBrand} orientation={"vertical"} in={false}>
              {selectedBrand && (
                <>
                  <Box>
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none",
                          margin: "0px",
                          padding: 2,
                        },
                      }}
                    >
                      {Row(
                        t("virtualsCards.expiryDate"),
                        t("virtualsCards.respExpiryDate")
                      )}
                      {Row(
                        t("virtualsCards.price"),
                        t("virtualsCards.respPrice")
                      )}
                      {Row(
                        t("virtualsCards.transactionFee"),
                        t("virtualsCards.respTransactionFee")
                      )}
                      {Row(
                        t("virtualsCards.transactionLimit"),
                        t("virtualsCards.respTransactionLimit")
                      )}
                    </Table>
                  </Box>
                  <Spacing marginBottom={"20px"} />
                  <Box>
                    <Typography sx={styles.label}>
                      {t("virtualsCards.invitFriendCodeEnter")}
                    </Typography>
                    <KTextField
                      validation={register("code")}
                      fullWidth
                      required
                      placeholder=""
                    />
                    {errors.code && <p>{errors.code.message}</p>}
                  </Box>
                  <Spacing marginBottom={"20px"} />
                  <RoundGradientButton
                    disabled={!isValid || isSubmitting}
                    fullWidth
                    type="submit"
                    variant={"contained"}
                    color={"inherit"}
                    style={{
                      background: isSubmitting
                        ? "linear-gradient(to right, #C471ED, #4A90ED)"
                        : "",
                    }}
                  >
                    {isSubmitting ? (
                      <>
                        <CircularProgress
                          size={24}
                          sx={{
                            color: "#FFFFFF",
                            marginRight: "5%",
                          }}
                        />
                        <Typography
                          sx={{
                            color: isSubmitting ? "#FFFFFF" : "",
                          }}
                        >
                          {t("virtualsCards.creationLoading")}
                        </Typography>
                      </>
                    ) : (
                      <Typography>
                        {t("virtualsCards.creationLabel")}
                      </Typography>
                    )}
                  </RoundGradientButton>
                </>
              )}
            </Collapse>
          </TransitionGroup>
        </Box>
      </Box>

      <Popup
        title=""
        open={popup}
        onClose={handleCloseDialog}
        additionalStyle={{ backgroundColor: "#FFFFFF" }}
      >
        {content}
      </Popup>
    </Box>
  );
};

export default VirtualCard;

/** @type{import("@mui/material").SxProps}*/
const styles = {
  masterCard: {
    backgroundImage: `url("https://res.cloudinary.com/payqin-ltd/image/upload/v1699474278/web-app/masterCard_jazb2q.png")`,
  },
  titleField: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#000000",
    marginBottom: 5,
  },
  input: { backgroundColor: "rgb(249, 249, 249)", borderRadius: "8px" },
  label: {
    fontWeight: 500,
    fontSize: "13px",
    color: "#959595",
    marginBottom: "1%",
  },
  addCardButton: {
    display: "flex",
    justifyContent: "space-around",
  },

  // STYLE DE LA CARTE
  "@keyframes flipCard": {
    from: {
      transform: "rotateY(0deg)",
    },
    to: {
      transform: "rotateY(180deg)",
    },
  },
  cardItem: {
    width: "100%",
    height: "100%",
    transition: "transform 0.6s",
    transformStyle: "preserve-3d",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  cardItemFlipped: {
    animation: "$flipCard 0.6s",
    transform: "rotateY(180deg)",
  },
  cardCvv: {
    fontSize: "16px",
    color: "#FFFFFF",
    fontWeight: 700,
    display: "flex",
    justifyContent: "flex-end",
  },
  logo: {
    width: "18.42px",
    height: "23.29px",
    transform: "rotate(-0.31deg)",
    flex: "none",
    order: 1,
    flexGrow: 0,
    // backgroundImage: `url(${subtractImgUrl})`,
  },
  visaLogo: {
    width: "48.77px",
    height: "23.96px",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
  logos: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px",
    gap: "7.7px",
    width: "79px",
    height: "24px",
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
  validity: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "4px",
    // position: "absolute",
    // width: "51px",
    height: "38px",
    order: 0,
    flexGrow: 0,
  },
  visa: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: "0px",
    top: "178px",
  },
  balance: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "4px",
    // position: "absolute",
    width: "48px",
    height: "38px",
    left: "24px",
    top: "178px",
  },
  balanceAndValid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardNumber: {
    // position: "absolute",
    height: "34px",
    left: "24px",
    top: "136px",
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "26px",
    lineHeight: "34px",
    color: "#FFFFFF",
  },
  chip: {
    // position: "absolute",
    width: "37.22px",
    height: "28.36px",
    left: "24px",
    top: "67px",
    opacity: "0.7",
    // backgroundImage: `url(${chipImgUrl})`,
  },
  virtualCardLogo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
    // gap: "152px",

    // position: "absolute",
    // width: "311.85px",
    height: "27px",
    // left: "24px",
    // top: "24px",
  },
  payqinLogo: {
    width: "71.85px",
    height: "20px",
    // background: `url(${payqinImgUrl})`,
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
  backPaperContainer: {
    // backgroundImage: `url(${bgImgUrl})`,
    backgroundSize: "cover",
    // position: "absolute",
    maxWidth: {
      lg: "360px",
      sm: "340px",
      xs: "320px",
    },
    width: "360px",
    maxHeight: "240px",
    height: "240px",
    // top: 0,
    // right: 0,
    borderRadius: "24px",
  },
  paperContainer: {
    // backgroundImage: `url(${bgImgUrl})`,
    backgroundSize: "cover",
    position: "relative",
    maxWidth: {
      lg: "360px",
      sm: "340px",
      xs: "320px",
    },
    width: "360px",
    maxHeight: "240px",
    height: "240px",
    // left: "0px",
    // top: "0px",
    borderRadius: "24px",
  },
  container: {
    width: {
      sm: "70%",
      md: "50%",
    },
    margin: "auto",
  },
};
