import { combineReducers } from 'redux';
import SubOperation from "../types/SubOperation";
import channelOperationReducer from "./channelOperation.reducer";

export default combineReducers({
    channelOperation: channelOperationReducer
});

export interface IRootState {
    channelOperation: SubOperation | null;
}