import React, {useEffect, useState} from "react";
import {
    AppBar,
    Avatar,
    Box,
    Button,
    FormControl,
    ListItemIcon,
    Menu,
    MenuItem,
    Select,
    SelectChangeEvent,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {
    AvatarGradientIcon,
    BankTransferIcon,
    SendMobileCreditIcon,
    SendTopUpPayqinTransferIcon,
} from "../../shared/_utils/constants/icon";
import {Logout, Settings} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useSidebarContext} from "../SidebarContext";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {accountService} from "../../shared/services";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {useAuthUserContext} from "../../shared/context/AuthUserContext";
import {t} from "i18next";
import Popup from "../Popup";
import ShowOperationsPopup from "../ShowOperationsPopup";
import SubOperation from "../../shared/types/SubOperation";
import useProviderMobileMoney from "../../shared/hook/useProviderMobileMoney";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

// import { inputStyles, rootStyles } from "../../components/KTextField";

interface ISettingsForm {
    language: string;
}

const KHeader = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const {user} = useAuthUserContext();

    const {i18n} = useTranslation("translation");

    const {toggleSidebar} = useSidebarContext();

    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<ISettingsForm>();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [sendMoneyAnchorEl, setSendMoneyAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const [popupProviderMobileMoney, setPopupProviderMobileMoney] =
        useState(false);

    const [providerMobileMoney, setProviderMobileMoney] = useState<SubOperation[]>([]);

    const [errorTopUpProviders, setErrorTopUpProviders] = useState<string | null>(
        null
    );

    const [selectedLanguage, setSelectedLanguage] = useState<string>("");

    const open = Boolean(anchorEl);

    const openSend = Boolean(sendMoneyAnchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSendMoneyClick = (event: React.MouseEvent<HTMLElement>) => {
        setSendMoneyAnchorEl(event.currentTarget);
    };

    const handleMobileMoney = (subOperation: SubOperation | undefined) => {
        if (!subOperation) return;

        if (subOperation.code == "cinetpay") {
            subOperation.type = "CINETPAY_TRANSFER";
        } else if (subOperation.code == "wave") {
            subOperation.type = "WAVE_TRANSFER";
        }
        navigate(`/recipients?transaction=${subOperation.type?.toLowerCase()}`);

        setPopupProviderMobileMoney(false);
    };

    const getMobileMoneyProviders = async (): Promise<void> => {
        useProviderMobileMoney()
            .then((res) => setProviderMobileMoney(res))
            .catch((err) => setErrorTopUpProviders(err));
    };

    const handleLanguageChange = (event: SelectChangeEvent) => {
        const language = event.target.value as string;
        localStorage.setItem("language", language);
        setSelectedLanguage(language);
        i18n.changeLanguage(language).then((r) => window.location.reload());
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        accountService.logout();
        toast.success("Vous êtes deconnecté");
        navigate("/login");
    };

    const goToSettings = () => {
        navigate("/settings");
    };

    const goToTransferPageWithParams = async (params: String) => {
        if (params === "MOBILE_MONEY_TRANSFER") {
            await getMobileMoneyProviders();
            setPopupProviderMobileMoney(true);
            return;
        }
        navigate(`/recipients?transaction=${params.toLowerCase()}`);
    };
    const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));

    useEffect(() => {
        let language = i18n.language;
        language = language.split("-")[0];
        setSelectedLanguage(language);
    }, []);

    const [age, setAge] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };


    return (
        <>
            <Popup
                title={t("selectProvider")}
                open={popupProviderMobileMoney}
                onClose={setPopupProviderMobileMoney}
            >
                {providerMobileMoney && providerMobileMoney.length > 0 ? (
                    <ShowOperationsPopup
                        additionalStyle={{
                            margin: 1,
                            background: "#FFFFFF",
                            border: "1px solid #C7C7C7",
                        }}
                        items={providerMobileMoney}
                        handleChannelOperation={handleMobileMoney}
                    />
                ) : (
                    <Typography>{errorTopUpProviders}</Typography>
                )}
            </Popup>

            <AppBar position="sticky" sx={styles.appBar}>
                {!isMdScreen && (
                    <Box onClick={toggleSidebar}>
                        <MenuOutlinedIcon color={"disabled"} fontSize={"large"}/>
                    </Box>
                )}

                <Toolbar>
                    {/* <IconButton title="Notifications" color="secondary">
            <Badge badgeContent={1} color="error">
              <NotificationIsFilledFalse />
            </Badge>
          </IconButton> */}

                    <Button
                        id="button-send-money"
                        aria-controls={openSend ? "button-send-money" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openSend ? "true" : undefined}
                        variant="outlined"
                        disableElevation
                        onClick={handleSendMoneyClick}
                        endIcon={<KeyboardArrowDownIcon/>}
                        style={{
                            borderRadius: "8px",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: `${user?.picture ? "8px" : "10px"} 16px`,
                            margin: "0 0 0 20px",
                            border: "1.5px solid",
                            borderColor:
                                "linear-gradient(95.08deg, #B349EB 0%, #4A90E2 100%)",
                        }}
                    >
                        <Typography
                            sx={{
                                // textTransform: "none",
                                textTransform: "none",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#000000",
                            }}
                        >
                            {t("sendMoney")}
                        </Typography>
                    </Button>


                    {/*<div>*/}
                        {/*<FormControl sx={{m: 1, minWidth: 80}}>*/}
                            <Select
                                {...register("language")}
                                defaultValue={"fr"}
                                value={selectedLanguage || "fr"}
                                onChange={handleLanguageChange}
                                autoWidth
                                sx={{
                                    borderRadius: "8px",
                                    "& fieldset": {
                                        border: "none",
                                    },
                                    "&:hover": {
                                        backgroundColor: "rgba(63, 81, 181, 0.04)!important",
                                    },
                                    display: 'flex', justifyContent: "center", alignItems: "center",
                                }}
                                // inputProps={{ IconComponent: () => null }}
                                inputProps={{sx: {padding: '10 !important'}}}
                            >

                                <MenuItem key={"fr"} value={"fr"} selected>
                                    <Box sx={{display: 'flex', justifyContent: "center"}}>
                                        <Box
                                            component={"img"}
                                            src={`https://flagcdn.com/w40/fr.png`}
                                        />
                                        <span style={{marginLeft: "10px"}}>
                                    {t("french")}</span>
                                    </Box>
                                </MenuItem>
                                <MenuItem key={"en"} value={"en"}>
                                    <Box sx={{display: 'flex', justifyContent: "center"}}>
                                        <Box
                                            component={"img"}
                                            src={`https://flagcdn.com/w40/gb.png`}
                                        /><span style={{marginLeft: "10px"}}>{t("english")}</span>
                                    </Box>
                                </MenuItem>
                            </Select>
                        {/*</FormControl>*/}
                    {/*</div>*/}

                    <Button
                        id="demo-customized-button"
                        aria-controls={open ? "demo-customized-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        variant="text"
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon/>}
                        startIcon={
                            user?.picture ? (
                                <Avatar
                                    src={user.picture}
                                    alt={user.firstName}
                                    sx={{width: "30px!important", height: "30px!important"}}
                                />
                            ) : (
                                <AvatarGradientIcon/>
                            )
                        }
                        style={{
                            borderRadius: "8px",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: `${user?.picture ? "8px" : "10px"} 16px`,
                            margin: "0 0 0 0px",
                        }}
                    >
                    </Button>

                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{horizontal: "right", vertical: "top"}}
                        anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                    >
                        <MenuItem onClick={goToSettings}>
                            <ListItemIcon>
                                <Settings fontSize="small"/>
                            </ListItemIcon>
                            {t("navigations.settings")}
                        </MenuItem>
                        <MenuItem onClick={logout}>
                            <ListItemIcon>
                                <Logout fontSize="small"/>
                            </ListItemIcon>
                            {t("navigations.logout")}
                        </MenuItem>{" "}
                    </Menu>

                    <Menu
                        anchorEl={sendMoneyAnchorEl}
                        id="send-menu"
                        open={openSend}
                        onClose={() => setSendMoneyAnchorEl(null)}
                        onClick={() => setSendMoneyAnchorEl(null)}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{horizontal: "right", vertical: "top"}}
                        anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                    >
                        <MenuItem
                            onClick={() =>
                                goToTransferPageWithParams("MOBILE_MONEY_TRANSFER")
                            }
                        >
                            <ListItemIcon
                                sx={{display: "flex", justifyContent: "space-between"}}
                            >
                                <Typography>
                                    <SendMobileCreditIcon height={24} width={24}/>{" "}
                                </Typography>
                                <Typography sx={{marginLeft: "1.5%"}}>
                                    {t("mobileMoney")}{" "}
                                </Typography>
                            </ListItemIcon>
                        </MenuItem>
                        <MenuItem
                            onClick={() => goToTransferPageWithParams("BANK_TRANSFER")}
                        >
                            <ListItemIcon>
                                <Typography>
                                    <BankTransferIcon height={24} width={24}/>
                                </Typography>
                                <Typography sx={{marginLeft: "1.5%"}}>
                                    {t("bankTransfer")}
                                </Typography>
                            </ListItemIcon>
                        </MenuItem>
                        <MenuItem
                            onClick={() => goToTransferPageWithParams("PAYQIN_TRANSFER")}
                        >
                            <ListItemIcon>
                                <Typography>
                                    <SendTopUpPayqinTransferIcon height={24} width={24}/>
                                </Typography>
                                <Typography sx={{marginLeft: "1.5%"}}>
                                    {t("payqinTransfer")}
                                </Typography>
                            </ListItemIcon>
                        </MenuItem>{" "}
                    </Menu>
                </Toolbar>
            </AppBar>
        </>
    );
};

/** @type {import("@mui/material").SxProps} */
const styles = {
    appBar: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: {
            xs: "space-between",
            md: "flex-end",
        },
        paddingTop: "5px",
        bgcolor: "#ffffff",
        mb: 5,
        // boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
        boxShadow: "none",
    },
};

export default KHeader;
