import React, {useState} from 'react';
import {Box, Collapse, IconButton, IconButtonProps, Typography} from "@mui/material";
import {t} from "i18next";
import Spacing from "../../../components/Spacing";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KTextField from "../../../components/KTextField";
import emailRegex from "../../../shared/_utils/_helpers/emailRegex";
import RoundGradientButton from "../../../components/RoundGradientButton";
import CircularProgress from "@mui/material/CircularProgress";
import Popup from "../../../components/Popup";
import {SubmitHandler, useForm} from "react-hook-form";
import {ITransferForm} from "../../Recipients/components/SendMoneyForm";
import {styled} from "@mui/material/styles";

interface IVerificationCodeEmailProps {
    openDialog: boolean
    setOpenDialog: () => any
    expanded: boolean
    handleExpandClick: () => any
    onSubmit: (params: any) => any;
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

export interface IVerificationCodeEmailForm {
    email: string
}

const VerificationCodeEmailDialog = ({openDialog, setOpenDialog, expanded, handleExpandClick, onSubmit}: IVerificationCodeEmailProps) => {

    const {
        register,
        control,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        setError,
    } = useForm<IVerificationCodeEmailForm>({ mode: "onChange" });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const kHandleSubmit: SubmitHandler<IVerificationCodeEmailForm> = async (verificationCodeEmailData) => {

        if (!verificationCodeEmailData.email) {
            setError("email", {
                type: "custom",
                message: t('invalidField'),
            });
            return;
        }

        setIsSubmitting(true)

        const res = await onSubmit(verificationCodeEmailData);

        setIsSubmitting(false);

        if (res !== true) {
            setError("email", {
                type: "custom",
                message: res,
            });
        }
    }

    return (
        <Popup
            open={openDialog}
            onClose={setOpenDialog}
        >
            <Box m={2} mt={0}>
                <Box>
                    <Typography sx={{fontWeight: '500', fontSize: '1.5rem'}}>
                        {t('smsResendAttempts')}
                    </Typography>
                </Box>
                <Spacing marginBottom="50px"/>
                <Typography onClick={handleExpandClick} sx={{
                    fontWeight: '500',
                    fontSize: '1.2rem',
                    color: '#2B91FF',
                    textTransform: "initial",
                    cursor: "pointer",
                    width: "fit-content"
                }}>
                    {t('receiveByEmail')}
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </Typography>

                <Spacing marginBottom="20px"/>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box
                        component="form"
                        mt={0}
                        noValidate
                        onSubmit={handleSubmit(kHandleSubmit)}
                        sx={{display: "flex", justifyContent: "space-between", gap: {xs: 2, md: 10}}}
                    >
                        <Box sx={{display: "flex", flexDirection: "column", flex: 3}}>
                        <KTextField
                            validation={register("email", {
                                pattern: {
                                    value: emailRegex,
                                    message: t("invalidField"),
                                },
                            })}
                            placeholder="Ex: johndoe@gmail.com"
                            fullWidth
                        />
                        {errors.email && <p style={{color: "red"}}>{errors.email.message}</p>}
                        </Box>
                        <Box sx={{flex: 2}}>
                        <RoundGradientButton
                            type="submit"
                            variant={"contained"}
                            color={"inherit"}
                            sx={{
                                background: isSubmitting
                                    ? "linear-gradient(to right, #C471ED, #4A90ED)"
                                    : "",
                            }}
                            size="medium"
                            fullWidth
                        >
                            {isSubmitting && (
                                <>
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "#FFFFFF",
                                            marginRight: "5%",
                                        }}
                                    />
                                </>
                            )}
                            <Typography sx={{color: isSubmitting ? "#FFFFFF" : ""}}>
                                {t("next")}
                            </Typography>
                        </RoundGradientButton>
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        </Popup>
    );
};

export default VerificationCodeEmailDialog;


const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
