import React from 'react';
import {Backdrop, Button} from "@mui/material";
import RoundGradientButton from "./RoundGradientButton";
import GradientCircularProgress from "./GradientCircularProgress";

interface IGradientCircularProgressWithBackdropProps {
    open: boolean;
    setOpen: (params: any) => void
}

const GradientCircularProgressWithBackdrop = ({open, setOpen}: IGradientCircularProgressWithBackdropProps) => {

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <GradientCircularProgress/>
            </Backdrop>
        </div>
    );
};

export default GradientCircularProgressWithBackdrop;
