import React from "react";
import {
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AddNewCardIcon } from "../../../shared/_utils/constants/icon";
import "./newCardButtonStyles.css";

interface NewCardButtonProps {
  additionalStyle?: React.CSSProperties;
  title?: string;
  children?: React.ReactNode;
  action?: (params: any) => any;
}

const NewCardButton = (props: NewCardButtonProps) => {
  const { additionalStyle, title, children, action } = props;

  return (
    <Card
      sx={{ ...styles.newCardButton, ...additionalStyle }}
      className="cardButtonRounded"
      onClick={action}
    >
      <CardContent sx={styles.cardContent}>
        {children}
        <Typography sx={styles.title}>{title}</Typography>
      </CardContent>
    </Card>
  );
};

export default NewCardButton;

const styles = {
  title: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#001021",
  },
  cardContent: {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  newCardButton: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    cursor: "pointer",
    minWidth: {
      xs: "130px",
      sm: "156px",
    },
    left: "0px",
    top: "0px",
  },
};
