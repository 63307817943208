import React from "react";
import { Box, Card, CardContent, Grid, Paper, Typography } from "@mui/material";
import Spacing from "./Spacing";
import { styled } from "@mui/material/styles";
import { MasterCardIcon, VisaCardIcon } from "../shared/_utils/constants/icon";
import { ICardView } from "../containers/Cards/Cards";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const bgImgUrl = process.env.PUBLIC_URL + "/physical-card-front0.png";
// const bgImgUrl = process.env.PUBLIC_URL + '/physical-card-background-last.png';
const payqinImgUrl = process.env.PUBLIC_URL + "/payqinLogo.svg";
const chipImgUrl = process.env.PUBLIC_URL + "/Chip.svg";
const subtractImgUrl = process.env.PUBLIC_URL + "/subtract.svg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const PhysicalCard = ({
  balance,
  cardPan,
  cardType,
  expiration,
}: ICardView) => {
  return (
    <Card sx={styles.paperContainer}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: {
            lg: "360px",
            sm: "340px",
            xs: "320px",
          },
          justifyContent: "space-between",
          width: "360px",
          maxHeight: "240px",
          height: "240px",
        }}
      >
        <Box sx={styles.virtualCardLogo}>
          <Typography
            sx={{
              color: "#F9F9F9",
              fontSize: "1rem",
              fontWeight: "400px",
            }}
          >
            {t("physicalCards.title")}
          </Typography>
          <Box sx={styles.payqinLogo}></Box>
        </Box>
        <Box sx={{ display: "flex", gap: 1, textAlign: "center" }}>
          <Box sx={styles.chip}></Box>
          <Box sx={styles.logo}></Box>
        </Box>
        <Typography sx={styles.cardNumber}>{cardPan}</Typography>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          <Grid item xs={7}>
            <Typography
              sx={{
                fontSize: ".7em",
                fontWeight: "500",
                color: "#FFFFFF",
              }}
            >
              {t("physicalCards.balance")}
            </Typography>
            <Typography
              sx={{
                fontSize: "1em",
                fontWeight: "700",
                color: "#FFFFFF",
              }}
            >
              {balance} CFA
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 0 }}>
              <Grid item xs={7}>
                <Typography
                  sx={{
                    fontSize: ".7em",
                    fontWeight: "500",
                    color: "#FFFFFF",
                  }}
                >
                  {t("physicalCards.expiryDate")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1em",
                    fontWeight: "700",
                    color: "#FFFFFF",
                  }}
                >
                  {expiration}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                sx={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  justifyContent: "center",
                }}
              >
                <VisaCardIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PhysicalCard;

const styles = {
  logo: {
    width: "18.42px",
    height: "23.29px",
    transform: "rotate(-0.31deg)",
    flex: "none",
    order: 1,
    flexGrow: 0,
    backgroundImage: `url(${subtractImgUrl})`,
  },
  visaLogo: {
    width: "48.77px",
    height: "23.96px",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
  logos: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px",
    gap: "7.7px",
    width: "79px",
    height: "24px",
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
  validity: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "4px",
    // position: "absolute",
    // width: "51px",
    height: "38px",
    order: 0,
    flexGrow: 0,
  },
  visa: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: "0px",
    top: "178px",
  },
  balance: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "4px",
    // position: "absolute",
    width: "48px",
    height: "38px",
    left: "24px",
    top: "178px",
  },
  balanceAndValid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardNumber: {
    // position: "absolute",
    height: "34px",
    left: "24px",
    top: "136px",
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "26px",
    lineHeight: "34px",
    color: "#FFFFFF",
  },
  chip: {
    // position: "absolute",
    width: "37.22px",
    height: "28.36px",
    left: "24px",
    top: "67px",
    opacity: "0.7",
    backgroundImage: `url(${chipImgUrl})`,
  },
  virtualCardLogo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
    // gap: "152px",

    // position: "absolute",
    // width: "311.85px",
    height: "27px",
    // left: "24px",
    // top: "24px",
  },
  payqinLogo: {
    width: "71.85px",
    height: "20px",
    background: `url(${payqinImgUrl})`,
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
  paperContainer: {
    backgroundImage: `url(${bgImgUrl})`,
    backgroundSize: "cover",
    position: "relative",
    maxWidth: {
      lg: "360px",
      sm: "340px",
      xs: "320px",
    },
    width: "360px",
    maxHeight: "240px",
    height: "240px",
    // left: "0px",
    // top: "0px",
    borderRadius: "24px",
  },
};
