import React, {useEffect, useState} from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    RadioGroup,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import {DeepPartial, Controller} from "react-hook-form";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import "./../style.css";
import {BusinessTypes, BusinessTypesValid, BusinessTypesValues, IBusinessRequestForm} from "../index";
import {IUseFormProps} from "../../../../shared/types/IUseFormsProps";
import Spacing from "../../../../components/Spacing";
import KTextField from "../../../../components/KTextField";
import LocationDataForm from "../../../../components/LocationDataForm";
import {t} from "i18next";
import BpRadio from "../../../Recipients/Common/BpRadio";
import {VertoBeneficiary} from "../../../../shared/types/types";
import {validVertoBeneficiary} from "../../../Recipients/BankRecipient/Forms/PersonalInfoForm";

export interface IBankInfoFormData {
    bankAccountType: "UGBA" | "SOCGEN" | "BDA";
    accountNumber: string;
    addToRecipient?: boolean;
}

const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#C7C7C7",
        backgroundColor: "#C7C7C7",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#C7C7C7",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#C7C7C7",
        },
        "&:hover fieldset": {
            borderColor: "#C7C7C7",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#C7C7C7",
        },
    },
});

const InfoCompanyForm: React.FC<IUseFormProps<IBusinessRequestForm>> = ({
                                                                            register,
                                                                            isValid,
                                                                            errors,
                                                                            control,
                                                                            setValue,
                                                                        }) => {
    const [locationError, setLocationError] = useState<string | null>(null);
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [selectedCompanyType, setSelectedCompanyType] =
        useState<BusinessTypes>();

    useEffect(() => {
    }, []);

    const handleCompanyTypeChange = (event: SelectChangeEvent) => {
        const selectedCompanyTypeRadio = event.target.value as BusinessTypes;
        if (BusinessTypesValid.includes(selectedCompanyTypeRadio)) {
            setSelectedCompanyType(selectedCompanyTypeRadio)
        }
        ;
    };

    return (
        <Box>
            <Box sx={{...styles.container, ...{p: 1}}}>
                <Spacing marginBottom={"20px"}/>
                <FormControl fullWidth>
                    <Typography sx={styles.label}>
                        {t("business.businessType")}
                    </Typography>
                    {/*<Spacing marginBottom={"20px"} />*/}
                    <Controller
                        control={control}
                        name="companyType"
                        render={({field}) => (
                            <div>
                                <RadioGroup
                                    row
                                    sx={{display: "flex", justifyContent: "space-evenly"}}
                                    // onChange={(e) => {
                                    //     field.onChange(event?.target.value as BusinessTypes)
                                    //     handleCompanyTypeChange(e)
                                    //
                                    // }}
                                    // onChange={(e) => {
                                    //     field.onChange(e.target.value)
                                    //     // handleCompanyTypeChange(e)
                                    // }}
                                >
                                    <Button
                                        variant={"text"}
                                        key={BusinessTypesValues.INDIVIDUAL}
                                        sx={{
                                            border: "none",
                                            backgroundColor: "#FFFFFF",
                                            textTransform: "revert",
                                            color: "#000000",
                                        }}
                                    >
                                        <FormControlLabel
                                            value={BusinessTypesValues.INDIVIDUAL}
                                            control={<BpRadio/>}
                                            label={t("withdrawScreen.benficiaryIndividual")}
                                            {...register("companyType", {
                                                required: {
                                                    value: true,
                                                    message: t("requiredField")
                                                }
                                            })}
                                        />
                                    </Button>
                                    <Button
                                        variant={"text"}
                                        key={BusinessTypesValues.BUSINESS}
                                        sx={{
                                            border: "none",
                                            backgroundColor: "#FFFFFF",
                                            textTransform: "revert",
                                            color: "#000000",
                                        }}
                                    >
                                        <FormControlLabel
                                            {...register("companyType", {
                                                required: {
                                                    value: true,
                                                    message: t("requiredField")
                                                }
                                            })}
                                            value={BusinessTypesValues.BUSINESS}
                                            control={<BpRadio/>}
                                            label={t("withdrawScreen.beneficiaryBusiness")}
                                        />
                                    </Button>
                                </RadioGroup>
                                {errors.companyType && (
                                    <p style={styles.errorLabel}> {errors.companyType.message}</p>
                                )}
                            </div>)}
                    />
                </FormControl>
                <Spacing marginBottom={"10px"}/>
                <Box>
                    <Typography sx={styles.label}>{t("business.companyName")}</Typography>

                    <KTextField
                        validation={register("companyName", {
                            required: t("requiredField"),
                        })}
                        fullWidth
                        // required
                        placeholder="Ex: PayQin"
                    />
                    {errors.companyName && (
                        <p style={styles.errorLabel}> {errors.companyName.message}</p>
                    )}
                </Box>
                <Spacing marginBottom={"20px"}/>

                <Box>
                    <Typography sx={styles.label}>
                        {t("business.companyNumber")}
                    </Typography>

                    <KTextField
                        validation={register("rccm", {
                            required: t("requiredField"),
                        })}
                        fullWidth
                        // required
                        placeholder="Ex: "
                    />
                    {errors.rccm && (
                        <p style={styles.errorLabel}>{errors.rccm.message}</p>
                    )}
                </Box>
                <Spacing marginBottom={"20px"}/>

                <Grid container columns={12} spacing={4}>
                    <Grid item xs={7} md={7} lg={7}>
                        <Typography sx={styles.label}>
                            {t("business.companyChiefFirstName")}
                        </Typography>
                        <KTextField
                            validation={register("firstName", {
                                required: t("requiredField"),
                            })}
                            fullWidth
                            // required
                            placeholder="Ex: John"
                        />
                        {errors.firstName && (
                            <p style={styles.errorLabel}>{errors.firstName.message}</p>
                        )}
                    </Grid>

                    <Grid item xs={5} md={5} lg={5}>
                        <Typography sx={styles.label}>
                            {t("business.companyChiefLastName")}
                        </Typography>
                        <KTextField
                            validation={register("lastName", {
                                required: t("requiredField"),
                            })}
                            fullWidth
                            // required
                            placeholder="Ex: Doe"
                        />
                        {errors.lastName && (
                            <p style={styles.errorLabel}>{errors.lastName.message}</p>
                        )}
                    </Grid>
                </Grid>

                <Spacing marginBottom={"20px"}/>

                <Box>
                    <Typography sx={styles.label}>
                        {t("business.companyEmail")}
                    </Typography>
                    <KTextField
                        validation={register("email", {
                            required: t("requiredField"),
                        })}
                        fullWidth
                        // required
                        placeholder="Ex: johndoe@email.com"
                    />
                    {errors.email && (
                        <p style={styles.errorLabel}>{errors.email.message}</p>
                    )}
                </Box>
            </Box>

            <Spacing marginBottom={"30px"}/>
            <Box>
                <Box>
                    <Box sx={styles.container}>
                        <b>{t("business.companyAddressIndication")}</b>
                    </Box>

                    <LocationDataForm
                        locationError={locationError}
                        onLocationChange={({lat, lng}) => {
                            setLatitude(lat);
                            setLongitude(lng);
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default InfoCompanyForm;

/** @type{import("@mui/material").SxProps}*/
const styles = {
    container: {
        width: {
            sm: "70%",
            md: "70%",
            lg: "70%",
        },
        margin: "auto",
    },
    errorLabel: {
        fontSize: "14px",
        color: "red",
    },
    label: {
        fontWeight: 500,
        fontSize: "13px",
        color: "#959595",
        marginBottom: "1%",
    },
};
