import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import { IAmountFieldForm } from "../containers/Cards/Cards";
import AmountFormatter from "../shared/_utils/_helpers/AmountFormatter";
import KTextField, {
  inputStyles,
  rootInputStyles,
  rootStyles,
} from "./KTextField";
import { UseFormRegister } from "react-hook-form/dist/types/form";
import { t } from "i18next";

export interface AmountFieldProps {
  balance?: string | number;
  register?: UseFormRegister<IAmountFieldForm>;
  control?: Control<IAmountFieldForm, any>;
  errors?: FieldErrors<IAmountFieldForm>;
  onChangeAmount?: (param: any) => any;
  currency?: string;
  autoFocus?:boolean;
  placeholder?:string;
  fullWidth?: boolean;
  border?: boolean;
  initialAmount?: string;
}

const AmountField: React.FC<AmountFieldProps> = ({
  initialAmount,
  balance,
  register,
  control,
  placeholder,
  autoFocus,
  errors,
  onChangeAmount,
  currency,
  fullWidth = false,
  ...props
}) => {
  const [formattedValue, setFormattedValue] = useState<string>("");

  const changeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const { amountWithoutSpaces, formattedAmount } = AmountFormatter(
      e.target.value
    );
    setFormattedValue(formattedAmount);
    if (onChangeAmount) {
      onChangeAmount(amountWithoutSpaces);
    }
  };

  useEffect(() => {
    setFormattedValue(initialAmount || "");
  }, [currency]);

  const widthInChars = Math.max(formattedValue.length, 5);

  return (
    <Box sx={styles.balance}>
      <Box sx={styles.amount}>
        <TextField
          {...props}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            changeAmount(e);
          }}
          value={formattedValue}
          autoComplete={"off"}
          required={true}
          autoFocus={autoFocus}
          fullWidth={fullWidth}
          sx={{
            ...{
              input: {
                // position: 'flex-end',
                color: "#959595",
                // fontSize: '1.7em',
                fontWeight: 700,
                width: fullWidth ? null : `${widthInChars}ch`,
                // width: formattedValue!.length > 0 ? `${formattedValue!.length + 13}ch` : '5ch',
              },
              "& fieldset": { border: "solid #C7C7C7 1px" },
            },
            ...rootStyles,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                <Typography
                  sx={{
                    color: "#959595",
                    fontSize: ".875em",
                    fontWeight: 700,
                  }}
                >
                  {currency}
                </Typography>
              </InputAdornment>
            ),
            ...rootInputStyles,
            ...{
              border: "none",
              //         width: formattedValue!.length > 0 ? `${formattedValue!.length + 13}ch` : '13ch',
            },
          }}
          inputProps={{
            sx: {
              ...inputStyles,
            },
          }}
          placeholder={placeholder}
        />
      </Box>
      {balance && (
        <Typography sx={styles.availableBalance}>
          {t("availableBalance")} {balance} CFA
        </Typography>
      )}
      {errors && errors.amount && (
        <Typography
          style={{
            color: "#F94144",
            fontSize: "1rem",
            fontWeight: 400,
          }}
        >
          {errors.amount.message}
        </Typography>
      )}
    </Box>
  );
};

export default AmountField;

/**
 * @type {import("@mui/material").SxProps}
 */
const styles = {
  availableBalance: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "22px",
    alignItems: "center",
    textAlign: "center",
    color: "#959595",
  },
  amount: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    // width: 'px',
    order: 1,
    flexGrow: 0,
  },
  balance: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "26px",
    lineHeight: "51px",
    alignItems: "center",
    textAlign: "center",
    color: "#959595",
  },
};
