import { httpService } from "./http.service";
import ICredential from "../types/ICredential";
import jwt_decode from "jwt-decode";
import { IUser } from "../../containers/Auth/models/auth.response";

const payID = "4147fba7ca77acd161e872d44edab3c9";
const payPassword = "79bbb1ef5820a348fedd43c29d1298a7";

const toHex = (buffer: any) => {
  const hashArray = Array.from(new Uint8Array(buffer));
  return hashArray.map((bytes) => bytes.toString(16).padStart(2, "0")).join("");
};

const computeAuthParameters = async () => {
  const timestamp = Date.now();
  const toEncode = new TextEncoder().encode(payID + payPassword + timestamp);
  const encodedBuffer = await crypto.subtle.digest("SHA-256", toEncode);
  const payAuth = toHex(encodedBuffer);
  return {
    timestamp,
    payAuth,
  };
};

const registration = async (
  email: string,
  firstName: string,
  lastName: string,
  token: string
) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      mode: "no-cors",
      Authorization: "Bearer " + token,
    },
  };

  const body = {
    email: email,
    firstName: firstName,
    lastName: lastName,
  };
  return httpService.Axios.put("/users/registration", body, requestOptions);
};

const checkConfirmationEmail = async (
  email: string,
  code: string,
  token: string
) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      mode: "no-cors",
      Authorization: "Bearer " + token,
    },
  };

  const body = {
    email: email,
    code: code,
  };
  return httpService.Axios.put(
    "/users/check-confirmation-mail",
    body,
    requestOptions
  );
};

const confirmationEmail = async (
  email: string,
  token: string
) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      mode: "no-cors",
      Authorization: "Bearer " + token,
    },
  };

  const body = {
    email: email,
  };
  return httpService.Axios.post(
    "/users/resend-email-confirmation",
    body,
    requestOptions
  );
};

const verificationPhoneNumberByEmail = async (email: string, phoneNumber: string) => {
  const { timestamp, payAuth } = await computeAuthParameters();

  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      // 'Accept-Language': I18n.currentLocale(),
      mode: "no-cors",
      // 'version' : appVersion,
      // PayQin soooo special auth parameters ...
      PayTimestamp: timestamp,
      PayAuthorization: payAuth,
      PayID: payID,
    },
  };

  const body = { email: email, phone: phoneNumber };

  return httpService.Axios.post(
    "/auth/new-user-send-code-email",
    body,
    requestOptions
  );
};

const sendVerificationCode = async (email: string, captchaResponse : string) => {
  const { timestamp, payAuth } = await computeAuthParameters();

  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      // 'Accept-Language': I18n.currentLocale(),
      mode: "no-cors",
      // 'version' : appVersion,
      // PayQin soooo special auth parameters ...
      PayTimestamp: timestamp,
      PayAuthorization: payAuth,
      PayID: payID,
      CaptchaResponse: captchaResponse,
    },
  };

  return httpService.Axios.post(
    "/auth/send-verification-code-email",
    { email },
    requestOptions
  );
};

const sendVerificationCodePhone = async (credentials: ICredential, captchResponse: string) => {
  const { timestamp, payAuth } = await computeAuthParameters();

  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      // 'Accept-Language': I18n.currentLocale(),
      mode: "no-cors",
      PayTimestamp: timestamp,
      PayAuthorization: payAuth,
      PayID: payID,
      CaptchaResponse: captchResponse,
    },
  };
  return httpService.Axios.post(
    "/auth/send-verification-code",
    credentials,
    requestOptions
  );
};

const checkOtpVerificationCodePhone = (phone: string, code: string) => {
  return httpService.Axios.post("/auth/login", { phone, code });
};

const checkOtp = (email: string, code: string) => {
  return httpService.Axios.post("auth/login-email", { email, code });
};

let saveToken = (token: string) => {
  localStorage.setItem("token", token);
};

let saveUser = (user: IUser) => {
  localStorage.setItem("user", JSON.stringify(user));
};

let getUser = () => {
  let user = localStorage.getItem("user");
  if (user) return JSON.parse(user) as IUser;
};

let logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

let isLogged = () => {
  let token = localStorage.getItem("token");
  return !!token;
};

let getToken = () => {
  return localStorage.getItem("token");
};

let getTokenInfo = () => {
  return jwt_decode(getToken() as string);
};

export const accountService = {
  confirmationEmail,
  checkConfirmationEmail,
  registration,
  verificationPhoneNumberByEmail,
  sendVerificationCodePhone,
  checkOtpVerificationCodePhone,
  checkOtp,
  saveToken,
  logout,
  isLogged,
  getToken,
  getTokenInfo,
  saveUser,
  getUser,
  sendVerificationCode,
};
