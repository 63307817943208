import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";
import "./slickStyles.css";

interface CarouselProps {
  additionalStyle?: React.CSSProperties;
  items?: JSX.Element[];
  onSlideChange?: (index: number) => void;
  initialSlide?: number;
  isVirtualCardLoading?: boolean;
}

const Carousel = ({
  additionalStyle,
  items,
  onSlideChange,
  initialSlide,
  isVirtualCardLoading = true,
}: CarouselProps) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(initialSlide);

  const handleSlideChange = (index: number) => {
    setCurrentSlide(index);
    onSlideChange(items[index]);
  };

  useEffect(() => {
    handleSlideChange(initialSlide);
  }, []);

  const slickSettings = {
    responsive: [
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    dots: true,
    speed: 1000,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    variableWidth: true,
    adaptiveHeight: true,
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "60px",
    afterChange: handleSlideChange,
    initialSlide: initialSlide,
  };

  return (
    <Box m="20" sx={{ ...additionalStyle }}>
      <Box sx={styles.dots}>
        <Slider className="slider" {...slickSettings} ref={sliderRef}>
          {items.map((item, index) => {
            return (
              <div style={{ marginTop: 5 }} key={index}>
                {item}
              </div>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};

const styles = {
  dots: {
    marginBottom: "20px",
  },
};

export default Carousel;
