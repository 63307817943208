import React, {useEffect, useState} from "react";
import Spacing from "../../../../components/Spacing";
import {
    Button,
    Collapse,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Slide,
    TextField,
    Typography,
} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import Box from "@mui/material/Box";
import {ISupportedCountry} from "../../../Cards/models/physicalCard.response";
import {physicalCardsRepository} from "../../../Cards/repository/physicalCard.repository";
import {IBankRecipientData, VertoBeneficiaryValue} from "../index";
import {countryService} from "../../../../shared/services";
import {
    IFormProps,
    IUseFormProps,
} from "../../../../shared/types/IUseFormsProps";
import {Operation, VertoBeneficiary} from "../../../../shared/types/types";
import BpRadio from "../../Common/BpRadio";
import {TransitionProps} from "@mui/material/transitions";
import {TransitionGroup} from "react-transition-group";
import KTextField, {
    inputStyles,
    rootStyles,
} from "../../../../components/KTextField";
import {CountrySelect} from "../../components/CountrySelect";
import {t} from "i18next";
import {recipientsRepository} from "../../repository/recipients.repository";
import {ICountry} from "../../../../shared/types/IRecipient";
import {isEmpty} from "../../../../shared/_utils/isEmpty";

export const validVertoBeneficiary: VertoBeneficiary[] = [
    "individual",
    "company",
];

const PersonalInfoForm: React.FC<IFormProps<IBankRecipientData>> = ({
                                                                        form,
                                                                    }) => {
    const [countries, setCountries] = useState<ICountry[]>([]);
    const [countriesError, setCountriesError] = useState<string | null>(null);
    const [selectedCountry, setSelectedCountry] = useState<string>();
    const [selectedVertoBeneficiary, setSelectedVertoBeneficiary] =
        useState<VertoBeneficiary>();
    const [selectedCurrency, setSelectedCurrency] = useState<any>();
    const [currencies, setCurrencies] = useState<any[]>([]);
    const [language, setLanguage] = useState<string>("");

    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: {errors, isValid},
        setError,
        getValues,
        clearErrors,
        watch,
    } = form;

    const getAvailableCountriesForBankTransfer = async () => {
        const {data, error} = await recipientsRepository.getAvailableCountriesForBankTransfer();
        if (null !== data && data.success) {
            setCountries(data.currencies);
            return data.currencies
        } else {
            return [];
        }
    };

    const initForm = (_countriesForBankTransfer?: ICountry[]) => {
        if (getValues("entity") && validVertoBeneficiary.includes(getValues("entity")))
            setSelectedVertoBeneficiary(getValues("entity"));

        if (!isEmpty(_countriesForBankTransfer) && _countriesForBankTransfer) {
            const _currency = _countriesForBankTransfer.find(c => c.currency === watch("currency")) ?? ""

            if (_currency && "name" in _currency) {
                setSelectedCurrency(_currency.name);
            }

            const _country = _countriesForBankTransfer.find(c => c.name === watch("country")) ?? ""
            if (_country && "name" in _country) {
                setSelectedCountry(_country.name);
                setValue("icountryFlag", _country)
            }
        }
    }

    useEffect(() => {
        const fetchCountriesForBankTransfer = async () => {
            const _countriesForBankTransfer = await getAvailableCountriesForBankTransfer()
            initForm(_countriesForBankTransfer);
        }

        fetchCountriesForBankTransfer().then(r => {
        })

        const lang = localStorage.getItem("language")
        if (lang)
            setLanguage(lang);
    }, []);

    const handleCountryChange = (event: SelectChangeEvent) => {
        const countryName = event.target.value;
        const foundCountry = countries.find(item => item.name == countryName)
        if (countryName && foundCountry) {
            setSelectedCountry(countryName);
            setValue("icountryFlag", foundCountry)
        }
    };

    const handleVertoBeneficiaryChange = (event: SelectChangeEvent) => {
        const vertoBeneficiary = event.target.value as VertoBeneficiary;
        if (validVertoBeneficiary.includes(vertoBeneficiary))
            setSelectedVertoBeneficiary(vertoBeneficiary);
    };

    const handleCurrencyChange = (event: SelectChangeEvent) => {
        const countryName = event.target.value as any;
        const foundCurrency = countries.find(item => item.name == countryName)
        if (countryName && foundCurrency) {
            setSelectedCurrency(countryName);
            setValue("currency", foundCurrency.currency)
        }
    };

    return (
        <Box sx={{p: 1}}>
            <Spacing marginBottom={"10px"}/>

            <FormControl fullWidth>
                <FormLabel id="demo-row-radio-buttons-group-label" sx={styles.label}>
                    {t("withdrawScreen.beneficiaryType")}
                </FormLabel>
                <Spacing marginBottom={"20px"}/>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    sx={{display: "flex", justifyContent: "space-evenly"}}
                    onChange={handleVertoBeneficiaryChange}
                >
                    <Button
                        variant={"text"}
                        sx={{
                            border: "none",
                            backgroundColor: "#FFFFFF",
                            textTransform: "revert",
                            color: "#000000",
                        }}
                    >
                        <FormControlLabel
                            value={VertoBeneficiaryValue.INDIVIDUAL}
                            {...register("entity", {
                                required: {
                                    value: true,
                                    message: t("requiredField")
                                }
                            })}
                            checked={watch("entity") == VertoBeneficiaryValue.INDIVIDUAL}
                            control={<BpRadio/>}
                            label={t("withdrawScreen.benficiaryIndividual")}
                        />
                    </Button>
                    <Button
                        variant={"text"}
                        sx={{
                            border: "none",
                            backgroundColor: "#FFFFFF",
                            textTransform: "revert",
                            color: "#000000",
                        }}
                    >
                        <FormControlLabel
                            value={VertoBeneficiaryValue.BUSINESS}
                            {...register("entity", {
                                required: {
                                    value: true,
                                    message: t("requiredField")
                                }
                            })}
                            checked={watch("entity") == VertoBeneficiaryValue.BUSINESS}
                            control={<BpRadio/>}
                            label={t("withdrawScreen.beneficiaryBusiness")}
                        />
                    </Button>
                </RadioGroup>
            </FormControl>
            {errors.entity && (
                <p style={styles.errorLabel}>
                    {errors.entity.message}
                </p>
            )}
            <Spacing marginBottom={"20px"}/>

            <TransitionGroup>
                <Collapse
                    key={selectedVertoBeneficiary}
                    orientation={"vertical"}
                    in={false}
                >
                    {selectedVertoBeneficiary && (
                        <>
                            {selectedVertoBeneficiary == "individual" ? (
                                <Grid container columns={12} spacing={4}>
                                    <Grid item xs={7} md={7} lg={7}>
                                        <Typography sx={styles.label}>{t("firstName")}</Typography>
                                        <KTextField
                                            validation={register("firstName", {
                                                required: t("requiredField"),
                                            })}
                                            defaultValue={watch("firstName")}
                                            fullWidth
                                            placeholder="John"
                                        />
                                        {errors.firstName && (
                                            <p style={styles.errorLabel}>
                                                {errors.firstName.message}
                                            </p>
                                        )}
                                    </Grid>
                                    <Grid item xs={5} md={5} lg={5}>
                                        <Typography sx={styles.label}>{t("lastName")}</Typography>

                                        <KTextField
                                            validation={register("lastName", {
                                                required: t("requiredField"),
                                            })}
                                            defaultValue={watch("lastName")}
                                            fullWidth
                                            placeholder="Doe"
                                        />
                                        {errors.lastName && (
                                            <p style={styles.errorLabel}>{errors.lastName.message}</p>
                                        )}
                                    </Grid>
                                </Grid>
                            ) : (
                                <Box>
                                    <Typography sx={styles.label}>{t("companyName")}</Typography>

                                    <KTextField
                                        validation={register("companyName", {
                                            required: t("requiredField"),
                                        })}
                                        fullWidth
                                        placeholder="PayQin Ltd."
                                    />
                                    {errors.companyName && (
                                        <p style={styles.errorLabel}>
                                            {errors.companyName.message}
                                        </p>
                                    )}
                                </Box>
                            )}
                            <Spacing marginBottom={"20px"}/>

                            <Box>
                                <Typography sx={styles.label}>Email</Typography>

                                <KTextField
                                    validation={register("email")}
                                    fullWidth
                                    placeholder="johndoe@email.com"
                                />
                                {errors.email && (
                                    <p style={styles.errorLabel}>{errors.email.message}</p>
                                )}
                            </Box>
                            <Spacing marginBottom={"20px"}/>
                            <Grid container columns={12} spacing={4}>
                                <Grid item xs={5} md={5} lg={5}>
                                    <Typography sx={styles.label}>
                                        {t("currency")}
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Controller
                                            name="currency"
                                            control={control}
                                            shouldUnregister={false}
                                            rules={{required: t("requiredField")}}
                                            render={({field}) => (
                                                <Select
                                                    {...field}
                                                    value={selectedCurrency ?? ""}
                                                    onChange={handleCurrencyChange}
                                                    sx={{
                                                        sx: {...rootStyles},
                                                    }}
                                                    inputProps={{
                                                        sx: {...inputStyles},
                                                    }}
                                                >
                                                    {countries.map((country) => (
                                                        <MenuItem
                                                            key={country._id}
                                                            value={country.name}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    fontSize: "16px",
                                                                }}
                                                            >
                                                                <img
                                                                    src={country.flag}
                                                                    alt=""
                                                                    style={{marginRight: "8px", width: "20px"}}
                                                                />
                                                                {country.currency}
                                                            </Box>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        <Typography sx={styles.errorLabel}>
                                            {errors.country && errors.country.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={7} md={7} lg={7}>
                                    <Typography sx={styles.label}>{t("country")}</Typography>
                                    <FormControl fullWidth>
                                        <Controller
                                            name="country"
                                            control={control}
                                            shouldUnregister={false}
                                            rules={{required: t("selectCountryError")}}
                                            render={({field}) => (
                                                <Select
                                                    {...field}
                                                    {...register("country")}
                                                    value={selectedCountry ?? ""}
                                                    onChange={handleCountryChange}
                                                    sx={{
                                                        sx: {...rootStyles},
                                                    }}
                                                    inputProps={{
                                                        sx: {...inputStyles},
                                                    }}
                                                >
                                                    {/* {countryService.transferCountries.map(
                            (country: ICountry) => (
                              <MenuItem key={country.code} value={country.code}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "16px",
                                  }}
                                >
                                  <img
                                    src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                                    alt=""
                                    style={{ marginRight: "8px" }}
                                  />
                                  {country.label}
                                </Box>
                              </MenuItem>
                            )
                          )} */}

                                                    {countries.length > 0 &&
                                                    countries.map((country: ICountry) => {
                                                        const designation = language == 'fr' ?
                                                            country.designation.fr :
                                                            country.designation.en
                                                        return <MenuItem
                                                            key={country._id}
                                                            value={country.name}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    fontSize: "16px",
                                                                }}
                                                            >
                                                                <img
                                                                    src={country.flag}
                                                                    alt={country.name}
                                                                    style={{marginRight: "8px", width: "20px"}}
                                                                />
                                                                {designation}

                                                            </Box>
                                                        </MenuItem>
                                                    })}
                                                </Select>
                                            )}
                                        />
                                        <Typography sx={styles.errorLabel}>
                                            {errors.country && errors.country.message}
                                        </Typography>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Spacing marginBottom={"20px"}/>

                            <Spacing marginBottom={"20px"}/>
                            <Typography sx={styles.label}>{t("phoneNumber")}</Typography>
                            <Grid container columns={12} spacing={4}>
                                <Grid item xs={5} md={5} lg={5}>
                                    <CountrySelect
                                        label=""
                                        data={countries}
                                        {...register("countryCode", {
                                            required: t("physicalCards.countryCodeError"),
                                        })}
                                        defaultValue={watch("countryCode")}
                                    />
                                    {errors.countryCode && (
                                        <p style={styles.errorLabel}>{errors.countryCode.message}</p>
                                    )}
                                </Grid>
                                <Grid item xs={7} md={7} lg={7}>
                                    <KTextField
                                        validation={register("phoneNumber", {
                                            required: t("physicalCards.phoneNumberError"),
                                            pattern: {
                                                value: /^(?=[0-9]).{8,12}$/,
                                                message: t("physicalCards.phonePatternError"),
                                            },
                                        })}
                                        fullWidth
                                        placeholder="XXXXXXXXXXX"
                                    />
                                    {errors.phoneNumber && (
                                        <p style={styles.errorLabel}>
                                            {errors.phoneNumber.message}
                                        </p>
                                    )}
                                </Grid>
                            </Grid>
                            <Spacing marginBottom={"20px"}/>
                            <Box>
                                <Typography sx={styles.label}>
                                    {t("withdrawScreen.beneficiaryAddress")}
                                </Typography>
                                <KTextField
                                    validation={register("address")}
                                    fullWidth
                                    placeholder="Ex: Boulevard De Gaulle, Street E-24, Abidjan, Ivory Coast"
                                />
                                {errors.address && (
                                    <p style={styles.errorLabel}>{errors.address.message}</p>
                                )}
                            </Box>
                        </>
                    )}
                </Collapse>
            </TransitionGroup>
        </Box>
    );
};

export default PersonalInfoForm;

/** @type{import("@mui/material").SxProps}*/
const styles = {
    titleField: {
        fontWeight: 700,
        fontSize: "16px",
        color: "#000000",
        marginBottom: 5,
    },
    input: {backgroundColor: "rgb(249, 249, 249)", borderRadius: "8px"},
    label: {
        fontWeight: 500,
        fontSize: "13px",
        color: "#959595",
        marginBottom: "1%",
    },
    errorLabel: {
        color: "red",
        fontSize: "14px",
    },
    addCardButton: {
        display: "flex",
        justifyContent: "space-around",
    },
};
