import React, {useState} from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";
import Spacing from "../../../../components/Spacing";
import {AddNewCardlinearGradientIcon, CheckIcon} from "../../../../shared/_utils/constants/icon";
import RoundGradientButton from "../../../../components/RoundGradientButton";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";

const GetStared = () => {
    const navigate = useNavigate();

    const [isRoundGradientButtonClicked, setIsRoundGradientButtonClicked] = useState(false);

    const handleGetStartedButton = () => {
        setIsRoundGradientButtonClicked(true);
        navigate("/cards/get-started/complete-form");
    }

    return (
        <Box>
            <Box sx={styles.container}>
                <Typography sx={{fontSize: "1.25rem", fontWeight: 700, display: "flex", justifyContent: 'center', margin: "auto"}}>
                    {t('orderNewPhysicalCard')}
                </Typography>
                <Spacing marginBottom={"50px"}/>
                <Box component={"img"} src={"https://res.cloudinary.com/payqin-ltd/image/upload/v1699461187/web-app/carte_payqin_16_avril_Plan_de_travail_1_vbmrxp.png"}
                     style={{width: "60%", transform: "rotate(-10deg)", display: "flex", margin: "auto"}}>
                </Box>
                <Spacing marginBottom={"50px"}/>
                <Typography sx={{fontSize: '20px', fontWeight: 700, marginBottom: 2}}>
                    {t('physicalCardBenefits')}
                </Typography>
                <Typography sx={{fontSize: "14px", fontWeight: 400, marginBottom: 2}}>
                    {t('physicalCardBenefitsSubTitle')}
                </Typography>
                <Box sx={{display: 'flex', gap: 2}}>
                    <Box>
                        <CheckIcon/>
                    </Box>
                    <Typography sx={{fontSize: "14px", fontWeight: 400}}>
                        {t('physicalCardBenefitsOne')}
                    </Typography>
                </Box>
                <Spacing marginBottom={"20px"}/>
                <Box sx={{display: 'flex', gap: 2}}>
                    <Box>
                        <CheckIcon/>
                    </Box>
                    <Typography sx={{fontSize: "14px", fontWeight: 400}}>

                        {t('physicalCardBenefitsTwo')} <br/>
                        {t('countryPayQin')}
                    </Typography>
                </Box>
                <Box mt={5}>
                    <RoundGradientButton fullWidth onClick={handleGetStartedButton} variant="contained" color="inherit">
                        <Typography>
                            {t('getStarted')}
                        </Typography>
                    </RoundGradientButton>
                </Box>
            </Box>
        </Box>
    );
};

export default GetStared;


/** @type{import("@mui/material").SxProps}*/
const styles =
    {
        addCardButton: {
            display: 'flex',
            justifyContent: 'space-around'
        },
        container: {
            width: {
                'sm':
                    '70%', 'md':
                    '60%', 'lg':
                    '40%'
            }
            ,
            margin: "auto"
        }
    }

