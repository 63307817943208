import {httpService} from "./http.service";
import {IBusinessRequestDto} from "../../containers/Business/Request";

const checkPendingRetailerRequest = () => {
    return httpService.Axios.get('/retailer/check-pending-request')
}

const accountVerification = () => {
    return httpService.Axios.get('/retailer/verified')
}

const registrationRequest = (businessRequestDto: IBusinessRequestDto) => {
    return httpService.Axios.post('/retailer/register', businessRequestDto)
}

export const businessService = {checkPendingRetailerRequest, accountVerification, registrationRequest}