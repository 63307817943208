import React from "react";
import {Avatar, Box, Card, CardContent, Typography} from "@mui/material";
import IPropsContactItem from "../Common/types/IPropsContactItem";

const ContactCard = (props: IPropsContactItem) => {
  const { onClickCard, recipient } = props;

  const handleCardClick = () => {
    onClickCard();
  };

  return (
    <Card sx={styles.container} onClick={handleCardClick}>
      <CardContent>
        <Box sx={styles.infos}>
          <Avatar sx={styles.avatar} src={recipient?.picture || ""} />
          <Typography sx={styles.userName}>
            {recipient?.lastName || "-"} {recipient?.firstName || "-"}
          </Typography>
          <Typography sx={styles.phoneNumber}>
            {recipient.phoneNumber ?? "--"}
          </Typography>
        </Box>
        <Box sx={styles.accountBox}>
          <Avatar
            sx={styles.account}
            alt="Masoud"
            src="https://res.cloudinary.com/payqin-ltd/image/upload/v1688577749/web-app/Top-up_s9clhm.png"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default ContactCard;

/**
 * @type {import("@mui/material").SxProps}
 */
const styles = {
  account: {
    alignContent: "flex-end",
    position: "relative",
    right: 0,
    width: "32px",
    height: "32px",
  },
  accountBox: {
    position: "absolute",
    right: "5px",
    top: "5px",
    margin: { lg: "20px", md: "5px" },
  },
  infos: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flex: 4,
    // position: "relative",
    // padding: {
    //     'xs': '10px', 'md': '0px'
    // },
    // left: 50
  },
  avatar: {
    // flex: 2,
    // top: '20px',
    width: { md: "64px", xs: "50px" },
    height: { md: "64px", xs: "50px" },
  },
  phoneNumber: {
    m: 1,
    fontWeight: 400,
    color: "#959595",
    fontSize: "1rem",
  },
  userName: {
    mt: 1,
    fontWeight: 700,
    color: "#001021",
    fontSize: "16px",
    textTransform: "none",
  },
  container: {
    mr: 2,
    // padding: {'md': '50px', 'sx': '200px'},
    border: "1px solid rgba(199, 199, 199, 1)",
    borderRadius: "8px",
    position: "relative",
    minWidth: { lg: "266px", md: "200px", sm: "150px" },
    minHeight: { lg: "188px", md: "144px", sm: "110px" },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
};
