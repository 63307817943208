import {AxiosError, AxiosResponse, isAxiosError} from "axios";
import {t} from "i18next";
import {IApiResponse} from "../../../types/IApiResponse";
import toast from "react-hot-toast";
import {IDataResponse} from "../../../types/IDataResponse";
import {red} from "@mui/material/colors";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {Box, Button, Divider, IconButton} from "@mui/material";
import {CloseIcon} from "../../constants/icon";

export function handleAPICommonResp(payload: IDataResponse) {
    const {success, error, errorMessage, message, statusText} = payload;

    let displayMessage = errorMessage || statusText || message;

    if (!success || error) {
        if (displayMessage == null) {
            displayMessage = t("errorOccurred");
        }

        throw displayMessage;
    }
}

export function displayError(exception: any): IApiResponse<null> {
    const resp = {} as IApiResponse<null>;

    if (isAxiosError(exception)) {
        const axiosError = exception as AxiosError;

        if (axiosError.response) {
            const axiosResponse: AxiosResponse = axiosError.response;
            const {data, status, statusText} = axiosResponse;
            resp.error = data.errorMessage || data.message || data.statusText || axiosError.message;
            resp.statusCode = status
            resp.data = null
        } else {
            resp.data = null
            resp.error = axiosError.message;
        }
    } else {
        resp.data = null
        resp.error = exception.toString() ?? t("errorOccurred");
    }

    if (resp.error)
        toast.error(t(resp.error))

    return resp;
}
