import React, {useEffect, useState} from "react";
import { IBankRecipientData } from "./index";
import Spacing from "../../../components/Spacing";
import { Card, CardContent, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { countryService } from "../../../shared/services";
import { t } from "i18next";
import {displayError} from "../../../shared/_utils/_helpers/error";
import axios from "axios";

const ReviewAdd: React.FC<{ data: IBankRecipientData }> = (formData) => {

  return (
    <Box sx={styles.container}>
      <Card>
        <CardContent>
          <Spacing marginBottom={"10px"} />

          <Typography sx={styles.title}>
            {t("withdrawScreen.beneficiaryBankDetails")}
          </Typography>
          <Spacing marginBottom={"10px"} />
          <Box sx={styles.row}>
            <Typography sx={styles.label}>
              {t("withdrawScreen.bankName")}{" "}
            </Typography>
            <Typography sx={styles.data}>{formData.data.bankName}</Typography>
          </Box>
          <Box sx={styles.row}>
            <Typography sx={styles.label}>
              {t("withdrawScreen.accountNumber")}{" "}
            </Typography>
            <Typography sx={styles.data}>
              {formData.data.accountNumber}
            </Typography>
          </Box>
          <Box sx={styles.row}>
            <Typography sx={styles.label}>
              {t("withdrawScreen.swiftCode")}
            </Typography>
            <Typography sx={styles.data}>{formData.data.swiftCode}</Typography>
          </Box>
          <hr />
          <Typography sx={styles.title}>
            {t("withdrawScreen.beneficiaryDetails")}
          </Typography>
          <Spacing marginBottom={"10px"} />
          <Box sx={styles.row}>
            <Typography sx={styles.label}>Email</Typography>
            <Typography sx={styles.data}>{formData.data.email}</Typography>
          </Box>
          <Box sx={styles.row}>
            <Typography sx={styles.label}>
              {t("withdrawScreen.beneficiaryName")}
            </Typography>
            <Typography sx={styles.data}>
              {formData.data.firstName || formData.data.companyName}{" "}
              {formData.data.lastName}
            </Typography>
          </Box>
          <Box sx={styles.row}>
            <Typography sx={styles.label}> {t("phoneNumber")} </Typography>
            <Typography sx={styles.data}>
              {formData.data.countryCode} {formData.data.phoneNumber}
            </Typography>
          </Box>
          <Box sx={styles.row}>
            <Typography sx={styles.label}>{t("country")}</Typography>
            <Box sx={{display: "flex"}}>
              <Box
                  component={"img"}
                  src={formData.data.icountryFlag.flag}
                  style={{width: "40px"}}
              />
              <Typography sx={{...styles.data, ...{marginLeft: 1, marginRight: 1}}}>
                {localStorage.getItem('language') == 'fr' ?
                    formData.data.icountryFlag.designation.fr :
                    formData.data.icountryFlag.designation.en}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.row}>
            <Typography sx={styles.label}>{t("currency")}</Typography>
            <Typography sx={styles.data}>
              {formData.data.currency}
            </Typography>
          </Box>
          <Box sx={styles.row}>
            <Typography sx={styles.label}>
              {t("withdrawScreen.beneficiaryAddress")}
            </Typography>
            <Typography sx={styles.data}>{formData.data.address}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ReviewAdd;

/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
  data: {
    fontWeight: 400,
    fontSize: "1rem",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    mt: 2,
    mb: 2,
    ml: 1,
    mr: 1,
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: "700",
    display: "flex",
    justifyContent: "start",
  },
  label: {
    fontWeight: 500,
    fontSize: "13px",
    color: "#959595",
    textTransform: "none",
  },
  container: { borderRadius: "8px" },
};
