import {virtualCardsService} from "../../../shared/services/virtualsCards.service";
import {ICardsIdsResponse, IVirtualCard} from "../models/virutalCards.response";
import {cardsService} from "../../../shared/services/cards.service";
import {ITransactions} from "../../Wallet/models/transactionsResponse";
import {IApiResponse} from "../../../shared/types/IApiResponse";
import {ITransactionsVirtualCardFees} from "../../../shared/types/ITransactionsVirtualCardFees";
import {AxiosError, AxiosResponse, isAxiosError} from "axios";
import {IDataResponse} from "../../../shared/types/IDataResponse";

const withDraw = async (cardId: string, amount: string): Promise<IApiResponse<IDataResponse|null>> => {
    try {
        const {data, status, statusText} = await virtualCardsService.withDraw(cardId, amount);

        return Promise.resolve({
            data: data as IDataResponse,
            error: null,
        });
    } catch(error) {
        if (isAxiosError(error)) {
            const axiosError = error as AxiosError;
            const axiosResponse: AxiosResponse = axiosError.response!;

            const {data, status, statusText} = axiosResponse;

            return {
                data: null,
                error: data.errorMessage ?? data.statusText ?? data.message ?? data.statusText,
            };
        } else {
            return {
                data: null,
                error: 'An error occurred',
            };
        }
    }
}

const topUp = async (cardId: string, amount: string): Promise<IApiResponse<IDataResponse|null>> => {
    try {
        const {data, status, statusText} = await virtualCardsService.topUp(cardId, amount);

        return Promise.resolve({
            data: data as IDataResponse,
            error: null,
        });
    } catch(error) {
        if (isAxiosError(error)) {
            const axiosError = error as AxiosError;
            const axiosResponse: AxiosResponse = axiosError.response!;

            const {data, status, statusText} = axiosResponse;

            return {
                data: null,
                error: data.errorMessage ?? data.statusText ?? data.message ?? data.statusText,
            };
        } else {
            return {
                data: null,
                error: 'An error occurred',
            };
        }
    }
}

const getFundDetails = async (amount: string, isFunding: boolean = true): Promise<IApiResponse<ITransactionsVirtualCardFees|null>> => {
    try {
        const {data, status, statusText} = await virtualCardsService.getFundDetails(amount, isFunding);

        return Promise.resolve({
            data: data as ITransactionsVirtualCardFees,
            error: null,
        });
    } catch(error) {
        if (isAxiosError(error)) {
            const axiosError = error as AxiosError;
            const axiosResponse: AxiosResponse = axiosError.response!;

            const {data, status, statusText} = axiosResponse;

            return {
                data: null,
                error: data.errorMessage ?? data.statusText ?? data.message ?? data.statusText,
            };
        } else {
            return {
                data: null,
                error: 'An error occurred',
            };
        }
    }
}

const getCardTransactions = async (cardId: string): Promise<IApiResponse<ITransactions|null>> => {
    try {
        const {data, status, statusText} = await virtualCardsService.getCardTransactions(cardId);
        return Promise.resolve({
            data: data as ITransactions,
            error: null,
        });
    } catch (error) {
        return Promise.resolve({
            data: null,
            error: 'impossible de recuperer la liste des cartes',
        });
    }
}

const cardDetails = async (cardId: string): Promise<IApiResponse<IVirtualCard|null>> => {
    try {
        const {data, status, statusText} = await virtualCardsService.getCardDetails(cardId);
        return Promise.resolve({
            data: data as IVirtualCard,
            error: null,
        });
    } catch (error) {
        return Promise.resolve({
            data: null,
            error: 'impossible de recuperer la liste des cartes',
        });
    }
}

const cardsIds = async (): Promise<IApiResponse<ICardsIdsResponse|null>> => {
    try {
        const {data, status, statusText} = await virtualCardsService.getCardsId();
        return Promise.resolve({
            data: data as ICardsIdsResponse,
            error: null,
        });
    } catch (error) {
        return Promise.resolve({
            data: null,
            error: 'impossible de recuperer la liste des cartes',
        });
    }
}

export const virtualCardsRepository = {cardsIds, cardDetails, getCardTransactions, getFundDetails, topUp, withDraw}