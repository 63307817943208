import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Spacing from "../../../../../components/Spacing";
import { IUseFormProps } from "../../../../../shared/types/IUseFormsProps";
import { IBankTransferData } from "../index";
import InputFilesForm from "./InputFilesForm";
import AmountForm from "./AmountForm";
import { t } from "i18next";

const DocumentForm: React.FC<IUseFormProps<IBankTransferData>> = ({
  register,
  isValid,
  errors,
  control,
}) => {
  // // const [filename, setFilename] = useState<[]>([]);
  // const [uploadedFiles, setUploadedFiles] = useState([])
  //
  // const handleUploadFiles = (e: ChangeEvent<HTMLInputElement>) => {

  // const [filename, setFilename] = useState<[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [fileLimit, setFileLimit] = useState(false);
  const MAX_COUNT = 3;

  const handleUploadFiles = (files: File[]) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;

    const res = files.map((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(
            t("recipients.uploaddocsThirdError").replace(
              "<:max>",
              `${MAX_COUNT}`
            )
          );
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });

    // return uploaded;

    if (!limitExceeded) return uploaded;
  };

  const handleFileEvent = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Spacing marginBottom={"10px"} />
      {/* <Typography sx={styles.label}>
        {t("recipients.uploadDocsGuide")}
      </Typography>
      <Typography sx={styles.label}>
        1. {t("recipients.uploadDocsFirst")}
      </Typography>
      <Typography sx={styles.label}>
        2. {t("recipients.uploadDocsSecond")}
      </Typography>*/}
      <Typography sx={styles.label}>
        {t("recipients.uploadInstructions")}
      </Typography>

      <InputFilesForm control={control} />
    </Box>
  );
};

export default DocumentForm;

/** @type{import("@mui/material").SxProps}*/
const styles = {
  titleField: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#000000",
    marginBottom: 5,
  },
  input: { backgroundColor: "rgb(249, 249, 249)", borderRadius: "8px" },
  label: {
    fontWeight: 500,
    fontSize: "15px",
    color: "#959595",
    marginBottom: "2%",
  },
  addCardButton: {
    display: "flex",
    justifyContent: "space-around",
  },
};
