import {IconButton, Toolbar, Tooltip, Typography} from '@mui/material';
import React from 'react';
import {alpha} from "@mui/material/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import {t} from "i18next";

interface EnhancedTableToolbarProps {
    numSelected: number;
    handleDeleteBeneficiary?: () => void,
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const { numSelected, handleDeleteBeneficiary } = props;

    const _handleDeleteBeneficiary = async () => {
        if (handleDeleteBeneficiary) {
            handleDeleteBeneficiary()
        }
    }

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} {t('selected')}
                </Typography>
                <Tooltip title="Delete">
                    <IconButton onClick={_handleDeleteBeneficiary}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
        </Toolbar>
    );
}

export default EnhancedTableToolbar;
