import React from "react";
import Button from "@mui/material/Button";
import { HelpRounded } from "@mui/icons-material";
import { AlertIcon } from "../../../../../shared/_utils/constants/icon";
import { t } from "i18next";

interface HelpButtonProps {
  onClick: () => void;
}

const HelpButton: React.FC<HelpButtonProps> = ({ onClick }) => {
  return (
    <Button
      variant="text"
      color="primary"
      onClick={onClick}
      style={{ display: "flex", justifyContent: "flex-end", color: "#4696F7" }}
      startIcon={<AlertIcon color={"#4696F7"} />}
    >
      {t("instructions")}
    </Button>
  );
};

export default HelpButton;
