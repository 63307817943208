import React, {useEffect, useRef, useState} from 'react';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {Box, CircularProgress, Typography} from "@mui/material";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";

const WaveBasketResponse = () => {

    const queryParams = new URLSearchParams(location.search);
    const result = queryParams.get('result')
    const [content, setContent] = useState<JSX.Element>();
    const navigate = useNavigate();

    useEffect(() => {
        if (result && result == 'success') {
            setContent(
                <Box>
                    <CheckCircleIcon style={{...styles.zoomingIcon, ...{filter: "drop-shadow(0 0 20px #1b5e20)"}}} color={"success"} fontSize={"large"}/>
                    <Typography fontSize={"1.5rem"}>
                        {t("operationCompletedSuccessfully")}
                    </Typography>
                </Box>
            )
        } else if (result && result == 'failed') {
            setContent(
                <Box>
                    <CancelIcon style={{...styles.zoomingIcon, ...{filter: "drop-shadow(0 0 20px red)"}}} color={"error"} fontSize={"large"}/>
                    <Typography fontSize={"1.5rem"}>
                        {t("operationFailed")}
                    </Typography>
                </Box>
            )
        }
    }, [result]);

    const goToWalletScreenFn = () => {
        navigate("/")
    }

    return (
        <Box sx={styles.container}>
            {content &&
            <Box sx={{
                margin: "auto",
                position: "absolute",
                top: '35%',
                textAlign: "center"
            }}>
                {content}
            </Box>
            }
        </Box>
    );
};

export default WaveBasketResponse;

/**
 * @type
    {
        import("@mui/material").SxProps
    }
 */
const styles = {
    zoomingIcon: {
        fontSize: "100",
        animation: "$zoomInAndOut 3s infinite",
    },
    "@keyframes zoomInAndOut": {
        "0%": {
            transform: "scale(1)",
        },
        "50%": {
            transform: "scale(2)",
        },
        "100%": {
            transform: "scale(1)",
        },
    },
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: {
            sm: "70%",
            md: "60%",
            lg: "40%",
        },
        margin: "auto",
    },
};
