import React from 'react';
import {Box} from "@mui/material";
import {CardManagementIndex} from "../Cards";

interface IKTabPanelProps {
    children: JSX.Element,
    value: CardManagementIndex,
    index: CardManagementIndex
}
const KTabPanel = ({ children, value, index }: IKTabPanelProps) => {
    return (
        <div hidden={value !== index}>
            {value === index && <Box p={0}>{children}</Box>}
        </div>
    );
};

export default KTabPanel;
