import { IApiResponse } from "../../../shared/types/IApiResponse";
import {
  IBankListData,
  IBankListResponse,
  ICountriesResponse,
  IRecipientsResponse,
} from "../../../shared/types/IRecipient";
import { AxiosError, AxiosResponse, isAxiosError } from "axios";
import { recipientsService } from "../../../shared/services/recipients.service";
import { IDataResponse } from "../../../shared/types/IDataResponse";
import { IBankRecipientDto } from "../BankRecipient";
import { IUsersResponse } from "../models/user.response";
import { userService } from "../../../shared/services";
import { IUser } from "../../Auth/models/auth.response";
import {displayError, handleAPICommonResp} from "../../../shared/_utils/_helpers/error";

const deleteBeneficiaries = async (beneficiariesIds: string): Promise<IApiResponse<IDataResponse | null>> => {
  try {
    const { data, status, statusText } = await recipientsService.deleteBeneficiaries(beneficiariesIds);
    handleAPICommonResp(data)
    return Promise.resolve({
      data: data as IDataResponse,
      error: null,
    });
  } catch (error) {
    return displayError(error)
  }
};

const createOrUpdateBeneficiary = async (recipientDto: IBankRecipientDto, beneficiaryId?: string): Promise<IApiResponse<IDataResponse | null>> => {
  try {
    const { data, status, statusText } = beneficiaryId ? await recipientsService.updateBeneficiary(recipientDto, beneficiaryId) : await recipientsService.createBeneficiary(recipientDto);
    handleAPICommonResp(data)
    return Promise.resolve({
      data: data as IDataResponse,
      error: null,
    });
  } catch (error) {
    return displayError(error)
  }
};

const searchUser = async (
  phoneNumber: string
): Promise<IApiResponse<IUser[] | null>> => {
  try {
    const { data, status, statusText } = await userService.searchUser(
      phoneNumber
    );

    return Promise.resolve({
      data: data.result as IUser[],
      error: null,
    } as IUsersResponse);
  } catch (error) {
    return Promise.resolve({
      data: null,
      error:
        "Une erreur est survenue lors de la verification si l'utilisateur est PayQin",
    } as IUsersResponse);
  }
};

const addBankRecipient = async (
  bankRecipientDto: IBankRecipientDto
): Promise<IApiResponse<IDataResponse | null>> => {
  try {
    const { data, status, statusText } =
      await recipientsService.addBankRecipient(bankRecipientDto);
    return Promise.resolve({
      data: data as IDataResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;
        return Promise.resolve({
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        });
      } else {
        const message = axiosError.message;
        return Promise.resolve({
          data: null,
          error: message,
        });
      }
    } else {
      return Promise.resolve({
        data: null,
        error: "An error occurred",
      });
    }
  }
};

const getBankCodes = async (): Promise<
  IApiResponse<IBankListResponse | null>
> => {
  try {
    const { data, status, statusText } = await recipientsService.getBankCodes();
    return Promise.resolve({
      data: data as IBankListResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;
        return Promise.resolve({
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        });
      } else {
        const message = axiosError.message;
        return Promise.resolve({
          data: null,
          error: message,
        });
      }
    } else {
      return Promise.resolve({
        data: null,
        error: "An error occurred",
      });
    }
  }
};

const getRecipients = async (): Promise<
  IApiResponse<IRecipientsResponse | null>
> => {
  try {
    const { data, status, statusText } =
      await recipientsService.getRecipients();
    return Promise.resolve({
      data: data as IRecipientsResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;
        return Promise.resolve({
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        });
      } else {
        const message = axiosError.message;
        return Promise.resolve({
          data: null,
          error: message,
        });
      }
    } else {
      return Promise.resolve({
        data: null,
        error: "An error occurred",
      });
    }
  }
};

const getAvailableCountriesForBankTransfer = async (): Promise<
  IApiResponse<ICountriesResponse | null>
> => {
  try {
    const { data, status, statusText } =
      await recipientsService.getAvailableCountriesForBankTransfer();
    return Promise.resolve({
      data: data as ICountriesResponse,
      error: null,
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const axiosResponse: AxiosResponse = axiosError.response;
        const { data, status, statusText } = axiosResponse;
        return Promise.resolve({
          data: null,
          error:
            data.errorMessage ??
            data.statusText ??
            data.message ??
            data.statusText,
        });
      } else {
        const message = axiosError.message;
        return Promise.resolve({
          data: null,
          error: message,
        });
      }
    } else {
      return Promise.resolve({
        data: null,
        error: "An error occurred",
      });
    }
  }
};

export const recipientsRepository = {
  deleteBeneficiaries,
  createOrUpdateBeneficiary,
  getRecipients,
  addBankRecipient,
  searchUser,
  getBankCodes,
  getAvailableCountriesForBankTransfer,
};
