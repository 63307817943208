import SubOperation from "../types/SubOperation";
import IAction from "../actions/channelOperation.action";

export interface IChannelOperationState {
    selectedChannelOperation: SubOperation | null;
}

const initialState: IChannelOperationState = {selectedChannelOperation: null};

const channelOperationReducer = (state: IChannelOperationState = initialState, action: IAction) => {
    switch (action.type) {
        case 'SET_SELECTED_CHANNEL_OPERATION':
            return action.payload;
        default:
            return initialState;
    }
};

export default channelOperationReducer;
